import * as React from 'react'
import {useCallback, useEffect, useMemo, useState} from 'react'

import {PageSetting} from "../contexts/PageContext";
import {useNavigate, useParams} from "react-router-dom";
import {
    DefaultButton,
    DialogType,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {useJob} from "../hooks/useJob";
import {JobHeadingFrag} from "../components/JobHeadingFrag";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import {FrontendComponent, usePermittedFrontendComponents} from "../hooks/usePermittedFrontendComponents";
import {ITPSingleTable} from "../components/ITPSingleTable";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {SimpleDialog} from "../components/SimpleDialog";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {useFusinaRmiUrl} from "../hooks/useFusinaRmiUrl";
import {useSecurityContext} from "../contexts/SecurityContext";
import {ITPRevPolicy} from "../../../fusina-itp/controls/ITPRevPolicy";
import {ConfirmedCalloutBtn} from "../components/ConfirmedCalloutBtn";
import {ITPRevContext} from "../contexts/ITPRevContext";
import {ITPDiff} from "../components/ITPDiff";


export const ITPRevPage: React.FC = () => {
    const {jobId, itpId, revId, editFlag} = useParams()
    const {job, jobError} = useJob(jobId)

    const {
        data: currItps,
        refresh: currItpsRefresh,
        error: currItpsError,
    } = useFusinaRmiResource('ITP', 'find', {by: 'itp', itp: {_id: itpId}})

    const currItp = useMemo(() => currItps?.[0], [currItps])

    const {
        data: rev,
        refresh: revRefresh,
        error: revError,
    } = useFusinaRmiResource('ITPRevision', 'findOne', revId)

    const [editing, setEditing] = useState<InspectionTestPlan | null>(null)

    const refreshAll = useCallback(async () => {
        await Promise.all([
            currItpsRefresh(),
            revRefresh(),
        ])
    }, [currItpsRefresh, revRefresh])

    const currRevId = currItp?.revId ?? currItp?._id
    const isRevCurrent = !!revId && revId === currRevId
    const isRevObsolete = !!revId && revId < currRevId
    const isRevPending = !!revId && revId > currRevId

    const comp = usePermittedFrontendComponents()
    const navigate = useNavigate()
    const rmi = useFusinaRmi()

    useEffect(() => {
        if (currItp && editFlag && rev?.itp && rev?._id === revId && !editing) {
            const t = setTimeout(() => {
                // Dismissing the editFlag from the page location after we can set editing the validly loaded revision.
                setEditing(JSON.parse(JSON.stringify(rev.itp)))
                navigate(`/jobs/${currItp?.job?._id}/itp/${currItp?._id}/rev/${revId}`, {replace: true})
            }, 120) // Failsafe: add delay to avoid reacting on stale data.
            return () => {
                clearTimeout(t)
            }
        }
    }, [currItp, editFlag, rev, revId, editing])

    const securityContext = useSecurityContext()
    const apply_policy = useMemo(() =>
        securityContext && job && currItp && rev ? ITPRevPolicy({
            securityContext,
            job,
            itp: currItp,
            rev,
        }) : undefined, [securityContext, job, currItp, rev])

    const [showDiff, setShowDiff] = useState<boolean>(false)

    const error = jobError ?? revError ?? currItpsError ?? revError
    const isLoading = !job || !rev || !currItp

    if (error) {
        return <MaybeErrorMessageBar error={error}/>
    }
    if (isLoading) {
        return <>
            <br/>
            <Spinner size={SpinnerSize.large}/>
        </>
    }

    return <>
        <PageSetting
            iconName="Trackers"
            title={<>
                <div style={{paddingRight: 15}}>
                    <div>Piano Controllo Qualità</div>
                </div>
                <JobHeadingFrag/>
                {comp.has(FrontendComponent.ITPCal) && <>
                    <div style={{flexGrow: 1}}>
                    </div>
                    <DefaultButton
                        style={{marginTop: 6}}
                        iconProps={{
                            iconName: "Calendar"
                        }}
                        text="Calendario"
                        href={`#/jobs/${jobId}/itpCal`}
                    />
                </>}
            </>}
            sideChildren={null}
        />
        {isRevCurrent && <MessageBar messageBarType={MessageBarType.info}>
            Questa è la versione corrente del PCQ.
        </MessageBar> ||
        <MessageBar messageBarType={isRevObsolete ? MessageBarType.severeWarning : MessageBarType.warning} actions={<>
            {apply_policy.permissions.apply && isRevPending && <>
                <ConfirmedCalloutBtn
                    useMessageBarButton
                    action={() => rmi.ITPRevision.apply(rev._id).then(refreshAll)}
                    btnProps={{
                        text: 'Applica',
                    }}
                    calloutChildren={<>Stai per confermare questa revisione in sostituzione di quella previgente.</>}
                />
                &nbsp;
            </>}
            <MessageBarButton onClick={() => {
                navigate(`/jobs/${currItp?.job?._id}/itp/${currItp?._id}`)
            }}>
                Vedi corrente
            </MessageBarButton>
            {isRevPending && <>
                &nbsp;
                <MessageBarButton onClick={() => setShowDiff(true)}>
                    Differenze
                </MessageBarButton>
            </>}
        </>}>
            Questa è una {isRevObsolete ? 'versione obsoleta' : 'revisione pendente'} del PCQ.
        </MessageBar>}
        <ITPRevContext.Provider value={{prevItp: currItp, isNotCurrent: currRevId !== revId}}>
            <ITPSingleTable  // existing rev
                itp={rev.itp}
                onEditSuccess={refreshAll}
                onDeleteSuccess={() => {
                    navigate(`/jobs/${currItp?.job?._id}/itp/${currItp?._id}`)
                }}
                onSignSuccess={refreshAll}
                onActionFinish={refreshAll}
                onEditRequested={() => setEditing(JSON.parse(JSON.stringify(rev.itp)))}
                deleteAction={() => rmi.ITPRevision.delete(rev._id)}
                rejectAction={async (comment) => {
                    await rmi.ITPRevision.reject(rev._id, comment || null)
                    navigate(`/jobs/${currItp?.job?._id}/itp/${currItp?._id}`)
                    throw new Error('Visualization closed (actually not an error).')
                }}
                signAction={(signatureRole) => rmi.ITPRevision.sign(rev._id, signatureRole, !apply_policy.hasRoleForApply)
                    .then(async () => {
                        if (apply_policy.hasRoleForApply) {
                            await rmi.ITPRevision.apply(rev._id)
                        }
                        await refreshAll()
                    })}
                signatureUrlGetter={signature => useFusinaRmiUrl('ITPRevision', 'findSignaturePdf', rev.itp._id, signature._id, rev._id)}
            />
        </ITPRevContext.Provider>

        {editing && <SimpleDialog
            type={DialogType.largeHeader}
            title="Modifica revisione PCQ"
            subText=""
            noMaxWidth
            hidden={!editing}
        >
            <ITPRevContext.Provider value={{prevItp: currItp, isNotCurrent: currRevId !== revId}}>
                <ITPSingleTable // editable rev for update
                    itp={editing}
                    editable
                    onEditDismiss={() => {
                        setEditing(null)
                    }}
                    onEditSuccess={() => {
                        setEditing(null)
                        refreshAll().then()
                    }}
                    onSignSuccess={refreshAll}
                    onActionFinish={refreshAll}
                    updateAction={() => rmi.ITPRevision.update({_id: rev._id, itp: editing})}
                />
            </ITPRevContext.Provider>
        </SimpleDialog>}

        {showDiff && <SimpleDialog
            type={DialogType.close}
            veryEasyDismiss
            title="Differenze revisione PCQ"
            subText=""
            // noMaxWidth
            hidden={!showDiff}
            onDismiss={() => setShowDiff(false)}
        >
            <ITPDiff before={currItp} after={rev.itp}/>
        </SimpleDialog>}
    </>
}
