import * as React from 'react'
import {createContext, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {
    CommandBar,
    IContextualMenuItem,
    List,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    ProgressIndicator,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField,
    useTheme
} from '@fluentui/react'
import {PageSetting} from "../contexts/PageContext";
import {
    AuditLog,
    AuditLogEnvelope,
    EcoOdsEstimateSignedAuditLog,
    ITPCertOpAuditLog,
    ITPChownAuditLog,
    ITPEventAttachmentOpAuditLog,
    ITPEventOpAuditLog,
    ITPOpAuditLog,
    LetterDraftOpAuditLog,
    LetterSentAuditLog,
    LetterSignedAuditLog,
    MainApiServerReqAuditLog,
    MainApiServerResAuditLog,
    SystemAuditLog,
    UserAdminWriteAuditLog
} from "../../../fusina-audit/entities/AuditLog";
import {useNavigate} from "react-router-dom";
import {useFilteredAuditLogs} from "../hooks/useFilteredAuditLogs";

const today = new Date().toISOString().split('T')[0]

const AdminAuditCtx = createContext<{
    setFilter?: React.Dispatch<React.SetStateAction<string>>
}>({})

export const AdminAudit: React.FC = () => {
    const [filter, setFilter] = useState(`select(.t > "${today}")`)

    const {
        rows,
        error,
        pending,
        isConnected,
        isEOF,
    } = useFilteredAuditLogs(filter)

    //////////////////////////////////////////////////////////////////////////////
    // Scroll container on new rows
    const scrollableRef = useRef<HTMLDivElement>()
    const [oldScrollHeight, setOldScrollHeight] = useState<number>(0)
    useEffect(() => {
        const to = setTimeout(() => {
            const newScrollHeight = scrollableRef.current?.scrollHeight
            const scrollTopMax = oldScrollHeight - scrollableRef.current.clientHeight
            const tolerance = scrollableRef.current.clientHeight * 0.8
            try {
                if (oldScrollHeight === 0 || scrollableRef.current?.scrollTop >= scrollTopMax - tolerance) {
                    scrollableRef.current?.scrollTo?.({
                        top: newScrollHeight,
                        behavior: 'smooth',
                    })
                    scrollableRef.current?.lastElementChild?.scrollIntoView?.({
                        block: 'end',
                        behavior: 'smooth'
                    })
                } else {
                    scrollableRef.current?.scrollBy?.({top: 1, behavior: 'smooth'})
                }
            } finally {
                if (oldScrollHeight !== newScrollHeight) {
                    // NOTE: keep reporting zero height while there are no rows (so we can scroll to bottom on rows)
                    setOldScrollHeight(rows?.length > 0 ? newScrollHeight : 0)
                }
            }
        }, 10)
        return () => {
            clearTimeout(to)
        }
    }, [rows, scrollableRef, oldScrollHeight])

    //////////////////////////////////////////////////////////////////////////////
    // Pure UI stuff
    const [isFilterShown, setIsFilterShown] = useState<boolean>(false)
    const theme = useTheme()

    console.debug('AuditAdmin render', rows?.length, 'rows')

    return <>
        <PageSetting title="Audit"/>

        <CommandBar
            items={[
                {
                    key: 'logins',
                    text: 'Login di oggi',
                    onClick: () => setFilter(`select(.m.type=="Login")  | select(.t > "${today}")`)
                },
                {
                    key: 'signatures',
                    text: 'Firme di oggi',
                    onClick: () => setFilter(`select(.m.type=="LetterSigned")  | select(.t > "${today}")`)
                },
                {
                    key: 'signatures',
                    text: 'Messaggi di sistema',
                    onClick: () => setFilter(`select(.m.type=="System")  | select(any(.m.level==["warn","error","info"][]; .)) | select(.t > "${today}")`)
                },
                {
                    key: 'signatures',
                    text: 'Errori',
                    onClick: () => setFilter(`select(.m.type=="System") | select(.m.level=="error")  | select(.t > "${today}")`)
                },
                {
                    key: 'sse',
                    text: 'SSE',
                    onClick: () => setFilter(`select(.m.type=="System") | select(.m.level=="debug")  | select(.m.msg | startswith("SSE "))`)
                },
            ]}
            farItems={[
                // {
                //     key: 'scroll',
                //     text: isAutoScrolling ? 'Scroll: ON' : 'Scroll: OFF',
                //     onClick: () => setIsAutoScrolling(v => !v)
                // },
                {
                    key: 'jq',
                    text: 'JQ',
                    iconProps: {
                        iconName: 'FilterSettings',
                    },
                    onClick: () => setIsFilterShown(v => !v)
                },
            ]}
        />
        {isFilterShown && <Stack horizontal>
            {pending && <Spinner size={SpinnerSize.xSmall} styles={{root: {width: 24}}}/> ||
            <div style={{width: 24}}>&nbsp;</div>}
            <Stack.Item grow={1}>
                <TextField
                    value={filter}
                    onChange={((event, newValue) => setFilter(newValue))}
                />
            </Stack.Item>
            <div style={{width: 14}}>
            </div>
        </Stack>}
        {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
        <div>
            <ProgressIndicator
                percentComplete={undefined}
                styles={{root: pending ? {} : {opacity: 0}, itemProgress: {padding: 0}}}
            />
            <Stack horizontal style={{
                alignItems: 'center',
                height: '3em',
                padding: '0 0 0 24px',
                borderBottom: '1px solid ' + theme.palette.neutralLighter
            }}>
                <Text variant={"medium"} nowrap block
                      styles={{
                          root: {
                              width: 110,
                              flexShrink: '0 !important',
                              fontWeight: 500,
                              display: 'flex',
                              alignItems: 'center'
                          }
                      }}>
                    Data
                </Text>
                <Text variant={"medium"} nowrap block
                      styles={{
                          root: {
                              width: 90,
                              flexShrink: '0 !important',
                              fontWeight: 500,
                              display: 'flex',
                              alignItems: 'center'
                          }
                      }}>
                    &nbsp;&nbsp;Istanza
                </Text>
                <Text variant={"medium"} nowrap block styles={{
                    root: {
                        width: 90,
                        flexShrink: '0 !important',
                        fontWeight: 500,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }
                }}>
                    &nbsp;Messaggio
                </Text>
            </Stack>
            <AdminAuditCtx.Provider value={{setFilter}}>
                <div ref={scrollableRef} style={{
                    minHeight: '4em',
                    margin: '0 0 0 24px',
                    maxHeight: `calc(99vh - ${(scrollableRef?.current?.offsetTop ?? 250) + 22}px)`,
                    overflowY: 'auto',
                    userSelect: 'text'
                }} data-is-scrollable="true">
                    {rows?.length > 0 && <List
                        items={rows}
                        onRenderCell={(item, index) => {
                            return <AuditLogEnvelopeRowView key={index} row={item}/>
                        }}
                    />}
                    {(pending || !(isConnected || isEOF)) &&
                    <Spinner size={SpinnerSize.large} style={{margin: '20px 0'}}/>}
                    <div style={{height: '10vh'}}>
                    </div>
                </div>
            </AdminAuditCtx.Provider>
        </div>
    </>
}

const AuditLogEnvelopeRowView: React.FC<{
    row: AuditLogEnvelope
}> = props => {
    return <Stack horizontal styles={{root: {marginBottom: 2}}}>
        <Text variant={"xSmall"} nowrap block
              styles={{root: {width: 110, flexShrink: '0 !important', display: 'flex', alignItems: 'center'}}}>
            {new Date(props.row.t).toLocaleString(undefined, {
                dateStyle: 'short',
                timeStyle: 'medium',
            })}
            &nbsp;
        </Text>
        <Text variant={"xSmall"} nowrap block styles={{
            root: {
                width: 90,
                flexShrink: '0 !important',
                display: 'flex',
                alignItems: 'center',
                background: stringToColour(props.row?.s ?? ''),
                fontFamily: 'monospace',
                color: '#333',
                marginRight: 2,
                textAlign: 'center',
            }
        }}>
            &nbsp;&nbsp;{props.row.s}
            &nbsp;
        </Text>
        {props.row && <SmallErrorBoundary textGetter={() => JSON.stringify(props.row.m ?? props.row, undefined, 0)}>
            <AuditLogRowView msg={props.row.m}/>
        </SmallErrorBoundary>}
    </Stack>
}

const AuditLogRowView: React.FC<{
    msg: AuditLog
}> = props => {
    const type = props.msg.type

    if (type === 'Login') {
        return <MessageBar
            styles={{root: {maxWidth: 'calc(100% - 202px)'}}}
            messageBarType={MessageBarType.info}
            actions={<SubActionsBtn msg={props.msg}/>}
            isMultiline={false}
            delayedRender={false}
        >
            <b>Login</b>: {props.msg.email} da IP {props.msg.IP} UA {props.msg.UA}
        </MessageBar>
    }
    if (type === 'PasswordReset' && props.msg.phase === 'askResetOk') {
        return <MessageBar
            styles={{root: {maxWidth: 'calc(100% - 202px)'}}}
            messageBarType={MessageBarType.info}
            isMultiline={false}
            delayedRender={false}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            <b>Richiesta reset password</b> da {props.msg.email}
        </MessageBar>
    }
    if (type === 'PasswordReset' && props.msg.phase === 'doResetSuccess') {
        return <MessageBar
            styles={{root: {maxWidth: 'calc(100% - 202px)'}}}
            messageBarType={MessageBarType.info}
            isMultiline={false}
            delayedRender={false}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            <b>Password reimpostata</b> da {props.msg.email}
        </MessageBar>
    }
    if (type === 'PasswordReset') {
        return <MessageBar
            styles={{root: {maxWidth: 'calc(100% - 202px)'}}}
            messageBarType={MessageBarType.warning}
            isMultiline={false}
            delayedRender={false}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            <pre>{JSON.stringify(props.msg, undefined, 0)}</pre>
        </MessageBar>
    }
    if (type === 'LetterSigned') {
        const msg: LetterSignedAuditLog = props.msg
        return <MessageBar
            styles={{root: {maxWidth: 'calc(100% - 202px)'}}}
            messageBarType={MessageBarType.info}
            isMultiline={false}
            delayedRender={false}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            <b>Lettera firmata</b>
            {msg?.letterH?.type ? ` ${msg?.letterH?.type}` : ''}
            {msg?.letterH?.externalRef ? ` ${msg?.letterH?.externalRef}` : ''}
            {msg?.job?.code ? ` in ${msg?.job?.code}` : ''}
            &nbsp;da {props.msg?.signature?.userEmail}
            &nbsp;come {props.msg?.signature?.role}
        </MessageBar>
    }
    if (type === 'EcoOdsEstimateSigned') {
        const msg: EcoOdsEstimateSignedAuditLog = props.msg
        return <MessageBar
            styles={{root: {maxWidth: 'calc(100% - 202px)'}}}
            messageBarType={MessageBarType.info}
            isMultiline={false}
            delayedRender={false}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            <b>Preventivo firmato</b>:
            {msg?.letterH?.type ? ` ${msg?.letterH?.type}` : ''}
            {msg?.letterH?.externalRef ? ` ${msg?.letterH?.externalRef}` : ''}
            {msg?.job?.code ? ` in ${msg?.job?.code}` : ''}
            &nbsp;da {props.msg?.signature?.userEmail}
            &nbsp;come {props.msg?.signature?.role}
        </MessageBar>
    }
    if (type === 'LetterSent') {
        const msg: LetterSentAuditLog = props.msg
        return <MessageBar
            styles={{root: {maxWidth: 'calc(100% - 202px)'}}}
            messageBarType={MessageBarType.info}
            isMultiline={false}
            delayedRender={false}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            <b>Lettera trasmessa</b>:
            {msg?.letterH?.type ? ` ${msg?.letterH?.type}` : ''}
            {msg?.letterH?.externalRef ? ` ${msg?.letterH?.externalRef}` : ''}
            {msg?.job?.code ? ` in ${msg?.job?.code}` : ''}
            {msg?.operatingUser?.email ? ` da ${msg?.operatingUser?.email}` : ''}
        </MessageBar>
    }
    if (type === 'LetterDraftOp') {
        const msg: LetterDraftOpAuditLog = props.msg
        return <MessageBar
            styles={{root: {maxWidth: 'calc(100% - 202px)'}}}
            messageBarType={MessageBarType.info}
            isMultiline={false}
            delayedRender={false}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            <b>Lettera {{create: 'creata', delete: 'eliminata', rejectToDraft: 'rimandata in bozza'}[msg.operation]}</b>:
            {msg?.letterH?.type ? ` ${msg?.letterH?.type}` : ''}
            {msg?.letterH?.externalRef ? ` ${msg?.letterH?.externalRef}` : ''}
            {msg?.job?.code ? ` in ${msg?.job?.code}` : ''}
            {msg?.operatingUser?.email ? ` da ${msg?.operatingUser?.email}` : ''}
        </MessageBar>
    }
    if (type === 'UserAdminWrite') {
        const msg: UserAdminWriteAuditLog = props.msg
        return <MessageBar
            styles={{root: {maxWidth: 'calc(100% - 202px)'}}}
            messageBarType={MessageBarType.info}
            isMultiline={false}
            delayedRender={false}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            <b>Utente</b>
            &nbsp;{msg.subjectUser.email}
            &nbsp;{{create: 'creato', update: 'modificato'}[msg.operation]}
            &nbsp;da {msg.operatorUser.email}
        </MessageBar>
    }
    if (type === 'NotificationCreated') {
        return <MessageBar
            messageBarType={MessageBarType.info}
            isMultiline={false}
            delayedRender={false}
            styles={{root: {background: 'transparent', opacity: 0.75, maxWidth: 'calc(100% - 202px)'}}}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            Notifica creata
        </MessageBar>
    }
    if (type === 'System') {
        const msg: SystemAuditLog = props.msg
        return <MessageBar
            messageBarType={{
                'log': MessageBarType.info,
                'debug': MessageBarType.info,
                'info': MessageBarType.info,
                'warn': MessageBarType.warning,
                'error': MessageBarType.error,
            }[msg.level]}
            isMultiline={false}
            delayedRender={false}
            truncated
            styles={{
                root: (msg.level === 'log' || msg.level === 'debug') ? {
                    background: 'transparent',
                    opacity: msg.level === 'debug' ? 0.55 : 0.75,
                    maxWidth: 'calc(100% - 202px)',
                } : {
                    maxWidth: 'calc(100% - 202px)',
                },
                innerText: {whiteSpace: 'pre', fontFamily: 'monospace'}
            }}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            {msg.msg?.toString?.()}
        </MessageBar>
    }
    if (type === 'ITPOp') {
        const msg: ITPOpAuditLog = props.msg
        return <MessageBar
            styles={{root: {maxWidth: 'calc(100% - 202px)'}}}
            messageBarType={MessageBarType.info}
            isMultiline={false}
            delayedRender={false}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            <b>ITP {props.msg?.rev !== undefined ? 'revision ' : ''}{msg?.operation}</b>:
            {msg?.itp?.docN ? ` ${msg?.itp?.docN}` : ''}
            {msg?.job?.code ? ` in ${msg?.job?.code}` : ''}
            {msg?.operatingUser?.email ? ` da ${msg?.operatingUser?.email}` : ''}
            {msg?.operation === 'chown' ? ` (${(msg as ITPChownAuditLog)?.oldOwner?.email} -> ${(msg as ITPChownAuditLog)?.newOwner?.email})` : ''}
        </MessageBar>
    }
    if (type === 'ITPEventOp' || type === 'ITPCertOp') {
        const msg: ITPEventOpAuditLog | ITPCertOpAuditLog = props.msg
        return <MessageBar
            styles={{root: {maxWidth: 'calc(100% - 202px)'}}}
            messageBarType={MessageBarType.info}
            isMultiline={false}
            delayedRender={false}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            <b>
                {type === 'ITPEventOp' && 'Notifica'}
                {type === 'ITPCertOp' && 'Certificato'}
                &nbsp;PCQ: {msg?.operation}
            </b>:
            {msg?.itp?.docN ? ` ${msg?.itp?.docN}` : ''}
            {msg?.item ? ` item ${msg?.item}` : ''}
            {msg?.job?.code ? ` in ${msg?.job?.code}` : ''}
            {msg?.operatingUser?.email ? ` da ${msg?.operatingUser?.email}` : ''}
        </MessageBar>
    }
    if (type === 'ITPEventAttachmentOp') {
        const msg: ITPEventAttachmentOpAuditLog = props.msg
        return <MessageBar
            styles={{root: {maxWidth: 'calc(100% - 202px)'}}}
            messageBarType={MessageBarType.info}
            isMultiline={false}
            delayedRender={false}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            <b>Allegato a notifica PCQ: {msg.operation}</b>
            {msg?.itp?.docN ? ` ${msg?.itp?.docN}` : ''}
            {msg?.event?.itpEventN ? ` n. ${msg?.event?.itpEventN}` : ''}
            {msg?.job?.code ? ` in ${msg?.job?.code}` : ''}
            {msg?.operatingUser?.email ? ` da ${msg?.operatingUser?.email}` : ''}
        </MessageBar>
    }
    if (type === 'MainApiServerReq') {
        const msg: MainApiServerReqAuditLog = props.msg
        return <MessageBar
            isMultiline={false}
            delayedRender={false}
            truncated
            styles={{
                root: {
                    background: 'transparent',
                    opacity: 0.66,
                    maxWidth: 'calc(100% - 202px)',
                },
                innerText: {
                    fontSize: '0.8em'
                }
            }}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            Main API server &lt; request [{msg.reqId}] for <code>"{msg.reqPath}"</code> from
            window <code>[{msg.windowId}]</code> at <code>"{msg.winLocHash}"</code>.
        </MessageBar>
    }
    if (type === 'MainApiServerRes') {
        const msg: MainApiServerResAuditLog = props.msg
        return <MessageBar
            isMultiline={false}
            delayedRender={false}
            truncated
            styles={{
                root: {
                    background: 'transparent',
                    opacity: 0.66,
                    maxWidth: 'calc(100% - 202px)',
                },
                innerText: {
                    fontSize: '0.8em'
                }
            }}
            actions={<SubActionsBtn msg={props.msg}/>}
        >
            Main API server &gt; response for request [{msg.reqId}] in {Math.round(parseFloat(`${msg.elapsedMs}`))}ms
            with HTTP status code <code>{msg.httpStatus}</code>.
        </MessageBar>
    }
    const []: never[] = [type]  // Static type check
    throw new Error('Unexpected audit log type')
}

const SubActionsBtn: React.FC<{
    msg: AuditLog
}> = props => {
    const ctx = useContext(AdminAuditCtx)
    const navigate = useNavigate()

    const items = useMemo<IContextualMenuItem[]>(() => {
        const msg = props.msg as any;
        const items: IContextualMenuItem[] = [];
        const itemsPush = (text: string, onClick: () => void) => {
            items.push({key: `i_${items.length}`, text, onClick})
        }

        if (msg.letterH?._id || msg.letter) {
            itemsPush('Segui lettera', () => {
                ctx.setFilter?.(`select(.m.letterH._id=="${msg.letterH?._id ?? msg.letter}" or .m.letter=="${msg.letter ?? msg.letter}")`)
            })
            if (msg.job?._id) {
                itemsPush('Vedi lettera', () => {
                    navigate(`/jobs/${msg?.job?._id}/letters/${msg?.letterH?._id ?? msg.letter ?? ''}`)
                })
            }
        }

        if (msg?.itp?._id) {
            if (msg?.rev?._id) {
                itemsPush('Vedi revisione PCQ', () => {
                    navigate(`/jobs/${msg?.job?._id}/itp/${msg?.itp?._id}/rev/${msg?.rev?._id}`)
                })
            } else {
                itemsPush('Vedi PCQ', () => {
                    navigate(`/jobs/${msg?.job?._id}/itp/${msg?.itp?._id ?? ''}`)
                })
            }
        }

        if (msg?.itp?._id && msg?.event?._id) {
            itemsPush('Vedi notifica PCQ', () => {
                navigate(`/jobs/${msg?.job?._id}/itp/${msg?.itp?._id ?? ''}/events/${msg?.event?._id ?? ''}`)
            })
        }

        if (msg.sessionId) {
            itemsPush('Segui sessione', () => {
                ctx.setFilter?.(`select(. |= tostring | contains(${JSON.stringify(msg.sessionId)}))`)
            })
        }

        if (msg.reqId) {
            itemsPush('Segui richiesta', () => {
                ctx.setFilter?.(`select(. |= tostring | contains(${JSON.stringify(msg.reqId)}))`)
            })
        }

        if (msg.windowId) {
            itemsPush('Segui finestra', () => {
                ctx.setFilter?.(`select(. |= tostring | contains(${JSON.stringify(msg.windowId)}))`)
            })
        }

        const opUserId = msg.user ?? msg.operatingUser?._id ?? msg.operatorUser?._id
        if (opUserId) {
            itemsPush('Segui utente', () => {
                ctx.setFilter?.(`select(. |= tostring | contains(${JSON.stringify(opUserId)}))`)
            })
        }

        return items;
    }, [props.msg])

    if (!(items?.length > 0)) {
        return null
    }

    return <MessageBarButton
        menuProps={{items}}
        menuIconProps={{
            iconName: 'ChevronDownMed',
        }}
        styles={{
            root: {
                minWidth: 50,
                padding: 0,
            },
            label: {
                display: 'none',
            },
        }}
    />
};


const stringToColour = function (str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const rgb = [0, 0, 0];
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        rgb[i] = Math.floor(255 * 4 / 5 + value / 5)
    }
    return 'rgb(' + rgb.join(',') + ')';
}


/**
 * https://en.reactjs.org/docs/error-boundaries.html
 */
class SmallErrorBoundary extends React.Component<{ textGetter: () => string }, { error?: Error, hasError: boolean }> {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError(error) {
        return {
            error,
            hasError: true
        };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error)
    }

    render() {
        if (this.state.hasError) {
            return <MessageBar
                styles={{
                    root: {maxWidth: 'calc(100% - 202px)'},
                    innerText: {whiteSpace: 'pre', fontFamily: 'monospace'}
                }}
                messageBarType={MessageBarType.severeWarning}
                isMultiline={false}
                truncated
            >
                {this.props.textGetter?.()}
            </MessageBar>
        }
        return this.props.children;
    }
}
