import * as React from 'react'
import {useEffect, useMemo, useState} from 'react'
import {PageSetting} from "../contexts/PageContext";
import {makeJobsAdminPolicy} from "../../../fusina-jobs/controls/JobsAdminPolicy";
import {useSecurityContext} from "../contexts/SecurityContext";
import {
    ActionButton,
    ChoiceGroup,
    MessageBar,
    MessageBarType,
    Nav,
    SearchBox,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    useTheme
} from '@fluentui/react'
import {useNavigate, useParams} from "react-router-dom";
import {PageHeader} from "../components/PageHeader";
import './Home.css'
import {useJob} from "../hooks/useJob";
import {useJobContext} from "../contexts/JobContext";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import {MicroSpinner} from "../components/MicroSpinner";
import {FrontendComponent, usePermittedFrontendComponents} from "../hooks/usePermittedFrontendComponents";
import {RoleEnum} from "../../../fusina-authz/entities/RoleEnum";


const DEFAULT_JOB_SEARCH_LOCAL_STORAGE_KEY = 'DEFAULT_JOB_SEARCH'

function loadDefaultJobSearch(): string {
    return localStorage?.getItem?.(DEFAULT_JOB_SEARCH_LOCAL_STORAGE_KEY) ?? ''
}

function storeDefaultJobSearch(search?: string) {
    localStorage?.setItem?.(DEFAULT_JOB_SEARCH_LOCAL_STORAGE_KEY, search ?? '')
}

export const Home: React.FC = () => {
    const {jobId} = useParams()
    const sc = useSecurityContext()

    const {jobs, jobsError} = useJobContext()
    const {job, jobError, contractor, contractorError} = useJob(jobId)

    const navigate = useNavigate()
    const adminPolicy = useMemo(() => {
        return makeJobsAdminPolicy(sc)
    }, [sc])

    const [jobSearch, setJobSearch] = useState<string | null>(loadDefaultJobSearch())
    const theme = useTheme()

    const filteredJobs = useMemo(() => {
        if (!jobs) {
            return undefined
        }
        if (!jobSearch) {
            storeDefaultJobSearch(null)
            return jobs
        }
        const searchLow = jobSearch.toLowerCase()
        const filtered = jobs.filter(j => JSON.stringify([
            j.code, j.contract, j.title
        ]).toLowerCase().includes(searchLow))
        if (filtered?.length > 0) {
            storeDefaultJobSearch(jobSearch)
        }
        return filtered
    }, [jobs, jobSearch])

    const comps = usePermittedFrontendComponents()

    useEffect(() => {
        // NOTE: this is really unnecessary, but it may simplify the UX: Let's navigate the user if there's one path.
        if (jobs?.length === 1) {
            if (!sc?.user?.grants
                ?.some?.(g => g.role !== RoleEnum.JobReadAccess202310 && g.scopeType !== 'itp')
            ) {
                const itpGrants = sc?.user?.grants?.filter?.(g => g.scopeType === 'itp')
                if (itpGrants?.length === 1) {
                    navigate(`/jobs/${jobs?.[0]?._id}/itp/${itpGrants?.[0]?.scopeId}/`, {replace: true})
                } else {
                    navigate(`/jobs/${jobs?.[0]?._id}/itp/`, {replace: true})
                }
            } else {
                navigate(`/jobs/${jobs?.[0]?._id}/`, {replace: true})
            }
        }
    }, [sc, jobs]);

    return <>
        <PageSetting
            title={false}
            iconName={null}
            sideChildren={null}
            hideNavLinks={true}
            pageBackground={theme.semanticColors.bodyStandoutBackground}
        />

        <div className="Home-Container">
            <div className="Home-Box"
                 style={{padding: theme.spacing.l2, background: theme.semanticColors.bodyBackground}}>
                {jobId === undefined && <>
                    {jobs?.length > 0 && <Stack horizontal wrap tokens={{childrenGap: 'm'}}>
                        {!(jobSearch?.length > 0) && <Stack.Item grow={1000}>
                            <Text style={{
                                color: theme.semanticColors.disabledText,
                                fontSize: theme.fonts.small.fontSize,
                                fontWeight: theme.fonts.small.fontWeight
                            }}>
                                Selezionare la commessa in cui operare...
                            </Text>
                        </Stack.Item>}
                        <Stack.Item grow={1}>
                            <SearchBox underlined value={jobSearch}
                                       onChange={(ev, newValue) => setJobSearch(newValue)}/>
                        </Stack.Item>
                    </Stack>}
                    {jobs?.length > 0 && filteredJobs?.length === 0
                        ? <MessageBar messageBarType={MessageBarType.info}>
                            Nussuna commessa risponde alla ricerca.
                        </MessageBar>
                        : (jobs?.length === 0 && <MessageBar messageBarType={MessageBarType.info}>
                            Nussuna commessa configurata.
                        </MessageBar>)}
                    {jobsError && <MaybeErrorMessageBar error={jobsError}/>}
                    {jobs === undefined && !jobsError && <Spinner size={SpinnerSize.large}/>}
                    {jobs !== undefined && <Nav
                        styles={{
                            root: {
                                marginBottom: `-${theme.spacing.l1}`
                            },
                        }}
                        groups={[
                            {
                                name: 'Commesse',
                                links: filteredJobs?.map?.(job => ({
                                    name: job.code,
                                    url: `#/jobs/${job._id}`,
                                    title: job.title,
                                }))
                            },
                            ...((comps.has(FrontendComponent.ITPStats) || comps.has(FrontendComponent.ITPGlobalEventsCal)) ? [{
                                name: "Generale",
                                links: [
                                    ...(comps.has(FrontendComponent.ITPStats) ? [{
                                        name: 'Statistiche PCQ',
                                        url: '#/itpStats'
                                    }] : []),
                                    ...(comps.has(FrontendComponent.ITPGlobalEventsCal) ? [{
                                        name: 'Calendario PCQ',
                                        url: '#/itpGlobalCal'
                                    }] : []),
                                ]
                            }] : []),
                            ...(comps.has(FrontendComponent.admin) ? [{
                                name: "Amministrazione",
                                links: [
                                    {name: 'Utenti', url: '#/admin/users'},
                                    {name: 'Imprese', url: '#/admin/companies'},
                                    {name: 'Commesse', url: '#/admin/jobs'},
                                ]
                            }] : []),
                        ]}
                        onRenderGroupHeader={props =>
                            <h2 style={{
                                borderBottom: '1px solid ' + theme.semanticColors.buttonBorderDisabled,
                                marginBottom: theme.spacing.s1,
                                paddingBottom: theme.spacing.s1
                            }}>
                                <PageHeader>{props.name}</PageHeader>
                            </h2>}
                        onRenderLink={(props, defaultRender) => {
                            return <div style={{display: 'inline-flex', flexFlow: 'row', alignItems: 'baseline'}}>
                                <span>
                                    {props.name}
                                </span>
                                <Text variant={"small"} nowrap block style={{
                                    flexGrow: 1,
                                    flexShrink: 1,
                                    color: theme.semanticColors.disabledBodyText,
                                    marginLeft: 8
                                }}>
                                    {props.title}
                                </Text>
                            </div>
                        }}
                    />}
                </>}

                {jobId !== undefined && <>
                    <ActionButton
                        iconProps={{iconName: 'Back'}}
                        text="Home"
                        styles={{
                            root: {
                                display: 'block',
                                height: '32px',
                                position: 'absolute',
                                transform: `translate(-${theme.spacing.l2}, -${theme.spacing.l2})`,
                            },
                            label: {
                                opacity: 0.8,
                            }
                        }}
                        onClick={() => {
                            navigate('/')
                        }}
                    />
                    <Stack className="Home-JobStack"
                           style={{margin: `${theme.spacing.m} ${theme.spacing.l2} ${theme.spacing.l2}`}}
                           tokens={{childrenGap: 'm'}}>
                        <span style={{
                            borderBottom: '1px solid ' + theme.semanticColors.buttonBorderDisabled,
                            marginBottom: theme.spacing.s1,
                            paddingBottom: theme.spacing.s1
                        }}>
                            <PageHeader>
                                Commessa
                            </PageHeader>
                        </span>
                        <MaybeErrorMessageBar error={jobError ?? contractorError}/>
                        {job === undefined && !jobError && <Spinner size={SpinnerSize.large}/>}
                        {job && <Text style={{
                            color: theme.semanticColors.disabledText,
                            fontSize: theme.fonts.small.fontSize,
                            fontWeight: theme.fonts.small.fontWeight,
                            marginBottom: theme.spacing.l1
                        }}>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr',
                                columnGap: theme.spacing.s1,
                                rowGap: 2,
                                margin: '0 ' + theme.spacing.m,
                                alignItems: 'baseline',
                            }}>
                                <span style={{textAlign: 'right'}}>codice:</span>
                                <Text style={{
                                    color: theme.semanticColors.bodyText,
                                    fontSize: theme.fonts.mediumPlus.fontSize,
                                    fontWeight: theme.fonts.mediumPlus.fontWeight,
                                }}>
                                    {job?.code}
                                </Text>
                                <span style={{textAlign: 'right'}}>appaltatore:</span>
                                <span>{contractor?.denomination ?? <MicroSpinner/>}</span>
                                <span style={{textAlign: 'right'}}>contratto:</span>
                                <span>{job?.contract}</span>
                                <span style={{textAlign: 'right'}}>opera:</span>
                                <span>{job?.title}</span>
                            </div>
                        </Text>}
                        {job && <ChoiceGroup
                            className="Home-JobChoiceGroup"
                            selectedKey={null}
                            options={[
                                {
                                    key: 'letters',
                                    text: 'OdS/NI',
                                    title: 'Ordini di Servizio e Note Impresa',
                                    disabled: !comps.has(FrontendComponent.OdsNi),
                                    iconProps: {
                                        iconName: 'PublicEmail'
                                    },
                                    onClick() {
                                        navigate(`/jobs/${job?._id}/letters/`)
                                    }
                                },
                                {
                                    key: 'gdl',
                                    text: 'GdL',
                                    title: 'Giornale del Lavoro',
                                    disabled: !comps.has(FrontendComponent.GdL),
                                    iconProps: {
                                        iconName: 'TableGroup'
                                    },
                                    onClick() {
                                        navigate(`/jobs/${job?._id}/gdl/`)
                                    }
                                },
                                {
                                    key: 'itp',
                                    text: 'PCQ',
                                    title: 'Ispezioni controllo qualità',
                                    disabled: !comps.has(FrontendComponent.ITP),
                                    iconProps: {
                                        iconName: 'Trackers'
                                    },
                                    onClick() {
                                        navigate(`/jobs/${job?._id}/itp/`)
                                    }
                                }
                            ]}
                        />}
                    </Stack>
                </>}
            </div>
        </div>
    </>
}
