import {BaseLetter} from "./BaseLetter";


/** Represents the information about how a new letter shall be numbered in a given job,
 * considering the possibility of a request for a specific number and date */
export interface LetterNumberingOptions {

    insert?: {

        /**
         * Minimum allowed date for the given request, if any
         * Date as YYYY-MM-DD
         * @pattern ^(2[0-9]{3})/(0[1-9]|10|11|12)/(0[1-9]|[1-2][0-9]|30|31)$
         * */
        minDate: BaseLetter['date']

        /**
         * Maximum allowed date for the given request, if any
         * Date as YYYY-MM-DD
         * @pattern ^(2[0-9]{3})/(0[1-9]|10|11|12)/(0[1-9]|[1-2][0-9]|30|31)$
         * */
        maxDate: BaseLetter['date']
    }

    /** NOTE: it's always possible to append a new letter, but only with the hereby indicated ref, if not undefined. */
    append: {
        externalRef?: BaseLetter['externalRef']

        /**
         * Minimum allowed date for the given request, if any
         * Date as YYYY-MM-DD
         * @pattern ^(2[0-9]{3})/(0[1-9]|10|11|12)/(0[1-9]|[1-2][0-9]|30|31)$
         * */
        minDate: BaseLetter['date']
    }

    /**
     * This field informs about the reason why it may not be possible to insert with some requested setting:
     * - hole: you can't skip a number, you have to append with the provided ref
     * - duplicate: you can't reuse a ref that is already taken by another letter
     * - free_insert: you can't "insert" where you could just append
     */
    forbidden?: 'hole' | 'duplicate' | 'free_insert'
}

export const MIN_DATE = '2000/01/01'
export const MAX_DATE = '2999/12/31'

export const getMetLetterNumberingOptions: (letter: BaseLetter, options?: LetterNumberingOptions) => string[] = (letter, options) => {
    return [
        ...(options?.append &&
        letter.externalRef === options.append.externalRef &&
        letter.date >= options.append.minDate ?
            ['append'] : []),
        ...(options?.insert &&
        letter.date >= options.insert.minDate &&
        letter.date <= options.insert.maxDate ?
            ['insert'] : []),
    ]
}
