import * as React from 'react'

import {PageSetting} from "../contexts/PageContext";
import {Link, useParams} from "react-router-dom";
import {Spinner, SpinnerSize} from "@fluentui/react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {useJob} from "../hooks/useJob";
import {JobHeadingFrag} from "../components/JobHeadingFrag";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import {ITPEventMessaging} from "../components/ITPEventMessaging";
import {ITPItemContext} from '../contexts/ITPItemContext';
import {ITPEventResourceContext} from "../contexts/ITPEventResourceContext";


export const ITPEventPage: React.FC = () => {
    const {jobId, itpId, eventId} = useParams()
    const {job, contractor, jobError} = useJob(jobId)

    const {
        data: itps,
        error: itpError,
    } = useFusinaRmiResource('ITP', 'find', {by: 'itp', itp: {_id: itpId}})

    const {
        data: events,
        error: eventError,
        refresh: eventRefresh,
    } = useFusinaRmiResource('ITPEvents', 'find', {by: 'event', event: {_id: eventId}})

    if (!job || !contractor || !(events?.length > 0) || !(itps?.length > 0)) {
        return <MaybeErrorMessageBar error={jobError ?? itpError ?? eventError}/> || <>
            <br/>
            <Spinner size={SpinnerSize.large}/>
        </>
    }

    const event = events[0]
    const itp = itps[0]
    const item = itp.items.find(it => it.item === event.item)

    return <>
        <PageSetting
            iconName="Trackers"
            title={<>
                <div style={{paddingRight: 15, lineHeight: '1.125em', textAlign: 'left'}}>
                    <div style={{fontSize: '0.95em'}}>Notifica espletamento fasi PCQ</div>
                    <div><Link to={`jobs/${jobId}/itp/${itpId}`}><small>{itp.docN}</small></Link> fase {item.item}</div>
                </div>
                <JobHeadingFrag/>
            </>}
            sideChildren={null}
        />
        {/* NOTE: ITPItemContext: latest rev ok; event shall be shown with its revision from event creation */}
        <ITPItemContext.Provider value={{itp, item}}>
            <ITPEventResourceContext.Provider value={{onRefreshRequired: eventRefresh}}>
                <div style={{
                    padding: 24,
                    // maxWidth: 'calc(95vw - 20px)',
                    // overflowX: 'auto',
                    // boxSizing: 'border-box',
                    // scrolling is not really good here
                    // let's just limit the width on desktop
                    maxWidth: 890, // 500 for the notification table, 308 for the messages, 64 for the margins
                    margin: '0 auto',
                }}>
                    <ITPEventMessaging event={event}/>
                </div>
            </ITPEventResourceContext.Provider>
        </ITPItemContext.Provider>
    </>
}
