import {CommandBarButton, MaskedTextField, Stack, useTheme} from "@fluentui/react";
import * as React from "react";
import {useCallback} from "react";
import useThrottledCallback from "../hooks/useThrottledCallback";

export function CmdDateSearchBox(props: { value: string, onChange: (newValue?: string) => void }) {
    const theme = useTheme()
    const focusHandler = useCallback((ev) => {
        const input = ev.target as HTMLInputElement
        const end = input.value.replace(/[0-9]/g, '.').lastIndexOf('.') + 1;
        input.setSelectionRange(end, end);
    }, [])

    const {
        throttledCallback: updateSearch,
        isDelaying
    } = useThrottledCallback(props.onChange, 500, [])

    return <Stack horizontal>
        <MaskedTextField
            mask="9999/99/99"
            placeholder="Filtra date"
            value={props.value}
            onChange={(event, newValue) => {
                updateSearch(newValue)
            }}
            iconProps={{iconName: 'Calendar'}}
            underlined
            styles={{
                fieldGroup: {
                    height: 43,
                    borderBottomWidth: 0,
                    alignItems: "center",
                },
                icon: {
                    left: 8,
                    right: 'unset',
                    bottom: 'unset',
                    color: theme.semanticColors.link,
                },
                field: {
                    height: 43,
                    borderBottomWidth: 0,
                    paddingLeft: 32, // 24,
                    paddingRight: 8 + 24,
                    width: 'calc(64px + 10ch)'
                },
                wrapper: {
                    borderBottomWidth: 0,
                },
            }}
            onSelect={focusHandler}
            onFocus={focusHandler}
            onClick={focusHandler}
        />
        {props.value && <CommandBarButton
            style={{
                marginLeft: -28,
                background: 'transparent',
            }}
            iconProps={{
                iconName: isDelaying ? 'HourGlass' : 'Cancel',
                style: {
                    color: isDelaying ? theme.semanticColors.disabledSubtext : theme.semanticColors.bodyText
                }
            }}
            onClick={() => props.onChange('')}
        />}
    </Stack>
}
