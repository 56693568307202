import * as React from 'react';
import {PropsWithChildren, ReactElement, useMemo} from 'react';
import {DialogFooter, DialogType} from '@fluentui/react/lib/Dialog';
import {DialogContent, mergeStyles, Modal} from "@fluentui/react";
import {IDialogContentProps} from "@fluentui/react/src/components/Dialog/DialogContent.types";

export interface SimpleDialogProps {
    type?: DialogType
    title?: string | JSX.Element
    subText: string
    hidden?: boolean
    onDismiss?: (ev?: React.MouseEvent<HTMLButtonElement>) => unknown
    isBlocking?: boolean
    veryEasyDismiss?: boolean
    buttons?: ReactElement[]
    noMaxWidth?: boolean
}

const containerClassName = mergeStyles({
    maxWidth: 950,
})
const scrollableContentClassName = mergeStyles({
    overflowY: 'initial'
})

export const SimpleDialog: React.FC<PropsWithChildren<SimpleDialogProps>> = props => {

    const dialogContentProps = useMemo<IDialogContentProps>(() => ({
        type: props.type ?? DialogType.largeHeader,
        title: props.title ?? '',
        subText: props.subText,
        onDismiss: props.onDismiss,
    }), [props.type, props.title, props.subText, props.onDismiss])

    return <Modal
        isOpen={!props.hidden}
        onDismiss={props.veryEasyDismiss ? props.onDismiss : undefined}
        isBlocking={props.isBlocking === undefined ? true : props.isBlocking}
        containerClassName={props.noMaxWidth ? undefined : containerClassName}
        scrollableContentClassName={scrollableContentClassName}
    >
        <DialogContent {...dialogContentProps}>
            {props.children}
            {props.buttons && <DialogFooter>
                {props.buttons}
            </DialogFooter>}
        </DialogContent>
    </Modal>
}
