import * as React from "react";
import {useCallback, useState} from "react";
import {ActivityItem, Icon, IconButton, Link, Stack, useTheme} from "@fluentui/react";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {FusinaNotification} from "../../../fusina-notifications/entities/FusinaNotification";
import './NotificationItem.css'
import {RoleLabels} from "../../../fusina-authz/entities/RoleEnum";
import {LetterTypeSupAbbrLabels} from "../../../fusina-letters/entities/Letter";

export const NotificationItem: React.FC<{
    notification: FusinaNotification
    onMarkedAsSeen: (n: FusinaNotification) => void
}> = props => {
    const theme = useTheme()
    const rmi = useFusinaRmi()
    const [pending, setPending] = useState(false)
    const [seenState, setSeenLocalState] = useState(props.notification?.seen)
    const seen = seenState || props.notification?.seen
    const navigate = useNavigate()
    const href = useNotificationHref(props.notification)
    const markAsSeen = useCallback(() => {
        setPending(true)
        rmi.Notifications.markAsSeen(props.notification._id)
            .then(() => {
                setSeenLocalState(true)
                props.notification.seen = true
                props.onMarkedAsSeen(props.notification)
            })
            .finally(() => setPending(false))
    }, [props.notification, rmi])

    return <Stack horizontal tokens={{childrenGap: "s"}}
                  className={`NotificationItem ${seen ? 'seen' : 'notSeen'}`}
                  onClick={(ev) => {
                      // // @ts-ignore
                      // if (ev.target?.href) {
                      //     return // do not handle click on link?
                      // }
                      markAsSeen()
                      navigate(href)
                  }}>
        <Stack.Item grow={1}>
            <ActivityItem
                activityIcon={<NotificationIcon notification={props.notification}/>}
                activityDescription={<NotificationDescription notification={props.notification}/>}
                timeStamp={new Date(props.notification.createdAt).toLocaleString(undefined, {
                    dateStyle: "short",
                    timeStyle: "short"
                })}
            />
        </Stack.Item>
        {(!seen || pending) && <IconButton
            disabled={seen || pending}
            iconProps={{iconName: seen || pending ? "CheckMark" : "RadioBullet"}}
            styles={{
                root: {
                    background: 'transparent',
                },
                iconHovered: {
                    transform: 'scaleY(0.5) scaleX(2)'
                }
            }}
            className="NotificationItem-markAsRead"
            onClick={(ev) => {
                markAsSeen()
                ev.stopPropagation()  // do not navigate: prevent handling by parent stack item click handler
            }}
        />}
    </Stack>;
}


const useNotificationHref: (n: FusinaNotification) => string = (n: FusinaNotification) => {
    if (n.type === 'LetterWorkflowNotification') {
        return `/jobs/${n.job?._id}/letters/${n.letter?._id}`
    }
    if (n.type === 'ITPNotification') {
        return `/jobs/${n.job?._id}/itp/${n.itp?._id}`
    }
    if (n.type === 'ITPRevNotification') {
        return `/jobs/${n.job?._id}/itp/${n.itp?._id}/rev/${n.rev?._id}`
    }
    if (n.type === 'ITPEventNotification') {
        return `/jobs/${n.job?._id}/itp/${n.event?.itp?._id}/events/${n.event?._id}`
    }
    const []: never[] = [n]  // Static completeness check
    // @ts-ignore
    console.error('Unexpected notification type:', n.type)
}

const NotificationDescription: React.FC<{
    notification: FusinaNotification
}> = props => {
    const n = props.notification
    const href = useNotificationHref(n)

    if (n.type === 'LetterWorkflowNotification') {
        const label = `${n.job?.code} - ${LetterTypeSupAbbrLabels[n.letter?.type]} n°${n.letter?.externalRef}`

        if (n.subtype === 'internalSignatureRequest') {
            return <>Richiesta di firma: <br/><Link to={href} as={RouterLink}>{label}</Link></>
        }
        if (n.subtype === 'internalEstimateSignatureRequest') {
            return <>Richiesta di firma su preventivo: <br/><Link to={href} as={RouterLink}>{label}</Link></>
        }
        if (n.subtype === 'internalEstimateSignatureFulfill') {
            return <>preventivo firmato: <br/><Link to={href} as={RouterLink}>{label}</Link></>
        }
        if (n.subtype === 'internalSignatureReject') {
            return <>Firma annullata: <br/><Link to={href} as={RouterLink}>rivedi {label}</Link></>
        }
        if (n.subtype === 'internalSignatureFulfill') {
            return <>Firmata{n.signature?.role ? ' da ' + n.signature?.role : ''}: <br/><Link to={href}
                                                                                              as={RouterLink}>{label}</Link></>
        }
        if (n.subtype === 'externalCommunication') {
            return <>Ricevuta nuova lettera: <br/><Link to={href} as={RouterLink}>{label}</Link></>
        }
        if (n.subtype === 'externalSignatureFulfill') {
            return <>Firmata dal destinatario: <br/><Link to={href} as={RouterLink}>{label}</Link></>
        }
        if (n.subtype === 'internalDraftShared') {
            return <>Bozza: <Link to={href} as={RouterLink}>{label}</Link></>
        }

        const []: never[] = [n.subtype]  // Static completeness check
        console.error('Unexpected LetterWorkflowNotification subtype:', n.subtype)
        return <Link to={href} as={RouterLink}>Vedi {label}</Link>  // failsafe
    }

    if (n.type === 'ITPNotification' || n.type === 'ITPRevNotification') {
        if (n.subtype === 'signed') {
            if (n.type === 'ITPRevNotification') {
                // Not showing the signature role because il would break the text line.
                return <>Revisione PCQ firmata: <br/>
                    <Link to={href} as={RouterLink}>{n.itp.docN}</Link></>
            }
            return <>PCQ firmato da {RoleLabels[n.signature.role]}: <br/>
                <Link to={href} as={RouterLink}>{n.itp.docN}</Link></>
        }
        if (n.subtype === 'applied') {
            return <>Revisione PCQ applicata: <br/>
                <Link to={href} as={RouterLink}>{n.itp.docN}</Link></>
        }
        if (n.subtype === 'rejected') {
            return <>
                <span style={{color: 'red'}}>Firma negata su PCQ</span>:
                <br/>
                <Link to={href} as={RouterLink}>{n.itp.docN}</Link>
                {n.comment && <div style={{
                    borderRadius: 5,
                    padding: '0.15em 0.5em 0.5em',
                    background: '#fff5f0',
                    color: '#620900',
                }}>
                    <i>{n.comment}</i>
                </div>}
            </>
        }
        const []: never[] = [n]  // Static completeness check
    }

    if (n.type === 'ITPEventNotification') {
        if (n.subtype === 'event') {
            return <>Nuova notifica espletamento fasi PCQ:<br/>
                <Link to={href} as={RouterLink}>Vedi {n.event.itp.docN} fase {n.event.item}</Link></>
        }
        if (n.subtype === 'message') {
            if (n.msg.method === 'REPLY') {
                return <>Risposta a notifica PCQ:<br/>
                    <Link to={href} as={RouterLink}>Vedi {n.event.itp.docN} fase {n.event.item}</Link></>
            } else {
                return <>Modifica notifica PCQ:<br/>
                    <Link to={href} as={RouterLink}>Vedi {n.event.itp.docN} fase {n.event.item}</Link></>
            }
        }
        const []: never[] = [n.subtype]  // Static completeness check
        return <Link to={href} as={RouterLink}>Vedi {n.event.itp.docN} fase {n.event.item}</Link>  // failsafe
    }

    const []: never[] = [n]  // Static completeness check
    // @ts-ignore
    console.error('Unexpected notification type:', n.type)
    return <>Notifica</>  // failsafe
}

const NotificationIcon: React.FC<{
    notification: FusinaNotification
}> = props => {
    const n = props.notification
    if (n.type === 'LetterWorkflowNotification') {
        if (n.subtype === 'internalSignatureRequest') {
            return <Icon iconName="EditMail"/>  // Pencil
        }
        if (n.subtype === 'internalEstimateSignatureRequest') {
            return <Icon iconName="WindowEdit"/>  // Pencil
        }
        if (n.subtype === 'internalEstimateSignatureFulfill') {
            return <Icon iconName="ActivateOrders"/>  // sheet with list and check
        }
        if (n.subtype === 'internalSignatureReject') {
            return <Icon iconName="MailAlert"/>  // Exclamation mark
        }
        if (n.subtype === 'internalSignatureFulfill') {
            return <Icon iconName="MailCheck"/>  // Checkmark
        }
        if (n.subtype === 'externalCommunication') {
            return <Icon iconName="MailSolid"/>  // Filled
        }
        if (n.subtype === 'externalSignatureFulfill') {
            return <Icon iconName="MailCheck"/>  // Checkmark
        }
        if (n.subtype === 'internalDraftShared') {
            return <Icon iconName="NewMail"/>  // Plus sign on letter
        }

        const []: never[] = [n.subtype]  // Static completeness check
        console.error('Unexpected LetterWorkflowNotification subtype:', n.subtype)
        return <Icon iconName="PublicEmail"/>  // failsafe
    }

    if (n.type === 'ITPNotification' || n.type === 'ITPRevNotification') {
        if (n.subtype === 'signed') {
            return <Icon iconName="OpenEnrollment"/>  // Pencil on list
        }
        if (n.subtype === 'rejected') {
            return <Icon iconName="RemoveFromShoppingList"/>  // cross on list
        }
        if (n.subtype === 'applied') {
            return <Icon iconName="OpenEnrollment"/>  // Pencil on list
        }
    }

    if (n.type === 'ITPEventNotification') {
        return <Icon iconName="ScheduleEventAction"/>  // bolt on calendar
    }

    const []: never[] = [n]  // Static completeness check
    // @ts-ignore
    console.error('Unexpected notification type:', n.type)
    return <Icon iconName="Message"/>  // failsafe
}
