export class ObjectNotFoundException extends Error {
    constructor() {
        super("Object not found for remote method invocation");
    }
}

export class MethodNotFoundException extends Error {
    constructor() {
        super("Method not found for remote invocation");
    }
}

export class RemoteError extends Error {
    constructor(message: string, public readonly code?: string | number) {
        super(message);
    }
}

export class UnauthorizedError extends Error {
}