import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {ITPItem} from "../../../fusina-itp/entities/ITPItem";
import {
    Callout,
    CommandBar,
    ConstrainMode,
    DirectionalHint,
    IObjectWithKey,
    MarqueeSelection,
    MessageBar,
    MessageBarType,
    Selection,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
} from "@fluentui/react";
import * as React from "react";
import {useContext, useMemo, useState} from "react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {useFusinaRmiUrl} from "../hooks/useFusinaRmiUrl";
import {ITPCertificatePolicy} from "../../../fusina-itp/controls/ITPCertificatePolicy";
import {useJobContext} from "../contexts/JobContext";
import {useSecurityContext} from "../contexts/SecurityContext";
import {SimpleDialog} from "./SimpleDialog";
import {ITPCertsDeletionView} from "./ITPCertsDeletionView";
import {ITPCertsDetailsListBase} from "./ITPCertsDetailsListBase";
import {ITPCertsUpload} from "./ITPCertsUpload";
import {useId} from "@fluentui/react-hooks";
import {getITPCertExposedFileName} from "../../../fusina-itp/controls/ITPCertExposedFileName";
import {ITPRevContext} from "../contexts/ITPRevContext";


export interface ITPCertListItem extends IObjectWithKey {
    _id: string
    fileName: string
    date: Date
}

/** Map MongoDB ObjectID in hex format to JS Date */
const dateFromObjectId = (objectId) => {
    try {
        return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
    } catch (e) {
        console.warn(e)
        return undefined
    }
};

export const mapITPCertListItems: (itp: InspectionTestPlan, item: ITPItem) => (ids: string[]) => ITPCertListItem[] = (itp, item) => ids =>
    ids.map(_id => ({
        _id,
        key: _id,
        fileName: getITPCertExposedFileName(itp, item, _id),
        date: dateFromObjectId(_id),
    }))

/**
 * Main visualization component for ITP certificates associated to some ITP item.
 *
 * Shows a list of certificates, allows for upload and download.
 */
export const ITPCertsView: React.FC<{
    itp: InspectionTestPlan
    item: ITPItem
}> = props => {

    const {
        data,
        error,
        refresh,
    } = useFusinaRmiResource('ITPCertificates', 'list', props.itp._id, props.item.item)

    const items = useMemo<ITPCertListItem[]>(() => {
        if (data) {
            const items = mapITPCertListItems(props.itp, props.item)(data)
            items.reverse()
            return items
        }
    }, [data])

    const [selected, setSelected] = useState<ITPCertListItem | undefined>(undefined)
    const [selectionCount, setSelectionCount] = useState<number | undefined>(undefined)
    const selection = useMemo(() => new Selection<ITPCertListItem>({
        onItemsChanged() {
            const sel = selection.getSelection()
            setSelectionCount(sel?.length)
            if (sel?.length === 1) {
                setSelected(sel[0])
            } else {
                setSelected(undefined)
            }
        },
        onSelectionChanged() {
            const sel = selection.getSelection()
            setSelectionCount(sel?.length)
            if (sel?.length === 1) {
                setSelected(sel[0])
            } else {
                setSelected(undefined)
            }
        },
    }), [])

    const selectedUrl = useFusinaRmiUrl('ITPCertificates', 'download', props.itp._id, props.item.item, selected?._id)

    const {job} = useJobContext()
    const sc = useSecurityContext()

    const policy = useMemo(() => ITPCertificatePolicy({
        itp: props.itp,
        item: props.item,
        job,
        securityContext: sc,
    }), [job, sc, props.itp, props.item])

    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [isUploading, setIsUploading] = useState<boolean>(false)

    const addBtnId = useId('ITPCertsView-add')

    const revCtx = useContext(ITPRevContext)

    return <Stack>

        <CommandBar
            items={[
                {
                    key: 'new',
                    iconProps: {
                        iconName: 'Add',
                    },
                    text: 'Carica',
                    disabled: !policy.permissions.write || revCtx.isNotCurrent,
                    onClick() {
                        setIsUploading(v => !v)
                    },
                    className: addBtnId
                },
                {
                    key: 'delete',
                    iconProps: {
                        iconName: 'Delete',
                    },
                    text: 'Elimina',
                    disabled: !policy.permissions.delete || !(selectionCount > 0),
                    onClick() {
                        setIsDeleting(true)
                    }
                },
                {
                    key: 'download',
                    iconProps: {
                        iconName: 'Download',
                    },
                    text: 'Download',
                    disabled: !policy.permissions.read || !selected,
                    href: selectedUrl,
                }
            ]}
            farItems={[
                // TODO?: search something
            ]}
        />

        {error && <MaybeErrorMessageBar error={error}/>}

        {data === undefined && !error && <>
            <br/>
            <Spinner size={SpinnerSize.medium}/>
        </>}

        {data?.length === 0 && <>
            <br/>
            <MessageBar messageBarType={MessageBarType.info}>
                Nessun certificato
            </MessageBar>
        </>}

        {data?.length > 0 && <MarqueeSelection selection={selection}>
            <ITPCertsDetailsListBase
                constrainMode={ConstrainMode.horizontalConstrained}
                selectionMode={SelectionMode.multiple}
                selection={selection}
                items={items}
                onItemInvoked={(item: ITPCertListItem) => {
                    if (item._id === selected._id && window?.location?.href) {
                        window.location.href = selectedUrl
                    }
                }}
            />
        </MarqueeSelection>}

        <Callout
            target={`.${addBtnId}`}
            hidden={!isUploading}
            onDismiss={() => {
                setIsUploading(false)
            }}
            directionalHint={DirectionalHint.bottomLeftEdge}
            role="dialog"
            style={{padding: '20px 24px'}}
        >
            <ITPCertsUpload
                itp={props.itp}
                item={props.item}
                onUploaded={() => {
                    refresh().then()
                }}
                onFinish={() => {
                    setIsUploading(false)
                }}
            />
        </Callout>

        <SimpleDialog
            title={"Eliminazione certificati PCQ"}
            subText={`Questo strumento consente di rimuovere dal sistema eventuali file caricati per errore.`}
            hidden={!isDeleting}
        >
            <ITPCertsDeletionView
                itp={props.itp}
                item={props.item}
                list={selection.getSelection()}
                onFinish={() => {
                    setIsDeleting(false)
                    selection.setAllSelected(false)
                    refresh().then()
                }}
            />
        </SimpleDialog>
    </Stack>
}
