import type {PolicyContext} from "../../fusina-policies/PolicyContext";
import type {Job} from "../../fusina-jobs/entities/Job";
import {getJobRoles} from "../../fusina-jobs/controls/JobGrants";
import {RoleEnum} from "../../fusina-authz/entities/RoleEnum";

export interface ITPCountsPolicyContext extends PolicyContext {
    job?: Job
}

export function ITPCountsPolicy(ctx: ITPCountsPolicyContext) {
    const sc = ctx.securityContext;
    const myRoles: Set<RoleEnum> = new Set([...(ctx.job ? getJobRoles(ctx.job, sc?.user?.grants) : []), ...ctx.securityContext?.user?.globalRoles ?? []])

    return {
        myRoles,

        permissions: {
            count: myRoles.has(RoleEnum.CDL_Q) || myRoles.has(RoleEnum.Admin)
                || myRoles.has(RoleEnum.SupervisorQ202310)
        }
    }
}
