import * as React from 'react'
import {useContext} from 'react'
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {MaskedTextField, Stack, TextField} from "@fluentui/react";
import {useForceUpdate} from "@fluentui/react-hooks";
import {ITP_CODE_CLEANER} from "../../../fusina-itp/controls/ITPValidator";
import {ITPRevContext} from "../contexts/ITPRevContext";

export const ITPHeaderEditor: React.FC<{
    itp: InspectionTestPlan
    onChange?: () => void
}> = props => {
    const forceUpdate = useForceUpdate()

    const revCtx = useContext(ITPRevContext)

    return <>
        <Stack horizontal tokens={{childrenGap: 'm'}}>

            <Stack.Item grow={1}>
                <MaskedTextField
                    label="Doc N."
                    mask="99999"
                    prefix="PBCFU"
                    value={props.itp.docN.replace(/^PBCFU/, '')}
                    onChange={(event, newValue) => {
                        props.itp.docN = newValue ? ('PBCFU' + ITP_CODE_CLEANER(newValue)) : undefined
                        forceUpdate()
                        props.onChange?.()
                    }}
                    readOnly={revCtx.isNotCurrent}
                    disabled={revCtx.isNotCurrent}
                    onRenderLabel={revCtx.isNotCurrent ? (props, DefaultRender) => <Stack horizontal
                                                                                          verticalAlign="center">
                        <DefaultRender {...props}/>
                        <Stack.Item grow={1}>&nbsp;</Stack.Item>
                        <small>riferimento non modificabile</small>
                    </Stack> : undefined}
                />
            </Stack.Item>

            <Stack.Item grow={1}>
                <TextField
                    label="Revisione"
                    value={props.itp.revN}
                    onChange={(event, newValue) => {
                        props.itp.revN = newValue ? ITP_CODE_CLEANER(newValue) : undefined
                        forceUpdate()
                        props.onChange?.()
                    }}
                />
            </Stack.Item>
        </Stack>

        <TextField
            label="Titolo"
            value={props.itp.title}
            multiline
            autoAdjustHeight
            onChange={(event, newValue) => {
                props.itp.title = newValue ? newValue?.replace(/[\r\n]/g, ' ') : newValue
                forceUpdate()
                props.onChange?.()
            }}
        />

    </>
}
