import * as React from 'react'
import {useMemo, useRef, useState} from 'react'
import {DefaultButton, DialogType, PrimaryButton, ProgressIndicator, Stack} from "@fluentui/react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {useNavigate, useParams} from "react-router-dom";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import {Calendar} from "../components/calendar";
import {ITPEvent} from "../../../fusina-itp/entities/ITPEvent";
import {PageSetting} from "../contexts/PageContext";
import {JobHeadingFrag} from "../components/JobHeadingFrag";
import {useJob} from "../hooks/useJob";
import {ITPEventFilterCriteria} from "../../../fusina-itp/entities/ITPEventFilterCriteria";
import useThrottledCallback from "../hooks/useThrottledCallback";
import {SimpleDialog} from "../components/SimpleDialog";
import {ITPEventCreationFromJob} from "../components/ITPEventCreationFromJob";

export const ITPCalendarPage: React.FC = () => {

    const {jobId, view: url_view, date: url_date, eventId} = useParams()
    useJob(jobId)

    const [isCreating, setIsCreating] = useState<boolean>(false)
    const selectedDateRef = useRef<Date | undefined>()  // No reactivity is required, let's use it imperatively

    const [criteria, setCriteria_] = useState<ITPEventFilterCriteria[]>([])

    const {
        throttledCallback: setCriteria,
        isDelaying,
    } = useThrottledCallback(setCriteria_, 500, [])

    const filter = useMemo<ITPEventFilterCriteria>(() =>
        // NOTE: let's avoid a partial filter while there's no criteria from the Calendar component...
        criteria?.length <= 0 ? undefined : ({
            by: 'conjunction',
            criteria: [
                {by: 'job', job: {_id: jobId}},
                ...criteria,
            ]
        }), [criteria, jobId])

    const {
        data,
        error,
        refresh,
    } = useFusinaRmiResource('ITPEvents', 'find', filter)

    const openEvent = useMemo(() =>
        data?.find?.(ev => ev._id === eventId), [data, eventId])
    // NOTE: openEvent depends on what is loaded, which depends on the filter criteria, which depends on Calendar;
    //       openEvent is initially undefined and is found only when viewing the week or month where the event starts.

    const navigate = useNavigate()

    if (data === undefined && error) {
        return <MaybeErrorMessageBar error={error}/>
    }

    return <>
        <PageSetting
            iconName="Trackers"
            title={<>
                <div style={{paddingRight: 15, lineHeight: '1.125em', textAlign: 'left'}}>
                    <div style={{fontSize: '0.95em'}}>Calendario espletamento fasi PCQ</div>
                </div>
                <JobHeadingFrag/>
                <div style={{flexGrow: 1}}>
                </div>
                <Stack horizontal tokens={{childrenGap: 's1'}} style={{margin: '8px 0 4px 4px'}}>
                    <PrimaryButton
                        iconProps={{
                            iconName: "ScheduleEventAction"
                        }}
                        text="Nuova notifica"
                        onClick={() => setIsCreating(true)}
                    />
                    <DefaultButton
                        iconProps={{
                            iconName: "CheckListText"
                        }}
                        text="Piani"
                        href={`#/jobs/${jobId}/itp`}
                    />
                </Stack>
            </>}
            sideChildren={null}
        />

        <MaybeErrorMessageBar error={error}/>

        <ProgressIndicator
            percentComplete={undefined}
            styles={{root: (data === undefined || isDelaying) ? {} : {opacity: 0}, itemProgress: {padding: 0}}}
        />

        <div
            style={{
                margin: '12px 24px'
            }}
        >
            <Calendar
                events={data}
                onDatesRange={(min, max) => {
                    setCriteria([
                        {
                            by: 'dtStart',
                            dtStartMin: new Date(min),
                            dtStartMax: new Date(max),
                        }
                    ])
                }}
                view={url_view}
                selectedDate={url_date}
                openEvent={openEvent}
                eventContextLabelGetter={(ev: ITPEvent) => ev?.itp?.job?.code}
                onEventOpen={(ev: ITPEvent) => {
                    // NOTE: we replace the current location to support the back button and then we navigate.
                    navigate(`/jobs/${ev?.itp?.job?._id}/itpCal/week/${ev?.dtStart}/${ev?._id}`, {replace: true})
                    setTimeout(() => {
                        navigate(`/jobs/${ev?.itp?.job?._id}/itp/${ev?.itp?._id}/events/${ev?._id}`)
                    }, 1)
                }}
                eventHrefGetter={(ev: ITPEvent) =>
                    `/jobs/${ev?.itp?.job?._id}/itp/${ev?.itp?._id}/events/${ev?._id}`}
                onSelectedDate={date => {
                    selectedDateRef.current = date
                }}
            />
        </div>

        {isCreating && <SimpleDialog
            title="Nuova notifica espletamento fasi PCQ"
            subText={undefined}
            onDismiss={() => setIsCreating(false)}
            // veryEasyDismiss={true}
            isBlocking={false}
            type={DialogType.close}
            noMaxWidth
        >
            <ITPEventCreationFromJob
                initialDtsLocal={selectedDateRef.current}
                onDismiss={() => {
                    setIsCreating(false)
                }}
                onSent={(ev) => {
                    refresh().then(() => {
                        setIsCreating(false)
                        navigate(`/jobs/${ev?.itp?.job?._id}/itpCal/week/${ev?.dtStart}/${ev?._id}`, {replace: true})
                    })
                }}
            />
        </SimpleDialog>}
    </>
}
