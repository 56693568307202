import * as React from 'react'
import {useMemo} from 'react'
import {User} from "../../../fusina-users/entities/User";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {mergeStyles, MessageBar, MessageBarType, Spinner, SpinnerSize, useTheme} from "@fluentui/react";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import './ITPStatsTable.css'


// Heavily inspired from ITPSelectionFlow, itself inspired from ITPEventCreationFromJob

export const UserSelector: React.FC<{
    userFilter?: (user: User) => boolean
    onUserSelected?: (user: User) => void
}> = props => {

    const {
        data: storedUsers_NO_FILTERS,
        error: storedUsersError,
    } = useFusinaRmiResource('UsersAdmin', 'listUsers');

    const storedUsers = useMemo(() => {
        if (props.userFilter) {
            return storedUsers_NO_FILTERS?.filter?.(props.userFilter)
        } else {
            return storedUsers_NO_FILTERS
        }
    }, [storedUsers_NO_FILTERS, props.userFilter])

    const theme = useTheme()
    const majorTdStyle = mergeStyles({
        overflow: 'hidden',
        maxHeight: 75,
        textOverflow: 'ellipsis',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
        paddingLeft: '8px !important',
        paddingRight: '12px !important',
        textAlign: 'left !important',
    })
    const minorParTdStyle = mergeStyles({
        color: theme.semanticColors.bodySubtext,
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        maxHeight: 75,
        textOverflow: 'ellipsis',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
        paddingLeft: '8px !important',
        textAlign: 'left !important',
    })

    if (storedUsersError) {
        return <MaybeErrorMessageBar error={storedUsersError}/>
    }
    if (storedUsers_NO_FILTERS === undefined) {
        return <Spinner size={SpinnerSize.large}/>
    }
    return <>
        <table className="ITPStatsTable noHides selectRow" style={{borderTop: '1px solid #7772'}}>
            <thead>
            <tr>
                <td className={majorTdStyle}>Email</td>
                <td className={minorParTdStyle}>Nome completo</td>
            </tr>
            </thead>
            <tbody>
            {storedUsers.map(user => <tr
                key={user._id}
                onClick={() => {
                    props.onUserSelected(user)
                }}
            >
                <td className={majorTdStyle}>{user.email}</td>
                <td className={minorParTdStyle}>{user.fullName}</td>
            </tr>)}
            </tbody>
        </table>
        {storedUsers?.length === 0 && <>
            <MessageBar messageBarType={MessageBarType.info}>
                Nessun utente selezionabile.
            </MessageBar>
        </>}
    </>
}
