import * as React from "react";
import {useMemo, useRef, useState} from "react";
import {useSecurityContext} from "../contexts/SecurityContext";
import {useCompanyContext} from "../contexts/CompanyContext";
import {Callout, Icon, PrimaryButton, Stack, Text} from "@fluentui/react";
import {RoleEnum, RoleLabels} from "../../../fusina-authz/entities/RoleEnum";
import {PUBLIC_RMI} from "../config";
import {OverTopBarFirstLogos} from "./OverTopBarFirstLogos";
import {NotificationsBtn} from "./NotificationsBtn";
import {useJobContext} from "../contexts/JobContext";

function UserSheet() {
    const {user} = useSecurityContext()
    const {company} = useCompanyContext()  // TODO?: retrieve company from job?
    const {job} = useJobContext()

    const roles = useMemo<RoleEnum[]>(() => [...new Set([
        ...(user?.grants?.filter?.(g => g.scopeType === 'company' && g.scopeId === company?._id)?.map?.(g => g.role) ?? []),
        ...(user?.grants?.filter?.(g => g.scopeType === 'job' && g.scopeId === job?._id)?.map?.(g => g.role) ?? []),
        ...(user?.globalRoles?.filter?.(r => r != RoleEnum.Admin) ?? []),  // displayed more prominently
    ])], [user, company, job])

    return <Stack tokens={{childrenGap: "m"}}>
        <Text>
            <b>Email</b>: {user.email}
            {company?.denomination && <>
                <br/>
                <b>Company</b>: {company.denomination}
                {/* TODO: If many company ids, then JobsListService.findCompanyById and show dropdown */}
            </>}

            {job && <>
                <br/>
                <b>Commessa</b>: {job.code}
            </>}

            {roles?.length > 0 && <>
                <br/>
                <b>Ruolo</b>: {roles.map(r => RoleLabels[r] ?? '?').join(', ')}
            </>}

            {user.globalRoles?.find?.(g => g === RoleEnum.Admin) && <>
                <br/>
                <b>Amministratore</b> <Icon iconName="StatusCircleCheckmark"/>
            </>}
        </Text>
        <PrimaryButton
            //color={theme.semanticColors.primaryButtonText}
            text="Logout"
            onClick={() => {
                PUBLIC_RMI.auth.logout()
                    .then(() => {
                        window.location.href = '/'
                    })
            }}
        />
    </Stack>;
}

function OverTopBarUser() {
    const {
        user
    } = useSecurityContext()

    const {company} = useCompanyContext()

    const ref = useRef<HTMLButtonElement>()

    const [open, setOpen] = useState<boolean>(false)

    if (user === undefined) {
        return null
    }

    return <div style={{flexGrow: 0, display: 'flex'}} className="OverTopBarUser">
        <button type="button" style={{
            display: 'block',
            maxWidth: 'calc(99vw - 195px)',
            margin: '-3px 0',
            color: 'inherit',
            background: 'transparent',
            border: 'none',
            fontSize: "0.8em",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer'
        }} ref={ref} onClick={() => setOpen(v => !v)}>
            {user.email}
            {company?.denomination ? <span className="OverTopBarUser-company"> | {company?.denomination}</span> : ""}
        </button>
        {open && <Callout
            target={ref}
            role="alertdialog"
            gapSpace={0}
            style={{padding: 12}}
            onDismiss={() => setOpen(false)}
            setInitialFocus
        >
            <UserSheet/>
        </Callout>}
    </div>;
}

export const OverTopBar: React.FC = () => {
    return <>
        <OverTopBarFirstLogos/>
        <div style={{flexGrow: 1}}>&nbsp;</div>
        <div style={{overflow: 'visible', flexGrow: 0, display: 'flex', alignItems: 'center'}}>
            <NotificationsBtn/>
        </div>
        <OverTopBarUser/>
    </>
}
