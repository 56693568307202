import {ArgsType, ReturnType, useRmiResource} from "../../../js-rmi-react/useRmiResource";
import {FusinaRmiNaming} from "../../../fusina-dms-rmi-api/naming";

export function useFusinaRmiResource<K extends keyof FusinaRmiNaming = keyof FusinaRmiNaming, M extends keyof FusinaRmiNaming[K] = keyof FusinaRmiNaming[K], E = Error>(path: K, method: M, ...args: ArgsType<FusinaRmiNaming[K][M]>): {
    data?: ReturnType<FusinaRmiNaming[K][M]>,
    error?: E,
    refresh: () => Promise<void>
} {
    // @ts-ignore
    return useRmiResource<FusinaRmiNaming>(path, method, ...args)
}
