import * as React from "react";
import {ReactNode} from "react";
import {useTheme} from "@fluentui/react";
import {useJobContext} from "../contexts/JobContext";
import {useMatch, useNavigate} from "react-router-dom";

export const TopBar: React.FC<{
    icon: ReactNode
    title: string | ReactNode
}> = props => {
    const theme = useTheme()

    const navigate = useNavigate()

    const {job} = useJobContext()
    const isJobLocMatch = useMatch('/jobs/*')
    // NOTE: I'm adding here a "back button" that does not got to home but to the job.
    // TODO?: remove explicit dependency to job entity?

    return <>
        {props.icon && <div style={{
            flexGrow: 0,
            flexShrink: 0,
            fontSize: '2em',
            background: theme.palette.neutralLighter,
            width: '1.9em',
            height: '1.9em',
            lineHeight: '1.9em',
            textAlign: "center",
            cursor: 'pointer',
        }} onClick={() => navigate(isJobLocMatch ? `/jobs/${job._id}` : '/')}>
            {props.icon}
        </div>}
        <div style={{
            marginLeft: 15,
            fontSize: '1.5em',
            opacity: 0.75,
            fontWeight: 'lighter',
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'center'
        }}>
            {props.title}
        </div>
    </>
}
