import {useCallback, useEffect, useState} from "react";
import {AuditLogEnvelope} from "../../../fusina-audit/entities/AuditLog";
import {useFusinaRmiUrl} from "./useFusinaRmiUrl";

export function useFilteredAuditLogs(filter: string) {
    const [rows, setRows] = useState<AuditLogEnvelope[]>([])

    const [error, setError] = useState<string | undefined>(undefined)
    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [isEOF, setIsEOF] = useState<boolean>(false)
    const [pending, setPending] = useState<boolean>(false)

    const openSSE = useCallback((filter: string, rows: AuditLogEnvelope[], onRowsUpdate: () => void) => {
        const sse = new EventSource(useFusinaRmiUrl('Audit', 'stream', filter.trim() || '.', true))
        sse.onopen = () => {
            while (rows?.length > 0) {
                rows.pop()
            }
            setError(undefined)
            setIsConnected(true)
            setIsEOF(false)
        }
        sse.onmessage = (ev => {
            // NOTE: ev.data is a JSON representation of a string, like: `"example"`.
            const msg: string = JSON.parse(ev.data)
            if (msg === 'EOF') {
                sse.close()  // Prevent automatic reconnection
                setIsConnected(false)
                setIsEOF(true)
                setPending(false)
            } else if (msg.startsWith('INPUT:')) {
                if (msg === 'INPUT:PAUSE' || msg === 'INPUT:DATA') {
                    setPending(true)
                }
                // if (msg === 'INPUT:RESUME') {
                //     setPending(false)
                // }
            } else {
                rows.push(JSON.parse(msg))
            }
            onRowsUpdate()
        })
        sse.onerror = (ev => {
            setError(`Errore da EventSource (${{
                [sse.OPEN]: 'OPEN',
                [sse.CONNECTING]: 'CONNECTING',
                [sse.CLOSED]: 'CLOSED',
            }[sse.readyState]}): ${ev.type}`)
            setIsConnected(sse.readyState === sse.OPEN)
            onRowsUpdate()
        })
        return sse
    }, [])

    useEffect(() => {
        const rows = []
        setRows(rows)
        setPending(true)
        let sse = null;
        const to = setTimeout(() => {
            let tou = null
            sse = openSSE(filter, rows, () => {
                if (tou) {
                    clearTimeout(tou)
                }
                tou = setTimeout(() => {
                    setPending(false)
                    setRows(rows.slice())
                }, 300)
                console.debug('new row pushed.')
            })
        }, 2000)
        return () => {
            clearTimeout(to)
            if (sse) {
                sse.close()
                setIsConnected(false)
            }
        }
    }, [filter])

    return {
        rows,
        error,
        pending,
        isConnected,
        isEOF,
    }
}
