import * as React from 'react'
import {useCallback, useState} from 'react'
import {ITPEventFilterCriteria} from "../../../fusina-itp/entities/ITPEventFilterCriteria";
import {Job} from "../../../fusina-jobs/entities/Job";
import {FusinaRmiNaming} from "../../../fusina-dms-rmi-api/naming";
import * as XLSX from "xlsx/xlsx";
import {EVENT_STATUS_LABELS_IT} from "../../../fusina-event/entities/EventProperties";
import {DefaultButton, MessageBar, MessageBarType, ProgressIndicator, Text,} from "@fluentui/react";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";

export const ITPEventsExportTools: React.FC<{
    job: Job
}> = props => {

    const [isPending, setIsPending] = useState<boolean>(false)
    const [isDone, setIsDone] = useState<boolean>(false)
    const [error, setError] = useState<Error | undefined>(undefined)

    const rmi = useFusinaRmi()

    const exportCallback = useCallback(async (fileExt: 'xlsx' | 'csv') => {
        setIsPending(true)
        setIsDone(false)
        setError(undefined)
        try {
            await exportITPEventsList(rmi, props.job, fileExt)
            setIsDone(true)
        } catch (e) {
            setError(e)
        } finally {
            setIsPending(false)
        }
    }, [props.job])

    if (isPending) {
        return <>
            <ProgressIndicator
                label="Download ed esportazione in corso..."
            />
        </>
    }
    if (error) {
        return <>
            <MaybeErrorMessageBar error={error}/>
            <br/>
            <DefaultButton
                text="Riprova"
                onClick={() => setError(undefined)}
            />
        </>
    }
    if (isDone) {
        return <>
            <MessageBar
                messageBarType={MessageBarType.success}
                onDismiss={() => {
                    setIsPending(false)
                    setIsDone(false)
                    setError(undefined)
                }}
            >
                Esportazione completata con successo. Un file dovrebbe comparire nei tuoi download.
            </MessageBar>
        </>
    }
    return <div style={{textAlign: 'center'}}>
        <Text variant={"mediumPlus"} style={{fontWeight: 500}}>
            Esportazione notifiche espletamento fasi PCQ
        </Text>
        <br/>
        <br/>
        <DefaultButton
            text="Esporta XLSX"
            iconProps={{iconName: 'ExcelDocument'}}
            onClick={() => exportCallback('xlsx')}
        />
        &nbsp;&nbsp;&nbsp;
        <DefaultButton
            text="Esporta CSV"
            iconProps={{iconName: 'TextDocument'}}
            onClick={() => exportCallback('csv')}
        />
    </div>
}

async function exportITPEventsList(rmi: FusinaRmiNaming, job: Job, fileExt: 'xlsx' | 'csv') {
    const notTooFast_pre_download = new Promise(resolve => setTimeout(resolve, 1500))
    const notTooFast_after_download = new Promise(resolve => setTimeout(resolve, 2000))
    // NOTE: This procedure can be extremely efficient and fast, to the point that it may be confusing,
    //       while it's actually downloading a report of the most recent data from the server.
    //       We introduce a minimum duration to give a visual importance to the procedure.
    //       Pay attention that, given how the promise is already started and later awaited,
    //       there's is NO artificial delay added in case of slow internet connection or slow processing.

    // NOTE: second revision: we may actually have a quadratic complexity: num of itps for the job * num of revs

    const filter: ITPEventFilterCriteria = {
        by: 'job',
        job: {
            _id: job._id
        }
    }
    const events_prom = rmi.ITPEvents.find(filter)

    const itps = await rmi.ITP.find({by: 'job', job: {_id: job._id}})

    // NOTE: we are going to export the data about the events including the properties of the item from the historic rev
    const itpRevs = (await Promise.all(itps.map(itp => rmi.ITPRevision.findAll(itp._id))))
        .flatMap(revs => revs)  // flatten

    const events = await events_prom
    events.sort((a, b) => {
        return a?._id > b?._id ? 1 : -1
    })

    const wb = XLSX.utils.book_new()
    const hh = ["Commessa", "PCQ Doc.N", "Fase",
        "n° notifica", "Stato notifica", "Data notifica", "Data dell'ispezione",
        "Componente", "Descrizione", "Oggetto dell'ispezione"]

    const nl2s: (str: string) => string = fileExt !== 'csv' ? s => s : s =>
        s.replace(/[\n]/g, ' ')

    const ws = XLSX.utils.aoa_to_sheet([
        hh,
        ...events.map(ev => {
            // [THE_HISTORICAL_REV_LOGIC]
            const itpRevId = ev.itp?.revId ?? ev.itp?._id  // rev or dummy rev for initial rev (id of itp itself)

            // find rev or initial rev from rev list if a second rev has ever been created
            const itp: InspectionTestPlan = itpRevs.find(_itpRev => _itpRev._id === itpRevId)?.itp
                // or the only rev if no second rev has ever been created
                ?? itps.find(_itp => _itp._id === itpRevId)

            const item = itp.items.find(it => it.item === ev.item)

            return [
                job.code,
                ev.itp?.docN,
                ev.item,
                ev.itpEventN,
                EVENT_STATUS_LABELS_IT[ev.status] ?? '?',
                new Date(ev?.messages?.[0]?.dtStamp).toLocaleDateString(undefined, {dateStyle: 'long'}),
                new Date(ev?.dtStart).toLocaleDateString(undefined, {dateStyle: 'long'}),
                nl2s(item?.comp ?? ''),
                nl2s(item?.desc ?? ''),
                nl2s(ev.description),
            ]
        })
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    await notTooFast_pre_download

    XLSX.writeFile(wb, `Elenco notifiche PCQ commessa ${(job?.code ?? '').replace(/[^a-zA-Z0-9-]*/, '')}.${fileExt}`);
    // NOTE: the above function generates a download in the browser.

    await notTooFast_after_download;
}
