import * as React from 'react'
import {Base8s5gf76h} from "./layout/Base8s5gf76h";
import {OverTopBarFirstLogos} from "./components/OverTopBarFirstLogos";
import {BasePageFooter} from "./components/BasePageFooter";


export const VisitorPage: React.FC<React.PropsWithChildren<{}>> = props => {
    return <Base8s5gf76h
        overTopBarChildren={<OverTopBarFirstLogos/>}
        mainChildren={<div style={{margin: 20}}>
            {props.children}
        </div>}
        footer={<BasePageFooter/>}
    />
}
