import * as React from "react";
import {useMemo, useState} from "react";
import {Job} from "../../../fusina-jobs/entities/Job";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useForceUpdate} from "@fluentui/react-hooks";
import {SimpleDialog} from "./SimpleDialog";
import {
    DefaultButton,
    DetailsList,
    DialogType,
    Icon,
    MessageBar,
    MessageBarType,
    ProgressIndicator,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack
} from "@fluentui/react";
import {getFileTypeIconProps} from "@fluentui/react-file-type-icons";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {ConfirmedCalloutBtn} from "./ConfirmedCalloutBtn";


interface Item {
    fn: string
    date: string
    ext: string
}

interface DeletionItem extends Item {
    state?: 'queue' | 'pending' | 'deleted' | 'error'
    error?: Error
}

export const GdLDeletionDialog: React.FC<{
    job: Job
    deletingItems: Item[]
    onDismiss: () => void
    onFinish: () => void
}> = props => {
    const rmi = useFusinaRmi()

    const deletionItems = useMemo<DeletionItem[]>(() =>
        JSON.parse(JSON.stringify(props.deletingItems)), [props.deletingItems])

    const [done, setDone] = useState<number | null>(null)

    const forceUpdate = useForceUpdate()

    return <SimpleDialog
        veryEasyDismiss
        onDismiss={props.onDismiss}
        type={DialogType.close}
        title={`Eliminazione GdL`}
        subText={`Questo strumento consente di rimuovere dal sistema eventuali file caricati per errore.`}
    >
        {done === null && <div style={{marginTop: '-1em'}}>
            Stai per eliminare {deletionItems.length === 1 ? 'il seguente' : 'i seguenti'} GdL della
            commessa {props.job?.code}.
        </div>}
        <DetailsList
            styles={{
                root: {
                    marginTop: 0
                }
            }}
            selectionMode={SelectionMode.none}
            items={deletionItems}
            compact
            columns={[
                {
                    key: 'icon',
                    iconName: 'Page',
                    isIconOnly: true,
                    minWidth: 16,
                    maxWidth: 16,
                    name: 'File type',
                    onRender(item: DeletionItem) {
                        return <Icon {...getFileTypeIconProps({extension: item.ext.toLowerCase(), size: 16})} />
                    }
                },
                {
                    key: 'date',
                    name: 'Data',
                    minWidth: 100,
                    fieldName: 'date',
                    onRender(item: DeletionItem) {
                        return <time dateTime={item.date.replace(/\//, '-')}>{item.date}</time>
                    }
                },
                ...(done === null ? [] : [{
                    key: 'status',
                    name: 'Stato',
                    minWidth: 200,
                    flexGrow: 10,
                    onRender(item: DeletionItem) {
                        return <Stack horizontal tokens={{childrenGap: 's1'}}>
                            <MaybeErrorMessageBar error={item.error}/>
                            {item.state === 'queue' && <span>In coda...</span>}
                            {item.state === 'pending' && <Spinner size={SpinnerSize.xSmall}/>}
                            {item.state === 'deleted' && <span>Eliminato</span>}
                        </Stack>
                    }
                }]),
            ]}
        />

        <br/>

        {done === null && <Stack horizontal style={{justifyContent: 'flex-end'}} tokens={{childrenGap: 'm'}}>
            <DefaultButton
                text="Annulla"
                onClick={props.onDismiss}
            />
            <ConfirmedCalloutBtn
                btnProps={{
                    text: "Elimina definitivamente",
                    iconProps: {
                        iconName: "Delete"
                    },
                }}
                action={() => {
                    setDone(0)
                    return Promise.all(deletionItems.map((item, i) => new Promise<void>(resolve => {
                        item.state = 'queue'
                        setTimeout(() => {
                            item.state = 'pending'
                            if (i === 0) {
                                forceUpdate()
                            }
                            rmi.GdL.delete(props.job._id, item.date)
                                .then(() => {
                                    item.state = 'deleted'
                                })
                                .catch(err => {
                                    item.state = 'error'
                                    item.error = err
                                })
                                .finally(() => {
                                    setDone(v => v + 1)  // NOTE: this also forces UI update
                                    resolve()
                                })
                        }, i * 10)
                    }))).then()
                }}
                calloutChildren={<>
                    <MessageBar messageBarType={MessageBarType.severeWarning}>
                        Stai per eliminare {deletionItems.length} file.
                    </MessageBar>
                </>}
            />
        </Stack>}

        {done !== null && done < deletionItems.length && <ProgressIndicator
            percentComplete={done / (deletionItems.length || 1)}
            description="Eliminazione in corso..."
        />}

        {done === deletionItems.length && <Stack horizontal style={{justifyContent: 'flex-end'}}>
            <DefaultButton
                text="Chiudi"
                onClick={props.onFinish}
            />
        </Stack>}
    </SimpleDialog>
}
