import * as React from 'react'
import {CSSProperties, ReactNode, useEffect, useState} from 'react'
import './style.css'
import {useTheme} from "@fluentui/react";
import ErrorBoundary from "../../components/ErrorBoundary";
import {useLocation} from "react-router-dom";

export function Base8s5gf76h(props: {
    mainChildren: ReactNode,
    topBarChildren?: ReactNode,
    overTopBarChildren?: ReactNode,
    sideChildren?: ReactNode,
    navLinks?: ReactNode,
    footer?: ReactNode,
    pageBackground?: string,
    overTopBarStyle?: CSSProperties,
}) {
    const [navOpen, setNavOpen] = useState<boolean>()

    const location = useLocation();
    useEffect(() => {
        setNavOpen(false)
    }, [location]);

    useEffect(() => {
        if (props.pageBackground) {
            let prev = document.body.style.background
            document.body.style.background = props.pageBackground
            return () => {
                document.body.style.background = prev
            }
        }
    }, [props.pageBackground])

    const theme = useTheme()
    return <div className="Base8s5gf76h" style={{background: props.pageBackground ?? undefined}}>
        <div className="OverTopBarSpacer" style={props.overTopBarStyle ?? {
            background: theme.palette.themeDarker,
            color: theme.palette.themeLighter
        }}>
            &nbsp;
        </div>
        <div className="OverTopBar" style={props.overTopBarStyle ?? {
            background: theme.palette.themeDarker,
            color: theme.palette.themeLighter
        }}>
            {props.overTopBarChildren}
        </div>

        {/*{props.topBarChildren &&*/}
        {/*<div className="TopBar" style={{background: theme.palette.themeDark, color: theme.palette.themeLighter}}>*/}
        {/*    {props.topBarChildren}*/}
        {/*</div>}*/}

        <div className="MainContainer">
            {props.navLinks &&
            <nav className="Nav" role="menu">
                <div className="NavBtn" onClick={() => setNavOpen(v => !v)}>
                    <big>&equiv;</big> &nbsp; Operazioni
                </div>
                <div className={"NavItems" + (navOpen ? '' : ' collapsed')}>
                    {props.navLinks}
                </div>
            </nav>}
            <div className="MainPre" style={{flexGrow: 1}}>
                {props.topBarChildren &&
                <div className="TopBar"
                     style={{
                         background: 'transparent',
                         color: theme.palette.themeDarker,
                         borderBottom: `1px solid ${theme.semanticColors.buttonBorderDisabled}`
                     }}>
                    {props.topBarChildren}
                </div>}
                <div className="MainPre2">
                    <div className="Main">
                        <ErrorBoundary>
                            {props.mainChildren}
                        </ErrorBoundary>
                    </div>
                    {props.sideChildren &&
                    <div className="Side" style={{background: theme.semanticColors.bodyStandoutBackground}}>
                        <ErrorBoundary>
                            {props.sideChildren}
                        </ErrorBoundary>
                    </div>}
                </div>
            </div>
        </div>
        {props.footer && <footer style={{background: theme.palette.neutralLight, color: '#0005'}} aria-hidden={true}>
            {props.footer}
        </footer>}
    </div>
}
