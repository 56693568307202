import * as React from 'react'
import {RoleEnum, RoleLabels} from "../../../fusina-authz/entities/RoleEnum";
import {User} from "../../../fusina-users/entities/User";
import {ActionButton, IconButton, IIconProps, Label, useTheme} from "@fluentui/react";
import {useForceUpdate} from "@fluentui/react-hooks";

const addRoleIcon: IIconProps = {iconName: 'AddFriend'};
const delRoleIcon: IIconProps = {iconName: 'UserRemove'};

export const UserGlobalRolesEditor: React.FC<{
    user?: User
    onChange?: () => void
}> = props => {
    const forceUpdate = useForceUpdate()
    const theme = useTheme()

    // NOTE: We won't support adding new global roles as they are only required for accessing the global ITP cal
    //       for a matter of simplicity, but, most importantly, also because a grant to a Company is required.
    //       A user with only global roles cannot properly access most of the functionalities of this platform.
    // NOTE: But... let's support our very only reason for this editor: allow add global CDL-Q to an existing user.

    return <div>
        <br/>
        {(props.user?.globalRoles?.length >= 1) && <>
            <Label>Ruoli in ambito generale</Label>
            <table>
                <tbody>
                {props.user?.globalRoles?.map?.((gr, index) =>
                    <tr key={index}>
                        <td style={{border: '1px solid ' + theme.semanticColors.inputBorder, borderRadius: 2}}>
                            &nbsp; {RoleLabels[gr]} &nbsp;
                        </td>
                        <td>
                            &nbsp; &nbsp;
                            {gr !== RoleEnum.Admin && <IconButton
                                className="UserGrantsEditor-item-removeBtn"
                                iconProps={delRoleIcon}
                                onClick={() => {
                                    props.user.globalRoles.splice(index, 1)
                                    forceUpdate()
                                    props.onChange?.()
                                }}
                            />}
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </>}
        {[RoleEnum.SupervisorQ202310, RoleEnum.CDL_Q].map(_the_role => <>
            {((props.user?.grants?.length >= 1 && props.user?.grants?.find?.(g => g.role === _the_role)) && !props.user?.globalRoles?.includes?.(_the_role)
                ? <div>
                    <ActionButton
                        iconProps={addRoleIcon}
                        onClick={() => {
                            if (props.user.globalRoles?.length === undefined) {
                                props.user.globalRoles = []
                            }
                            props.user.globalRoles.push(_the_role)
                            forceUpdate()
                            props.onChange?.()
                        }}
                    >
                        Aggiungi ruolo {RoleLabels[_the_role]} in ambito generale
                    </ActionButton>
                </div>
                : <>
                </>)}
        </>)}
    </div>
}
