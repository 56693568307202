import * as React from "react";
import {useContext} from "react";
import {CalViews} from './commons'
import {MonthCalView} from "./MonthCalView";
import {WeekCalView} from "./WeekCalView";
import {CalContext} from "./CalContext";

export const MultiCalView: React.FC = () => {
    const [cal] = useContext(CalContext)
    const view = cal?.view

    if (view === CalViews.month) {
        return <MonthCalView/>
    }
    if (view === CalViews.week) {
        return <WeekCalView/>
    }
    const []: never[] = [view]
    console.error('Unexpected value for calendar view', view)
}
