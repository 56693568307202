import bcrypt from "bcryptjs";

const BCRYPTJS_ROUNDS: number = 11  // See benchmarks

export type VerifyPasswordResult = "verified" | "fail";

/**
 * https://github.com/dcodeIO/bcrypt.js/wiki/Benchmark
 */
export class PasswordHashing {
    hash(password: string): Promise<string> {
        return bcrypt.hash(password, BCRYPTJS_ROUNDS)
    }

    /** Returns true iff verified; false otherwise */
    async verify(password: string, hash: string): Promise<VerifyPasswordResult> {
        try {
            const res = await bcrypt.compare(password, hash)
            return res === true ? 'verified' : 'fail'
        } catch (e) {
            return 'fail'
        }
    }
}
