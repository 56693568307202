import {SecurityContext} from "../../fusina-authz/entities/SecurityContext";
import {RoleEnum} from "../../fusina-authz/entities/RoleEnum";

export function makeJobsAdminPolicy(
    securityContext: SecurityContext
) {
    const hasGlobalAdminRole = !!securityContext?.user?.globalRoles?.includes?.(RoleEnum.Admin)
    return {
        hasGlobalAdminRole,
        permissions: {
            adminJobs: hasGlobalAdminRole
        }
    }
}
