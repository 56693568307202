import * as React from 'react'
import {CSSProperties, useCallback, useEffect, useMemo, useState} from 'react'
import {FileUploadInput} from "./FileUploadInput";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {
    ChoiceGroup,
    DefaultButton,
    IChoiceGroupOption,
    IconButton,
    Label,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    ProgressIndicator,
    Stack,
    useTheme
} from "@fluentui/react";
import {EcoItem} from "../../../fusina-jobs/entities/EcoItem";
import * as XLSX from "xlsx/xlsx";
import {amountFormatter} from "../../../fusina-letters/entities/EcoOdS";
import {toast} from "react-hot-toast";

interface FmtOpt extends IChoiceGroupOption {
    /** Low-level format parsing options; Not all combinations give reasonable effects. */
    data: {
        raw: boolean,
        numbers: 'european' | 'auto',
    }
}

const csvOpt: FmtOpt = {
    key: 'european',
    text: 'Europeo (per CSV UTF-8)',
    data: {
        raw: false,
        numbers: 'european',
    }
}

/** High-level user-facing options; internally consistent. */
const formatOptions: FmtOpt[] = [
    {
        key: 'auto',
        text: 'Automatico da XLSX',
        data: {
            raw: true,
            numbers: 'auto',
        }
    },
    csvOpt,
]

const readNumber = (data: number | string | undefined, fmt: FmtOpt['data']): string => {
    if (data === undefined) {
        return undefined
    }
    if (fmt.numbers === 'european') {
        return data.toString().replace(/\./g, '').replace(/,/g, '.')
    }
    return data.toString()
}

const tdStyle: CSSProperties = {
    verticalAlign: 'top',
    padding: '0.32em 1.25em 0.32em 0.5em',
    borderTop: '1px solid #5551',
    lineHeight: '0.8em',
}

export const EcoItemsImporter: React.FC<{
    onDismiss: () => void
    onImport: (items: EcoItem[]) => void
}> = props => {
    const [error, setError] = useState<Error | undefined>(undefined)
    const [isProcessing, setIsProcessing] = useState<boolean>(false)
    const [workbook, setWorkbook] = useState<any>(undefined)
    const [sheetName, setSheetName] = useState<any>(undefined)
    const sheet = useMemo(() => workbook?.Sheets?.[sheetName], [sheetName, workbook])
    const [data, setData] = useState<EcoItem[] | undefined>(undefined)
    const [ignoredLines, setIgnoredLines] = useState<(number | string)[]>(undefined)

    const resetFile = useCallback(() => {
        setWorkbook(undefined)
        setSheetName(undefined)
        setData(undefined)
        setIgnoredLines(undefined)
        setError(undefined)
    }, [])

    const [fmtOpt, setFmtOpt] = useState<FmtOpt>(formatOptions[0])

    const processFile = useCallback(async (file: File) => {
        setIsProcessing(true)
        resetFile()
        try {
            setFmtOpt(file.type === 'text/csv' ? csvOpt : formatOptions[0])
            const workbook = XLSX.read(await file.arrayBuffer())
            console.debug({workbook})
            setWorkbook(workbook)
            setSheetName(workbook.SheetNames[0])
        } catch (e) {
            console.error(e)
            setError(e)
        }
        setIsProcessing(false)
    }, [])

    useEffect(() => {
        if (!workbook) {
            return
        }
        setIsProcessing(true)
        setData([])
        setIgnoredLines([])
        try {
            console.debug({sheetName, sheet})

            const rows = XLSX.utils.sheet_to_json(sheet, {raw: fmtOpt.data.raw}) as Record<string, unknown>[]
            console.debug({rows})

            const items = []
            rows.map(obj => ({
                ...Object.fromEntries(Object.entries(obj)
                    .map(([k, v]) => [k.trim(), (v ?? '').toString().trim()])),
                __rowNum__: obj.__rowNum__ as number,
            }))
                .filter(obj => Object.values(obj).find(v => !!v))
                .forEach(obj => {
                    const item: EcoItem = {
                        item: obj['CodiceArticolo'],
                        description: obj['DescBreve'],
                        unitOfMeasure: obj['UnitaDiMisura'],
                        unitPrice: obj['PrezzoUnitario'] ? parseFloat(readNumber(obj['PrezzoUnitario'], fmtOpt.data)) : undefined,
                    }
                    if (Object.values(item).join('') === '') {
                        return
                    }
                    if (!item.item || !item.description || !item.unitOfMeasure || !(item.unitPrice > 0)) {
                        setIgnoredLines(v => [...v, obj.__rowNum__])
                        return
                    }
                    items.push(item)
                })
            console.debug({items})
            setData(items)
        } catch (e) {
            console.error(e)
            setError(e)
        }
        setIsProcessing(false)
    }, [sheet, fmtOpt])

    useEffect(() => {
        console.debug({sheetName})
    }, [sheetName])

    const theme = useTheme();

    return <Stack tokens={{childrenGap: 'm'}}>
        {workbook === undefined && !isProcessing && <div>
            <FileUploadInput label="Seleziona un file da importare..." processFile={processFile}/>
        </div>}

        {workbook !== undefined && error === undefined && workbook.SheetNames?.length > 1 && sheetName !== undefined &&
        <ChoiceGroup
            label="Foglio"
            options={workbook.SheetNames.map((sn, i) => ({
                key: sn.toString?.(),
                text: workbook?.Props?.SheetNames?.[i] ?? sn,
            }))}
            defaultSelectedKey={sheetName}
            selectedKey={sheetName}
            onChange={(ev, option) => setSheetName(option.key)}
        />}
        {workbook !== undefined && error === undefined && workbook.SheetNames?.length === 1 && <div>
            <Label
                style={{display: 'inline'}}>Foglio</Label>: {workbook?.Props?.SheetNames?.[0] ?? workbook.SheetNames[0]}
        </div>}

        {(workbook !== undefined || isProcessing) && error === undefined && <ProgressIndicator
            label={isProcessing ? 'Lettura file in corso...' : (
                data?.length > 0 ? 'Lettura file completata!' : 'Nessun articolo trovato!')}
            percentComplete={isProcessing ? undefined : 1}
            description={`Caricate: ${data?.length ?? 0} righe${isProcessing ? '...' : '.'}`}
        />}

        {error === undefined && !(data?.length > 0) && <MessageBar
            messageBarType={workbook ? MessageBarType.warning : MessageBarType.info}
            isMultiline
            onClick={() => {
                navigator.clipboard.writeText(`CodiceArticolo\tDescBreve\tUnitaDiMisura\tPrezzoUnitario`)
                    .then(() => toast.success('Header copiati negli appunti!', {duration: 3000}))
            }}
        >
            {workbook
                ? 'Assicurati di usare esattamente i seguenti header nella prima riga'
                : 'Sono richiesti esattamente i seguenti header'}
            : <br/>
            <span style={{userSelect: 'text', fontWeight: '600'}}>
                CodiceArticolo, DescBreve, UnitaDiMisura, PrezzoUnitario
            </span>.
            &nbsp;
            <IconButton iconProps={{iconName: 'Copy'}} title="Copia negli appunti" styles={{
                root: {height: 20, transform: 'translateY(4px)'},
            }}/>
        </MessageBar>}

        {error !== undefined && <MaybeErrorMessageBar error={error}/>}

        {error === undefined && ignoredLines?.length > 0 &&
        <MessageBar messageBarType={MessageBarType.warning} truncated isMultiline={false}>
            {ignoredLines.length > 1 && <>
                Sono state ignorate {ignoredLines.length} righe non valide:
            </> || <>
                È stata ignorata una riga non valida:
            </>}
            &nbsp;riga {ignoredLines.join(', ')} nel file sorgente.
        </MessageBar>}

        {workbook !== undefined && error === undefined && data?.length > 0 && <Stack>
            <ChoiceGroup
                label="Formato numeri"
                options={formatOptions}
                selectedKey={fmtOpt.key}
                onChange={(ev, option: FmtOpt) => setFmtOpt(option)}
            />
            <br/>
            <Label>Anteprima</Label>
            <div style={{
                color: theme.semanticColors.bodySubtext,
                fontSize: theme.fonts.small.fontSize,
                wordBreak: 'break-word',
                height: 'fit-content',
                maxHeight: 'max(130px , min(40vh, calc(90vh - 550px)))',
                overflowY: 'auto',
                border: '1px solid ' + theme.semanticColors.inputBorder,
                borderRadius: 2,
            }}>
                <table style={{borderCollapse: 'collapse'}}>
                    <thead style={{fontWeight: '600'}}>
                    <tr>
                        <td style={tdStyle}>Articolo</td>
                        <td style={tdStyle}>Descrizione</td>
                        <td style={tdStyle}>U.M.</td>
                        <td style={tdStyle}>Prezzo unitario [€]</td>
                    </tr>
                    </thead>
                    <tbody>
                    {data?.map?.(item => <tr>
                        <td style={tdStyle}>{item.item}</td>
                        <td style={{
                            ...tdStyle,
                            maxWidth: 220,
                            fontSize: '0.8em',
                            lineHeight: '1em'
                        }}>{item.description}</td>
                        <td style={tdStyle}>{item.unitOfMeasure}</td>
                        <td style={{
                            ...tdStyle,
                            textAlign: 'right'
                        }}>{amountFormatter(item.unitPrice, undefined, 2)}
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </Stack>}

        <br/>

        <Stack horizontal horizontalAlign="end" style={{textAlign: "right"}} tokens={{childrenGap: 's1'}}>
            <DefaultButton
                text={"Annulla"}
                onClick={() => {
                    if (data || error) {
                        resetFile()
                    } else {
                        props.onDismiss()
                    }
                }}
            />
            <PrimaryButton
                disabled={isProcessing || data === undefined || error !== undefined}
                text={"Importa"}
                onClick={() => {
                    props.onImport(data)
                    resetFile()
                }}
            />
        </Stack>
    </Stack>
}


/* Old CSV file processing code
   NOTE: import {parse} from 'csv-parse/browser/esm';

    const source = await file.text()
    const parser = parse(source, {
        delimiter: ';',
    })
    // source.on('data', chunk => parser.write(chunk))
    let line = 0
    parser.on('data', row => {
        ++line;
        const [item, description, unitOfMeasure, unitPrice] = row.map(s => s.trim())
        if (line === 1) {
            if (item !== 'CodiceArticolo' || description !== 'DescBreve' || unitOfMeasure !== 'UnitaDiMisura' || unitPrice !== 'PrezzoUnitario') {
                const e = new Error('Header non riconosciuti.')
                setError(e)
                parser.destroy()
                throw e
            }
            return
        }
        if (`${item}${description}${unitOfMeasure}${unitPrice}` === '') {
            return
        }
        if (!item || !description || !unitOfMeasure || !unitPrice) {
            setIgnoredLines(v => [...v, line])
            return
        }
        setData(v => [...v, {
            item,
            description,
            unitOfMeasure,
            unitPrice: parseFloat(unitPrice.replace(',', '.')),
        }])
    })
    parser.once('error', e => setError(e))
    parser.once('end', () => {
        setIsProcessing(false)
        console.debug('CSV processing end.')
    })
    parser.once('close', () => {
        setIsProcessing(false)
        console.debug('CSV processing close.')
    })
    console.debug('CSV processing started.')

*/
