import * as React from "react";

/**
 * https://en.reactjs.org/docs/error-boundaries.html
 */
export default class ErrorBoundary extends React.Component<{ devMode?: boolean }, { error?: Error, hasError: boolean }> {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError(error) {
        return {
            error,
            hasError: true
        };
    }

    componentDidCatch(error, errorInfo) {
        // TODO: error reporting?
        console.error(error)
    }

    render() {
        if (this.state.hasError) {
            return <div style={{
                color: '#3d454c',
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                flexFlow: 'column',
                flexGrow: 1,
                minHeight: '20vh',
            }}>
                <span>Qualcosa è andato storto.</span>
                <br/>
                <button type="button" onClick={() => {
                    window.location.reload()
                }}>Prova a ricaricare
                </button>
            </div>;
        }
        return this.props.children;
    }
}
