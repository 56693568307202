import {SearchBox} from "@fluentui/react";
import * as React from "react";

export function CmdSearchBox(props: { onChange: (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void }) {
    return <SearchBox onChange={props.onChange} placeholder="Cerca" underlined styles={{
        root: {
            height: 43,
            borderBottomWidth: 0,
        }
    }}/>;
}
