import * as React from 'react'
import {useMemo} from 'react'
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {Icon, MessageBar, MessageBarType, Spinner, SpinnerSize, TextField, useTheme} from "@fluentui/react";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useNavigate} from "react-router-dom";
import {ConfirmedCalloutBtn} from "./ConfirmedCalloutBtn";
import {ITPHeadersList} from "./ITPHeadersList";
import {ITPRevPolicy} from "../../../fusina-itp/controls/ITPRevPolicy";
import {useSecurityContext} from "../contexts/SecurityContext";
import {useJobContext} from "../contexts/JobContext";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {useForceUpdate} from "@fluentui/react-hooks";
import {ITP_CODE_CLEANER} from "../../../fusina-itp/controls/ITPValidator";

export const ITPRevsList: React.FC<{
    itpId: string
    onDismiss?: () => void
    onNewRevCreated?: () => void
    onScreenRevId?: string
}> = props => {

    const {
        data: currItps,
        error: currItpsError,
    } = useFusinaRmiResource('ITP', 'find', {by: 'itp', itp: {_id: props.itpId}})

    const currItp = useMemo(() => currItps?.[0], [currItps])
    const currRevId = currItp?.revId ?? currItp?._id

    const {
        data: revs,
        error: revsError,
    } = useFusinaRmiResource("ITPRevision", "findAll", props.itpId)

    const hasPendingRevs = revs?.find?.(r => r._id > currRevId)

    const rmi = useFusinaRmi()

    const {job, jobError} = useJobContext()

    const error = jobError ?? currItpsError ?? revsError

    const securityContext = useSecurityContext()

    const currItpStr = useMemo<string>(() => JSON.stringify(currItp), [currItp])
    const newRevItp = useMemo<InspectionTestPlan | undefined>(() => currItp ? {
        ...JSON.parse(currItpStr),
        signatures: [],
    } : undefined, [currItpStr])

    const new_rev_policy = useMemo(() =>
        securityContext && job && currItp && newRevItp ? ITPRevPolicy({
            securityContext,
            job,
            itp: currItp,
            rev: {itp: newRevItp},
        }) : undefined, [securityContext, job, currItp, newRevItp])

    const navigate = useNavigate()

    const theme = useTheme()

    const forceUpdate = useForceUpdate()

    if (error) {
        return <MaybeErrorMessageBar error={error}/>
    }
    if (revs === undefined || currItp === undefined || new_rev_policy === undefined) {
        return <Spinner size={SpinnerSize.large}/>
    }
    return <>
        {revs?.length >= 1 && <ITPHeadersList withLastEditDate withStatus rows={revs.map(r => ({
            key: r._id,
            itp: r.itp,
            onClick() {
                navigate(`/jobs/${job?._id}/itp/${props.itpId}/rev/${r._id}`)
                props.onDismiss?.()
            },
            status: r?._id === currRevId ? <>
                {currItp.signatures.length >= 2 ? <>
                    <Icon iconName="StatusCircleInner" styles={{root: {color: theme.palette.themePrimary}}}/>
                    &nbsp; Vigente
                </> : <>
                    <Icon iconName="StatusCircleInner" styles={{root: {color: theme.palette.yellow}}}/>
                    &nbsp; In attesa di firma
                </>}
            </> : <>
                <Icon iconName="StatusCircleRing" styles={{root: {color: theme.semanticColors.disabledText}}}/>
                &nbsp; {r?._id > currRevId ? 'Pendente' : 'Obsoleta'}
            </>,
            preHighlight: props.onScreenRevId === r._id
        }))}/>}

        {revs?.length === 0 &&
        <MessageBar messageBarType={MessageBarType.info}>
            Non sono presenti revisioni per questo PCQ.
        </MessageBar>}

        {!hasPendingRevs && <>
            <br/>
            <ConfirmedCalloutBtn
                btnProps={{
                    disabled: new_rev_policy.permissions.write !== true,
                    iconProps: {
                        iconName: 'Add',
                    },
                    text: 'Nuova revisione',
                    primary: false,
                    styles: {
                        root: {
                            margin: '0 auto',
                        },
                    },
                }}
                action={() => rmi.ITPRevision.start(props.itpId, newRevItp)
                    .then(id => {
                        props.onNewRevCreated?.()  // NOTE: navigation is not sufficient from within ITPRevPage.
                        navigate(`/jobs/${job._id}/itp/${props.itpId}/rev/${id}/edit`)
                    })}
                calloutChildren={<>
                    Stai per avviare il processo di revisione del PCQ.
                    <br/>
                    <TextField
                        label="Numero revisione"
                        value={newRevItp.revN}
                        onChange={(event, newValue) => {
                            newRevItp.revN = newValue ? ITP_CODE_CLEANER(newValue) : undefined
                            forceUpdate()
                        }}
                    />
                </>}
            />
        </>}
    </>
}
