import {useFusinaRmiResource} from "./useFusinaRmiResource";
import {useCompanyContext} from "../contexts/CompanyContext";
import {Company} from "../../../fusina-jobs/entities/Company";


/** Returns the company for which the user hash a */
export function useDefaultCompany(): Company {
    const {
        data: storedCompanies,
    } = useFusinaRmiResource('JobsAdmin', 'listCompanies')

    const {company} = useCompanyContext()

    return company ?? storedCompanies?.[0]
}
