import {Remote, Serializable} from "./types";
import {RemoteError, UnauthorizedError} from "./exceptions";
import {toast} from "react-hot-toast";

type FetchFunction = (url: string, init?: RequestInit) => Promise<Response>

export interface RmiStubRegistryConfig {
    fetch?: FetchFunction
    baseUrl: string;
    token?: string | (() => string)
}

/** List of subscribers for HTTP 401 errors
 * @see subscribe401 */
const RMI_401_CALLBACKS: (() => void)[] = []


/** Subscribe for HTTP 401 errors generated by RMI calls
 * -- so you can refresh the auth status and display a login page when appropriate. */
export function subscribe401(cb: () => void) {
    const i = RMI_401_CALLBACKS.indexOf(cb)
    if (i === -1) {
        RMI_401_CALLBACKS.push(cb)
    }
}

/** Unsubscribe the given subscriber
 * @see subscribe401 */
export function unsubscribe401(cb: () => void) {
    const i = RMI_401_CALLBACKS.indexOf(cb)
    if (i >= 0) {
        RMI_401_CALLBACKS.splice(i, 1)
    }
}

const _WINDOW_ID: string = (() => {
    return crypto?.randomUUID?.() ?? Math.floor(Math.random() * 1e12 + 1e11).toString(36)
})();
const _get_loc_hash: () => string = () => {
    try {
        // noinspection JSDeprecatedSymbols
        return btoa(window?.location?.hash ?? '')
    } catch (e) {
        return undefined
    }
}

/** Client-side object proxy constructor with types */
export function RmiStub<T extends object = Remote>(registryConfig: RmiStubRegistryConfig,
                                                   instancePath: string): T {

    async function invoke(method: string, params: Serializable[]): Promise<Serializable> {

        const formData = new FormData()

        params?.forEach?.(param => {
            if (param instanceof File) {
                formData.append('file', param, param.name)
            } else {
                formData.append('param', JSON.stringify(param))
            }
        })

        const url = registryConfig.baseUrl + '/' + instancePath + '/' + method

        const init: RequestInit = {
            method: 'POST',
            body: formData,
            credentials: 'include',
            mode: 'same-origin',
            headers: {
                'X-Window-Id': _WINDOW_ID,
                'X-Win-Loc-Hash': _get_loc_hash(),
            },
        }

        // TODO: Move toast logic away from here; maybe move it to createRmiStubs.
        const toast_id = toast.loading(`${instancePath}/${method}`)

        try {
            let response;
            try {
                response = await (registryConfig.fetch ?? fetch)(url, init)
            } catch (e) {
                toast.error(`Errore di rete: ${e?.message ?? ''}`, {id: toast_id})
                throw e
            }

            if (response.status === 500) {
                const content = await response.json()
                toast.error(`${instancePath}/${method} Errore ${content?.error?.code ?? ''}`, {id: toast_id})
                throw new RemoteError(content?.error?.message, content?.error?.code)
            }
            if (response.status === 401) {
                // toast.error('Non autorizzato', {id: toast_id})
                toast.remove(toast_id)
                setTimeout(() => {
                    RMI_401_CALLBACKS.forEach(cb => {
                        try {
                            cb()
                        } catch (e) {
                            console.warn(e)
                        }
                    })
                    // NOTE: After signaling the 401 for one failed request,
                    //       we can avoid immediately signaling other concurrent requests
                    //       as first signal will lead to login page with disabled handler.
                }, 250 + 750 * Math.random())
                throw new UnauthorizedError()
            }
            if (response.status === 413) {
                toast.error(`${instancePath}/${method} Errore: payload troppo grande`, {id: toast_id})
                throw new Error('Richiesta rifiutata: payload troppo grande')
            }
            if (response.status !== 200) {
                toast.error(`${instancePath}/${method} Errore`, {id: toast_id})
                throw new Error(`Unexpected status ${response.status} from server`)
            }

            toast.remove(toast_id)
            setTimeout(() => {
                toast.remove(toast_id)
            }, 400) // failsafe toast removal because otherwise it sometimes remains spinning...
            if (!response.headers.get('Content-Type')?.startsWith?.('application/json')) {
                return response.blob()
            }
            return (await response.json())

        } finally {
            setTimeout(() => {
                toast.remove(toast_id)
            }, 5000)
        }
    }

    const methodProxyCache: Record<string, unknown> = {}

    return new Proxy<T>({} as T, {
        get(_, method) {
            if (typeof method !== 'string') {
                return undefined
            }
            if (methodProxyCache[method]) {
                return methodProxyCache[method]
            }
            const methodProxy = new Proxy(ALL_BOUND_METHODS_PROXY_TARGET, {
                apply: (_, __, params) => {
                    return invoke(method, params)
                }
            })
            methodProxyCache[method] = methodProxy
            return methodProxy
        }
    })
}

const ALL_BOUND_METHODS_PROXY_TARGET = () => {
    /* will never be called*/
}
