export const isSameDate: (a: Date, b: Date) => boolean = (a, b) => {
    const aa = new Date(a)
    const bb = new Date(b)
    aa.setHours(0, 0, 0, 0)
    bb.setHours(0, 0, 0, 0)
    const aaa = Math.floor(aa.getTime() / 1000)
    const bbb = Math.floor(bb.getTime() / 1000)
    return aaa === bbb
}

export const getShortTimeString: (date: Date) => string = date => {
    return date.toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
    })
}

export const WEEK_DAY_OFFSET = 1 // 1 => start from monday; 0 => start from sunday

/** in range 0-6 */
export const getLocalWeekDay: (date: Date) => number = date =>
    ((7 + date.getDay() - WEEK_DAY_OFFSET) % 7)

export enum CalViews {
    week = 'week',
    month = 'month',
}

export const CAL_VIEW_LABELS: Record<CalViews, string> = {
    [CalViews.month]: 'Mese',
    [CalViews.week]: 'Settimana',
}

export const CAL_VIEW_ICON_NAME: Record<CalViews, string> = {
    [CalViews.month]: 'Calendar',
    [CalViews.week]: 'CalendarWeek',
}

export const MONTHS_IT = [
    'gennaio',
    'febbraio',
    'marzo',
    'aprile',
    'maggio',
    'giugno',
    'luglio',
    'agosto',
    'settembre',
    'ottobre',
    'novembre',
    'dicembre',
]

/** Same order as JS Date.getDay(): sunday, monday, ... */
export const WEEK_DAYS_IT = [
    'domenica',
    'lunedì',
    'martedì',
    'mercoledì',
    'giovedì',
    'venerdì',
    'sabato',
]

/** Same order as JS Date.getDay(): sunday, monday, ... */
export const WEEK_DAYS_ABBR_IT = [
    'dom',
    'lun',
    'mar',
    'mer',
    'gio',
    'ven',
    'sab',
]
