import {ActionButton} from "@fluentui/react";
import * as React from "react";
import {useCallback} from "react";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {EcoOdS} from "../../../fusina-letters/entities/EcoOdS";

export function EcoOdsEstimatePreviewBtn({letter}: { letter: EcoOdS }) {
    const rmi = useFusinaRmi()

    const handleLetterPreview = useCallback(() => {
        const popup = window.open('about:blank', 'EcoOdSPreview', 'width=530,height=760,popup=yes')
        popup.document.documentElement.innerHTML = 'Loading ...'
        rmi.EcoOds.findEstimateHtml(letter._id)
            .then(html => {
                popup.document.documentElement.innerHTML = html
            })
            .catch(e => {
                popup.document.documentElement.innerHTML = e.message ?? 'Error';
            });
    }, [letter._id])

    if (!letter.estimate || letter.estimate?.signatures?.length > 0) {
        return null  // Don't generate a preview
    }

    return <ActionButton iconProps={{iconName: "Preview"}} text="Anteprima preventivo"
                         onClick={handleLetterPreview}/>;
}
