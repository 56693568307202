import {FontWeights, Text, useTheme} from "@fluentui/react";
import * as React from "react";
import {PropsWithChildren} from "react";

export const PageHeader: React.FC<PropsWithChildren<{}>> = props => {
    const theme = useTheme()
    return <Text variant="xLarge" style={{fontWeight: FontWeights.semibold, color: theme.palette.black}}>
        {props.children}
    </Text>
}
