import * as React from 'react'
import {useMemo, useState} from 'react'
import {Link, MessageBar, MessageBarType, PrimaryButton, Stack, Text, useTheme} from "@fluentui/react";
import {DateOnlyPicker} from "./DateOnlyPicker";
import {LettersExportFilter} from "../../../fusina-letters/entities/LettersExportFilter";
import {useFusinaRmiUrl} from "../hooks/useFusinaRmiUrl";
import {Job} from "../../../fusina-jobs/entities/Job";

export const LettersExportArchiveForm: React.FC<{
    job: Job,
    onDismiss: () => void,
}> = props => {
    const [exportArchiveFilter, setExportArchiveFilter] = useState<LettersExportFilter>({})
    const exportArchiveUrl = useFusinaRmiUrl('Letters', 'exportByJob', props.job?._id, exportArchiveFilter)
    const isClear = useMemo(() => JSON.stringify(exportArchiveFilter) === '{}', [exportArchiveFilter])
    const isInvalidRange = exportArchiveFilter?.minDate && exportArchiveFilter?.maxDate
        && exportArchiveFilter?.minDate > exportArchiveFilter?.maxDate

    const theme = useTheme()

    return <>
        <Stack tokens={{childrenGap: 'm'}}>
            <Stack horizontal wrap tokens={{childrenGap: 's2'}}>
                <Stack.Item grow={1} style={{minWidth: 200}}>
                    <DateOnlyPicker value={exportArchiveFilter?.minDate} label="Data iniziale"
                                    styles={{
                                        root: {
                                            opacity: exportArchiveFilter?.minDate ? 1 : 0.7,
                                        }
                                    }}
                                    onChange={newValue => setExportArchiveFilter(f => ({...f, minDate: newValue}))}/>
                </Stack.Item>
                <Stack.Item grow={1} style={{minWidth: 200}}>
                    <DateOnlyPicker value={exportArchiveFilter?.maxDate} label="Data finale"
                                    styles={{
                                        root: {
                                            opacity: exportArchiveFilter?.maxDate ? 1 : 0.7,
                                        }
                                    }}
                                    onChange={newValue => setExportArchiveFilter(f => ({...f, maxDate: newValue}))}/>
                </Stack.Item>
            </Stack>
            {isInvalidRange &&
            <MessageBar messageBarType={MessageBarType.warning}>Intervallo date non valido</MessageBar>}
            {!isClear && <Stack.Item grow={1} align="center">
                <Link onClick={() => setExportArchiveFilter({})}>
                    Cancella filtri
                </Link>
            </Stack.Item>}
            <PrimaryButton
                href={exportArchiveUrl}
                target="_blank"
                onClick={props.onDismiss}
                text="Avvia download"
                // iconProps={{iconName: 'Download'}}
            />
            <Stack.Item grow={1}>
                <Text variant="smallPlus" style={{color: theme.semanticColors.bodySubtext}}>
                    Saranno escluse le bozze e le lettere in attesa di firma del mittente o del destinatario.
                </Text>
                {/*<Stack tokens={{childrenGap: 's2'}}>*/}
                {/*    <Checkbox disabled label={"Bozze"}/>*/}
                {/*    <Checkbox disabled label={"In attesa di firma"}/>*/}
                {/*    <Checkbox disabled checked label={"Lettere ricevute dal destinatario"}/>*/}
                {/*</Stack>*/}
            </Stack.Item>
        </Stack>
    </>
}
