import * as React from 'react'
import {useMemo, useState} from 'react'
import {useForceUpdate} from "@fluentui/react-hooks";
import {
    MessageBar,
    MessageBarButton,
    MessageBarType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    TextField
} from "@fluentui/react";
import {Stack} from '@fluentui/react/lib/Stack';

import {Editor} from '../../../editor-react/Editor';
import {Letter, parseExternalRefNumber} from "../../../fusina-letters/entities/Letter";
import {OrderItemsEditor} from "./OrderItemsEditor";
import {LetterAttachmentsEditor} from "./LetterAttachmentsEditor";
import {EcoOdS, OrderEstimate} from "../../../fusina-letters/entities/EcoOdS";
import {EcoOdsEstimatePolicy} from "../../../fusina-letters/controls/EcoOdsPolicy";
import {useSecurityContext} from "../contexts/SecurityContext";
import {useJobContext} from "../contexts/JobContext";
import LettersPolicy from "../../../fusina-letters/controls/LettersPolicy";
import {DateOnlyPicker, formatDateOnly} from "./DateOnlyPicker";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {CancelEditsBtn} from "./CancelEditsBtn";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {getMetLetterNumberingOptions} from "../../../fusina-letters/entities/LetterNumberingOptions";
import {useDelayedValue} from "../hooks/useDelayedValue";
import {RoleEnum} from "../../../fusina-authz/entities/RoleEnum";

export interface LetterEditorProps {
    object: Letter
    onSaveSuccess: () => void
    onDismiss: () => void
}

const TODAY = new Date()


/**
 * Editor UI for fusina letters.
 *
 * NOTE: mutations are applied on the same object instance passed in the props, without reactivity.
 *
 */
export const LetterEditor: React.FC<LetterEditorProps> = props => {
    const forceUpdate = useForceUpdate();
    const rmi = useFusinaRmi()
    const [editingRef, setEditingRef] = useState<string>(props.object?.externalRef)
    const [pending, setPending] = useState<boolean>(false)
    const [error, setError] = useState<Error | undefined>(undefined)

    const numberingQuery_ = useMemo<Pick<Letter, 'job' | 'externalRef' | '_id' | 'type'>>(() => ({
        _id: props.object._id,
        type: props.object.type,
        job: props.object.job,
        externalRef: props.object.externalRef,
    }), [props.object._id, props.object.type, props.object.job, props.object.externalRef]);

    const numberingQuery = useDelayedValue(numberingQuery_, 300)

    const {
        data: numOpts,
        refresh: numberingRefresh,
        error: numberingError,
    } = useFusinaRmiResource('Letters', 'getNumberingOptions', numberingQuery)

    const parsedObjExtRef = useMemo(() =>
        parseExternalRefNumber(props.object?.externalRef), [props.object?.externalRef])

    const refNumError = useMemo<Error | undefined>(() => {
        if (numOpts === undefined || parsedObjExtRef === undefined) {
            return undefined
        }
        const parsed = parsedObjExtRef;
        const ref = parsed?.normalized;
        if (!ref || ref !== editingRef) {
            return new Error('Formato numerazione non valido' + (
                numOpts ? `. Suggerimento: ${numOpts.append.externalRef}` : ''))
        }
        if (parsed.progressive <= 0) {
            return new Error(`Utilizzare numero ${numOpts.append.externalRef ?? '1'}.`)
        }
        if (ref === numOpts.append.externalRef) {
            return undefined
        }
        if ((numOpts.append.externalRef !== undefined && ref > numOpts.append.externalRef)
            || numOpts.forbidden === 'free_insert'
            || numOpts.forbidden === 'hole') {
            return new Error(`Utilizzare numero ${numOpts.append.externalRef}.`)
        }
        if (numOpts.forbidden === 'duplicate') {
            return new Error(`Numero ${ref} già occupato. ` + (
                parsed.postfix36 === null
                    ? `Puoi aggiungere un suffisso; esempio: ${ref}/B`
                    : 'Usare un suffisso diverso.'
            ))
        }
    }, [editingRef, parsedObjExtRef, numOpts])

    const dateError = useMemo<Error | undefined>(() => {
        if (numOpts === undefined || !props.object.date || !props.object.externalRef) {
            return undefined
        }
        if (props.object?.externalRef === numOpts.append.externalRef) {
            if (props.object.date < numOpts.append.minDate) {
                return new Error('Data troppo vecchia per la numerazione scelta');
            }
        }
        if (numOpts?.insert === undefined) {
            return // Not a date issue
        }
        if (props.object.date < numOpts.insert.minDate) {
            return new Error('Data troppo vecchia per la numerazione scelta');
        }
        if (props.object.date > numOpts.insert.maxDate) {
            return new Error('Data troppo recente per la numerazione scelta');
        }
    }, [props.object.date, props.object.externalRef, numOpts])

    const securityContext = useSecurityContext()
    const {job} = useJobContext()
    const canEditSubjectAndBody = useMemo<boolean | undefined>(() => {
        if (securityContext === undefined || job === undefined || props.object === undefined) {
            return undefined
        }
        return LettersPolicy({
            job,
            securityContext,
            oldLetter: props.object,
            newLetter: {
                ...props.object,
                subject: props.object?.subject + 'EDIT TEST',
                message: props.object?.message + 'EDIT TEST',
            }
        }).permissions.write === true
        // Prevent errors while using admin privileged corrective operations (task #2650)
    }, [securityContext, job, props.object])

    const canIgnoreDateErrors = useMemo(() =>
        securityContext?.user?.globalRoles?.includes?.(RoleEnum.Admin), [securityContext])

    return <>
        <Stack>
            <Stack horizontal tokens={{childrenGap: "m"}}>
                <Stack.Item grow={1}>
                    <TextField
                        label="n°" value={editingRef}
                        maxLength={4 + 1 + 3}
                        onChange={(ev, newValue) => {
                            const v = newValue.replace(/[^\/a-zA-Z0-9]/g, '').toUpperCase()
                            setEditingRef(v)
                            const ref = parseExternalRefNumber(v)?.normalized
                            if (ref === v) {
                                props.object.externalRef = ref
                            }
                            forceUpdate()
                        }}/>
                </Stack.Item>
                <Stack.Item grow={1}>
                    <DateOnlyPicker
                        label="Data" value={props.object?.date ?? formatDateOnly(TODAY)}
                        styles={{root: {minWidth: 150}}}
                        onChange={(newValue) => {
                            props.object.date = newValue;
                            forceUpdate()
                        }}/>
                </Stack.Item>
            </Stack>
            <MaybeErrorMessageBar error={numberingError}/>
            <MaybeErrorMessageBar error={refNumError ?? (!canIgnoreDateErrors ? dateError : undefined)}/>
            {(dateError && !refNumError) && numOpts && <div>
                Opzioni:
                <ul style={{marginTop: 0}}>
                    <li>
                        Utilizzare n° {numOpts.append.externalRef} e data uguale o successiva
                        a {numOpts.append.minDate}
                    </li>
                    {numOpts.insert && <>
                        {(numOpts.insert.minDate < numOpts.insert.maxDate) &&
                        <li>
                            Inserire data compresa tra {numOpts.insert.minDate} e {numOpts.insert.maxDate}
                        </li>}
                        {(numOpts.insert.minDate === numOpts.insert.maxDate) &&
                        <li>
                            Inserire data {numOpts.insert.minDate ?? numOpts.insert.maxDate}
                        </li>}
                        {parsedObjExtRef?.postfix36 !== null
                            ? <li>
                                Inserire un suffisso diverso nella numerazione
                            </li>
                            : <li>
                                Inserire un suffisso alfanumerico; esempio: {parsedObjExtRef?.normalized}/B
                            </li>}
                    </> || (props.object.date < numOpts.append.minDate && <>
                        <li>
                            Usare una numerazione precedente, eventualmente con un suffisso alfanumerico;
                            esempio: 0001/B
                        </li>
                    </>)}
                </ul>
            </div>}

            <TextField
                label="Oggetto"
                readOnly={!canEditSubjectAndBody}
                disabled={!canEditSubjectAndBody}
                multiline
                autoAdjustHeight
                value={props.object?.subject}
                onChange={(ev, newVal) => {
                    props.object.subject = (newVal ?? '')
                        .replace(/\n/g, ' ')
                        .replace(/\r/g, ' ')
                        .replace(/[ ]+/g, ' ')
                    forceUpdate()
                }}/>

            {canEditSubjectAndBody === undefined && <Spinner/>}
            {canEditSubjectAndBody && <>

                <LetterBodyEditor letter={props.object}/>

                <LetterAttachmentsEditor letter={props.object}/>
            </>}

            <br/>
            <Stack horizontal horizontalAlign="end" tokens={{childrenGap: 's1'}}>
                <MaybeErrorMessageBar error={error}/>

                <CancelEditsBtn editable={props.object} onClick={props.onDismiss}/>
                <PrimaryButton key="save" disabled={pending || !!refNumError || (!!dateError && !canIgnoreDateErrors)}
                               onClick={async () => {
                                   setPending(true);
                                   try {
                                       const numOpts = await rmi.Letters.getNumberingOptions(props.object)
                                       const met = getMetLetterNumberingOptions(props.object, numOpts)
                                       if (met.length <= 0 && !canIgnoreDateErrors) {
                                           await numberingRefresh();
                                           setError(new Error('Verificare n° e data, quindi riprovare.'))
                                           return
                                       }
                                       if (props.object._id) {
                                           await rmi.Letters.updateDraft(props.object._id, props.object)
                                       } else {
                                           props.object._id = await rmi.Letters.createDraft(props.object)
                                       }
                        props.onSaveSuccess?.()
                    } catch (err) {
                        setError(err)
                    } finally {
                        forceUpdate()
                        setPending(false)
                    }
                }}>
                    Salva&nbsp;bozza {pending && <Spinner size={SpinnerSize.xSmall} style={{marginLeft: '0.5em'}}/>}
                </PrimaryButton>
            </Stack>
        </Stack>
    </>
}

/** Rest of the letter editor UI apart from the heading info */
const LetterBodyEditor: React.FC<{
    letter: Letter
}> = props => {
    if (props.letter.type === 'EcoOdS') {
        return <EcoOdsBodyEditor letter={props.letter}/>
    }
    return <LetterMessageEditor letter={props.letter}/>
}

/** Specific logic for EcoOds (letters with estimate) */
const EcoOdsBodyEditor: React.FC<{
    letter: EcoOdS
}> = props => {
    const [inserted, setInserted] = useState<boolean>(() =>
        props.letter?.estimate ? !!props.letter?.message?.includes?.(makeEstimateHtmlTable(props.letter.estimate)) : undefined)

    const forceUpdate = useForceUpdate();
    const sc = useSecurityContext()
    const {job} = useJobContext()
    const estPolicy = EcoOdsEstimatePolicy({
        securityContext: sc,
        oldLetter: props.letter,
        newLetter: props.letter,
        job: job,
    })
    const letterPolicy = LettersPolicy({
        securityContext: sc,
        oldLetter: props.letter,
        newLetter: props.letter,
        job: job,
    })

    if (estPolicy.permissions.write) {
        // The estimate can be edited, then we will edit it.
        return <>
            <br/>
            <MessageBar messageBarType={MessageBarType.info}>
                <b>Step 1 di 2</b>:
                Stai predisponendo il preventivo dell'OdS.
                Dopo la convalida del preventivo sarà possibile inserire l'ordine nel messaggio della lettera.
            </MessageBar>
            <LetterMessageEditor letter={props.letter}/>
            <OrderItemsEditor items={props.letter.estimate.items} contractualItems={job?.contractItems}/>
            <br/>
        </>
    }
    // if (!estPolicy.signedByPM) {
    //     // Also consider the intermediate state: the estimate cannot be written because is signed, but not by PM.
    //     // In that situation the CDL must be warned that the estimate is still not confirmed.
    //     // NOTE: we cannot allow changing the message now as it would affect the estimate rendering.
    //     // NOTE: post scriptum: I later added a policy condition preventing
    //     return <>
    //         <br/>
    //         <MessageBar messageBarType={MessageBarType.warning}>
    //             Il preventivo è in attesa di convalida.
    //         </MessageBar>
    //         <br/>
    //     </>
    // }
    if (letterPolicy.permissions.write) {
        // The estimate cannot be edited, then we consider it as confirmed and we move on to edit the message.
        return <>
            <div>&nbsp;</div>
            {!inserted &&
            <MessageBar
                messageBarType={MessageBarType.info}
                isMultiline={false}
                actions={
                    <div>
                        <MessageBarButton onClick={() => {
                            props.letter.message += ' \n ' + makeEstimateHtmlTable(props.letter.estimate);
                            setInserted(true)
                            forceUpdate()
                        }}>
                            Inserisci
                        </MessageBarButton>
                    </div>
                }
            >
                C'è un preventivo associato a questa lettera. Inserire l'ordine nel messaggio?
                {/* TODO: Action button to copy info into an HTML table */}
            </MessageBar>}
            <LetterMessageEditor letter={props.letter}/>
        </>
    }
}

/** Generic logic for the textual message of a letter */
const LetterMessageEditor: React.FC<{
    letter: Letter
}> = props => {
    return <>
        <Editor label="Messaggio"
                initialValue={props.letter.message ?? ''}
                onChange={(v) => {
                    props.letter.message = v
                }}/>
    </>
}

const htmlEntities = raw => raw.replace(/[\u00A0-\u9999<>\&]/g, c => `&#${c.charCodeAt(0)};`)

const makeEstimateHtmlTable = (estimate: OrderEstimate) => `
    <table>
        <tr>
            <td><b>Articolo</b></td>
            <td><b>Descrizione</b></td>
            <td><b>U.M.</b></td>
            <td><b>Quantità</b></td>
        </tr>
        ${estimate.items.map(item => `
        <tr>
            <td>${htmlEntities(item.item)}</td>
            <td>${htmlEntities(item.description)}</td>
            <td>${item.unitOfMeasure}</td>
            <td>${item.quantity}</td>
        </tr>
        `).join('')}
    </table>
`
