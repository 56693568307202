import * as React from 'react'
import {ThemeProvider} from '@fluentui/react'
import {initializeIcons} from '@fluentui/font-icons-mdl2';
import {initializeFileTypeIcons} from '@fluentui/react-file-type-icons';

import {HashRouter as Router, Route, Routes,} from "react-router-dom";
import {Toaster} from 'react-hot-toast';


import './index.css'

import {RmiContext} from "../../js-rmi-react/RmiContext";
import {SecurityContextStore} from "./contexts/SecurityContext";
import {CompanyContextStore} from "./contexts/CompanyContext";
import {Page} from "./Page";
import {PageContextStore} from "./contexts/PageContext";
import {AdminHome} from "./pages/AdminHome";
import {JobContextStore} from "./contexts/JobContext";
import {VisitorPage} from "./VisitorPage";
import {LoginPage} from "./pages/LoginPage";
import {PUBLIC_RMI, RMI} from "./config";
import {ServerStatusStore} from "./contexts/ServerStatusContext";
import {EcoOdsExpenses} from "./pages/EcoOdsExpenses";
import {GdL} from "./pages/GdL";
import {Letters} from "./pages/Letters";
import {Home} from "./pages/Home";
import {ITP} from "./pages/ITP";
import {ITPEventPage} from "./pages/ITPEventPage";
import {ITPStatsPage} from "./pages/ITPStatsPage";
import {ITPCalendarPage} from "./pages/ITPCalendarPage";
import {ITPGlobalCalendarPage} from "./pages/ITPGlobalCalendarPage";
import {ITPRevPage} from "./pages/ITPRevPage";
import {myTheme} from "./themes";

initializeIcons('/fonts/');
initializeFileTypeIcons();  //TODO?: download the SVG icons?

console.debug({rmi: RMI})


export const App: React.FC = () => {
    return <React.StrictMode>
        <ServerStatusStore>
            <RmiContext.Provider value={RMI}>
                <SecurityContextStore visitorPage={
                    <RmiContext.Provider value={PUBLIC_RMI}>
                        <ThemeProvider theme={myTheme}>
                            <Router>
                                <VisitorPage>
                                    <Routes>
                                        <Route path="*" element={<LoginPage/>}/>
                                    </Routes>
                                </VisitorPage>
                            </Router>
                        </ThemeProvider>
                    </RmiContext.Provider>
                }>
                    <CompanyContextStore>
                        <JobContextStore>
                                <PageContextStore>
                                    <Router>
                                        <Page>
                                            <Routes>
                                                <Route path="admin/*" element={<AdminHome/>}/>
                                                <Route path="itpStats" element={<ITPStatsPage/>}/>
                                                <Route path="jobs/:jobId/gdl" element={<GdL/>}/>
                                                <Route path="jobs/:jobId/letters/:letterId/expenses"
                                                       element={<EcoOdsExpenses/>}/>
                                                <Route path="jobs/:jobId/letters/:letterId" element={<Letters/>}/>
                                                <Route path="jobs/:jobId/letters/" element={<Letters/>}/>
                                                <Route path="jobs/:jobId/itp/" element={<ITP/>}/>
                                                <Route path="jobs/:jobId/itp/:itpId" element={<ITP/>}/>
                                                <Route path="jobs/:jobId/itp/:itpId/rev/:revId"
                                                       element={<ITPRevPage/>}/>
                                                <Route path="jobs/:jobId/itp/:itpId/rev/:revId/:editFlag"
                                                       element={<ITPRevPage/>}/>
                                                <Route path="jobs/:jobId/itp/:itpId/events/:eventId"
                                                       element={<ITPEventPage/>}/>
                                                <Route path="jobs/:jobId/itpCal" element={<ITPCalendarPage/>}/>
                                                <Route path="jobs/:jobId/itpCal/:view/:date"
                                                       element={<ITPCalendarPage/>}/>
                                                <Route path="jobs/:jobId/itpCal/:view/:date/:eventId"
                                                       element={<ITPCalendarPage/>}/>
                                                <Route path="itpGlobalCal" element={<ITPGlobalCalendarPage/>}/>
                                                <Route path="itpGlobalCal/:view/:date"
                                                       element={<ITPGlobalCalendarPage/>}/>
                                                <Route path="itpGlobalCal/:view/:date/:eventId"
                                                       element={<ITPGlobalCalendarPage/>}/>
                                                <Route path="jobs/:jobId" element={<Home/>}/>
                                                <Route path="" element={<Home/>}/>
                                                <Route path="*" element={<>Pagina non trovata</>}/>
                                            </Routes>
                                        </Page>
                                    </Router>
                                </PageContextStore>
                        </JobContextStore>
                    </CompanyContextStore>
                </SecurityContextStore>
            </RmiContext.Provider>
            <Toaster
                containerStyle={{zIndex: 2000000}}
                position="bottom-right"/>{/* toastOptions={{iconTheme: {primary: myTheme.semanticColors.primaryButtonBackground, secondary: myTheme.semanticColors.buttonBackground}}}/>*/}
        </ServerStatusStore>
    </React.StrictMode>
}
