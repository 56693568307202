import * as React from 'react'

import {PageSetting} from "../contexts/PageContext";
import {useParams} from "react-router-dom";
import {DefaultButton, Spinner, SpinnerSize} from "@fluentui/react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {useJob} from "../hooks/useJob";
import {JobHeadingFrag} from "../components/JobHeadingFrag";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import {ITPTable} from "../components/ITPTable";
import {FrontendComponent, usePermittedFrontendComponents} from "../hooks/usePermittedFrontendComponents";


export const ITP: React.FC = () => {
    const {jobId} = useParams()
    const {job, jobError} = useJob(jobId)

    useFusinaRmiResource('ITP', 'find', {by: 'job', job: {_id: jobId}})  // Start loading before it's needed.

    const comp = usePermittedFrontendComponents()

    if (!job) {
        return <MaybeErrorMessageBar error={jobError}/> || <>
            <br/>
            <Spinner size={SpinnerSize.large}/>
        </>
    }

    return <>
        <PageSetting
            iconName="Trackers"
            title={<>
                <div style={{paddingRight: 15}}>Piani Controllo Qualità</div>
                <JobHeadingFrag/>
                {comp.has(FrontendComponent.ITPCal) && <>
                    <div style={{flexGrow: 1}}>
                    </div>
                    <DefaultButton
                        style={{marginTop: 6}}
                        iconProps={{
                            iconName: "Calendar"
                        }}
                        text="Calendario"
                        href={`#/jobs/${jobId}/itpCal`}
                    />
                </>}
            </>}
            sideChildren={null}
        />
        <ITPTable job={job}/>
    </>
}
