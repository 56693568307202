import * as React from 'react'

import {PageSetting} from "../contexts/PageContext";
import {useParams} from "react-router-dom";
import {Spinner, SpinnerSize} from "@fluentui/react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {useJob} from "../hooks/useJob";
import {GdLTable} from "../components/GdLTable";
import {JobHeadingFrag} from "../components/JobHeadingFrag";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";


export const GdL: React.FC = () => {
    const {jobId} = useParams()
    const {job, jobError} = useJob(jobId)

    useFusinaRmiResource('GdL', 'list', jobId)  // Start loading before it's needed.

    if (!job) {
        return <MaybeErrorMessageBar error={jobError}/> || <>
            <br/>
            <Spinner size={SpinnerSize.large}/>
        </>
    }

    return <>
        <PageSetting
            iconName="TableGroup"
            title={<>
                <div style={{paddingRight: 15}}>Giornali del Lavoro</div>
                <JobHeadingFrag/>
            </>}
            sideChildren={null}
        />
        <GdLTable job={job}/>
    </>
}
