import {ArgsType} from "../../../js-rmi-react/useRmiResource";
import {RMI_API_ROOT} from "../config";
import {FusinaRmiNaming} from "../../../fusina-dms-rmi-api/naming";

export function useFusinaRmiUrl<K extends keyof FusinaRmiNaming = keyof FusinaRmiNaming, M extends keyof FusinaRmiNaming[K] = keyof FusinaRmiNaming[K], E = Error>(path: K, method: M, ...args: ArgsType<FusinaRmiNaming[K][M]>): string {

    // NOTE: This is actually a simple function, not a react hook, and is being used as such.
    //       => Don't use hooks here.

    const sp = args.length > 0 ? '?' + new URLSearchParams(args.map(a => ['param', JSON.stringify(a)])).toString() : ''
    return `${RMI_API_ROOT}/${path}/${method.toString()}${sp}`
}
