/** QCR Records codes */
export enum QCRCode {
    S = "S",
    Q = "Q",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
}

export interface QCRCodeDmsSemantics {
    isFileUploadAllowed: boolean
}

export const getQCRCodeDmsSemantics: (code: QCRCode) => QCRCodeDmsSemantics = (code: QCRCode) => {
    switch (code) {
        case QCRCode.S:
            return {isFileUploadAllowed: false}
        case QCRCode.Q:
        case QCRCode.A:
        case QCRCode.B:
        case QCRCode.C:
        case QCRCode.D:
            return {isFileUploadAllowed: true}
        default:
            throw new Error('Unknown QCR code')
    }
}

export const getQCRCodeMeaning = (code: QCRCode) => {
    switch (code) {
        case QCRCode.S:
            return "Solo firma del PCQ"
        case QCRCode.Q:
            return "Certificati di qualifica e documetazione pertinente alla fase"
        case QCRCode.A:
            return "Certificato di controllo/esecuzione test"
        case QCRCode.B:
            return "Certificato di origine del materiale"
        case QCRCode.C:
            return "Certificato di Conformità"
        case QCRCode.D:
            return "Certificato tipico in accordo alla norma applicabile"
        default:
            throw new Error('Unknown QCR code')
    }
}

export const getQCRCodeTitle = (code: QCRCode) => {
    switch (code) {
        case QCRCode.S:
            return "QCP SIGNATURE"
        case QCRCode.Q:
            return "CERTIFICATE OF QUALIFICATION"
        case QCRCode.A:
            return "TEST CERTIFICATE OR CONTROL CERTIFICATE"
        case QCRCode.B:
            return "MILL CERTIFICATE"
        case QCRCode.C:
            return "CERTIFICATE OF COMPLIANCE"  // COMPILANCE (from provided doc) or COMPLIANCE (english)?
        case QCRCode.D:
            return "TYPE TEST CERTIFICATE"
        default:
            throw new Error('Unknown QCR code')
    }
}
