import {useCallback, useState} from "react";

export function useLocallyStoredState<S>(localStorageKey: string, initialState: S | (() => S)): [S, (newState: S) => void] {
    const [state, setState] = useState(() => {
        try {
            const v = window?.localStorage?.getItem?.(localStorageKey) ?? null
            if (v !== null) {
                return JSON.parse(v)
            }
        } catch (e) {
            console.warn(e)
        }
        if (typeof initialState === 'function') {
            return (initialState as (() => S))()
        }
        return initialState
    })

    const setStateIntercepted = useCallback((newState: S) => {
        setState(newState)
        try {
            window?.localStorage?.setItem?.(localStorageKey, JSON.stringify(newState))
        } catch (e) {
            console.warn(e)
        }
    }, [setState])

    return [state, setStateIntercepted]
}
