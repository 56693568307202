import * as React from 'react'
import {mergeStyles, useTheme} from "@fluentui/react";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import './ITPStatsTable.css'

export interface ITPHeadersListRow {
    key: number | string
    itp: Pick<InspectionTestPlan, 'docN' | 'revN' | 'title' | 'lastEditDate'>
    onClick?: () => void
    status?: React.ReactNode
    preHighlight?: boolean
}

export const ITPHeadersList: React.FC<{
    withTitle?: boolean
    withLastEditDate?: boolean
    withStatus?: boolean
    rows: ITPHeadersListRow[]
}> = props => {
    const theme = useTheme()
    const minorParTdStyle = mergeStyles({
        fontSize: theme.fonts.small.fontSize,
        color: theme.semanticColors.bodySubtext,
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        maxHeight: 75,
        maxWidth: 350,
        textOverflow: 'ellipsis',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
        paddingLeft: '8px !important',
        paddingRight: '12px !important',
        textAlign: 'left !important',
    })
    const majorTdStyle = mergeStyles({
        overflow: 'hidden',
        maxHeight: 75,
        textOverflow: 'ellipsis',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
        paddingLeft: '8px !important',
        paddingRight: '12px !important',
    })

    return <>
        <table className="ITPStatsTable noHides selectRow" style={{borderTop: '1px solid #7772'}}>
            <thead>
            <tr>
                <td className={majorTdStyle}>PCQ doc. N</td>
                <td className={majorTdStyle}>Rev.</td>
                {props.withTitle && <td className={minorParTdStyle}>Titolo</td>}
                {props.withLastEditDate && <td className={minorParTdStyle}>Ultima modifica</td>}
                {props.withStatus && <td className={minorParTdStyle}>Stato</td>}
            </tr>
            </thead>
            <tbody>
            {props.rows.map((row, i) => <tr
                key={row.key}
                onClick={row.onClick}
                className={row.preHighlight ? 'preHighlight' : undefined}
            >
                <td className={majorTdStyle}>{row.itp.docN}</td>
                <td className={majorTdStyle}>{row.itp.revN}</td>
                {props.withTitle && <td className={minorParTdStyle}>{row.itp.title}</td>}
                {props.withLastEditDate && <td className={minorParTdStyle}>{lastEditDateFmt(row.itp.lastEditDate)}</td>}
                {props.withStatus && <td className={minorParTdStyle}>{row.status ?? ''}</td>}
            </tr>)}
            </tbody>
        </table>
    </>
}

const lastEditDateFmt: (date?: Date | string) => string = date =>
    new Date(date).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })
