import {Job} from "../../fusina-jobs/entities/Job";
import {EcoOdS} from "../entities/EcoOdS";
import {DailyOrderExpenses} from "../entities/DailyOrderExpenses";
import {RoleEnum} from "../../fusina-authz/entities/RoleEnum";
import {PolicyContext} from "../../fusina-policies/PolicyContext";
import LettersPolicy from "./LettersPolicy";
import {InvalidContextException} from "../../fusina-policies/exceptions";

// TODO: Review and test all of the following

export interface EcoOdsPolicyContext extends PolicyContext {
    job: Job
    oldLetter?: EcoOdS
    newLetter?: EcoOdS
    estimateSignatureRole?: RoleEnum
    writingExpenses?: DailyOrderExpenses
}

/**
 * Operational policy regarding the EcoOdS's.
 *
 * NOTE: The policy can be evaluated also without specifying any letter.
 */
export function EcoOdsEstimatePolicy(ctx: EcoOdsPolicyContext) {
    if ((ctx.oldLetter && ctx.oldLetter.type !== 'EcoOdS') ||
        (ctx.newLetter && ctx.newLetter.type !== 'EcoOdS')) {
        throw new InvalidContextException('Not an EcoOds')
    }
    // NOTE: letters are optionally specified
    const letterPolicy = LettersPolicy(ctx);
    const sc = ctx.securityContext;
    const myRoles = new Set(letterPolicy.myRoles)
    const iAmBuyer = letterPolicy.iAmBuyer
    const iAmAdmin = letterPolicy.iAmAdmin
    const signatures = ctx.oldLetter?.estimate?.signatures ?? []  // NOTE: letters are optionally specified
    const signedByMe = !!signatures.find(s => s.userId === sc.userId)
    const signedByCDL = !!signatures.find(s => s.role === RoleEnum.CDL)
    const signedBySM = !!signatures.find(s => s.role === RoleEnum.SM)
    const signedByPM = !!signatures.find(s => s.role === RoleEnum.PM)
    const letterWritePermission = letterPolicy.permissions.write

    return {
        myRoles, iAmBuyer,
        iAmAdmin,
        signatures, signedByMe, signedByCDL, signedBySM, signedByPM,
        letterWritePermission,

        permissions: {
            // NOTE: letters are optionally specified

            read: iAmBuyer || iAmAdmin,

            sign: !signedByMe && iAmBuyer && myRoles.has(ctx.estimateSignatureRole) && (
                (ctx.estimateSignatureRole === RoleEnum.CDL) ||
                (ctx.estimateSignatureRole === RoleEnum.SM && signedByCDL) ||
                (ctx.estimateSignatureRole === RoleEnum.PM && signedByCDL && signedBySM) ||

                false // close off the or chain
            ),

            write: letterWritePermission && iAmBuyer && signatures.length <= 0,
        }
    }
}

export function ExpensesPolicy(ctx: EcoOdsPolicyContext) {
    if ((ctx.oldLetter && ctx.oldLetter.type !== 'EcoOdS') ||
        (ctx.newLetter && ctx.newLetter.type !== 'EcoOdS')) {
        throw new InvalidContextException('Not an EcoOds')
    }
    const letterPolicy = LettersPolicy(ctx)
    const iAmBuyer = letterPolicy.iAmBuyer
    const iAmAdmin = letterPolicy.iAmAdmin

    // TODO: no read/write if not sent

    return {
        iAmBuyer,
        iAmAdmin,
        permissions: {
            read: iAmBuyer || iAmAdmin,
            write: iAmBuyer,
        }
    }
}
