import {createTheme} from "@fluentui/react";

export const myTheme = createTheme({
    palette: {
        themePrimary: '#1a6fed',
        themeLighterAlt: '#f5f9fe',
        themeLighter: '#d8e7fc',
        themeLight: '#b7d2fa',
        themeTertiary: '#72a6f4',
        themeSecondary: '#347fef',
        themeDarkAlt: '#1763d5',
        themeDark: '#1454b4',
        themeDarker: '#0f3e85',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#9baede',
        neutralSecondary: '#4c6cbd',
        neutralSecondaryAlt: '#4c6cbd',
        neutralPrimaryAlt: '#133b9f',
        neutralPrimary: '#002991',
        neutralDark: '#001f6e',
        black: '#001751',
        white: '#ffffff',
    }
});

/*

const myTheme = createTheme({
    palette: {
        themePrimary: '#0063f7',
        themeLighterAlt: '#f5f9ff',
        themeLighter: '#d5e5fe',
        themeLight: '#b1cffd',
        themeTertiary: '#64a0fa',
        themeSecondary: '#1e75f8',
        themeDarkAlt: '#0059df',
        themeDark: '#004bbc',
        themeDarker: '#00378b',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#af9ad3',
        neutralSecondary: '#6e4ca8',
        neutralSecondaryAlt: '#6e4ca8',
        neutralPrimaryAlt: '#3f197f',
        neutralPrimary: '#2e0a6e',
        neutralDark: '#230853',
        black: '#1a063d',
        white: '#ffffff',
    }
});

const myTheme = createTheme({
    palette: {
        themePrimary: '#cf0291',
        themeLighterAlt: '#fdf3fa',
        themeLighter: '#f7d0eb',
        themeLight: '#f0a9db',
        themeTertiary: '#e25cba',
        themeSecondary: '#d41b9d',
        themeDarkAlt: '#ba0283',
        themeDark: '#9d026e',
        themeDarker: '#740151',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#af9ad3',
        neutralSecondary: '#6e4ca8',
        neutralSecondaryAlt: '#6e4ca8',
        neutralPrimaryAlt: '#3f197f',
        neutralPrimary: '#2e0a6e',
        neutralDark: '#230853',
        black: '#1a063d',
        white: '#ffffff',
    }
});

*/


export const myMatrixTheme = createTheme({
    palette: {
        themePrimary: '#ff1500',
        themeLighterAlt: '#0a0100',
        themeLighter: '#290300',
        themeLight: '#4d0600',
        themeTertiary: '#990d00',
        themeSecondary: '#e01300',
        themeDarkAlt: '#ff2d19',
        themeDark: '#006b3b',//'#ff4d3d',
        themeDarker: '#008c4f',//'#ff7c70',
        neutralLighterAlt: '#1e1e1e',
        neutralLighter: '#282828',
        neutralLight: '#363636',
        neutralQuaternaryAlt: '#404040',
        neutralQuaternary: '#474747',
        neutralTertiaryAlt: '#666666',
        neutralTertiary: '#294d19',
        neutralSecondary: '#539932',
        neutralSecondaryAlt: '#539932',
        neutralPrimaryAlt: '#7ae04a',
        neutralPrimary: '#8aff54',
        neutralDark: '#a6ff7d',
        black: '#beff9f',
        white: '#141414',

        // // Too pink
        // themePrimary: '#f71166',
        // themeLighterAlt: '#0a0104',
        // themeLighter: '#280310',
        // themeLight: '#4a051e',
        // themeTertiary: '#940a3d',
        // themeSecondary: '#da0f59',
        // themeDarkAlt: '#f82875',
        // themeDark: '#f9498a',
        // themeDarker: '#fb78a8',
        // neutralLighterAlt: '#1e1e1e',
        // neutralLighter: '#282828',
        // neutralLight: '#363636',
        // neutralQuaternaryAlt: '#404040',
        // neutralQuaternary: '#474747',
        // neutralTertiaryAlt: '#666666',
        // neutralTertiary: '#294d19',
        // neutralSecondary: '#539932',
        // neutralSecondaryAlt: '#539932',
        // neutralPrimaryAlt: '#7ae04a',
        // neutralPrimary: '#8aff54',
        // neutralDark: '#a6ff7d',
        // black: '#beff9f',
        // white: '#141414',

        // // Too green
        // themePrimary: '#44ff00',
        // themeLighterAlt: '#030a00',
        // themeLighter: '#0b2900',
        // themeLight: '#144d00',
        // themeTertiary: '#299900',
        // themeSecondary: '#3ce000',
        // themeDarkAlt: '#57ff19',
        // themeDark: '#71ff3d',
        // themeDarker: '#96ff70',
        // neutralLighterAlt: '#1e1e1e',
        // neutralLighter: '#282828',
        // neutralLight: '#363636',
        // neutralQuaternaryAlt: '#404040',
        // neutralQuaternary: '#474747',
        // neutralTertiaryAlt: '#666666',
        // neutralTertiary: '#2a4c35',
        // neutralSecondary: '#53976a',
        // neutralSecondaryAlt: '#53976a',
        // neutralPrimaryAlt: '#7ade9c',
        // neutralPrimary: '#8bfcb1',
        // neutralDark: '#a7fdc3',
        // black: '#befed3',
        // white: '#141414',
    }
});
