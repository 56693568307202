import * as React from 'react'
import {useCallback, useState} from 'react'
import {
    ActionButton,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IconButton,
    IIconProps,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    SelectionMode,
    TextField,
} from "@fluentui/react";
import {useForceUpdate} from "@fluentui/react-hooks";
import {EcoItem} from "../../../fusina-jobs/entities/EcoItem";
import {DecimalField} from "./DecimalField";
import {EcoItemsImporterBtn} from "./EcoItemsImporterBtn";


const ecoItemsColumns: IColumn[] = [
    {
        name: 'Articolo',
        key: 'item',
        fieldName: 'item',
        minWidth: 100,
        maxWidth: 130,
        targetWidthProportion: 0.2,
        flexGrow: 0.2,
        styles: {
            cellTitle: {
                padding: '0 9px',
            }
        }
    },
    {
        name: 'Descrizione',
        key: 'description',
        fieldName: 'description',
        minWidth: 200,
        targetWidthProportion: 0.4,
        flexGrow: 0.4,
        styles: {
            cellTitle: {
                padding: '0 9px',
            }
        }
    },
    {
        name: 'U.M.',
        key: 'unitOfMeasure',
        fieldName: 'unitOfMeasure',
        minWidth: 70,
        targetWidthProportion: 0.1,
        flexGrow: 0.01,
        styles: {
            cellTitle: {
                padding: '0 9px',
            }
        }
    },
    {
        name: 'P.U. [€]',
        key: 'unitPrice',
        minWidth: 120,
        targetWidthProportion: 0.1,
        flexGrow: 0.01,
        styles: {
            cellTitle: {
                justifyContent: 'flex-end',
                padding: '0 9px',
            }
        }
    },
    {
        name: '',
        key: 'buttons',
        minWidth: 30,
        maxWidth: 50,
        flexGrow: 0.01,
        targetWidthProportion: 0.1,
    },
]

const addItemIcon: IIconProps = {iconName: 'Add'};
const delItemIcon: IIconProps = {iconName: 'Delete'};


export const EcoItemsEditor: React.FC<{
    items: EcoItem[]
}> = props => {

    const forceUpdate = useForceUpdate();

    const _renderItemColumn = useCallback((item: EcoItem, index: number, column: typeof ecoItemsColumns[number]) => {
        // return item[column.fieldName]
        if (column.key === 'item') {
            return <TextField
                className="EcoItemsEditor-item"
                value={item.item}
                onChange={(event, newValue) => {
                    item.item = newValue
                    forceUpdate()
                }}/>
        }
        if (column.key === 'description') {
            return <TextField
                className="EcoItemsEditor-description"
                value={item.description}
                onChange={(event, newValue) => {
                    item.description = newValue
                    forceUpdate()
                }}/>
        }
        if (column.key === 'unitOfMeasure') {
            return <TextField
                className="EcoItemsEditor-unitOfMeasure"
                value={item.unitOfMeasure}
                onChange={(event, newValue) => {
                    item.unitOfMeasure = newValue
                    forceUpdate()
                }}/>
        }
        if (column.key === 'unitPrice') {
            return <DecimalField
                className="EcoItemsEditor-unitPrice"
                value={item.unitPrice}
                min={0}
                minDp={2}
                styles={{
                    field: {
                        textAlign: 'right',
                        fontFamily: 'monospace !important',
                    }
                }}
                onChange={(newValue) => {
                    item.unitPrice = newValue
                    forceUpdate()
                }}/>
        }
        if (column.key === 'buttons') {
            return <>
                <IconButton
                    className="EcoItemsEditor-delete"
                    iconProps={delItemIcon}
                    onClick={() => {
                        props.items.splice(index, 1)
                        forceUpdate()
                    }}/>
            </>
        }
        return null
    }, [])

    const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(!(props?.items?.length > 20))

    if (!isDetailsOpen) {
        return <>
            <br/>
            <MessageBar
                messageBarType={MessageBarType.info}
                actions={<MessageBarButton text="Vedi tutti" onClick={() => setIsDetailsOpen(true)}/>}
                isMultiline={false}
            >
                <b>Sono presenti {props?.items?.length ?? 0} articoli contrattuali</b>
            </MessageBar>
        </>
    }

    return <>
        <DetailsList
            compact
            onShouldVirtualize={() => false}  // it was not re-rendering on scroll
            layoutMode={DetailsListLayoutMode.justified}
            cellStyleProps={{
                cellLeftPadding: 2,
                cellRightPadding: 2,
                cellExtraRightPadding: 2,
            }}
            selectionMode={SelectionMode.none}
            items={[...props.items]}
            columns={ecoItemsColumns}
            onRenderItemColumn={_renderItemColumn}
        />

        <div>
            <ActionButton
                iconProps={addItemIcon}
                onClick={() => {
                    props.items.push({
                        description: "",
                        item: "",
                        unitOfMeasure: "",
                        unitPrice: 0
                    })
                    forceUpdate()
                }}
            >
                Aggiungi articolo contrattuale
            </ActionButton>

            <EcoItemsImporterBtn
                btnProps={{
                    text: "Importa da CSV/XLSX",
                    iconProps: addItemIcon,
                }}
                onImport={(items: EcoItem[]) => {
                    props.items.push(...items)
                    forceUpdate()
                }}
            />
        </div>
    </>
}
