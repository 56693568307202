import * as React from 'react'

import {PageSetting} from "../contexts/PageContext";
import {FusinaLettersTable} from "../components/FusinaLettersTable";
import {useParams} from "react-router-dom";
import {Spinner, SpinnerSize} from "@fluentui/react";
import {JobHeadingFrag} from "../components/JobHeadingFrag";
import {useJob} from "../hooks/useJob";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";


export const Letters: React.FC = () => {
    const {jobId} = useParams()
    const {job, jobError} = useJob(jobId)

    if (!job) {
        return <MaybeErrorMessageBar error={jobError}/> || <>
            <br/>
            <Spinner size={SpinnerSize.large}/>
        </>
    }

    return <>
        <PageSetting
            iconName='PublicEmail'
            title={<>
                <div style={{paddingRight: 15}}>Lettere OdS/NI</div>
                <JobHeadingFrag/>
            </>}
        />
        <FusinaLettersTable/>
    </>
}
