import * as React from 'react'
import {useEffect, useMemo, useState} from 'react'
import {Spinner, SpinnerSize, Stack} from '@fluentui/react'
import {PageSetting} from "../contexts/PageContext";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import '../components/ITPStatsTable.css'
import {useFilteredAuditLogs} from "../hooks/useFilteredAuditLogs";
import {useForceUpdate} from "@fluentui/react-hooks";
import type {Session} from "../../../fusina-login/entities/Session";
import type {AuditLogEnvelope, SystemAuditLog} from "../../../fusina-audit/entities/AuditLog";


const MIN_DATE = new Date(Date.now() - 3600_000).toISOString().split('T')[0]

const SESSIONS_POLL_MS: number = 20_000

export const AdminAuditSessions: React.FC = () => {

    const {
        data: sessions,
        refresh: sessionsRefresh,
        error: sessionsError,
    } = useFusinaRmiResource('UsersAdmin', 'listSessions')

    const {
        data: users,
        error: usersError,
    } = useFusinaRmiResource('UsersAdmin', 'listUsers')

    const auditFilter = useMemo(() => `select(.t > "${MIN_DATE}") | select((.m.type=="System" and (.m.msg | startswith("SSE "))) or (.m.type=="System" and (.m.msg | startswith("SEE ")))) | {m: {msg: .m.msg}}`, [])

    const {rows, isConnected, pending} = useFilteredAuditLogs(auditFilter)
    const [connections, setConnections] = useState<Map<string, number>>(() => new Map())
    useEffect(() => {
        try {
            setConnections(SSEReduce(rows).session_to_connections)
        } catch (e) {
            console.error(e)
            setConnections(new Map())
        }
    }, [rows])

    const sessionsSort = useMemo<Partial<Session>[]>(() => {
        if (!sessions) {
            return []
        }
        const sess: Partial<Session>[] = sessions.slice()
        sess.sort((a, b) => a.expiresAt < b.expiresAt ? -1 : 1)
        const sids = new Set(sess.map(s => s._id))
        for (let sid of connections.keys()) {
            if (!sids.has(sid)) {
                sess.push({_id: sid, createdAt: new Date()})
            }
        }
        sess.reverse()
        return sess
    }, [sessions, connections])

    const forceUpdate = useForceUpdate()
    useEffect(() => {
        const i = setInterval(forceUpdate, 5_000)  // re-render time spans
        return () => {
            clearInterval(i)
        }
    }, [])
    useEffect(() => {
        const h = () => {
            sessionsRefresh().then(() => {
                if (!cancelled) {
                    i = setTimeout(h, SESSIONS_POLL_MS)
                }
            })
        }
        let i = setTimeout(h, SESSIONS_POLL_MS)
        let cancelled = false;
        return () => {
            cancelled = true
            clearTimeout(i)
        }
    }, [])

    if (sessionsError || usersError) {
        return <MaybeErrorMessageBar error={sessionsError || usersError}/>
    }

    if (!sessions || !users) {
        return <Spinner size={SpinnerSize.large}/>
    }

    return <>
        <PageSetting title="Sessioni correnti"/>
        <table className="ITPStatsTable" style={{whiteSpace: 'nowrap'}}>
            <thead>
            <tr>
                <td>Utente</td>
                <td>Login</td>
                <td>Scadenza</td>
                <td onClick={() => sessionsRefresh()}>
                    Attività
                    {/*<IconButton iconProps={{iconName: 'Refresh'}} onClick={() => sessionsRefresh()}/>*/}
                </td>
                <td>
                    <Stack horizontal horizontalAlign="center">
                        <span>Connessioni</span>
                        {(!isConnected || pending) && <Spinner size={SpinnerSize.xSmall}/> || <>&nbsp; &nbsp;</>}
                    </Stack>
                </td>
            </tr>
            </thead>
            {sessionsSort.map(sess => <tbody>
            <tr style={{opacity: (connections.get(sess._id) ?? 0 >= 1) ? 1 : 0.4}}>
                <td>{users.find(u => u._id === sess.user)?.email}</td>
                <td>{sess.createdAt ? relTime(sess.createdAt) : ''}</td>
                <td>{sess.expiresAt ? relTime(sess.expiresAt) : ''}</td>
                <td>{sess.expiresAt ? relTime(new Date(new Date(sess.expiresAt).getTime() - 3600 * 2 * 1000), SESSIONS_POLL_MS) : ''}</td>
                <td>{isConnected ? (connections.get(sess._id) ?? 0) : ''}</td>
            </tr>
            </tbody>)}
        </table>
    </>
}

const relTime: (date: Date | string, maxRecentMs?: number) => string = (date, maxRecentMs) => {
    const difference = (Date.now() - new Date(date).getTime()) / 1000;
    if (maxRecentMs !== undefined && difference * 1000 < maxRecentMs) {
        return `<${Math.floor(maxRecentMs / 1000)}s fa`
    }
    if (difference < -86400 * 2) {
        return `tra ${Math.floor(-difference / 86400)} gg`
    } else if (difference < -3600 * 2) {
        return `tra ${Math.floor(-difference / 3600)} ore`
    } else if (difference < -60 * 2) {
        return `tra ${Math.floor(-difference / 60)} min`
    } else if (difference < 0) {
        return `tra ${Math.floor(-difference)}s`
    } else if (difference < 60 * 2) {
        return `${Math.floor(difference)}s fa`
    } else if (difference < 3600 * 2) {
        return `${Math.floor(difference / 60)} min fa`
    } else if (difference < 86400 * 2) {
        return `${Math.floor(difference / 3600)} ore fa`
    } else if (difference < 2620800 * 2) {
        return `${Math.floor(difference / 86400)} gg fa`
    } else if (difference < 31449600 * 2) {
        return `${Math.floor(difference / 2620800)} mesi fa`
    } else {
        return `${Math.floor(difference / 31449600)} anni fa`
    }
}


function SSEReduce(rows: AuditLogEnvelope[]) {
    const stream_to_session = new Map<string, string>()

    for (let row of rows) {
        const msg = (row.m as SystemAuditLog)?.msg ?? ''
        const matchOpen = msg.match(/SEE ([a-zA-Z0-9\-_]{8,32}) for session ([a-zA-Z0-9\-_]{8,32}).*/i)
        if (matchOpen) {
            const id = matchOpen[1]
            const ses = matchOpen[2]
            if (ses && id) {
                stream_to_session.set(id, ses)
            }
        }
        const matchClose = msg.match(/SSE ([a-zA-Z0-9\-_]{8,32}) (Closed\.|End of async iteration\.)/i)
        if (matchClose) {
            const id = matchClose[1]
            const ev = matchClose[2]
            if (ev && id) {
                if (ev === 'Closed.' || ev === 'End of async iteration.') {
                    stream_to_session.delete(id)
                }
            }
        }
    }

    const session_to_connections = new Map<string, number>()
    for (let ses of stream_to_session.values()) {
        session_to_connections.set(ses, (session_to_connections.get(ses) ?? 0) + 1)
    }

    return {stream_to_session, session_to_connections}
}
