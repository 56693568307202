import * as React from "react";
import {useState} from "react";
import {
    Callout,
    DefaultButton,
    IButtonProps,
    MessageBarButton,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Text
} from "@fluentui/react";
import {useId} from "@fluentui/react-hooks";
import {Stack} from "@fluentui/react/lib/Stack";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";

export const ConfirmedCalloutBtn: React.FC<{
    btnProps: IButtonProps
    calloutChildren?: React.ReactNode | string
    initialDelay?: number
    action: () => Promise<void>
    confirmBtnProps?: IButtonProps
    useMessageBarButton?: boolean
}> = props => {
    const [error, setError] = useState<Error>(undefined)
    const [isPending, setIsPending] = useState<boolean>(false)
    const [isConfirming, setIsConfirming] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)

    const id = useId('ConfirmedCalloutBtn-preConfirm')

    const BtnComp = props.useMessageBarButton ? MessageBarButton : DefaultButton

    return <>
        <BtnComp
            {...props.btnProps}
            id={id}
            onClick={() => {
                setIsConfirming(true)
                setIsPending(true)
                setIsSuccess(false)
                setError(undefined)
                setTimeout(() => setIsPending(false), props.initialDelay ?? 1500)
            }}
        />
        <Callout
            hidden={!isConfirming}
            coverTarget
            role="dialog"
            style={{padding: '20px 24px'}}
            onDismiss={() => setIsConfirming(false)}
            target={'#' + id}
            isBeakVisible={false}
            setInitialFocus
        >
            <Text block variant="xLarge" style={{fontWeight: 200, marginBottom: 12}}>
                Confermi?
            </Text>

            {props.calloutChildren}

            <Stack horizontal style={{marginTop: 20}} tokens={{childrenGap: 'm'}}>
                {!isSuccess && !error && <PrimaryButton
                    text="Conferma"
                    {...props.confirmBtnProps ?? {}}
                    className="ConfirmedCalloutBtn-Confirm"
                    disabled={isPending}
                    onClick={() => {
                        setIsPending(true)
                        setError(undefined)
                        props.action()
                            .then(() => {
                                setIsSuccess(true)
                                setTimeout(() => {
                                    setIsConfirming(false)
                                }, 1000)
                            })
                            .catch(err => {
                                setError(err)
                            })
                            .finally(() => {
                                setIsPending(false)
                            })
                    }}
                />}
                {isSuccess && <DefaultButton
                    disabled
                    iconProps={{
                        iconName: "CheckMark"
                    }}
                />}
                {isPending && <Spinner size={SpinnerSize.small}/>}
            </Stack>

            {error && <>
                <br/>
                <MaybeErrorMessageBar error={error}/>
            </>}
        </Callout>
    </>
}
