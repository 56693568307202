import * as React from "react";
import {useCallback, useState} from "react";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {ITPItem} from "../../../fusina-itp/entities/ITPItem";
import {FileUploadInput} from "./FileUploadInput";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {DefaultButton, MessageBar, MessageBarType, PrimaryButton, ProgressIndicator, Stack} from "@fluentui/react";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {usePdfFileCheck} from "../hooks/usePdfFileCheck";
import {ITPCertEventAssocMaker} from "./ITPCertEventAssocMaker";


const ENABLE_EVENT_ASSOC: boolean = false;

const AUTO_CLOSE_DELAY: number | false = ENABLE_EVENT_ASSOC ? false : 5000;

export const ITPCertsUpload: React.FC<{
    itp: InspectionTestPlan
    item: ITPItem
    onUploaded: () => void
    onFinish: () => void
}> = props => {
    const rmi = useFusinaRmi()

    const [file, setFile] = useState<File | null>(null)
    const {error: fileCheckError} = usePdfFileCheck(file)

    const [uploadPending, setUploadPending] = useState<boolean>(false)
    const [newCertId, setNewCertId] = useState<string | null>(null)
    const [error, setError] = useState<Error | null>(null)
    const [closeTimeout, setCloseTimeout] = useState(undefined)

    const finished = useCallback(() => {
        setFile(null)
        setNewCertId(null)
        setError(undefined)
        if (closeTimeout) {
            clearTimeout(closeTimeout)
            setCloseTimeout(undefined)
        }
        props.onFinish()
    }, [props.onFinish])

    if (error) {
        return <>
            <MaybeErrorMessageBar error={error} onDismiss={() => setError(undefined)}/>
        </>
    }

    if (uploadPending) {
        return <>
            <ProgressIndicator
                description="Upload in corso..."
            />
        </>
    }

    if (newCertId) {
        return <>
            <MessageBar messageBarType={MessageBarType.success}>
                File caricato con ID: <code style={{userSelect: 'text'}}>{newCertId}</code>
            </MessageBar>
            <br/>

            {ENABLE_EVENT_ASSOC
                ? <ITPCertEventAssocMaker
                    itp={props.itp}
                    item={props.item}
                    newCertId={newCertId}
                    onFinished={finished}
                />
                : <DefaultButton
                    text="Chiudi"
                    onClick={finished}
                />}
        </>
    }

    if (file) {
        return <>
            Confermi di aver selezionato un file PDF valido per questa fase?
            <br/>
            <br/>
            File selezionato: <b>{file.name}</b>
            <small>
                <br/>
                Tipo: {file.type}
                <br/>
                Dimensione: {Math.ceil(file.size / 1024)}kB
            </small>
            <br/>
            <MaybeErrorMessageBar error={fileCheckError}/>
            <br/>
            <Stack horizontal tokens={{childrenGap: 'm'}}>
                <DefaultButton
                    text="Annulla"
                    onClick={finished}
                />
                <PrimaryButton
                    text="Invia"
                    disabled={!!fileCheckError}
                    onClick={() => {
                        setUploadPending(true)
                        rmi.ITPCertificates.upload(props.itp._id, props.item.item, file)
                            .then((certId) => {
                                setNewCertId(certId)
                                if (AUTO_CLOSE_DELAY) {
                                    setCloseTimeout(setTimeout(finished, AUTO_CLOSE_DELAY))
                                }
                                props.onUploaded?.()
                            })
                            .catch(err => setError(err))
                            .finally(() => {
                                setUploadPending(false)
                            })
                    }}
                />
            </Stack>
        </>
    }

    return <>
        Selezionare un file PDF da caricare.
        <br/>
        <br/>
        <FileUploadInput
            label=""
            processFile={async newValue => {
                setFile(newValue)
            }}
        />
    </>
}
