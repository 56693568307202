import * as React from "react";
import {useMemo} from "react";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {ConfirmedCalloutBtn} from "./ConfirmedCalloutBtn";
import {RoleEnum, RoleLabels} from "../../../fusina-authz/entities/RoleEnum";
import {useJobContext} from "../contexts/JobContext";
import {useSecurityContext} from "../contexts/SecurityContext";
import {ITPPolicy} from "../../../fusina-itp/controls/ITPPolicy";
import {ITPValidator} from "../../../fusina-itp/controls/ITPValidator";

export const ITPSignBtn: React.FC<{
    itp: InspectionTestPlan
    onSignSuccess: () => void
    signatureRole: RoleEnum
    signAction: (signatureRole: RoleEnum) => Promise<void>
}> = props => {
    const {job} = useJobContext()

    const sc = useSecurityContext()

    const policy = useMemo(() => ITPPolicy({
        itp: props.itp,
        job: job,
        securityContext: sc,
        signatureRole: props.signatureRole,
    }), [props.itp, job])

    const stopForValidation = useMemo<boolean>(() => {
        if (!policy.permissions.write) {
            return false
        }
        const msgs = new ITPValidator().validate(props.itp)
        return msgs.length >= 1
    }, [props.itp, policy.permissions.write])

    if (!policy.permissions.sign) {
        return null
    }

    return <ConfirmedCalloutBtn
        btnProps={{
            text: 'Firma',
            className: 'ITPSignBtn',
            primary: true,
            disabled: stopForValidation,
            title: stopForValidation ? 'Ci sono errori di validazione' : undefined,
        }}
        calloutChildren={<>
            Stai per firmare come {RoleLabels[props.signatureRole]}.
        </>}
        action={() => props.signAction(props.signatureRole).then(() => props.onSignSuccess())}
    />
}
