import {ITPPolicy, ITPPolicyContext} from "./ITPPolicy";
import {InspectionTestPlan} from "../entities/InspectionTestPlan";
import {ITPItem} from "../entities/ITPItem";
import {ITPRev} from "../entities/ITPRev";

export interface ITPRevPolicyContext extends ITPPolicyContext {
    rev: ITPRev
}

const REVISABLE_ITP_KEYS: (keyof InspectionTestPlan)[] = [
    'items',
    'revN',
    'revId',
    'title',
    'lastEditDate',
    'signatures',
    'lastEventN',
]

const REVISABLE_ITEM_KEYS: (keyof ITPItem)[] = [
    'notes',
    'presences',
    'refDocs',
    'comp',
    'recordsQCR',
    'desc',
    'abolished',
]

const LIVE_ITP_KEYS: (keyof InspectionTestPlan)[] = [
    'lastEventN',
]

/** this function evaluated on previous and revised ITP must give the same result */
const getFixedParts: (itp: InspectionTestPlan) => string = itp => {
    const keys: (keyof InspectionTestPlan)[] = (Object.keys(itp) as (keyof InspectionTestPlan)[])
        .filter(k => !REVISABLE_ITP_KEYS.includes(k))
    keys.sort()
    return JSON.stringify(keys.map(k => [k, itp[k]]))
}
// NOTE: the items list requires a different validation method; the following is not ok:
//       (k === 'items' ? v.flatMap((item: ITPItem) => Object.entries(([ik, iv]) => ik in REVISABLE_ITEM_KEYS ? [] : [iv])) : [])

const getItemFixedParts: (item: ITPItem) => string = item => {
    const keys: (keyof ITPItem)[] = (Object.keys(item) as (keyof ITPItem)[])
        .filter(k => !REVISABLE_ITEM_KEYS.includes(k))
    keys.sort()
    return JSON.stringify(keys.map(k => [k, item[k]]))
}

/** this function evaluated on previous ITP gives the live data the must override the revised ITP */
export const getLiveOverrideParts: (itp: InspectionTestPlan) => Partial<InspectionTestPlan> = itp =>
    Object.fromEntries(LIVE_ITP_KEYS.map(k => [k, itp[k]]))

/** Policy that limits the operations on an ITP event message */
export function ITPRevPolicy(ctx: ITPRevPolicyContext) {
    const prevItpPolicy = ITPPolicy(ctx)
    const revItpPolicy = ITPPolicy({...ctx, itp: ctx.rev.itp})

    const {
        myRoles,
        isClient_CQ,
        isContractor_CQ,
        isOwnerOrNoOwner,
        isSignedByClient: isPrevITPSignedByClient,
    } = prevItpPolicy

    const {
        isNotSigned: noRevSignatures,
        isSignedByClient: isRevSignedByClient,
        isSignedByContractor: isRevSignedByContractor,
    } = revItpPolicy

    const itpFixedParts = getFixedParts(ctx.itp)
    const revFixedParts = getFixedParts(ctx.rev.itp)
    const isFixedDataUnchanged_root = itpFixedParts && revFixedParts && itpFixedParts === revFixedParts

    const isNotMissingAnyPreItems = ctx.itp.items
        .filter(preIt => !ctx.rev.itp.items.find(revIt => revIt.item === preIt.item))
        .length === 0

    const itpItemsFixedParts = ctx.itp.items
        .map(item => getItemFixedParts(item))
    itpItemsFixedParts.sort()
    const revItemsFixedParts = ctx.rev.itp.items
        .filter(revIt => ctx.itp.items.find(preIt => preIt.item === revIt.item))
        .map(item => getItemFixedParts(item))
    revItemsFixedParts.sort()
    const isItemsFixedDataUnchanged = itpItemsFixedParts.join(',') === revItemsFixedParts.join(',')

    const isFixedDataUnchanged = isItemsFixedDataUnchanged && isFixedDataUnchanged_root

    const hasRoleForApply = isClient_CQ

    return {
        myRoles,

        isClient_CQ,
        isContractor_CQ,
        hasRoleForApply,

        isPrevITPSignedByClient,
        isOwnerOrNoOwner,
        noRevSignatures,
        isFixedDataUnchanged,
        isNotMissingAnyPreItems,
        isRevSignedByClient,
        isRevSignedByContractor,

        permissions: {
            read: prevItpPolicy.permissions.read
                && revItpPolicy.permissions.read,

            write: revItpPolicy.permissions.write
                && isPrevITPSignedByClient && isNotMissingAnyPreItems && isFixedDataUnchanged,

            sign: revItpPolicy.permissions.sign
                && isPrevITPSignedByClient && isNotMissingAnyPreItems && isFixedDataUnchanged,

            reject: revItpPolicy.permissions.reject,

            apply: hasRoleForApply && isPrevITPSignedByClient && isRevSignedByClient && isRevSignedByContractor,
        }
    }
}
