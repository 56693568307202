import * as React from "react";
import {Letter} from "../../../fusina-letters/entities/Letter";
import {useFusinaRmiUrl} from "../hooks/useFusinaRmiUrl";
import {AttachmentCard} from "./AttachmentCard";

export function LetterAttachmentCard(props: {
    letter: Letter
    name: string
    width?: string
}) {
    const downloadUrl = useFusinaRmiUrl('Letters', 'downloadAttachment', props.letter?._id, props.name)

    return <AttachmentCard width={props.width} onClickHref={downloadUrl} name={props.name}/>;
}

export function EstimateAttachmentCard(props: {
    letter: Letter
    name: string
    width?: string
}) {
    const downloadUrl = useFusinaRmiUrl('EcoOds', 'downloadEstimateAttachment', props.letter?._id, props.name)

    return <AttachmentCard width={props.width} onClickHref={downloadUrl} name={props.name}/>;
}
