import * as React from 'react'
import {CSSProperties} from 'react'

/** SVG with a curve based on 3 values (any range accepted) */
export const MicroQuadraticSvg: React.FC<{
    width: number
    height: number
    lower?: number
    upper?: number
    values: [number, number, number]
    style?: CSSProperties
}> = props => {
    const max = props.upper ?? Math.max(...props.values)
    const min = props.lower ?? Math.min(...props.values)
    const range = max - min
    const values = props.values.map(v => (v - min) * 10 / (range === 0 ? 1 : range))
    return <svg
        style={props.style}
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        viewBox='0 -10 20 10'
        preserveAspectRatio="none"
        version="1.0"
    >
        {props.lower !== undefined && <path
            stroke="currentColor"
            strokeLinecap="butt"
            fill="transparent"
            fillOpacity="0"
            strokeWidth="2"
            strokeDasharray="2 2"
            d={`M 0 0 L 20 0`}
        />}
        <path
            stroke="currentColor"
            strokeLinecap="round"
            fill="transparent"
            fillOpacity="0"
            d={`M 0 ${-values[0]} Q 10 ${-values[1]} 20 ${-values[2]}`}
        />
    </svg>

}
