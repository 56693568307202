/** Presence (witness or waiver) codes */
import {ITPParty} from "./ITPParty";

export enum PresenceCode {
    H = "H",
    W = "W",
    R = "R",
}

export interface PresenceCodeDmsSemantics {
    isNotificationMandatory: boolean
    isWitnessOrWaiverMandatory: boolean
}

export const getPresenceCodeDmsSemantics: (code: PresenceCode) => PresenceCodeDmsSemantics = (code: PresenceCode) => {
    switch (code) {
        case PresenceCode.H:
            return {isNotificationMandatory: true, isWitnessOrWaiverMandatory: true}
        case PresenceCode.W:
            return {isNotificationMandatory: true, isWitnessOrWaiverMandatory: false}
        case PresenceCode.R:
            return {isNotificationMandatory: false, isWitnessOrWaiverMandatory: false}
        default:
            throw new Error('Unknown presence code')
    }
}

export const getPresenceCodeActivity_it = (code: PresenceCode) => {
    switch (code) {
        case PresenceCode.H:
            return 'Le attività successive non possono proseguire fino al completamento del controllo eseguito in presenza del testimone oppure previa comunicazione scritta del testimone'
        case PresenceCode.W:
            return 'Il controllo deve essere effettuato con o senza la presenza della parte testimone e le attività seccessive possono continuare'
        case PresenceCode.R:
            return 'Il controllo deve essere effettuato e le attività seccessive possono continuare'
        default:
            throw new Error('Unknown presence code')
    }
}

export const getPresenceCodeActivity_en = (code: PresenceCode) => {
    switch (code) {
        case PresenceCode.H:
            return 'activities cannot proceed until the implementation of the attended test or until the receipt in writing of the waiver'
        case PresenceCode.W:
            return 'the test will be carried out even if not witnessed and the following activities will proceed'
        case PresenceCode.R:
            return 'the test will be carried out and the following activities will proceed'
        default:
            throw new Error('Unknown presence code')
    }
}

interface PresenceCodeDropdownOption {
    key: keyof typeof PresenceCode
    text: keyof typeof PresenceCode
    data: {
        notif: string
        wow: string
        a: string
    }
}

export const PresenceCodeDropdownOptions: PresenceCodeDropdownOption[] = [
    {
        key: 'H',
        text: 'H',
        data: {
            notif: 'Necessaria',
            wow: 'Mandatoria (risposta necessaria)',
            a: 'Le attività successive non possono proseguire fino al completamento del controllo eseguito in presenza del testimone oppure previa comunicazione scritta del testimone',
        },
    },
    {
        key: 'W',
        text: 'W',
        data: {
            notif: 'Necessaria',
            wow: 'Non mandatoria (risposta non necessaria)',
            a: 'Il controllo deve essere effettuato con o senza la presenza della parte testimone e le attività seccessive possono continuare',
        },
    },
    {
        key: 'R',
        text: 'R',
        data: {
            notif: 'Non necessaria',
            wow: 'Non mandatoria',
            a: 'Il controllo deve essere effettuato e le attività seccessive possono continuare',
        },
    },
];

export const OptionalPresenceCodeDropdownOptions: (PresenceCodeDropdownOption | {
    key: null,
    text: '',
    data: undefined,
})[] = [
    {
        key: null,
        text: '',
        data: undefined
        // TODO? is it really an option? Or should we make the whole party as optional?
    },
    ...PresenceCodeDropdownOptions,
]

export const ITP_REQUIRED_PARTIES = [
    ITPParty.cnt,
    ITPParty.cl,
]
