import {
    DefaultButton,
    Label,
    Selection,
    SelectionMode,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    useTheme
} from "@fluentui/react";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {ITPEventsDetailsListBase} from "./ITPEventsDetailsListBase";
import {ConfirmedCalloutBtn} from "./ConfirmedCalloutBtn";
import {ITPItemContext} from "../contexts/ITPItemContext";
import {ITPEventFullView} from "./ITPEventFullView";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {ITPEventFilterCriteria} from "../../../fusina-itp/entities/ITPEventFilterCriteria";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {ITPEvent} from "../../../fusina-itp/entities/ITPEvent";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {ITPItem} from "../../../fusina-itp/entities/ITPItem";

/**
 * Additional UX that allows to associate a just uploaded cert to some notification of the same itp item.
 */
export const ITPCertEventAssocMaker: React.FC<{
    itp: InspectionTestPlan
    item: ITPItem
    newCertId?: string
    onFinished?: () => void
}> = props => {

    const eventsQuery = useMemo<ITPEventFilterCriteria>(() => ({
        by: 'item', itp: {_id: props.itp._id}, item: props.item.item
        //by: 'conjunction', criteria: [ {by: 'status', status: NOT CANCELLED! ],
    }), [props.itp._id, props.item.item])

    const {
        data: events_all,
        error: eventsError,
    } = useFusinaRmiResource('ITPEvents', 'find', eventsQuery)

    const events = useMemo(() =>
        events_all?.filter?.(ev => ev.status !== 'CANCELLED'), [events_all])

    const [assocEventId, setAssocEventId] = useState<string | undefined>(undefined)
    const [assocStatus, setAssocStatus] = useState<'initial' | 'pending' | 'success'>('initial')
    const [assocError, setAssocError] = useState<Error | undefined>(undefined)
    const assocEvent = useMemo<ITPEvent | null>(() =>
        events?.find?.(ev => ev._id === assocEventId) ?? null, [assocEventId])

    const eventsSelection = useMemo(() => new Selection<ITPEvent>({
        onSelectionChanged() {
            const sel = eventsSelection.getSelection()
            console.debug({sel})
            if (sel?.length === 1) {
                setAssocEventId(sel[0]._id)
            } else {
                setAssocEventId(undefined)
            }
        },
        getKey(event: ITPEvent) {
            return event._id
        },
    }), [])

    const finished = useCallback(() => {
        setAssocEventId(undefined)
        setAssocError(undefined)
        setAssocStatus('initial')
        props.onFinished?.()
    }, [props.onFinished])

    const theme = useTheme()
    return <>
        <br/>
        {assocStatus === 'pending' && <Spinner size={SpinnerSize.large}/>}
        {assocStatus === 'initial' && <>
            <Stack tokens={{childrenGap: 'm'}}>
                <MaybeErrorMessageBar error={eventsError}/>
                {events === undefined && <Spinner size={SpinnerSize.small}/>}
                {events !== undefined && <Stack>
                    <Label>Notifica Ispezione</Label>
                    <div style={{maxHeight: 200, overflow: 'auto', maxWidth: 300}}>
                        <ITPEventsDetailsListBase
                            // styles={{root: {marginTop: 0}}}
                            items={events}
                            selectionMode={SelectionMode.single}
                            selection={eventsSelection as Selection}
                        />
                    </div>
                    <Stack horizontal tokens={{childrenGap: 's1'}}>
                        <div style={{marginTop: '0.75em', flexGrow: 1, display: 'flex'}}>
                            <ConfirmedCalloutBtn
                                btnProps={{
                                    primary: true,
                                    text: 'Associa',
                                    disabled: !assocEventId,
                                    iconProps: {
                                        iconName: 'Link12',  // 'CalendarReply',
                                    },
                                    styles: {
                                        root: {
                                            flexGrow: 1,
                                            display: 'block',
                                        },
                                    },
                                }}
                                calloutChildren={assocEventId ? <>
                                    Stai per associare il certificato alla seguente notifica:
                                    <div style={{
                                        maxHeight: 200,
                                        overflowY: 'auto',
                                        marginTop: '1em',
                                        fontSize: '0.8em',
                                        border: '1px solid ' + theme.semanticColors.inputBorder,
                                        borderRadius: 3
                                    }}>
                                        {/* NOTE: ITPItemContext: this shall be the latest version (for cert upload) */}
                                        <ITPItemContext.Provider value={{itp: props.itp, item: props.item}}>
                                            <ITPEventFullView eventProps={assocEvent}/>
                                        </ITPItemContext.Provider>
                                    </div>
                                </> : undefined}
                                action={async () => {
                                    setAssocError(undefined)
                                    setAssocStatus('pending')
                                    try {
                                        // await rmi.ITPEvents.attachCert(assocEventId, newCertId)
                                        // setAssocStatus('success')
                                        // finished()
                                        // noinspection ExceptionCaughtLocallyJS
                                        throw new Error('NOT IMPLEMENTED')
                                    } catch (e) {
                                        setAssocError(e)
                                        setAssocStatus('initial')
                                        throw e
                                    }
                                }}
                            />
                        </div>
                    </Stack>
                </Stack>}
                <MaybeErrorMessageBar error={assocError}/>

                <Separator>oppure</Separator>

                <DefaultButton
                    styles={{
                        root: {
                            flexGrow: 1,
                        }
                    }}
                    text="Chiudi senza associare"
                    onClick={finished}
                />
            </Stack>
        </>}
        {assocStatus === 'success' && <>
            <DefaultButton
                text="Chiudi"
                onClick={finished}
            />
        </>}
    </>
}

/*
    Older alternative to full blown events table:

    <Dropdown
        placeholder="Selezionare..."
        selectedKey={assocEventId}
        styles={{
            root: {
                width: 250
            }
        }}
        options={events
            ?.filter?.(ev => ev.status !== 'CANCELLED')
            ?.map?.(ev => {
                const d = new Date(ev.dtStart)
                const ds = d.toLocaleDateString(undefined, {dateStyle: 'long'}) + ' '
                    + d.toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit',
                        timeZoneName: 'short',
                    })
                return {
                    key: ev._id,
                    text: `${ev.itpEventN} - ${ds}`,
                    title: ev.description,
                }
            }) ?? []}
        onChange={(event, option) => {
            setAssocEventId(option.key as string)
        }}
     />
*/
