import * as React from 'react'
import {useContext, useMemo} from 'react'
import {Icon, useTheme} from "@fluentui/react";
import {ServerStatusContext} from "../contexts/ServerStatusContext";
import {MicroQuadraticSvg} from "./MicroQuadraticSvg";

declare const FUSINA_DMS_VERSION: string

export const BasePageFooter: React.FC<{}> = props => {
    const theme = useTheme()

    const status = useContext(ServerStatusContext)
    const loadRev = useMemo(() => status?.server?.loadavg?.slice?.()?.reverse() as [number, number, number], [status?.server?.loadavg])
    const good = status?.server?.loadavg[0] <= 1 && status?.server?.db === 'connected'

    return <>
        <code style={{fontSize: theme.fonts.xSmall.fontSize}}>
            c: {FUSINA_DMS_VERSION}
            &nbsp;
        </code>
        <code style={{fontSize: theme.fonts.xSmall.fontSize, flexGrow: 1}}>
            s: {FUSINA_DMS_VERSION !== status?.server?.v ? status?.server?.v : '==='}
            &nbsp;[{status?.instances?.size}]
        </code>
        {loadRev &&
        <MicroQuadraticSvg width={20} height={10} lower={0} upper={Math.max(0.5, ...loadRev)} values={loadRev}
                           style={{marginTop: -1}}/>}
        <code style={{fontSize: theme.fonts.xSmall.fontSize, flexGrow: 0}}>
            {status?.server?.loadavg && <>
                &nbsp;L: {Math.min(...status.server.loadavg)}-{Math.max(...status.server.loadavg)}&nbsp;
                {good
                    ? <Icon style={{color: theme.palette.green, verticalAlign: -1}} iconName="StatusCircleOuter"/>
                    : <Icon style={{color: theme.palette.yellow, verticalAlign: -1}} iconName="StatusCircleOuter"/>}
            </>}
            {status?.error !== undefined && <>
                {navigator?.onLine ? 'error' : 'offline'}
                &nbsp;
                <span style={{color: theme.palette.red}}>
                    <Icon iconName="IncidentTriangle"/>
                </span>
            </>}
            {status.rtt > 3000 && <>
                &nbsp;slow RTT
            </>}
            {status?.server && status?.server?.db !== 'connected' && <>
                &nbsp;db: {status?.server?.db ?? '?'}
            </>}
        </code>
    </>
}
