import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
    Link,
    mergeStyles,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    ProgressIndicator,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField,
    useTheme
} from "@fluentui/react";
import {usePublicRmi} from "../hooks/usePublicRmi";
import {useLocation, useNavigate} from 'react-router-dom'
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import {UnauthorizedError} from "../../../js-rmi/exceptions";
import zxcvbn from 'zxcvbn'
import {ResetResult} from "../../../fusina-login/boundaries/PublicLoginService";

const formStyle = mergeStyles({
    maxWidth: 400,
    margin: '0 auto',
    minHeight: 'calc(95vh - 110px)',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
})

export const LoginPage: React.FC = () => {
    const navigate = useNavigate()
    const publicRmi = usePublicRmi()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const [pending, setPending] = useState<boolean>(false)
    const [error, setError] = useState(undefined)

    const handleLogin = useCallback(() => {
        if (pending) {
            return
        }
        setError(undefined)
        setPending(true)
        publicRmi.auth.login(email.trim().toLowerCase(), password)
            // @ts-ignore
            .then(() => navigate(0, {replace: true}))
            .catch(setError)
            .finally(() => setPending(false))
        return false
    }, [pending, email, password])

    const handleKeyPress = useCallback((ev) => {
        if (ev.key === 'Enter') {
            handleLogin?.()
        }
    }, [handleLogin])

    // --------------------------- END OF LOGIN LOGICS / START OF RESET LOGICS ---------------------------

    const location = useLocation()
    const [phase, setPhase] = useState<'login' | 'askReset' | 'doReset' | 'resetSuccess'>('login')
    useEffect(() => { // Let's accept the direct link to this reset page but then remove from url.
        if (location.pathname === '/reset') {
            setPhase('askReset')
            // navigate('/', {replace: true})
        }
    }, [location])

    // the reset password is prefixed by a long token received by askReset
    const [otpLongPrefix, setOtpLongPrefix] = useState<string | undefined>(undefined)
    const [otpExpiryDate, setOtpExpiryDate] = useState<Date | undefined>(undefined)
    const handleAskReset = useCallback(() => {
        if (!(email.length >= 3)) {
            console.error('Redundant check. Other conditions should have prevented this attempt.')
            return
        }
        setPhase('askReset')  // In case of another request after going to doReset
        setError(undefined)
        setPending(true)
        setTimeout(() => {
            publicRmi.auth.askReset(email.trim().toLowerCase())
                .then(res => {
                    if (res.type === 'reset' && res.success) {
                        setOtpLongPrefix(res.otpLongPrefix)
                        setOtpExpiryDate(new Date(res.otpExpiryDate))
                        setOtpShortSuffix?.(undefined)
                    } else {
                        throw new Error('Richiesta fallita. Si prega di riprovare più tardi...')
                    }
                })
                .then(() => new Promise<void>(resolve => setTimeout(() => {
                    setPhase('doReset')
                    resolve()
                }, 3000)))  // delay as the server does not immediately send the email, which is also slow
                .catch(setError)
                .finally(() => setPending(false))
        }, 500)  // delay for throttling
    }, [email])

    // will display warning of imminent otp expiry
    const [otpExpiryWarning, setOtpExpiryWarning] = useState<string | undefined>(undefined)
    useEffect(() => {
        if (!otpExpiryDate) {
            setOtpExpiryWarning(undefined)
            return
        }
        const t = setInterval(() => {
            const left = otpExpiryDate.getTime() - Date.now()
            if (left <= 1000 * 15 * 60) {
                setOtpExpiryWarning(`Scade tra ${Math.ceil(left / 60_000)} minuti.`)
            }
            if (left / 60_000 < 1.01) {
                setOtpExpiryWarning('Scade tra meno di un minuto.')
            }
            if (left <= 1000) {
                setPhase('askReset')
                setOtpLongPrefix(undefined)
                setOtpExpiryWarning(undefined)
                setOtpExpiryDate(undefined)
                setOtpShortSuffix?.(undefined)
            }
        }, 10_000)
        return () => clearInterval(t)
    }, [otpExpiryDate])

    // the user provides the suffix received by email to complete the reset password
    const [otpShortSuffix, setOtpShortSuffix] = useState<string>(undefined)
    const canSetNewPassword = otpShortSuffix?.length >= 1  // implicit: already got otpLongPrefix

    // the user will later choose a password...
    const strength = useMemo(() =>
        phase !== 'login' && password && zxcvbn(password, [email]) || undefined, [password, phase])
    const isNewPasswordAcceptable = useMemo(() =>
        password?.length > 6 && strength?.score >= 4, [password, strength])
    const [repeatPassword, setRepeatPassword] = useState<string>(undefined)

    // we can send a doReset once we have email, reset password, new password
    const handleDoReset = useCallback(() => {
        if (password !== repeatPassword || !isNewPasswordAcceptable) {
            console.error('Redundant check. Other conditions should have prevented this attempt.')
            return
        }
        setError(undefined)
        setPending(true)
        publicRmi.auth.doReset(email.trim().toLowerCase(), otpLongPrefix + otpShortSuffix, password)
            .then(result => {
                if (result.type === 'reset' && result.success) {
                    setPhase('resetSuccess')
                    // NOTE: we do not clear the pw, so it is already filled on login
                } else {
                    throw result
                }
            })
            .catch(err => {
                setError(new Error(err?.type === 'reset' && err?.reason
                    ? (err as ResetResult).reason
                    : 'Controllare di aver digitato correttamente il codice temporaneo.'))
            })
            .finally(() => setPending(false))
    }, [email, otpLongPrefix, otpShortSuffix, password, isNewPasswordAcceptable, repeatPassword])

    // after success...
    useEffect(() => {
        if (phase === 'resetSuccess') {
            const t = setTimeout(() => window?.location?.reload?.(), 60_000)
            return () => clearInterval(t)
            // NOTE: We must not leave the credentials on this page for too much time as the desktop may be shared and
            //       the transient user may not be aware that it's a matter of 1 click for another user to see the pwd.
        }
    }, [phase])

    const theme = useTheme()


    /* ########################### END OF RESET LOGICS / START OF LOGIN FORM ########################### */

    return <>

        {phase === 'login' &&
        <form target="_self" action="#" onSubmit={handleLogin} className={formStyle}>
            <Stack tokens={{childrenGap: 'm'}}>
                <Text variant={"xxLarge"} style={{fontWeight: 100}}>Login richiesto</Text>
                <Stack>
                    <TextField
                        label="Email"
                        autoFocus
                        value={email}
                        role='email'
                        type='email'
                        autoComplete='email username'
                        onChange={((event, newValue) => setEmail(newValue))}
                        onSubmit={handleLogin}
                        onKeyPress={handleKeyPress}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        autoComplete='current-password'
                        value={password}
                        canRevealPassword
                        onChange={((event, newValue) => setPassword(newValue))}
                        onSubmit={handleLogin}
                        onKeyPress={handleKeyPress}
                    />
                </Stack>
                <MaybeErrorMessageBar error={error && error instanceof UnauthorizedError
                    ? {message: "Autenticazione fallita. Riprovare..."} : error}/>
                <Stack horizontal>
                    {pending && <Spinner size={SpinnerSize.medium}/> ||
                    <PrimaryButton
                        text="Accedi"
                        onClick={handleLogin}
                    />}
                    {!pending && <Stack.Item grow={1} style={{textAlign: 'right'}}>
                        <Link onClick={() => {
                            setPhase('askReset')
                            setError(undefined)
                        }}><small>
                            Recupera o imposta credenziali
                        </small></Link>
                    </Stack.Item>}
                </Stack>
            </Stack>
            <br/>
            <br/>
            <br/>
        </form>}


        {/* --------------------------- END OF LOGIN FORM / START OF RESET FORM --------------------------- */}


        {(phase === 'askReset' || phase === 'doReset' || phase === 'resetSuccess') &&
        <form target="_self" action="#" onSubmit={() => false} className={formStyle} autoCorrect="false">
            <Stack tokens={{childrenGap: 'm'}}>
                <Text variant={"xxLarge"} style={{fontWeight: 100}}>Impostazione nuove credenziali</Text>
                {phase === 'askReset' && <>
                    <span>
                        Inserisci il tuo indirizzo email già registrato. <br/>
                        Ti sarà inviato un codice temporaneo da inserire in questa pagina come metodo di autenticazione.
                        Questo ti consentirà di scegliere una nuova password per il tuo account.
                    </span>
                    <MessageBar messageBarType={MessageBarType.info}>
                        Se non sei già registrato non puoi accedere autonomamente.
                    </MessageBar>
                    <TextField
                        label="Email"
                        autoFocus
                        value={email}
                        autoComplete='email username'
                        role='email'
                        type='email'
                        onChange={((event, newValue) => setEmail(newValue))}
                    />
                    <Stack horizontal tokens={{childrenGap: 'm'}}>
                        <PrimaryButton
                            disabled={pending || !(email?.length >= 3)}
                            text="Richiedi codice temporaneo"
                            onClick={pending ? undefined : handleAskReset}
                        />
                        {pending && <Spinner size={SpinnerSize.medium}/>}
                    </Stack>
                </>}

                {phase === 'doReset' && <>
                    <div onClick={() => setPhase('askReset')}>
                        <TextField
                            label="Email"
                            disabled
                            value={email}
                            autoComplete='email username'
                            role='email'
                            type='email'
                            style={{cursor: "pointer"}}
                        />
                    </div>
                    <span>
                        A breve dovresti ricevere il codice temporaneo.
                        Se non lo trovi nella tua casella potresti non essere registrato,
                        oppure potresti averlo ricevuto nella "posta indesiderata".
                    </span>
                    <Stack>
                        <TextField
                            label="Codice temporaneo"
                            autoFocus
                            autoComplete="one-time-code"
                            value={otpShortSuffix}
                            onChange={(event, newValue) => {
                                const chars = newValue.toUpperCase().replace(/[^0-9A-Z]/g, '')
                                const code = chars.substring(0, 4) + (chars.length >= 5 ? '-' + chars.substring(4) : '')
                                setOtpShortSuffix(code)
                            }}
                            onSelect={ev => { // Do not allow putting the cursor at mid, but allow full selection
                                const target = ev.target as HTMLInputElement
                                target.selectionStart = target.selectionEnd - target.selectionStart >= 1 ? 0 : otpShortSuffix?.length ?? 0
                                target.selectionEnd = otpShortSuffix?.length ?? 0
                            }}
                            onRenderLabel={(props, DefaultRender) => <Stack horizontal>
                                <DefaultRender {...props}/>
                                <Stack.Item grow={1}>&nbsp;</Stack.Item>
                                <Link onClick={() => setPhase('askReset')}><small>Richiedi nuovo</small></Link>
                            </Stack>}
                            styles={{
                                field: {
                                    textAlign: 'center',
                                    fontSize: '1.25em',
                                }
                            }}
                            description={otpExpiryWarning}
                        />
                        {!canSetNewPassword && <div>
                            <br/>
                            Per continuare inserisci il codice ricevuto via email.
                        </div>}
                        {canSetNewPassword && <>
                            <br/>
                            <TextField
                                label="Nuova password"
                                type="password"
                                autoComplete='new-password'
                                value={password}
                                canRevealPassword
                                maxLength={50}
                                onChange={((event, newValue) => setPassword(newValue))}
                            />
                            <Stack style={{minHeight: isNewPasswordAcceptable ? undefined : 70}}>
                                <ProgressIndicator
                                    percentComplete={((strength?.guesses_log10 ?? 0) - 2) / 9.5}
                                    // NOTE: we should not en
                                    barHeight={4}
                                    styles={{
                                        progressBar: {
                                            background: strength?.score >= 4 ? theme.semanticColors.successIcon : (
                                                strength?.score >= 3 ? '#ffa600' : (
                                                    strength?.score >= 2 ? theme.semanticColors.severeWarningIcon : (
                                                        theme.semanticColors.errorIcon)))
                                        },
                                        itemDescription: {
                                            color: strength?.score >= 4 ? undefined : (
                                                strength?.score >= 3 ? undefined : (
                                                    theme.semanticColors.errorText))
                                        },
                                    }}
                                    description={
                                        strength?.feedback?.warning || (
                                            strength?.score >= 4 ? 'La tua password è ' + (
                                                strength?.guesses_log10 >= 14 ? 'sicurissima.' + (
                                                    strength?.guesses_log10 >= 16 ? ' Puoi sceglierne una più semplice.' + (
                                                        strength?.guesses_log10 > 20 ? ' Usi un password manager?' + (
                                                            strength?.guesses_log10 > 30 ? ' Sono fatti per questa password.' : '') : '') : '') : 'sicura.') : (
                                                strength?.score >= 3 ? 'La password è moderatamente sicura.' : (
                                                    strength?.score >= 2 ? 'Password poco sicura' : (
                                                        strength?.score >= 1 ? 'Password non sicura' : (
                                                            '')))))}
                                />
                                {strength?.feedback?.suggestions?.map?.(sugg => <small>{sugg}</small>)}
                            </Stack>
                            {!isNewPasswordAcceptable && <div>
                                <br/>
                                Per continuare scegli una password
                                {password ? ' più sicura' : ''}.
                            </div>}
                            {isNewPasswordAcceptable && <TextField
                                label="Ripeti la nuova password"
                                type="password"
                                autoComplete='new-password'
                                value={repeatPassword}
                                canRevealPassword
                                maxLength={50}
                                onChange={((event, newValue) => setRepeatPassword(newValue))}
                                errorMessage={repeatPassword !== undefined && repeatPassword !== password ? 'Non corrisponde.' : undefined}
                                onKeyPress={ev => {
                                    if (ev.key === 'Enter' && password === repeatPassword) {
                                        handleDoReset?.()
                                    }
                                }}
                            />}
                        </>}
                    </Stack>
                    <Stack horizontal>
                        {pending && <Spinner size={SpinnerSize.medium}/>}
                        {!pending && isNewPasswordAcceptable && <PrimaryButton
                            text="Invia"
                            disabled={password !== repeatPassword}
                            onClick={handleDoReset}
                        />}
                    </Stack>
                </>}

                {phase === 'resetSuccess' && <>
                    <Stack>
                        <TextField
                            label="Email"
                            readOnly
                            value={email}
                            role='email'
                            type='email'
                            autoComplete='email username'
                        />
                        <TextField
                            label="Password"
                            readOnly
                            type="password"
                            value={password}
                            canRevealPassword
                        />
                    </Stack>
                    <MessageBar messageBarType={MessageBarType.success}>
                        La nuova password è stata impostata dopo l'avvenuta autenticazione con il codice temporaneo.
                    </MessageBar>
                    <Stack horizontal>
                        {pending && <Spinner size={SpinnerSize.medium}/> ||
                        <PrimaryButton
                            autoFocus
                            text="Accedi"
                            onClick={() => {
                                setPhase('login')
                                handleLogin()
                            }}
                        />}
                    </Stack>
                </>}

                <MaybeErrorMessageBar error={error}/>
            </Stack>
            <br/>
            <br/>
            <br/>
        </form>}
    </>
}
