import {createContext, Dispatch, SetStateAction} from "react";
import {CalViews} from "./commons";
import {EventBase} from "../../../../fusina-event/entities/EventBase";

export interface CalState {
    view: CalViews
    selectedDate: Date
    events: EventBase[]
    minDate?: Date
    maxDate?: Date
    onEventOpen?: (ev: EventBase) => void

    /** This may be something like the calendar name or some other context like that */
    eventContextLabelGetter?: (ev: EventBase) => (string | undefined)
    eventHrefGetter?: (ev: EventBase) => (string | undefined)

    /** Same as eventContextLabelGetter but for SmallEventFrag */
    eventSmallFragContextLabelGetter?: (ev: EventBase) => (string | undefined)

    /** Optional event that shall be rendered as open (match by object ref identity comparison) */
    openEvent?: EventBase
}

export type ICalContext = [CalState, Dispatch<SetStateAction<CalState | undefined>>]

export const CalContext = createContext<ICalContext>(undefined)
