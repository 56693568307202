import * as React from 'react'
import {createContext, ReactNode, useContext, useEffect, useMemo, useState} from 'react'

export interface PageState {
    title: string | ReactNode
    iconName: string
    sideChildren?: ReactNode
    hideNavLinks?: boolean,
    pageBackground?: string,
    pageTheme?: string
}

const DEFAULT_PAGE_STATE: PageState = {
    title: '',
    iconName: '',
    sideChildren: null,
    hideNavLinks: false,
    pageBackground: null,
    pageTheme: null,
}

export interface IPageContext extends PageState {
    set: React.Dispatch<React.SetStateAction<PageState>>
}

const PageContext = createContext<IPageContext>({
    ...DEFAULT_PAGE_STATE,
    set() {
        console.trace('PageContext not provided')
    },
})


/** PageContext store and provider */
export const PageContextStore: React.FC<React.PropsWithChildren<{}>> = props => {
    const [state, setState] = useState<PageState>(DEFAULT_PAGE_STATE)
    const ctx = useMemo<IPageContext>(() => ({...state, set: setState}), [state, setState])
    return <PageContext.Provider value={ctx}>
        {props.children}
    </PageContext.Provider>
}


/** Mount this component to set some page context setting. It renders nothing by itself. */
export const PageSetting: React.FC<Partial<PageState>> = props => {
    const {set} = useContext(PageContext)
    useEffect(() => {
        // TODO: review correctness of this
        let pop = undefined;
        set(old => {
            console.debug('PageSetting', {old, props})
            // console.debug('PageSetting set', props)
            pop = () => {
                // console.debug('PageSetting pop', old)
                set({
                    ...DEFAULT_PAGE_STATE, ...old,
                    sideChildren: null,
                    hideNavLinks: false,
                    pageBackground: null,
                    pageTheme: null
                })
            }
            return {
                ...DEFAULT_PAGE_STATE,
                ...old,
                sideChildren: null,
                hideNavLinks: false,
                pageBackground: null,
                ...props
            }
        })
        return pop
    }, [...Object.values(props)])  // WARNING: infinite re-rendering loop if deps omitted.
    return null
}


/** Retrieve reactive page context state */
export function usePageContext() {
    return useContext(PageContext)
}
