import * as echarts from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ITPStats} from "../../../fusina-itp/entities/ITPStats";
import {ITPEventStats} from "../../../fusina-itp/entities/ITPEventStats";
import {ITPCertificatesStats} from "../../../fusina-itp/entities/ITPCertificatesStats";
import {useTheme} from "@fluentui/react";
import {EChartsOption} from "echarts";
import {ParallelChart} from "echarts/charts";
import {ParallelComponent, VisualMapComponent} from "echarts/components";

echarts.use([
    ParallelComponent,
    VisualMapComponent,
    ParallelChart,
    CanvasRenderer
]);

export type ParallelPlotVariant =
    | 'events'
    | 'certs'
    | 'events certs'


export const ITPStatsParallelPlot: React.FC<{
    itpsStatsStruct: ITPStats
    itpEventsStatsStruct?: ITPEventStats
    itpCertsStatsStruct?: ITPCertificatesStats
    plotVariant: ParallelPlotVariant
}> = props => {
    const {itpsStatsStruct, itpEventsStatsStruct, itpCertsStatsStruct} = props

    const graphRef = useRef<HTMLDivElement>()

    const [graph, setGraph] = useState<echarts.ECharts | undefined>(undefined)

    const theme = useTheme()

    useEffect(() => {
        if (graphRef.current) {
            const g = echarts.init(graphRef.current)
            g.setOption<EChartsOption>({
                visualMap: {
                    show: false,
                    left: 12,
                    width: 12,
                    dimension: 2,
                    min: 0.0,
                    max: 1.0,
                    itemWidth: 12,
                    borderWidth: 0,
                    realtime: false,
                    precision: 1,
                    inRange: {
                        // color: ['#3e751d', '#124a9f', '#930c00'],
                        // color: ['#009A7A','#006FBB','#E70066','#E4010A']
                        color: [/*'#006b3b',*/ '#005b96', '#960048', '#800005']
                    }
                },
            })
            setGraph(g)
            const rh = () => g.resize()
            window?.addEventListener?.('resize', rh)
            return () => {
                g.dispose()
                setGraph(undefined)
                window?.removeEventListener?.('resize', rh)
            }
        }
    }, [graphRef?.current])

    useEffect(() => {
        if (graph) {
            graph.setOption({
                parallel: {
                    left: 48,
                    right: 48,
                    parallelAxisDefault: {
                        type: 'value',
                        name: 'parallelAxisDefault',
                        nameLocation: 'end',
                        nameGap: 20,
                        nameTextStyle: {
                            color: theme.semanticColors.bodyText,
                            fontSize: 12
                        },
                        axisLine: {
                            lineStyle: {
                                color: theme.semanticColors.inputBorder
                            }
                        },
                        axisTick: {
                            lineStyle: {
                                color: theme.semanticColors.inputBorder
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            color: theme.semanticColors.bodyText
                        },
                    },
                },
                parallelAxis: [
                    {dim: 0, type: 'category', name: 'Commessa'},
                    {dim: 1, type: 'category', name: 'PCQ', scale: true},
                    {
                        dim: 2, name: 'Fase', inverse: true, nameLocation: 'start', axisLabel: {
                            formatter: (value) => value <= 0.001 ? 'prima' : (value >= 0.999 ? 'ultima' : '') //Math.floor(100 * value) + '%'
                        },
                    },
                    {dim: 3, name: 'Notifiche'},
                    {dim: 4, name: 'Certificati'},
                ],
                series: {
                    name: 'counts',
                    type: 'parallel',
                    lineStyle: {
                        width: 1,
                        opacity: 0.5
                    },
                    emphasis: {
                        disabled: true,
                    },
                    realtime: false,
                    inactiveOpacity: 0,
                    smooth: true,
                    data: Object.entries(itpsStatsStruct.jobs).flatMap(([jobId, jobStruct]) =>
                        Object.entries(jobStruct.itps).flatMap(([itpId, itpStruct]) =>
                            itpStruct.items.map((item, i, items) => [
                                jobStruct.code,
                                itpStruct.docN,
                                i / (items.length - 1), // itpStruct.stats.count,
                                props.plotVariant.includes('events')
                                    ? itpEventsStatsStruct?.jobs?.[jobId]?.itps?.[itpId]?.items?.[item] ?? 0
                                    : undefined,
                                props.plotVariant.includes('certs')
                                    ? itpCertsStatsStruct?.jobs?.[jobId]?.itps?.[itpId]?.items?.[item] ?? 0
                                    : undefined,
                            ])))
                },
            })
        }
    }, [graph, props.plotVariant, itpsStatsStruct, itpCertsStatsStruct, itpEventsStatsStruct])

    return <>
        <div ref={graphRef} style={{height: 'calc(100vh - 160px)'}}>
        </div>
    </>
}
