import {ITPPolicy, ITPPolicyContext} from "./ITPPolicy";

export interface ITPEventAttachmentsPolicyContext extends ITPPolicyContext {
}

/** Policy that limits the operations on an ITP certificate */
export function ITPEventAttachmentsPolicy(ctx: ITPEventAttachmentsPolicyContext) {
    const itpPolicy = ITPPolicy(ctx)

    const {
        myRoles,

        isContractor_CQ,

        isOwnerOrNoOwner,

    } = itpPolicy

    const canWrite = isContractor_CQ && itpPolicy.permissions.read && isOwnerOrNoOwner

    return {
        myRoles,

        isOwnerOrNoOwner,

        permissions: {
            read: itpPolicy.permissions.read,

            write: canWrite,

            delete: canWrite,
        }
    }
}
