import * as React from "react";
import {useCallback} from "react";
import {Letter} from "../../../fusina-letters/entities/Letter";
import {FileUploadInput} from "./FileUploadInput";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {EcoOdS} from "../../../fusina-letters/entities/EcoOdS";

export const LetterAttachmentAddBtn: React.FC<{
    label?: string
    letter: Letter
    onBeforeProcess?: () => void
    onProcessing?: (result: Promise<void>) => void
}> = props => {

    const rmi = useFusinaRmi()

    const processFile = useCallback(async (file: File) => {
        props.onBeforeProcess?.()
        const res = rmi.Letters.addAttachment(props.letter._id, file)
        props.onProcessing?.(res)
        return res
    }, [props.letter])

    return <FileUploadInput label={props.label} processFile={processFile}/>
}

export const EstimateAttachmentAddBtn: React.FC<{
    label?: string
    letter: EcoOdS
    onBeforeProcess?: () => void
    onProcessing?: (result: Promise<void>) => void
}> = props => {

    const rmi = useFusinaRmi()

    const processFile = useCallback(async (file: File) => {
        props.onBeforeProcess?.()
        const res = rmi.EcoOds.addEstimateAttachment(props.letter._id, file)
        props.onProcessing?.(res)
        return res
    }, [props.letter])

    return <FileUploadInput label={props.label} processFile={processFile}/>
}
