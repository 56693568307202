import {DependencyList, useCallback, useState} from "react";

/**
 * Small debounce circuit to put in front of callback,
 * similar to lodash.throttled, but with reactive status signal.
 * This shall not be used in place of useCallback.
 */
export default function useThrottledCallback<T extends Function>(callback: T, timeout: number, deps: DependencyList) {
    const [isDelaying, setIsDelaying] = useState<boolean>(false);
    const [postponed, setPostponed] = useState(null);
    const [lastCall, setLastCall] = useState(Date.now());

    const throttledCallback = useCallback((...args) => {
        const remaining = timeout - (Date.now() - lastCall);
        if (remaining <= 0) {
            setLastCall(Date.now())
            callback(...args)
        } else {
            if (postponed) {
                clearTimeout(postponed)
            }
            setIsDelaying(true)
            setPostponed(setTimeout(() => {
                setIsDelaying(false)
                setLastCall(Date.now())
                callback(...args)
            }, remaining));
        }
    }, [timeout, lastCall, callback, postponed, ...deps]);

    return {
        isDelaying,
        throttledCallback
    };
}