import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {amountFormatter, EcoOdS, sumOrderItems} from "../../../fusina-letters/entities/EcoOdS";
import {useJobContext} from "../contexts/JobContext";
import {
    ColumnActionsMode,
    CommandBar,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    DialogType,
    IColumn,
    ISelection,
    mergeStyles,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Selection,
    Spinner,
    SpinnerSize,
    useTheme
} from "@fluentui/react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {DailyOrderExpenses} from "../../../fusina-letters/entities/DailyOrderExpenses";
import {formatDateOnly} from "./DateOnlyPicker";
import {DateOnlyPickerExpanded} from "./DateOnlyPickerExpanded";
import {SimpleDialog} from "./SimpleDialog";
import {DailyOrderExpensesEditor} from "./DailyOrderExpensesEditor";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {CancelEditsBtn} from "./CancelEditsBtn";
import './EcoOdsExpensesTable.css'
import {CmdDateSearchBox} from "./CmdDateSearchBox";
import {ExpensesPolicy} from "../../../fusina-letters/controls/EcoOdsPolicy";
import {useSecurityContext} from "../contexts/SecurityContext";
import {exportLettersExpenses} from "./EcoOdsExpensesExportFunction";
import {RMI} from "../config";

type PivotedExpenseQuantities = {
    [K in `item_${string}`]: number
} & {
    date: string,
}


export const EcoOdsExpensesTable: React.FC<{
    letterId: string
}> = props => {
    const theme = useTheme()
    const {job} = useJobContext()

    const securityContext = useSecurityContext()

    const {data: letter} = useFusinaRmiResource('Letters', 'findById', props.letterId)

    const policy = useMemo(() =>
        ExpensesPolicy({
            job,
            securityContext,
            oldLetter: letter as EcoOdS,
        }), [letter, job, securityContext])

    const isExpensesClosed = !!(letter as EcoOdS)?.expensesMeta?.closed

    const canWrite = policy?.permissions?.write && !isExpensesClosed

    const {
        data: storedExpenses_ALL,
        refresh: refreshExpenses,
        error,
    } = useFusinaRmiResource("EcoOds", "findExpenses", props.letterId)

    const [searchDates, setSearchDates] = useState<string>(undefined)
    const searchDatesRegex = useMemo(() => searchDates ? new RegExp('^' + searchDates.replace(/_/g, '[0-9]')) : undefined, [searchDates])

    const dateToDoc = useCallback((date: string) => storedExpenses_ALL.find(e => e._id.date === date), [storedExpenses_ALL])

    const storedExpenses = useMemo(() =>
        searchDatesRegex ? storedExpenses_ALL?.filter?.(exp => searchDatesRegex.test(exp?._id?.date)) : storedExpenses_ALL, [storedExpenses_ALL, searchDatesRegex])

    const allItems = useMemo<string[]>(() => {
        const s = new Set<string>()
        storedExpenses?.forEach?.(expense => {
            expense.items.forEach(item => {
                s.add(item.item)
            })
        })
        const arr = Array.from(s.values())
        arr.sort()
        return arr
    }, [storedExpenses])

    const pivotedExpenseQuantities = useMemo<PivotedExpenseQuantities[]>(() =>
        storedExpenses?.map?.(expense => {
            const row = {
                date: expense._id.date
            };
            allItems.forEach(it => {
                const q = expense.items.filter(item => item.item === it).map(item => item.quantity).reduce((p, c) => p + c, 0)
                row[`item_${it}`] = q !== 0 ? q : ''
            })
            return row
        }), [storedExpenses, allItems])

    const [highlightedItem, setHighlightedItem] = useState<string>(undefined)

    const columns = useMemo<IColumn[]>(() => {
        const highlightStyle = mergeStyles({
            background: theme.semanticColors.listItemBackgroundChecked
        })

        const columns: IColumn[] = [
            {
                name: '',
                key: 'date',
                fieldName: 'date',
                isRowHeader: true,
                minWidth: 180,
                maxWidth: 200,
                flexGrow: 0.1,
                targetWidthProportion: 0.1,
                columnActionsMode: ColumnActionsMode.disabled,
            },
        ];
        allItems.forEach(it => {
            columns.push({
                key: `item_${it}`,
                name: it,
                minWidth: 50, // 25, // 90,
                maxWidth: 120,
                flexGrow: 0.001,
                targetWidthProportion: 0.001,
                fieldName: `item_${it}`,
                onColumnClick() {
                    setHighlightedItem(it)
                },
                className: it === highlightedItem ? highlightStyle : undefined,
                onRenderHeader() {
                    return <div style={{
                        textAlign: 'right',
                        transform: 'rotate(-15deg)',
                        transformOrigin: 'top left',
                        height: '1.5em',
                        lineHeight: '0.9em',
                        fontSize: '0.9em'
                    }}>
                        {it}
                    </div>
                },
                ariaLabel: it
            })
        })
        columns.push({
            // This column is to occupy the rightmost space
            key: 'terminator',
            name: '',
            columnActionsMode: ColumnActionsMode.disabled,
            minWidth: 1,  // This also allows more visibility to the rightmost column headers // Well, it's not required.
            flexGrow: 100,
            targetWidthProportion: 1,
        })
        return columns
    }, [allItems, job?.contractItems, highlightedItem])

    const [updatingDoc, setUpdatingDoc] = useState<DailyOrderExpenses>(undefined)

    const [isInserting, setIsInserting] = useState<boolean>(false)

    const [selectedDate, setSelectedDate] = useState<string>()

    const selection = useMemo(() => new Selection<PivotedExpenseQuantities>({
        onSelectionChanged() {
            const sel = selection.getSelection()
            if (sel?.length === 1) {
                setSelectedDate(sel[0].date)
            } else {
                setSelectedDate(undefined)
            }
        },
        getKey(item) {
            return item.date
        },
    }) as unknown as ISelection<PivotedExpenseQuantities & { key: string }>, [])

    // TODO?: fixed header/footer?
    //        see styles imposing maximum height to details list:
    //        https://developer.microsoft.com/en-us/fluentui#/controls/web/scrollablepane

    return <>
        <CommandBar
            items={[
                {
                    key: 'add',
                    text: 'Aggiungi',
                    iconProps: {
                        iconName: 'Add'
                    },
                    disabled: !canWrite,
                    onClick() {
                        setIsInserting(true)
                    }
                },
                {
                    key: 'edit',
                    text: 'Modifica',
                    iconProps: {
                        iconName: 'Edit'
                    },
                    disabled: selectedDate === undefined || !canWrite,
                    onClick() {
                        if (selectedDate) {
                            setUpdatingDoc(dateToDoc(selectedDate))
                        }
                    }
                },
                {
                    key: 'xlsx_expenses',
                    text: 'Esporta XLSX',
                    iconProps: {iconName: 'ExcelDocument'},
                    onClick() {
                        RMI.EcoOds.findExpenses(letter._id).then(expenses => {
                            exportLettersExpenses([letter], job, expenses, 'xlsx')
                        })
                    }
                },
            ]}
            farItems={[
                {
                    key: 'search',
                    iconProps: {
                        iconName: 'Calendar',
                    },
                    text: 'Filtra date',
                    onClick() {
                        const d = new Date()
                        const m = d.getMonth() + 1
                        setSearchDates(`${d.getFullYear()}/${m >= 10 ? m : '0' + m}`)
                    },
                    onRender: searchDates ? () => {
                        return <CmdDateSearchBox value={searchDates} onChange={(search) => setSearchDates(search)}/>
                    } : undefined
                }
            ]}
        />
        {pivotedExpenseQuantities?.length > 0 &&
        <DetailsList
            selection={selection}
            className="EcoOdsExpensesTable-DetailsList"
            layoutMode={DetailsListLayoutMode.justified}
            items={pivotedExpenseQuantities}
            columns={columns}
            compact={true}
            onItemInvoked={canWrite ? row => {
                setUpdatingDoc(dateToDoc(row.date))
            } : undefined}
            onRenderDetailsHeader={(detailsHeaderProps, DefaultHeader) => <>
                <DefaultHeader {...detailsHeaderProps} />
                <DetailsRow
                    {...detailsHeaderProps}
                    compact={false}
                    // selectionMode={SelectionMode.none} // if you do this you break the consistency w.r.t. the table
                    itemIndex={-1}
                    item={Object.fromEntries([
                        ['date', <b>Prezzo contrattuale</b>],
                        ...new Array(...allItems).map(it => {
                            const ci = job?.contractItems?.find?.(it_ => it_.item === it)
                            return [`item_${it}`, ci
                                ? `${amountFormatter(ci.unitPrice)} €/${ci.unitOfMeasure}`  // NOTE: not setting 2 decimal points because it may require 3 or more.
                                : <span
                                    style={{color: theme.semanticColors.disabledSubtext, fontSize: '0.9em'}}>N/D</span>]
                        })
                    ])}
                />
            </>}
            onRenderDetailsFooter={detailsFooterProps => <>
                <DetailsRow
                    {...detailsFooterProps}
                    // selectionMode={SelectionMode.none} // if you do this you break the consistency w.r.t. the table
                    compact={false}
                    itemIndex={-1}
                    item={Object.fromEntries([
                        ['date', <b>Quantità totale</b>],
                        ...new Array(...allItems).map(it =>
                            [`item_${it}`, <span>
                                {storedExpenses?.flatMap?.(expense =>
                                    expense.items.filter(it_ => it_.item === it)).map(it_ => it_.quantity).reduce((p, q) => p + q, 0)}
                            </span>])
                    ])}
                />
                <DetailsRow
                    {...detailsFooterProps}
                    // selectionMode={SelectionMode.none} // if you do this you break the consistency w.r.t. the table
                    compact={false}
                    itemIndex={-1}
                    item={Object.fromEntries([
                        ['date', <b>Importo a consuntivo</b>],
                        ...new Array(...allItems).map(it =>
                            [`item_${it}`, <span>
                                {amountFormatter(sumOrderItems(storedExpenses?.flatMap?.(expense =>
                                    expense.items.filter(it_ => it_.item === it))), 2)} €
                            </span>])
                    ])}
                />
            </>}
        />
        || (pivotedExpenseQuantities === undefined
            ? <Spinner size={SpinnerSize.large}/>
            : <MessageBar messageBarType={MessageBarType.info}>
                Nessun dato da mostrare.
            </MessageBar>)}
        <MaybeErrorMessageBar error={error}/>
        {updatingDoc && <UpdateDialog
            doc={updatingDoc}
            onDismiss={() => {
                setUpdatingDoc(undefined)
                refreshExpenses().then()
            }}
        />}
        {isInserting && <InsertDialog
            letterId={props.letterId}
            onDismiss={() => {
                setIsInserting(false)
                refreshExpenses().then()
            }}
            onUpdateRequested={date => {
                setIsInserting(false)
                setUpdatingDoc(dateToDoc(date))
            }}
        />}
    </>
}

const SaveButton: React.FC<{
    doc: DailyOrderExpenses
    onSuccess: () => void
    onError: (error: unknown) => void
}> = props => {
    const rmi = useFusinaRmi()
    const [pending, setPending] = useState(false)
    const save = useCallback(() => {
        setPending(true)
        rmi.EcoOds.writeExpenses(props.doc)
            .then(props.onSuccess)
            .catch(props.onError)
            .finally(() => {
                setPending(false)
            })
    }, [props.doc, props.onSuccess, props.onError])
    return <PrimaryButton
        disabled={pending}
        onClick={save}
    >
        Salva {pending && <Spinner size={SpinnerSize.small}/>}
    </PrimaryButton>;
};


const UpdateDialog: React.FC<{
    onDismiss: () => void
    doc: DailyOrderExpenses
}> = props => {
    const {job} = useJobContext()
    const [error, setError] = useState(undefined)

    const doc = props.doc

    return <SimpleDialog
        type={DialogType.largeHeader}
        title='Modifica consuntivo'
        subText=""
        buttons={[
            <CancelEditsBtn editable={doc} onClick={() => {
                props.onDismiss()
            }}/>,
            <SaveButton doc={doc} onSuccess={props.onDismiss} onError={setError}/>
        ]}
    >
        {(doc && job && !error)
            ? <DailyOrderExpensesEditor
                doc={doc}
                contractualItems={job?.contractItems}
            />
            : (error
                ? <MaybeErrorMessageBar error={error}/>
                : <Spinner size={SpinnerSize.large}/>)}
        <MaybeErrorMessageBar error={error}/>
    </SimpleDialog>
}


const InsertDialog: React.FC<{
    onDismiss: () => void
    letterId: string
    onUpdateRequested: (date: string) => void
}> = props => {
    const rmi = useFusinaRmi()

    const {
        data: storedExpenses,
        error: errorExpenses,
    } = useFusinaRmiResource("EcoOds", "findExpenses", props.letterId)

    const {job} = useJobContext()

    const today = useMemo(() => formatDateOnly(new Date()), [])
    const yesterday = useMemo(() => formatDateOnly(new Date(Date.now() - 24 * 3600_000)), [])

    const [date, setDate] = useState<string>(formatDateOnly(new Date()))

    const [doc, setDoc] = useState<DailyOrderExpenses>(undefined)

    const [error, setError] = useState()

    return <SimpleDialog
        type={DialogType.largeHeader}
        title='Inserimento consuntivo'
        subText={doc ? '' : 'Selezionare la data per la quale si intende aggiungere i costi a consuntivo.'}
        buttons={doc ? [
            <CancelEditsBtn editable={doc} onClick={() => {
                props.onDismiss()
            }}/>,
            <SaveButton doc={doc} onSuccess={props.onDismiss} onError={setError}/>
        ] : [
            <DefaultButton
                text='Annulla'
                onClick={() => {
                    props.onDismiss()
                }}
            />,
            <PrimaryButton
                text='Prosegui'
                disabled={!date}
                onClick={() => {
                    if (storedExpenses?.find?.(exp => exp._id.date === date)) {
                        props.onUpdateRequested(date)
                    } else {
                        setDoc({
                            _id: {
                                date,
                                order: props.letterId,
                            },
                            items: [],
                        })
                    }
                }}
            />
        ]}
    >
        <MaybeErrorMessageBar error={errorExpenses}/>
        {doc
            ? <DailyOrderExpensesEditor
                doc={doc}
                contractualItems={job?.contractItems}
            />
            : <>
                <DateOnlyPickerExpanded value={date} onChange={date => setDate(date)}/>

                {date && <>
                    <br/>
                    Data selezionata: <b>{date}</b>
                    {date === today && ' (oggi)'}
                    {date === yesterday && ' (ieri)'}
                    <br/>
                    <br/>
                </>}

                {storedExpenses?.find?.(exp => exp._id.date === date) &&
                <MessageBar messageBarType={MessageBarType.success} isMultiline>
                    Data già presente. Proseguendo saranno caricati i dati già inseriti.
                </MessageBar> || <br/>}
            </>}
        <MaybeErrorMessageBar error={error}/>
    </SimpleDialog>
}
