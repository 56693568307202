import {UserGrant} from "../../fusina-authz/entities/UserGrants";
import {Job} from "../entities/Job";
import {RoleEnum} from "../../fusina-authz/entities/RoleEnum";

export function getJobGrants(job: Job, grants?: UserGrant[]): UserGrant[] {
    return grants?.filter?.(g => (
        (g.scopeType === 'job' && g.scopeId === job._id && job._id) ||
        (g.scopeType === 'company' && g.scopeId === job.buyer && job.buyer) ||
        (g.scopeType === 'company' && g.scopeId === job.contractor && job.contractor)
    )) ?? []
}

export function getJobRoles(job: Job, grants?: UserGrant[]): Set<RoleEnum> {
    return new Set(getJobGrants(job, grants).map(g => g.role))
}
