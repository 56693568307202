import * as React from 'react'
import {createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react'
import {SERVER_BASE_URL} from "../config";

export interface IServerStatusContext {
    server?: {
        i: string
        v: string
        loadavg: [number, number, number]
        freemem: number
        db: string
    },
    rtt?: number
    instances?: Set<string>
    error?: unknown
    setDisconnected: (error?: unknown) => void
    refreshStatus: () => void
}

export const ServerStatusContext = createContext<IServerStatusContext>({
    setDisconnected() {
    }, refreshStatus() {
    }
})


export const ServerStatusStore: React.FC<PropsWithChildren<{}>> = props => {
    const instances = useMemo<Set<string>>(() => new Set(), [])

    const [status, setStatus] = useState<Omit<IServerStatusContext, 'setDisconnected' | 'refreshStatus'>>({})

    const setDisconnected = useCallback((error: unknown) => {
        setStatus(v => ({...v, server: undefined, error}))
    }, [])

    const refreshStatus = useCallback(async () => {
        const start = Date.now()
        try {
            const r = await fetch(SERVER_BASE_URL + '/api/s/status', {
                credentials: 'omit',
                mode: 'same-origin',
                keepalive: true,
            })
            const s = await r.json()
            const elapsed = Date.now() - start
            instances.add(s.server?.i)
            setStatus({
                ...s,
                instances,
                rtt: elapsed,
            })
        } catch (err) {
            console.error(err)
            setStatus({
                error: err,
                instances,
            })
        }
    }, [])

    useEffect(() => {
        refreshStatus().then()

        window.addEventListener('offline', setDisconnected);
        window.addEventListener('online', refreshStatus);
        // const t = setInterval(() => {  // NOTE: the status is already refreshed by the notifications SSE reconnecting
        //     refreshStatus().then()
        // }, 600_000)

        return () => {
            window.removeEventListener('offline', setDisconnected)
            window.removeEventListener('online', refreshStatus)
            // clearInterval(t)
        }
    }, [])

    const ctx = useMemo(() => ({
        ...status,
        setDisconnected,
        refreshStatus,
    }), [status, setDisconnected, refreshStatus])

    return <ServerStatusContext.Provider value={ctx}>
        {props.children}
    </ServerStatusContext.Provider>
}
