import * as React from 'react'
import {DatePicker, DayOfWeek, IDatePickerStyleProps, IDatePickerStyles, IStyleFunctionOrObject} from "@fluentui/react";
import {useDateOnlyFromString} from "../hooks/useDateOnlyFromString";
import {useCalendarStrings} from "../hooks/useCalendarStrings";

export function formatDateOnly(d: Date): string {
    return d.getTime() ? `${d.getFullYear()}/${(d.getMonth() + 1) <= 9 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)}/${d.getDate() <= 9 ? ('0' + d.getDate()) : d.getDate()}` : ''
}

export const DateOnlyPicker: React.FC<{
    label?: string
    value: string
    disabled?: boolean
    onChange?: (newValue: string) => void
    styles?: IStyleFunctionOrObject<IDatePickerStyleProps, IDatePickerStyles>
}> = props => {
    const value = useDateOnlyFromString(props.value)
    const strings = useCalendarStrings()

    return <DatePicker
        styles={props.styles}
        strings={strings}
        firstDayOfWeek={DayOfWeek.Monday}
        showGoToToday
        isMonthPickerVisible
        disabled={props.disabled}
        formatDate={formatDateOnly}
        label={props.label}
        value={value}
        maxDate={new Date()}
        onClick={props.disabled ? () => {
        } : undefined}
        onSelectDate={date => {
            if (!props.disabled) {
                props.onChange?.(formatDateOnly(date))
            }
        }}
    />
}
