import * as React from "react";
import {EcoItem} from "../../../fusina-jobs/entities/EcoItem";
import {DateOnlyPicker} from "./DateOnlyPicker";
import {Spinner, SpinnerSize, Stack, TextField} from "@fluentui/react";
import {OrderItemsEditor} from "./OrderItemsEditor";
import {DailyOrderExpenses} from "../../../fusina-letters/entities/DailyOrderExpenses";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";

export const DailyOrderExpensesEditor: React.FC<{
    doc: DailyOrderExpenses
    contractualItems?: EcoItem[]
}> = props => {

    const {
        data: letter,
        error: letterError,
    } = useFusinaRmiResource('Letters', 'findById', props.doc._id.order)

    if (letterError) {
        return <MaybeErrorMessageBar error={letterError}/>
    }

    if (!letter) {
        return <Spinner size={SpinnerSize.medium}/>
    }

    return <Stack tokens={{childrenGap: 'm'}}>
        <Stack horizontal wrap tokens={{childrenGap: 'm'}}>
            <Stack.Item grow={1}>
                <TextField
                    label="ODS n°"
                    disabled
                    value={letter.externalRef}
                />
            </Stack.Item>
            <Stack.Item grow={1}>
                <DateOnlyPicker
                    label="Data lavori"
                    disabled
                    value={props.doc?._id?.date}
                />
            </Stack.Item>
        </Stack>
        <OrderItemsEditor items={props.doc.items} contractualItems={props.contractualItems}/>
    </Stack>
}
