import * as React from 'react'
import {useCallback, useMemo, useState} from 'react'
import {Stack} from '@fluentui/react/lib/Stack';
import {Letter} from "../../../fusina-letters/entities/Letter";
import {Callout, DefaultButton, PrimaryButton, Spinner, SpinnerSize, Text} from "@fluentui/react";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useSecurityContext} from "../contexts/SecurityContext";
import LettersPolicy from "../../../fusina-letters/controls/LettersPolicy";
import {Job} from "../../../fusina-jobs/entities/Job";
import {useId} from '@fluentui/react-hooks';


export const RejectToDraftBtn: React.FC<{
    job: Job
    letter: Letter
    onSuccess?: (letterId: string) => void
}> = props => {

    const rmi = useFusinaRmi()
    const sc = useSecurityContext()

    const policy = useMemo(() => props.job ? LettersPolicy({
        job: props.job,
        securityContext: sc,
        oldLetter: props.letter,
    }) : undefined, [sc, props.job, props.letter])

    const [pending, setPending] = useState<boolean>(false)
    const [confirming, setConfirming] = useState<boolean>(false)

    const handle = useCallback(() => {
        setConfirming(false)
        setPending(true)
        rmi.Letters.rejectToDraft(props.letter._id)
            .then(() => {
                props.onSuccess?.(props.letter._id)
            })
            .catch(console.error) // TODO
            .finally(() => setPending(false))
    }, [rmi, policy])

    const preConfirmHandle = useCallback(() => {
        setConfirming(true)
        setPending(true)
        setTimeout(() => {
            setPending(false)
        }, 1000)
    }, [])

    const buttonId = useId('RejectToDraftBtn-preConfirm');

    if (policy?.permissions?.rejectToDraft !== true) {
        return null
    }

    return <>
        <DefaultButton
            iconProps={{iconName: 'EraseTool'}}
            id={buttonId} disabled={pending}
            onClick={preConfirmHandle}
        >
            Rimanda in bozza
        </DefaultButton>
        <Callout
            hidden={!confirming}
            coverTarget
            role="dialog"
            style={{padding: '20px 24px'}}
            onDismiss={() => setConfirming(false)}
            target={'#' + buttonId}
            isBeakVisible={false}
            setInitialFocus
        >
            <Text block variant="xLarge" style={{fontWeight: 200, marginBottom: 12}}>
                Confermi?
            </Text>
            Stai per rimandare la lettera in bozza.
            <br/>
            Le firme presenti saranno annullate.
            <Stack horizontal style={{marginTop: 20}} tokens={{childrenGap: 'm'}}>
                <PrimaryButton onClick={handle} className="RejectToDraftBtn-Confirm"
                               text="Conferma" disabled={pending}/>
                {pending && <Spinner size={SpinnerSize.small}/>}
            </Stack>
        </Callout>
    </>
}
