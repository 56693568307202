import {Letter} from "../../../fusina-letters/entities/Letter";
import {ActionButton} from "@fluentui/react";
import * as React from "react";
import {useCallback} from "react";
import {useFusinaRmi} from "../hooks/useFusinaRmi";

export function LetterPreviewBtn({letter}: { letter: Letter }) {
    const rmi = useFusinaRmi()

    const handleLetterPreview = useCallback(() => {
        const popup = window.open('about:blank', 'LetterPreview', 'width=530,height=760,popup=yes')
        popup.document.documentElement.innerHTML = 'Loading ...'
        rmi.Letters.findHtmlById(letter._id)
            .then(html => {
                popup.document.documentElement.innerHTML = html
            })
            .catch(e => {
                popup.document.documentElement.innerHTML = e.message ?? 'Error';
            });
    }, [letter._id])

    if (letter.signatures.length > 0 ||
        (letter.type === 'EcoOdS' && letter.estimate.signatures.length < 3)) {  // TODO: use policy
        return null  // Don't generate a preview
    }

    return <ActionButton iconProps={{iconName: "Preview"}} text="Anteprima"
                         onClick={handleLetterPreview}/>;
}
