import type {PolicyContext} from "../../fusina-policies/PolicyContext";
import {RoleEnum} from "../../fusina-authz/entities/RoleEnum";

/** Policy applied to ITP events access with unlimited, global scope */
export function ITPGlobalEventsPolicy(ctx: PolicyContext) {
    const sc = ctx.securityContext;

    const myRoles: Set<RoleEnum> = new Set([...sc?.user?.globalRoles ?? []])

    // NOTE: this design is against multi-tenancy

    return {
        myRoles,

        permissions: {
            read_global_events: myRoles.has(RoleEnum.Admin) || myRoles.has(RoleEnum.CDL_Q)
                || myRoles.has(RoleEnum.SupervisorQ202310)
        }
    }
}
