import * as React from "react";
import {useContext, useMemo} from "react";
import {EventProperties} from "../../../fusina-event/entities/EventProperties";
import {useJobContext} from "../contexts/JobContext";
import {ITPItemContext} from "../contexts/ITPItemContext";
import './ITPEventFullView.css'
import {formatFullNotificationNumber, ITPEvent} from "../../../fusina-itp/entities/ITPEvent";
import {MessageBar, MessageBarType, Spinner, SpinnerSize, useTheme} from "@fluentui/react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";

const showUTC = false

export const ITPEventFullView: React.FC<{
    isPreConfirm?: boolean
    eventProps: Partial<EventProperties> | ITPEvent
    withHeader?: boolean
}> = props => {
    const eventProps = props.eventProps
    const {job, contractor} = useJobContext()
    const {itp: itp_last_rev, item: item_last_rev} = useContext(ITPItemContext)

    // [THE_HISTORICAL_REV_LOGIC]
    const itpRevId = (eventProps as ITPEvent)?.itp?.revId ?? (eventProps as ITPEvent)?.itp?._id
    const {
        data: historicalItpRev,
        error: historicalItpRevError,
    } = useFusinaRmiResource('ITPRevision', 'findOne', itpRevId)
    // NOTE: with the new API, the above call returns the initial dummy revision if there's no revision.

    const itp = historicalItpRev?.itp ?? itp_last_rev;

    const item = (eventProps as ITPEvent)?.itpItemCopy ?? (
        itpRevId === undefined ? item_last_rev : itp?.items?.find?.(it => it.item === item_last_rev.item));

    const dts = useMemo(() => new Date(eventProps.dtStart), [eventProps.dtStart])

    const theme = useTheme()

    if (historicalItpRevError) {
        console.trace('Error while retrieving ITP revision', historicalItpRevError)
        return <MaybeErrorMessageBar error={historicalItpRevError}/>
    }
    if (itp === undefined || item === undefined) {
        return <Spinner/>
    }

    return <div className="ITPEventFullView">
        {props.withHeader && <>
            <table className="ITPEventFullView-h">
                <tbody>
                <tr>
                    <td style={{border: '1px solid #000'}}>
                        <big><b>Notifica espletamento fasi PCQ</b></big>
                    </td>
                </tr>
                </tbody>
            </table>
            <br/>
        </>}
        <table className="ITPEventFullView-b">
            <tbody>
            <tr>
                <td colSpan={2}>
                    <b>Progetto:</b> PBCFU Centrale di Fusina (VE)
                    <br/>
                    <b>Opera:</b> {job.title}
                    <br/>
                    <b>Appaltatore:</b> {contractor?.denomination ?? <Spinner size={SpinnerSize.xSmall}/>}
                    <br/>
                    <b>Contratto:</b> {job.contract}
                    <br/>
                    <b>Commessa:</b> {job.code}
                </td>
            </tr>
            {props.isPreConfirm ? <>
                <tr>
                    <td><b>Notification no.:</b></td>
                    <td rowSpan={2} style={{
                        background: theme.semanticColors.infoBackground,
                        padding: 0,
                        verticalAlign: 'middle',
                    }}>
                        <MessageBar messageBarType={MessageBarType.info} isMultiline
                                    styles={{innerText: {fontStyle: 'italic'}}}>
                            Inviare la notifica per assegnarvi numerazione e data.
                        </MessageBar>
                    </td>
                </tr>
                <tr>
                    <td><b>Notification date:</b></td>
                </tr>
            </> : <>
                <tr>
                    <td><b>Notification no.:</b></td>
                    <td>
                        {formatFullNotificationNumber(eventProps as ITPEvent, job)}
                    </td>
                </tr>
                <tr>
                    <td><b>Notification date:</b></td>
                    <td>
                        {new Date((eventProps as ITPEvent)?.messages?.[0]?.dtStamp).toLocaleDateString(undefined, {dateStyle: 'long'})}
                    </td>
                </tr>
            </>}
            <tr>
                <td><b>PCQ doc N.:</b></td>
                <td>{itp.docN}</td>
            </tr>
            <tr>
                <td><b>PCQ titolo:</b></td>
                <td>{itp.title}</td>
            </tr>
            <tr>
                <td><b>Fase n. (ITEM):</b></td>
                <td>{item.item}</td>
            </tr>
            <tr>
                <td><b>Componente:</b></td>
                <td>{item.comp}</td>
            </tr>
            <tr>
                <td><b>Descrizione:</b></td>
                <td style={{whiteSpace: 'pre-wrap'}}>{item.desc}</td>
            </tr>
            <tr>
                <td><b>Documenti applicabili:</b></td>
                <td style={{whiteSpace: 'pre-wrap'}}>{item.refDocs}</td>
            </tr>
            <tr>
                <td><b>Oggetto dell'ispezione:</b></td>
                <td style={{whiteSpace: 'pre-wrap'}}>{eventProps.description}</td>
            </tr>
            <tr>
                <td><b>Luogo per l'ispezione:</b></td>
                <td style={{whiteSpace: 'pre-wrap'}}>{eventProps.location}</td>
            </tr>
            <tr>
                <td><b>Data dell'ispezione:</b></td>
                <td>{dts.toLocaleDateString(undefined, {dateStyle: 'long'})}</td>
            </tr>
            <tr>
                <td>
                    <b>Orario d'incontro:</b><br/>
                    {showUTC && <b><small><small>(ISO/UTC):</small></small></b>}
                </td>
                <td>
                    {dts.toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit',
                        timeZoneName: 'short',
                    })}
                    <br/>
                    {showUTC && <small><small>({dts.toISOString()})</small></small>}
                </td>
            </tr>
            <tr>
                <td>
                    <b>Persona di riferimento:</b><br/>
                    <b>Telefono:</b><br/>
                    <b>e-mail:</b><br/>
                </td>
                <td>
                    {eventProps.organizer.name} <br/>
                    {eventProps.organizer.telephone} <br/>
                    {eventProps.organizer.email} <br/>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
}
