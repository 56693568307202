import * as React from 'react'
import {useCallback, useContext, useMemo, useState} from 'react'
import {ITPItemContext} from "../contexts/ITPItemContext";
import {useSecurityContext} from "../contexts/SecurityContext";
import {
    Calendar,
    DayOfWeek,
    DefaultButton,
    Dropdown,
    IDropdownOption,
    Label,
    PrimaryButton,
    Stack,
    TextField,
    TimePicker,
    useTheme
} from "@fluentui/react";
import {EventProperties} from "../../../fusina-event/entities/EventProperties";
import moment from 'moment-timezone'
import {useCalendarStrings} from "../hooks/useCalendarStrings";
import {ConfirmedCalloutBtn} from "./ConfirmedCalloutBtn";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {ITPEventFullView} from "./ITPEventFullView";
import {ITPEvent} from "../../../fusina-itp/entities/ITPEvent";

enum EditingStep {
    date,
    time,
    location,
    description,
    preConfirm,
}

const STEP_LABELS: Record<EditingStep, string> = {
    [EditingStep.date]: 'data',
    [EditingStep.time]: 'ora',
    [EditingStep.location]: 'luogo',
    [EditingStep.description]: 'oggetto',
    [EditingStep.preConfirm]: 'anteprima',
}

const tzOptions: IDropdownOption[] = moment.tz.names().map(tz => ({
    key: tz,
    text: tz,
}))

const defaultTz = 'Europe/Rome' // moment.tz.guess()
const isTzReadonly = true

export const ITPEventsCreation: React.FC<{
    onDismiss: () => void
    onSent: (event: ITPEvent) => void
    initialDtsLocal?: Date
    // initialEventProps?: Partial<EventProperties>
}> = props => {
    const sc = useSecurityContext()
    const {itp, item} = useContext(ITPItemContext)

    const [tz, setTz] = useState<string>(defaultTz)
    const [dtsLocal, setDtsLocal] = useState<Date | undefined>(props.initialDtsLocal ?? undefined)

    const [eventProps, setEventProps] = useState<Partial<EventProperties>>({
        // ...(props.initialEventProps ?? {}),
        organizer: {  // For preview only, will be set by event factory
            email: sc.user.email,
            name: sc.user.fullName,
            telephone: sc.user.telephone,
        }
    })
    const pushDts = useCallback((dtsLocal: Date, tz: string) => {
        const m = moment(dtsLocal).tz(tz, true).utc()
        setEventProps(v => {
            const d = new Date(v.dtStart)
            d.setUTCFullYear(m.year(), m.month(), m.date())
            d.setUTCHours(m.hours(), m.minutes(), m.seconds())
            return {...v, dtStart: d}
        })
    }, [])

    const [step, setStep] = useState<EditingStep>(EditingStep.date)
    const calendarStrings = useCalendarStrings()
    const canGoToNextStep = useMemo<boolean>(() => {
        if (step === EditingStep.date) {
            return dtsLocal !== undefined
        }
        return true
    }, [eventProps])

    const rmi = useFusinaRmi()
    const sendAction = useCallback(() =>
        rmi.ITPEvents.create(itp._id, item.item, eventProps)
            .then(props.onSent), [rmi, itp, item, eventProps]);

    const theme = useTheme()

    return <Stack tokens={{childrenGap: 'l2'}}>
        <div style={{color: theme.semanticColors.bodySubtext}}>
            {Object.values(EditingStep).filter(x => typeof x === 'number').map((s, i) => <>
                {i !== 0 && <span>&nbsp;&rsaquo;&nbsp;</span>}
                <span
                    style={{
                        color: step < s ? theme.semanticColors.disabledBodySubtext : (
                            step === s ? theme.semanticColors.bodyText : undefined),
                        fontWeight: step === s ? 600 : undefined,
                    }}
                >{STEP_LABELS[s]}</span>
            </>)}
        </div>

        {step === EditingStep.date && <Stack>
            <Label>Data dell'ispezione</Label>
            <Calendar
                isMonthPickerVisible
                highlightSelectedMonth
                showGoToToday
                today={new Date()}
                firstDayOfWeek={DayOfWeek.Monday}
                strings={calendarStrings}
                value={dtsLocal}
                onSelectDate={date => {
                    const d = new Date(dtsLocal)
                    d.setFullYear(date.getFullYear(), date.getMonth(), date.getDate())
                    setDtsLocal(d)
                    pushDts(d, tz)
                }}
            />
            <br/>
            <span>
                Data selezionata: {dtsLocal
                ? <b>{dtsLocal.toLocaleDateString(undefined, {dateStyle: 'full'})}</b>
                : <small>Nessuna</small>}
            </span>
        </Stack>}

        {step === EditingStep.time && <Stack>
            <TimePicker
                label="Orario d'incontro"
                dropdownMaxWidth={150}
                dropdownWidth={100}
                increments={15}
                defaultValue={dtsLocal}
                onChange={(ev, date) => {
                    const d = new Date(dtsLocal)
                    d.setHours(date.getHours(), date.getMinutes(), date.getSeconds())
                    setDtsLocal(d)
                    pushDts(d, tz)
                }}
            />
            <Dropdown
                label="Fuso orario"
                options={tzOptions}
                disabled={isTzReadonly}
                selectedKey={tz}
                onChange={(event, option) => {
                    const tz = option.key.toString()
                    setTz(tz)
                    pushDts(dtsLocal, tz)
                }}
            />
        </Stack>}

        {step === EditingStep.location && <Stack>
            <TextField
                label="Luogo per l'ispezione"
                multiline
                autoAdjustHeight
                value={eventProps.location}
                onChange={(event, newValue) => {
                    setEventProps(v => ({...v, location: newValue}))
                }}
            />
        </Stack>}

        {step === EditingStep.description && <Stack>
            <TextField
                label="Oggetto dell'ispezione"
                description="Inserire qui eventuali altri dettagli o descrizione dell'incontro."
                multiline
                autoAdjustHeight
                value={eventProps.description}
                onChange={(event, newValue) => {
                    setEventProps(v => ({...v, description: newValue}))
                }}
            />
        </Stack>}

        {step === EditingStep.preConfirm && <Stack>
            <ITPEventFullView isPreConfirm eventProps={eventProps}/>
        </Stack>}

        <Stack horizontal tokens={{childrenGap: 'm'}}>
            {step === 0 && <DefaultButton
                text="Annulla"
                onClick={() => {
                    props.onDismiss()
                }}
            />}
            {step !== 0 && step <= EditingStep.preConfirm && <DefaultButton
                text="Indietro"
                onClick={() => setStep(v => v - 1)}
            />}
            <Stack.Item grow={1}>
                &nbsp;
            </Stack.Item>
            {step < EditingStep.preConfirm && <PrimaryButton
                text="Avanti"
                disabled={!canGoToNextStep}
                onClick={() => {
                    setStep(v => v + 1)
                    pushDts(dtsLocal, tz) // NOTE: dtStart can otherwise be undefined in case of initialDtsLocal
                }}
            />}
            {step === EditingStep.preConfirm && <Stack.Item>
                <ConfirmedCalloutBtn
                    btnProps={{
                        text: "Invia",
                        primary: true,
                    }}
                    calloutChildren={<>
                        Stai per inviare la notifica al CDL-Q.
                    </>}
                    action={sendAction}
                />
            </Stack.Item>}
        </Stack>
    </Stack>
}
