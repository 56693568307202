import {InspectionTestPlan} from "../entities/InspectionTestPlan";
import {ITPItem} from "../entities/ITPItem";
import {ITPParty} from "../entities/ITPParty";

export interface ITPValidationMessage {
    index?: number
    itemField?: keyof ITPItem
    party?: ITPParty
    msg: string
}

export const ITP_CODE_REGEX = /^[a-zA-Z0-9.\-_]*$/;
export const ITP_CODE_ILLEGAL_CHARS_REGEX = /[^a-zA-Z0-9.\-_]/g;

export const ITP_CODE_CLEANER: (input: string) => string = input => input
    .replace(ITP_CODE_ILLEGAL_CHARS_REGEX, '_')
    .replace(/__/g, '_')

export class ITPValidator {

    validateItem(itp: InspectionTestPlan, item: ITPItem, index?: number): ITPValidationMessage[] {
        const msgs: ITPValidationMessage[] = []

        if (item.item === undefined || item.item?.length > 64 || !(item.item.length >= 1)) {
            msgs.push({
                index,
                itemField: 'item',
                msg: 'Sono richiesti da 1 a 64 caratteri.'
            })
        }

        if (item.item.startsWith('.')) {
            msgs.push({
                index,
                itemField: 'item',
                msg: 'Deve iniziare con una lettera o un numero'
            })
        }

        if (!(ITP_CODE_REGEX.test(item.item))) {
            msgs.push({
                index,
                itemField: 'item',
                msg: 'Sono presenti caratteri non ammessi.'
            })
        }

        if (!item.recordsQCR) {
            msgs.push({
                index,
                itemField: 'recordsQCR',
                msg: 'Codice QCR richiesto.'
            })
        }

        if (!item.presences?.cl) {
            msgs.push({
                index,
                itemField: 'presences',
                party: ITPParty.cl,
                msg: 'Codice presenza richiesto.'
            })
        }

        if (itp.items.filter(_item => _item.item === item.item).length > 1) {
            msgs.push({
                index,
                itemField: 'item',
                msg: 'Valore duplicato'
            })
        }

        return msgs
    }

    /** The ITP is invalid if any msg is produced. */
    validate(itp: InspectionTestPlan): ITPValidationMessage[] {
        try {
            const msgs: ITPValidationMessage[] = []
            itp.items.forEach((item, index) => {
                msgs.push(...this.validateItem(itp, item, index))
            })
            return msgs
        } catch (e) {
            console.error(e)
            return [{
                msg: 'Unable to perform validation.'
            }]
        }
    }
}
