import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {
    ActionButton,
    CommandBarButton,
    DefaultButton,
    DirectionalHint,
    Icon,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    Panel,
    Spinner,
    SpinnerSize,
    Stack,
    TooltipHost,
    useTheme,
} from "@fluentui/react";
import {FusinaNotification} from "../../../fusina-notifications/entities/FusinaNotification";
import {NotificationItem} from "./NotificationItem";
import {useLocation} from "react-router-dom";
import {invalidateAllCache} from "../../../js-rmi-react/useRmiResource";
import {ServerStatusContext} from "../contexts/ServerStatusContext";
import {useFusinaRmiUrl} from "../hooks/useFusinaRmiUrl";
import ErrorBoundary from "./ErrorBoundary";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {MicroSpinner} from "./MicroSpinner";

const PAGE_INSTANCE_START_TIME = Date.now()

export const NotificationsBtn: React.FC = () => {
    const theme = useTheme()

    const status = useContext(ServerStatusContext)

    const rmi = useFusinaRmi()

    const sseUrl = useFusinaRmiUrl('Notifications', 'watch')

    const [connected, setConnected] = useState<boolean>(false)
    const [storedNotifications, setStoredNotifications] = useState<FusinaNotification[]>([])
    // TODO: move notifications event source to some context

    useEffect(() => {
        const sse = new EventSource(sseUrl, {
            withCredentials: true
        })
        let invalidationTimeout = null
        console.debug('SSE notifications connecting...', sse)
        sse.addEventListener('open', ev => {
            console.debug('SSE open', {ev})
            setStoredNotifications([])
            status.refreshStatus()
            setConnected(true)
        })
        sse.addEventListener('message', ev => {
            const notification: FusinaNotification = JSON.parse(ev.data)
            console.debug({ev, notification})
            setStoredNotifications(nn => [notification, ...nn])
            if (invalidationTimeout) {
                clearTimeout(invalidationTimeout)
            }
            invalidationTimeout = setTimeout(() => {
                if (Date.now() - PAGE_INSTANCE_START_TIME > 10_000) {
                    invalidateAllCache?.()
                }
                invalidationTimeout = null
            }, 100)
        })
        sse.addEventListener('error', ev => {
            console.error('SSE error', {ev})
            status.setDisconnected()
        })
        sse.addEventListener('close', ev => {
            console.debug('SSE close', {ev})
            status.setDisconnected()
            setConnected(false)
        })
        return () => {
            sse.close()
        }
    }, [setStoredNotifications])

    const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false)

    const location = useLocation();

    const [isAllOpen, setIsAllOpen] = useState<boolean>(false)

    const [isMarkAllAsReadPending, setIsMarkAllAsReadPending] = useState<boolean>(false)
    const [lastVisualizedNotifId, setLastVisualizedNotifId] = useState<string | undefined>(undefined)

    useEffect(() => {
        const lastNo = storedNotifications
            ?.map?.(x => x._id)
            ?.reduce?.((a, b) => a > b ? a : (b ?? a), undefined)
        if (notificationsOpen && lastNo) {
            const tio = setTimeout(() => {
                setLastVisualizedNotifId(noId => (lastNo > noId) || noId === undefined ? lastNo : noId)
            }, 2000)
            return () => {
                clearTimeout(tio)
            }
        } else {
            setLastVisualizedNotifId(undefined)
        }
    }, [storedNotifications, notificationsOpen]);

    useEffect(() => {
        setNotificationsOpen(false)
        // setIsAllOpen(false)
    }, [location]);

    return <>
        <TooltipHost
            styles={{
                root: {display: 'inline-flex'}
            }}
            content={storedNotifications?.length > 1
                ? `${storedNotifications?.length} notifiche`
                : (storedNotifications?.length === 1
                    ? '1 notifica'
                    : 'Nessuna notifica')}
            directionalHint={DirectionalHint.bottomAutoEdge}
        >
            <CommandBarButton
                className="NotificationsBtn"
                text={storedNotifications?.length >= 1 ? storedNotifications?.length + '' : undefined}
                iconProps={storedNotifications?.length > 0
                    ? {iconName: 'RingerSolid', color: '#fff'}
                    : {iconName: 'Ringer', color: theme.palette.themeLighter}}
                styles={{
                    root: {color: '#fff', height: '2.2em', margin: '-6px 0'},
                    rootHovered: {color: '#fff'},
                    icon: {color: storedNotifications?.length > 0 ? '#fff' : theme.palette.themeLighter},
                    iconHovered: {color: storedNotifications?.length > 0 ? '#DFFF00' : theme.palette.themeLighter},
                }}
                onClick={() => setNotificationsOpen(v => !v)}
                // disabled={storedNotifications?.length <= 0}
                style={{background: "transparent"}}
            />
        </TooltipHost>
        <Panel
            isOpen={notificationsOpen}
            onDismiss={() => setNotificationsOpen(false)}
            headerText="Notifiche"
            closeButtonAriaLabel="Chiudi"
            isLightDismiss={true}
            onRenderHeader={(props, DefaultRenderer) => {
                return <>
                    <DefaultRenderer {...props}/>
                    {!(connected && storedNotifications?.length <= 0) && <DefaultButton
                        text={`${isAllOpen ? 'Nascondi' : 'Vedi'} lette`}
                        onClick={() => setIsAllOpen(v => !v)}
                    />}
                    <div style={{flexGrow: 1}}>
                    </div>
                </>
            }}
            onRenderFooterContent={isAllOpen || !(storedNotifications?.length >= 1) ? undefined : (props, DefaultRender) => {
                return <>
                    <Stack>
                        <ActionButton
                            iconProps={{iconName: 'Archive'}}
                            disabled={isMarkAllAsReadPending || !lastVisualizedNotifId}
                            onClick={() => {
                                const lastId = lastVisualizedNotifId
                                setIsMarkAllAsReadPending(true)
                                rmi.Notifications.markAllAsSeen(lastId)
                                    .then(() => {
                                        setStoredNotifications(nn => nn.filter(no => no._id > lastId))
                                    })
                                    .finally(() => {
                                        setIsMarkAllAsReadPending(false)
                                    })
                            }}
                        >
                            Marca tutte come lette &nbsp;
                            {(isMarkAllAsReadPending || !lastVisualizedNotifId) && <MicroSpinner/>}
                        </ActionButton>
                    </Stack>
                    {/*<DefaultRender {...props}/>*/}
                </>
            }}
        >
            <br/>
            <ErrorBoundary>
                {isAllOpen ? <AllNotificationsList
                        onMarkedAsSeen={n => setStoredNotifications(nn => nn.filter(no => no._id !== n._id))}
                    /> :
                    <Stack tokens={{childrenGap: 'm'}}>
                        {!connected && <>
                            <br/>
                            <Spinner size={SpinnerSize.medium}/>
                        </>}
                        {storedNotifications?.map?.(n =>
                            <NotificationItem
                                key={n._id}
                                notification={n}
                                onMarkedAsSeen={n => setStoredNotifications(nn => nn.filter(no => no._id !== n._id))}
                            />)}
                        {connected && storedNotifications?.length <= 0 &&
                        <div style={{
                            color: theme.semanticColors.disabledSubtext,
                            display: "flex",
                            flexFlow: 'column',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}>
                            <span>
                                <br/>
                            </span>
                            <span style={{fontSize: '3em'}}>
                                <Icon iconName='InboxCheck'/>
                            </span>
                            <span>
                                <br/>
                                Nessuna notifica da leggere
                                <br/>
                                <br/>
                            </span>
                            <MessageBarButton
                                text='Vedi notifiche lette'
                                onClick={() => setIsAllOpen(v => !v)}
                            />
                        </div>}
                    </Stack>}
            </ErrorBoundary>
        </Panel>
    </>
}


const AllNotificationsList: React.FC<{
    onMarkedAsSeen: (n: FusinaNotification) => void
}> = props => {
    const {
        data: allNotifications,
        error,
    } = useFusinaRmiResource('Notifications', 'listInbox')

    return <>
        <MaybeErrorMessageBar error={error}/>
        <Stack tokens={{childrenGap: 'm'}}>
            {allNotifications?.map?.(n =>
                <NotificationItem
                    key={n._id}
                    notification={n}
                    onMarkedAsSeen={props.onMarkedAsSeen}
                />)}
            {allNotifications === undefined && !error && <Spinner size={SpinnerSize.medium}/>}
            {allNotifications?.length <= 0 && <MessageBar messageBarType={MessageBarType.info}>
                Nessuna notifica
            </MessageBar>}
        </Stack>
    </>
}
