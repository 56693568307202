import * as React from 'react'
import {Calendar, DayOfWeek} from "@fluentui/react";
import {getDateOnlyFromString, useDateOnlyFromString} from "../hooks/useDateOnlyFromString";
import {formatDateOnly} from "./DateOnlyPicker";
import {useCalendarStrings} from "../hooks/useCalendarStrings";

export const DateOnlyPickerExpanded: React.FC<{
    label?: string
    value: string
    disabled?: boolean
    onChange?: (newValue: string) => void
    restrictedDates?: string[]
}> = props => {
    const value = useDateOnlyFromString(props.value)
    const strings = useCalendarStrings()

    return <Calendar
        strings={strings}
        firstDayOfWeek={DayOfWeek.Monday}
        restrictedDates={props.restrictedDates?.map?.(v => getDateOnlyFromString(v))?.filter(d => !!d)}
        showGoToToday
        isMonthPickerVisible
        value={value}
        maxDate={new Date()}
        onSelectDate={date => {
            if (!props.disabled) {
                props.onChange?.(formatDateOnly(date))
            }
        }}
    />
}
