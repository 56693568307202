import * as React from "react";
import {useContext, useState} from "react";
import {CAL_VIEW_ICON_NAME, CAL_VIEW_LABELS, CalViews} from "./commons";
import {
    Calendar,
    Callout,
    DateRangeType,
    DayOfWeek,
    DefaultButton,
    DirectionalHint,
    Icon,
    IconButton,
    Stack,
    Text,
    useTheme,
} from "@fluentui/react";
import {CalContext} from "./CalContext";
import {useId} from "@fluentui/react-hooks";
import {useCalendarStrings} from "../../hooks/useCalendarStrings";
import {useScreenSize} from "../../hooks/useScreenSize";


export const MultiCalTopBar: React.FC<{
    prev: Date
    next: Date
    title: string | React.ReactNode
    dateRangeType?: DateRangeType
}> = props => {
    const theme = useTheme()
    const [cal, setCalState] = useContext(CalContext)

    const id = useId('MultiCalTopBar-')
    const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false)
    const strings = useCalendarStrings()
    const {isMobile} = useScreenSize()

    return <>
        <Stack horizontal tokens={{childrenGap: 's1'}} wrap>
            {!isMobile && <DefaultButton
                text="Oggi"
                onClick={() => setCalState(s => ({...s, selectedDate: new Date()}))}
            />}
            {!isMobile && <Stack.Item>
                <IconButton
                    styles={{icon: {fontSize: theme.fonts.small.fontSize}}}
                    iconProps={{iconName: 'ChevronLeftSmall'}}
                    onClick={() => setCalState(s => ({...s, selectedDate: props.prev}))}
                />
                <IconButton
                    styles={{icon: {fontSize: theme.fonts.small.fontSize}}}
                    iconProps={{iconName: 'ChevronRightSmall'}}
                    onClick={() => setCalState(s => ({...s, selectedDate: props.next}))}
                />
            </Stack.Item>}
            <Stack.Item align='center' id={id} onClick={() => setIsPickerOpen(v => !v)} style={{cursor: 'pointer'}}>
                <Text variant={isMobile ? 'medium' : 'large'}>
                    {props.title}
                </Text>
                &nbsp; &nbsp; <Icon iconName='ChevronDown'/>
                <Callout
                    directionalHint={isMobile ? DirectionalHint.bottomLeftEdge : DirectionalHint.bottomCenter}
                    role="dialog"
                    hidden={!isPickerOpen}
                    target={`#${id}`}
                    setInitialFocus
                    onDismiss={() => setIsPickerOpen(false)}
                >
                    <Calendar
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={strings}
                        dateRangeType={props.dateRangeType}
                        calendarDayProps={{
                            onNavigateDate: date => {
                                setCalState(s => ({...s, selectedDate: date}))
                            },
                        }}
                        calendarMonthProps={{
                            onNavigateDate: date => {
                                setCalState(s => ({...s, selectedDate: date}))
                            },
                        }}
                        value={cal.selectedDate}
                        onSelectDate={date => {
                            setCalState(s => ({...s, selectedDate: date}))
                        }}
                    />
                </Callout>
            </Stack.Item>
            <Stack.Item grow={1}>
                &nbsp;
            </Stack.Item>
            {Object.keys(CalViews).map(v => <DefaultButton
                style={{display: cal.view === CalViews[v] && isMobile ? 'none' : undefined}}
                toggle
                checked={cal.view === CalViews[v]}
                text={isMobile ? undefined : CAL_VIEW_LABELS[v]}
                iconProps={{
                    iconName: CAL_VIEW_ICON_NAME[v]
                }}
                onClick={() => setCalState(s => ({...s, view: CalViews[v]}))}
            />)}
        </Stack>
    </>
}
