import {MessageBar, MessageBarType} from "@fluentui/react";
import * as React from "react";

export const MaybeErrorMessageBar: React.FC<{
    error?: Error | undefined
    onDismiss?: () => void
}> = props => {

    if (props.error === undefined) {
        return null
    }

    if (!props.error.message) {
        console.warn('No message for displayed error', props.error)
    }

    return <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={false}
        onDismiss={props.onDismiss}
        truncated
        styles={{
            text: {
                userSelect: 'text'
            }
        }}
    >
        {props.error?.message?.length > 0 ? props.error.message : 'Error'}
    </MessageBar>
}
