import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {PageSetting} from "../contexts/PageContext";
import * as React from "react";
import {useRef, useState} from "react";
import {DefaultButton, Dropdown, ProgressIndicator, Spinner, SpinnerSize, Toggle} from "@fluentui/react";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import {useLocallyStoredState} from "../hooks/useLocallyStoredState";
import {ITPStatsParallelPlot, ParallelPlotVariant} from "../components/ITPStatsParallelPlot";
import {ITPStatsTable, ITPStatsTableImperativeHandle} from "../components/ITPStatsTable";
import {useSecurityContext} from "../contexts/SecurityContext";
import {RoleEnum} from "../../../fusina-authz/entities/RoleEnum";


const LS_SHOW_DETAILS = 'ITPStatsPage-showDetails';
const LS_USE_MATRIX_THEME = 'ITPStatsPage-dark';
const LS_SHOW_PLOTS = 'ITPStatsPage-plots';
const LS_PLOT_VARIANT = 'ITPStatsPage-plotVariant';

const FORCE_PLOT_VARIANT: ParallelPlotVariant | undefined = undefined //'events certs'

export const ITPStatsPage: React.FC = () => {
    const {
        data: itpsStatsStruct,
        error: itpsStatsError,
    } = useFusinaRmiResource('ITP', 'stats', {by: 'all'})

    const {
        data: itpEventsStatsStruct,
        error: itpEventsStatsError,
    } = useFusinaRmiResource('ITPEvents', 'stats', {by: 'all'})

    const {
        data: itpCertsStatsStruct,
        error: itpCertsStatsError,
    } = useFusinaRmiResource('ITPCertificates', 'stats')

    const isLoading = ((itpsStatsStruct ?? itpsStatsError) === undefined)
        || ((itpEventsStatsStruct ?? itpEventsStatsError) === undefined)
        || ((itpCertsStatsStruct ?? itpCertsStatsError) === undefined);

    const [showDetails, setShowDetails] = useLocallyStoredState<boolean>(LS_SHOW_DETAILS, false)

    const securityContext = useSecurityContext()
    const canAccessTheMatrix = securityContext?.user?.globalRoles?.includes?.(RoleEnum.Admin)
    const [isMatrix_, setIsMatrix] = useLocallyStoredState<boolean>(LS_USE_MATRIX_THEME, false)
    const isMatrix = isMatrix_ && canAccessTheMatrix;

    const [isPlots, setIsPlots] = useLocallyStoredState<boolean>(LS_SHOW_PLOTS, false)

    const [plotVariant, setPlotVariant] = useLocallyStoredState<ParallelPlotVariant>(LS_PLOT_VARIANT, 'events certs')

    const subpage = showDetails && isMatrix && isPlots ? 'plots' : 'table'

    // NOTE: I'm using useImperativeHandle to minimize the amount of code changes needed to introduce exportITPStats
    const tableRef = useRef<ITPStatsTableImperativeHandle>()
    const [isExporting, setIsExporting] = useState<boolean>(false)

    return <>
        <PageSetting
            iconName="Trackers"
            title={<>
                <div style={{paddingRight: 15}}>Statistiche PCQ</div>
                {isLoading && <Spinner size={SpinnerSize.medium}/>}
                <div style={{flexGrow: 1}}>&nbsp;</div>
                {tableRef?.current !== undefined && <div style={{lineHeight: '1em', paddingRight: '1em'}}>
                    <DefaultButton
                        disabled={isExporting || itpsStatsStruct === undefined || itpEventsStatsStruct === undefined || itpCertsStatsStruct === undefined}
                        iconProps={{iconName: 'ExcelDocument'}}
                        text="Esporta"
                        onClick={async () => {
                            setIsExporting(true)
                            try {
                                await tableRef?.current?.doExport?.()
                            } catch (e) {
                                console.trace(e)
                                alert('Errore durante l\'esportazione in formato Excel.')
                            } finally {
                                setIsExporting(false)
                                // keep the button disabled for a moment
                            }
                            await new Promise(resolve => setTimeout(resolve, 2000))
                        }}
                    />
                </div>}
                {showDetails && canAccessTheMatrix && <>
                    {subpage === 'plots' && FORCE_PLOT_VARIANT === undefined &&
                    <div style={{lineHeight: '1em', paddingRight: '1em'}}>
                        <Dropdown
                            selectedKey={plotVariant}
                            options={[
                                {key: 'events', text: 'Notifiche'},
                                {key: 'certs', text: 'Certificati'},
                                {key: 'events certs', text: 'Notif. e Cert.'},
                            ]}
                            onChange={((event, opt) => setPlotVariant(opt.key as ParallelPlotVariant))}
                        />
                    </div>}
                    {isMatrix && <div style={{lineHeight: '1em', paddingRight: '1em'}}>
                        <Toggle onText="Plots" offText="Table" checked={isPlots}
                                onChange={((event, checked) => setIsPlots(checked))}/>
                    </div>}
                    <div style={{lineHeight: '1em', opacity: isMatrix ? undefined : 0.5}}>
                        <Toggle onText="Matrix" offText="Chiaro" checked={isMatrix}
                                onChange={((event, checked) => setIsMatrix(checked))}/>
                    </div>
                </>}
            </>}
            sideChildren={null}
            pageTheme={isMatrix && showDetails ? "matrix" : null}
        />
        <MaybeErrorMessageBar error={itpsStatsError}/>
        <MaybeErrorMessageBar error={itpEventsStatsError}/>
        <MaybeErrorMessageBar error={itpCertsStatsError}/>

        {itpsStatsStruct === undefined && itpsStatsError === undefined &&
        <ProgressIndicator
            styles={{itemProgress: {padding: 0}, itemDescription: {padding: 24}}}
            description="Caricamento in corso..."/>}

        {itpsStatsStruct !== undefined && subpage === 'table' && <ITPStatsTable
            itpsStatsStruct={itpsStatsStruct}
            itpEventsStatsStruct={itpEventsStatsStruct}
            itpCertsStatsStruct={itpCertsStatsStruct}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
            innerRef={tableRef}
        />}

        {itpsStatsStruct !== undefined && subpage === 'plots' && <ITPStatsParallelPlot
            itpsStatsStruct={itpsStatsStruct}
            itpEventsStatsStruct={itpEventsStatsStruct}
            itpCertsStatsStruct={itpCertsStatsStruct}
            plotVariant={FORCE_PLOT_VARIANT ?? plotVariant}
        />}
    </>
}
