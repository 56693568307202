import * as React from 'react'
import {useMemo, useState} from 'react'
import {
    CommandBar,
    DefaultButton,
    DetailsList,
    PrimaryButton,
    Selection,
    SelectionMode,
    useTheme
} from '@fluentui/react'
import {SimpleDialog} from "../components/SimpleDialog";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useForceUpdate} from '@fluentui/react-hooks';
import {Job} from "../../../fusina-jobs/entities/Job";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {IColumn} from "@fluentui/react/src/components/DetailsList/DetailsList.types";
import {JobEditor} from "../components/JobEditor";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import useThrottledCallback from "../hooks/useThrottledCallback";
import {useDefaultCompany} from "../hooks/useDefaultCompany";
import {PageSetting} from "../contexts/PageContext";
import {CmdSearchBox} from "../components/CmdSearchBox";
import {useJobContext} from "../contexts/JobContext";
import {CancelEditsBtn} from "../components/CancelEditsBtn";
import {JobGrantsView} from "../components/JobGrantsView";


const jobsColumns: IColumn[] = [
    {
        fieldName: "code",
        key: "code",
        name: "Rif#",
        minWidth: 60,
        maxWidth: 100,
        targetWidthProportion: 0.1,
    },
    {
        fieldName: "title",
        key: "title",
        name: "Titolo",
        minWidth: 200,
        maxWidth: 900,
        targetWidthProportion: 0.5,
        flexGrow: 2,
    },
]


export const AdminJobs: React.FC<{}> = () => {
    const defaultCompany = useDefaultCompany()
    const {refreshJobs} = useJobContext()
    const theme = useTheme()
    const rmi = useFusinaRmi()

    const [search, setSearch] = useState<string | null>(null)

    const {
        throttledCallback: searchHandler
    } = useThrottledCallback<(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void>(
        (event, newValue) => {
            if (newValue) {
                setSearch(newValue)
            } else {
                setSearch(null)
            }
        }, 200, [])

    const {
        data: storedJobs_,
        error: storedJobsError,
        refresh: storedJobsRefresh,
    } = useFusinaRmiResource('JobsAdmin', 'listJobs'); //, null, search)

    const storedJobs = useMemo(() => {
        if (!search) {
            return storedJobs_
        }
        const searchLow = search.toLowerCase()
        return storedJobs_.filter(j => JSON.stringify(j).toLowerCase().includes(searchLow))
    }, [storedJobs_, search])

    const forceUpdate = useForceUpdate();

    const selection = useMemo<Selection<{ key: string, ID: string }>>(() =>
        new Selection({onSelectionChanged: forceUpdate}), [])

    const [editingJob, setEditingJob] = useState<Job | undefined>(undefined)
    const [isViewingGrants, setIsViewingGrants] = useState<boolean>(false)

    return <>
        <PageSetting title="Admin Commesse"/>

        <CommandBar
            style={{borderBottom: "1px solid", borderBottomColor: theme.palette.neutralLight}}
            items={[
                {
                    key: 'new',
                    text: 'Nuova',
                    iconProps: {
                        iconName: 'AddWork'
                    },
                    onClick() {
                        setEditingJob({
                            buyer: defaultCompany?._id,
                            code: "",
                            contract: "",
                            contractItems: [],
                            contractor: defaultCompany?._id,
                            title: "",
                        })
                    }
                },
            ]}
            farItems={[
                {
                    key: "search",
                    onRender: () => <CmdSearchBox onChange={searchHandler}/>
                }
            ]}/>

        <DetailsList
            selection={selection}
            columns={jobsColumns}
            items={storedJobs ?? []}
            selectionMode={SelectionMode.single}
            onItemInvoked={(item) =>
                setEditingJob(
                    JSON.parse(JSON.stringify( // deep clone
                        storedJobs.find(job => job._id === item._id))))}/>

        <MaybeErrorMessageBar error={storedJobsError}/>

        {editingJob && <SimpleDialog
            subText={""}
            title={editingJob._id ? 'Modifica commessa' : 'Nuova commessa'}
            hidden={false}
            buttons={[
                <DefaultButton
                    text="Vedi utenti e ruoli"
                    onClick={() => setIsViewingGrants(true)}
                />,
                <CancelEditsBtn editable={editingJob} onClick={() => setEditingJob(undefined)}/>,
                <PrimaryButton key="save" onClick={() => {
                    (editingJob._id
                        ? rmi.JobsAdmin.updateJob(editingJob._id, editingJob)
                        : rmi.JobsAdmin.createJob(editingJob))
                        .then(() => {
                            storedJobsRefresh().then()
                            refreshJobs().then()
                            setEditingJob(undefined)
                        })
                        .catch(e => {
                            console.error('Error while trying to save job', e)
                        })
                        .finally(() => forceUpdate());

                }}>Salva</PrimaryButton>
            ]}>
            <JobEditor job={editingJob}/>
        </SimpleDialog>}
        {isViewingGrants && editingJob && <SimpleDialog
            title={`Utenti e ruoli nella commessa ${editingJob?.code}`}
            subText={''}
            hidden={false}
            onDismiss={() => setIsViewingGrants(false)}
            buttons={[
                <DefaultButton text="Chiudi" onClick={() => setIsViewingGrants(false)}/>
            ]}
        >
            <JobGrantsView job={editingJob}/>
        </SimpleDialog>}
    </>
}
