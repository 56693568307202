import * as React from 'react'
import {useCallback, useMemo, useState} from 'react'
import {SimpleDialog} from "./SimpleDialog";
import {
    DefaultButton,
    Icon,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    ProgressIndicator,
    Text,
    useTheme
} from "@fluentui/react";
import {ITPSelectionFlow} from "./ITPSelectionFlow";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {Stack} from "@fluentui/react/lib/Stack";
import {ConfirmedCalloutBtn} from "./ConfirmedCalloutBtn";
import {User} from "../../../fusina-users/entities/User";
import {UserSelector} from "./UserSelector";
import './ITPStatsTable.css'
import {RoleEnum} from "../../../fusina-authz/entities/RoleEnum";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {Job} from "../../../fusina-jobs/entities/Job";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {MicroSpinner} from "./MicroSpinner";

export const UserItpOwnershipTransferDialog: React.FC<{
    userForDisplay: User
    userId: string
    onDismiss: () => void
}> = props => {

    const rmi = useFusinaRmi()

    const itpFilter = useCallback((itp: InspectionTestPlan) => itp.owner?._id === props.userId, [props.userId])

    const [selectedItps, setSelectedItps] = useState<InspectionTestPlan[]>(() => [])

    const [itpSelDone, setItpSelDone] = useState<boolean>(false)

    const selectedItpIds = useMemo(() => selectedItps.map(itp => itp._id), [selectedItps])

    const [newOwnerUser, setNewOwnerUser] = useState<User | undefined>(undefined)

    const jobFilter = useCallback((job: Job) =>
        props.userForDisplay?.grants?.some?.(grant => (
            (grant.scopeType === 'job' && grant.scopeId === job._id) ||
            (grant.scopeType === 'company' && grant.scopeId === job.contractor)
        )) ?? false, [props.userForDisplay])

    const {
        data: jobsData_NO_FILTER, error: jobsError
    } = useFusinaRmiResource('JobsList', 'findGrantedJobs')

    const jobsData = useMemo(() => jobsData_NO_FILTER?.filter?.(jobFilter), [jobsData_NO_FILTER, jobFilter])

    const userFilter = useCallback((user: User) => {
        if (user._id === props.userId) {
            return false  // do not transfer to itself.
        }
        const userApCqGrants = user.grants.filter(grant => grant.role === RoleEnum.AppaltatoreCQ)
        if (userApCqGrants.length <= 0) {
            return false
        }
        return selectedItps.some(itp => {
            const job = jobsData?.find?.(job => job._id === itp.job?._id)
            return userApCqGrants.some(grant =>
                (grant.scopeType === 'job' && itp.job._id === grant.scopeId) ||
                (grant.scopeType === 'company' && job.contractor === grant.scopeId)
            )
        })
    }, [selectedItps, jobsData])

    const [opStatus, setOpStatus] = useState<Record<string, {
        status: 'queued' | 'pending' | 'ok' | 'error',
        error?: Error
    }>>(() => ({}))
    const [isOpStarted, setIsOpStarted] = useState<boolean>(false)
    const [isOpDone, setIsOpDone] = useState<boolean>(false)

    const theme = useTheme()

    if (jobsError) {
        return <MaybeErrorMessageBar error={jobsError}/>
    }
    return <>
        <SimpleDialog
            title="Trasferimento gestione PCQ"
            subText="Questa funzionalità permette di trasferire di proprietà i PCQ che sono in gestione esclusiva di un utente."
            hidden={false}
        >
            {!itpSelDone && <>
                <Text style={{color: theme.semanticColors.bodySubtext}}>
                    L'utente {props.userForDisplay?.email} ha gestione esclusiva sui PCQ selezionabili di seguito.
                    <br/>
                    <br/>
                </Text>

                <ITPSelectionFlow
                    multiSelect
                    jobFilter={jobFilter}
                    itpFilter={itpFilter}
                    selectedIds={selectedItpIds}
                    autoSelectJob
                    onItpDeSelected={itp => {
                        setSelectedItps(itps => itps.filter(it => it._id !== itp._id))
                    }}
                    onItpSelected={itp => {
                        setSelectedItps(itps => [...itps.filter(it => it._id !== itp._id), itp])
                    }}
                />
            </>}
            {itpSelDone && !newOwnerUser && <>
                <UserSelector
                    userFilter={userFilter}
                    onUserSelected={user => setNewOwnerUser(user)}
                />
            </>}
            {itpSelDone && newOwnerUser && <>
                <Stack horizontal horizontalAlign="space-between">
                    <div>
                        <div><b>PCQ selezionati:</b></div>
                        <table className="ITPStatsTable noHides" style={{borderTop: '1px solid #7772'}}>
                            <tbody>
                            {selectedItps.map(itp => <tr
                                key={itp._id}>
                                <td>{itp.job?.code}&nbsp;</td>
                                <td>{itp.docN}&nbsp;</td>
                                <td>
                                    {isOpStarted && opStatus[itp._id] && <>
                                        {opStatus[itp._id].status === 'queued' &&
                                            <Text style={{color: theme.semanticColors.disabledBodySubtext}}>In
                                                coda...</Text>}
                                        {opStatus[itp._id].status === 'pending' && <MicroSpinner/>}
                                        {opStatus[itp._id].status === 'ok' && <Icon
                                            iconName="CompletedSolid"
                                            styles={{
                                                root: {
                                                    color: theme.semanticColors.successIcon,
                                                    verticalAlign: 'top',
                                                }
                                            }}
                                        />}
                                        <MaybeErrorMessageBar error={opStatus[itp._id].error}/>
                                    </>}
                                </td>
                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div style={{alignSelf: "center"}}>
                        &rang;
                    </div>
                    <div>
                        <div><b>Appaltatore precedente:</b></div>
                        {props.userForDisplay.email}<br/>
                        <br/>
                        <div><b>Nuovo appaltatore:</b></div>
                        {newOwnerUser.email}<br/>
                        {newOwnerUser.fullName}<br/>
                        {newOwnerUser.telephone}<br/>
                    </div>
                </Stack>
            </>}

            <br/>
            <br/>
            {!isOpStarted && <>
                {!itpSelDone &&
                    <Stack horizontal horizontalAlign="end" verticalAlign="center" tokens={{childrenGap: 's1'}}>
                        <Text style={{color: theme.semanticColors.disabledBodySubtext}}>
                            {selectedItps?.length} PCQ selezionati &nbsp; &nbsp;
                        </Text>
                        <DefaultButton
                            onClick={props.onDismiss}
                        >
                            Annulla
                        </DefaultButton>
                        <PrimaryButton
                            disabled={selectedItps?.length <= 0}
                            onClick={() => setItpSelDone(true)}
                        >
                            Prosegui
                        </PrimaryButton>
                    </Stack>}
                {itpSelDone && <Stack horizontal horizontalAlign="space-between" tokens={{childrenGap: 's1'}}>
                    <DefaultButton
                        onClick={() => {
                            if (newOwnerUser) {
                                setNewOwnerUser(undefined)
                            } else {
                                setItpSelDone(false)
                            }
                        }}
                    >
                        Indietro
                    </DefaultButton>
                    <div>
                        <ConfirmedCalloutBtn
                            btnProps={{text: 'Trasferisci', primary: true, disabled: !newOwnerUser || isOpStarted}}
                            action={async () => {
                                selectedItps.forEach(itp => {
                                    setOpStatus(s => ({...s, [itp._id]: {...s[itp._id], status: 'queued'}}))
                                })
                                setIsOpStarted(true)
                                for (let i = 0; i < selectedItps.length; ++i) {
                                    const itp = selectedItps[i]
                                    setOpStatus(s => ({...s, [itp._id]: {...s[itp._id], status: 'pending'}}))
                                    try {
                                        await rmi.ITPRevision.changeItpOwner(itp._id, props.userId, newOwnerUser._id)
                                        setOpStatus(s => ({...s, [itp._id]: {...s[itp._id], status: 'ok'}}))
                                    } catch (error) {
                                        console.trace(error)
                                        setOpStatus(s => ({...s, [itp._id]: {...s[itp._id], status: 'error', error}}))
                                    }
                                }
                                setIsOpDone(true)
                            }}
                            calloutChildren={<>
                                Stai per trasferire i PCQ selezionati ad un nuovo proprietario.
                            </>}
                        />
                    </div>
                </Stack>}
            </>}
            {isOpStarted && <>
                {!isOpDone && <ProgressIndicator/>}

                {isOpDone && <Stack horizontal horizontalAlign="end" tokens={{childrenGap: 's1'}}>
                    <MessageBar messageBarType={MessageBarType.success}>
                        Trasferimento completato con successo.
                    </MessageBar>
                    <DefaultButton onClick={props.onDismiss}>
                        Chiudi
                    </DefaultButton>
                </Stack>}
            </>}
        </SimpleDialog>
    </>
}
