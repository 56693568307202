import {useCallback, useEffect, useState} from "react";

const IS_MOBILE_FUNC = () => window.innerWidth < 768

export const useScreenSize = () => {
    const [isMobile, setIsMobile] = useState<boolean>(IS_MOBILE_FUNC);

    const handleResize = useCallback(() => {
        setIsMobile(IS_MOBILE_FUNC);
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return {
        isMobile,
    };
}
