import * as React from 'react'
import {useCallback, useMemo, useState} from 'react'
import {CommandBar, DetailsList, PrimaryButton, Selection, SelectionMode, useTheme} from '@fluentui/react'
import {SimpleDialog} from "../components/SimpleDialog";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useForceUpdate} from '@fluentui/react-hooks';
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {IColumn} from "@fluentui/react/src/components/DetailsList/DetailsList.types";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import useThrottledCallback from "../hooks/useThrottledCallback";
import {User} from "../../../fusina-users/entities/User";
import {UserEditor} from "../components/UserEditor";
import {PageSetting} from "../contexts/PageContext";
import {CmdSearchBox} from "../components/CmdSearchBox";
import {CancelEditsBtn} from "../components/CancelEditsBtn";

const companiesColumns: IColumn[] = [
    {
        fieldName: "email",
        key: "email",
        name: "Email",
        targetWidthProportion: 1,
        minWidth: 300,
        flexGrow: 1,
    },
]


export const AdminUsers: React.FC<{}> = () => {
    const theme = useTheme()
    const rmi = useFusinaRmi()

    const [search, setSearch] = useState<string | null>(null)

    const {
        throttledCallback: searchHandler
    } = useThrottledCallback<(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void>(
        (event, newValue) => {
            setSearch(newValue)
        }, 200, [])

    const {
        data: storedUsers,
        error: storedUsersError,
        refresh: storedUsersRefresh,
    } = useFusinaRmiResource('UsersAdmin', 'listUsers'); //, null, search)

    const filteredStoredUsers = useMemo(() => {
        if (!search) {
            return storedUsers
        }
        const searchLow = search.toLowerCase()
        return storedUsers.filter(com => JSON.stringify([
            // NOTE: do not consider ALL the fields, because the base64 logo url is likely to contain any 3-char search string
            com.email,
        ]).toLowerCase().includes(searchLow))
    }, [storedUsers, search])

    const forceUpdate = useForceUpdate();

    const selection = useMemo<Selection<{ key: string, ID: string }>>(() =>
        new Selection({onSelectionChanged: forceUpdate}), [])

    const [editingUser, setEditingUser] = useState<User | undefined>(undefined)
    const [editingError, setEditingError] = useState(undefined)

    const handleEdit = useCallback((id: string) => {
        setEditingUser(
            JSON.parse(JSON.stringify( // deep clone
                filteredStoredUsers.find(u => u._id === id))))
    }, [filteredStoredUsers])

    return <>
        <PageSetting title="Admin Utenti"/>

        <CommandBar
            style={{borderBottom: "1px solid", borderBottomColor: theme.palette.neutralLight}}
            items={[
                {
                    key: 'new',
                    text: 'Nuovo',
                    iconProps: {
                        iconName: 'AddFriend'
                    },
                    onClick() {
                        setEditingUser({
                            email: ""
                        })
                    }
                },
            ]}
            farItems={[
                {
                    key: "search",
                    onRender: () => <CmdSearchBox onChange={searchHandler}/>
                }
            ]}/>

        <DetailsList
            //onRenderDetailsHeader={() => null}
            selection={selection}
            columns={companiesColumns}
            items={filteredStoredUsers ?? []}
            selectionMode={SelectionMode.single}
            onItemInvoked={(item) => handleEdit(item._id)}
        />

        <MaybeErrorMessageBar error={storedUsersError}/>

        {editingUser && <SimpleDialog
            subText={""}
            title={editingUser._id ? 'Modifica utente' : 'Nuovo utente'}
            hidden={false}
            buttons={[
                <CancelEditsBtn editable={editingUser} onClick={() => {
                    setEditingUser(undefined)
                    setEditingError(undefined)
                }}/>,
                <PrimaryButton key="save" onClick={() => {
                    setEditingError(undefined)
                    const op = editingUser._id
                        ? rmi.UsersAdmin.updateUser(editingUser)
                        : rmi.UsersAdmin.createUser(editingUser)
                    op
                        .then(() => {
                            storedUsersRefresh()
                            setEditingUser(undefined)
                        })
                        .catch(e => {
                            console.error('Error while trying to save user', e)
                            setEditingError(e)
                        })
                        .finally(() => forceUpdate());

                }}>Salva</PrimaryButton>
            ]}>
            <MaybeErrorMessageBar error={editingError}/>
            <UserEditor user={editingUser}/>
        </SimpleDialog>}
    </>
}
