import type {InspectionTestPlan} from "./InspectionTestPlan";
import type {EventBase} from "../../fusina-event/entities/EventBase";
import type {ITPItem} from "./ITPItem";
import type {Job} from "../../fusina-jobs/entities/Job";

/*
 How do I structure the DB collections?
 Options:
    - no collection of events nor messages but all embedded in ITP plan, with messages
        - bad: ~5 messages per event, times ~10~100 invites per test, times ~50 tests per plan
            --> 5*100*50=25000 -->  less than 640 bytes/message hard limit
    - a collection of events with embedded messages
        - maybe good: up to ~1000 messages, 16kB each
    - a collection of messages with no events collection
        - like event-sourcing, but not our preferred way
    - a collection of messages and a collection of events
        - redundant, given that messages CAN be embedded in events and we already have notifications
    - a collection of events and reuse collection of notifications
        - yes! like the above maybe good, with denormalized messages in both ev embedding and notifications.
*/

// TODO: What if some party like general contractor is on Hold while the client is in R? The GE may not be notified...

/**
 * ITP Event (or invite)
 *
 * Domain object for UC205
 *
 * NOTE: domain policy: invites are sent from contractor to CDL-Q, who then manages to externally invite other parties.
 *       (ignore the standard: https://datatracker.ietf.org/doc/html/rfc5546#section-3.2.2.5 )
 */
export interface ITPEvent extends EventBase {
    /** Internal ObjectID */
    _id?: string

    /** Number that is assigned from itp.lastEventN when storing in the system */
    itpEventN?: number

    /** Internal reference to some InspectionTestPlan */
    itp: Pick<InspectionTestPlan, '_id' | 'docN' | 'job' | 'revId'>

    /** Internal reference to an item of the itp */
    item: ITPItem['item']

    /** Copy (de-normalization) of ITP item at the time of event creation
     *
     *  NOTE: this field has been later defined and may be undefined for old events.
     *
     *  NOTE: This is the best preferred source of data for the item.
     *        If not available, then revId may also not be available.
     *           In that case, the revision could be determined by timestamps.
     */
    itpItemCopy?: ITPItem
}

export const formatFullNotificationNumber: (event: ITPEvent, job: Pick<Job, 'code'>) => (string | undefined) = event => {
    return event.itpEventN?.toString?.()
}
