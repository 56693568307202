import * as React from 'react'
import {useMemo} from 'react'
import LettersPolicy from "../../../fusina-letters/controls/LettersPolicy";
import {DefaultButton} from "@fluentui/react";
import {useSecurityContext} from "../contexts/SecurityContext";
import {IContextualMenuItem} from "@fluentui/react/src/components/ContextualMenu/ContextualMenu.types";
import {Job} from "../../../fusina-jobs/entities/Job";
import {Letter} from "../../../fusina-letters/entities/Letter";

/** UC009 */
export const LetterReplyBtn: React.FC<{
    letter?: Letter
    job: Job
    newLetterItems?: IContextualMenuItem[]
}> = props => {

    const sc = useSecurityContext()

    const policy = useMemo(() => props.job ? LettersPolicy({
        job: props.job,
        securityContext: sc,
        oldLetter: props.letter,
    }) : undefined, [sc, props.job, props.letter])

    if (!policy.permissions.reply || props.newLetterItems?.length <= 0) {
        return null
    }

    return <DefaultButton
        iconProps={{
            iconName: 'Reply'
        }}
        text="Rispondi..."
        menuProps={{
            items: props.newLetterItems,
        }}
        menuIconProps={{
            style: {
                display: "none"
            }
        }}
    />
}
