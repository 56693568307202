import type {EventMessage} from "../../fusina-event/entities/EventMessage";
import {ITPPolicy, ITPPolicyContext} from "./ITPPolicy";


export interface ITPEventPolicyContext extends ITPPolicyContext {
    msg?: EventMessage,
}


/** Policy that limits the operations on an ITP event message */
export function ITPEventPolicy(ctx: ITPEventPolicyContext) {
    const itpPolicy = ITPPolicy(ctx)

    const {
        myRoles,

        isClient_CQ,
        isContractor_CQ,

        isSignedByClient,

        isOwnerOrNoOwner,

    } = itpPolicy

    const isITPSignedByClient = isSignedByClient

    const isReqOrPub = ctx.msg?.method === 'REQUEST' || ctx.msg?.method === 'PUBLISH'
    const isReply = ctx.msg?.method === 'REPLY'

    return {
        myRoles,

        isClient_CQ,
        isContractor_CQ,

        isITPSignedByClient,
        isOwnerOrNoOwner,

        isReqOrPub,
        isReply,

        // TODO: see canConfirm in ITPEventMessageForm

        permissions: {
            send: isITPSignedByClient && (
                (isReqOrPub && isContractor_CQ && isOwnerOrNoOwner) ||
                (isReply && isClient_CQ) ||
                false // close off the OR chain
            ) && itpPolicy.permissions.read,

            read: itpPolicy.permissions.read,
        }
    }
}
