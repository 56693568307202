import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {ITPEvent} from "../../../fusina-itp/entities/ITPEvent";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {ITPItem} from "../../../fusina-itp/entities/ITPItem";
import {useJobContext} from "../contexts/JobContext";
import {ITPFilterCriteria} from "../../../fusina-itp/entities/ITPFilterCriteria";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {useSecurityContext} from "../contexts/SecurityContext";
import {ITPEventPolicy} from "../../../fusina-itp/controls/ITPEventPolicy";
import {ITPEventFactory} from "../../../fusina-itp/controls/ITPEventFactory";
import {
    ActionButton,
    Icon,
    Link,
    mergeStyles,
    MessageBar,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    useTheme
} from "@fluentui/react";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {ITPItemContext} from "../contexts/ITPItemContext";
import {SimpleDialog} from "./SimpleDialog";
import {ITPEventsCreation} from "./ITPEventsCreation";
import {getITPPartyAbbr, ITPParty} from "../../../fusina-itp/entities/ITPParty";
import './ITPStatsTable.css'
import {Link as RouterLink} from "react-router-dom";

export const ITPEventCreationFromJob: React.FC<{
    initialDtsLocal?: Date
    onDismiss: () => void
    onSent: (event: ITPEvent) => void
}> = props => {
    const [itp, setItp] = useState<InspectionTestPlan | undefined>(undefined)
    const [item, setItem] = useState<ITPItem | undefined>(undefined)
    const [selected, setSelected] = useState<boolean>(false)
    const {job} = useJobContext()
    const filter = useMemo<ITPFilterCriteria | undefined>(() => job ? {
        by: 'job',
        job: {_id: job._id}
    } : undefined, [job])

    const {
        data, error
    } = useFusinaRmiResource('ITP', 'find', filter)
    const securityContext = useSecurityContext()

    useEffect(() => {
        if (data?.length === 1) {
            // Special case: only one obvious ITP
            setItp(data[0])
        } else {
            setItp(undefined)
        }
    }, [data])

    const items = useMemo<ITPItem[]>(() =>
        (data && job && securityContext && itp) ? itp.items.filter(item => {
            try {
                if (ITPEventPolicy({
                    itp,
                    job,
                    securityContext,
                    msg: new ITPEventFactory(securityContext, itp, item).new_event({}).messages[0]
                }).permissions.send === true) {
                    return true
                }
            } catch (e) {
                console.trace(e)
                // pass
            }
            return false
        }) : undefined, [data, job, securityContext, itp])

    const theme = useTheme()
    const minorParTdStyle = mergeStyles({
        fontSize: theme.fonts.small.fontSize,
        color: theme.semanticColors.bodySubtext,
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        maxHeight: 75,
        textOverflow: 'ellipsis',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
        paddingLeft: '8px !important',
        textAlign: 'left !important',
    })
    const majorTdStyle = mergeStyles({
        overflow: 'hidden',
        maxHeight: 75,
        textOverflow: 'ellipsis',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
        paddingLeft: '8px !important',
        paddingRight: '12px !important',
    })

    const header = useMemo(() =>
        <Stack
            horizontal
            wrap
            tokens={{childrenGap: 's1'}}
            verticalAlign="center"
            styles={{
                root: {
                    marginBottom: '1em',
                },
                inner: {
                    columnGap: '0.4em',
                }
            }}
        >
            <ActionButton
                text={`Commessa ${job?.code}`}
                iconProps={itp ? {
                    iconName: 'Back'
                } : undefined}
                disabled={!itp}
                onClick={() => {
                    setItp(undefined)
                }}
            />
            <Text style={{color: theme.semanticColors.disabledBodySubtext}}>
                &rsaquo;
            </Text>
            {itp && <>
                <Stack.Item>
                    <Link to={`/jobs/${job?._id}/itp/${itp?._id}`} as={RouterLink} target="_blank">
                        {itp.docN} {/*itpStruct.revN*/}
                        &nbsp;<Icon iconName="OpenInNewWindow"/>
                    </Link>
                </Stack.Item>
                <Text style={{color: theme.semanticColors.disabledBodySubtext}}>
                    &rsaquo;
                </Text>
                <Text style={{color: theme.semanticColors.disabledBodySubtext}}>
                    Selezionare una fase
                </Text>
            </> || <Text style={{color: theme.semanticColors.disabledBodySubtext}}>
                Selezionare un PCQ
            </Text>}
        </Stack>, [job, itp])

    if (error) {
        return <MaybeErrorMessageBar error={error}/>
    }

    if (job === undefined || data === undefined) {
        return <Spinner size={SpinnerSize.large}/>
    }

    // return <ITPTable job={job}/>

    if (itp && item && selected) {
        {/* NOTE: ITPItemContext: this shall be the latest version, for event creation */
        }
        return <ITPItemContext.Provider value={{itp, item}}>
            <SimpleDialog
                title="Nuova notifica espletamento fasi PCQ"
                subText=""
            >
                <ITPEventsCreation
                    initialDtsLocal={props.initialDtsLocal}
                    onDismiss={() => setSelected(false)}
                    onSent={event => props.onSent(event)}
                />
            </SimpleDialog>
        </ITPItemContext.Provider>
    }

    if (data?.length < 1) {
        return <MessageBar>
            Non ci sono PCQ
        </MessageBar>
    }

    if (!itp) {
        return <>
            {header}
            <table className="ITPStatsTable noHides selectRow" style={{borderTop: '1px solid #7772'}}>
                <thead>
                <tr>
                    <td className={majorTdStyle}>PCQ doc. N</td>
                    <td className={majorTdStyle}>Rev.</td>
                    <td className={minorParTdStyle}>Titolo</td>
                </tr>
                </thead>
                <tbody>
                {data.map(itp => <tr
                    key={itp._id}
                    onClick={() => {
                        setItp(itp)
                    }}
                >
                    <td className={majorTdStyle}>{itp.docN}</td>
                    <td className={majorTdStyle}>{itp.revN}</td>
                    <td className={minorParTdStyle}>{itp.title}</td>
                </tr>)}
                </tbody>
            </table>
        </>
    }

    if (items?.length < 1) {
        return <>
            {header}
            <MessageBar>
                Non ci sono fasi PCQ notificabili.
            </MessageBar>
        </>
    }

    return <>
        {header}
        <table className="ITPStatsTable noHides selectRow" style={{width: '90vw', borderTop: '1px solid #7772'}}>
            <thead>
            <tr>
                <td>Fase</td>
                <td>Componente</td>
                <td>Descrizione</td>
                <td>Documenti</td>
                <td>QCR</td>
                {Object.keys(ITPParty).map(p => <td>{getITPPartyAbbr(ITPParty[p])}</td>)}
                <td>Note</td>
            </tr>
            </thead>
            {items.map(item => <tbody>
            <tr
                key={item.item}
                onClick={() => {
                    setItem(item)
                    setSelected(true)
                }}
            >
                <td>{item.item}</td>
                <td>{item.comp}</td>
                <td>
                    <div className={minorParTdStyle}>
                        {item.desc}
                    </div>
                </td>
                <td>
                    <div className={minorParTdStyle}>
                        {item.refDocs}
                    </div>
                </td>
                <td>{item.recordsQCR}</td>
                {Object.keys(ITPParty).map(p =>
                    <td>{item.presences[p]}</td>)}
                <td>
                    <div className={minorParTdStyle}>
                        {item.notes}
                    </div>
                </td>
            </tr>
            </tbody>)}
        </table>
    </>
}
