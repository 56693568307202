import {useMemo} from "react";

export function useDateOnlyFromString(value: string): Date {
    return useMemo<Date>(() => getDateOnlyFromString(value), [value])
}

export const getDateOnlyFromString: (value: string) => Date = value => {
    try {
        const dateSplit = value?.split?.('/') ?? []
        if (dateSplit.length !== 3) {
            return undefined
        }
        const dateValue = new Date(0)
        dateValue.setFullYear(parseInt(dateSplit[0]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[2]))
        return dateValue
    } catch (e) {
        console.warn(e)
        return undefined
    }
}
