export enum ITPParty {
    /** Contractor */
    cnt = "cnt",
    /** Manufacturer */
    mnf = "mnf",
    /** General Contr. */
    gc = "gc",
    /** Client */
    cl = "cl",
    /** Notify Body */
    nb = "nb",
}

export const getITPPartyLabel_en = (party: ITPParty) => {
    switch (party) {
        case ITPParty.cnt:
            return "Contractor"
        case ITPParty.mnf:
            return "Manufacturer"
        case ITPParty.gc:
            return "General Contr."
        case ITPParty.cl:
            return "Client"
        case ITPParty.nb:
            return "Notify Body"
        default:
            throw new Error('Unknown party')
    }
}

export const getITPPartyLabel_it = (party: ITPParty) => {
    switch (party) {
        case ITPParty.cnt:
            return "Appaltatore"
        case ITPParty.mnf:
            return "Fabbricante"
        case ITPParty.gc:
            return "EPCM"
        case ITPParty.cl:
            return "Committente"
        case ITPParty.nb:
            return "Terza parte"
        default:
            throw new Error('Unknown party')
    }
}

export const getITPPartyAbbr = (party: ITPParty) => {
    switch (party) {
        case ITPParty.cnt:
            return "A"
        case ITPParty.mnf:
            return "F"
        case ITPParty.gc:
            return "E"
        case ITPParty.cl:
            return "C"
        case ITPParty.nb:
            return "T"
        default:
            throw new Error('Unknown party')
    }
}
