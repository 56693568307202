import {
    CommandBar,
    DialogType,
    MessageBar,
    MessageBarType,
    Selection,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack
} from "@fluentui/react";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import * as React from "react";
import {useContext, useMemo, useState} from "react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {ITPEventPolicy} from "../../../fusina-itp/controls/ITPEventPolicy";
import {useJobContext} from "../contexts/JobContext";
import {useSecurityContext} from "../contexts/SecurityContext";
import {ITPEventFactory} from "../../../fusina-itp/controls/ITPEventFactory";
import {ITPItemContext} from "../contexts/ITPItemContext";
import {ITPEventsDetailsListBase} from "./ITPEventsDetailsListBase";
import {ITPEvent} from "../../../fusina-itp/entities/ITPEvent";
import {SimpleDialog} from "./SimpleDialog";
import {ITPEventsCreation} from "./ITPEventsCreation";
import {ITPEventMessaging} from "./ITPEventMessaging";
import {ITPEventResourceContext} from "../contexts/ITPEventResourceContext";
import {ITPRevContext} from "../contexts/ITPRevContext";


export const ITPEventsView: React.FC = () => {
    const sc = useSecurityContext()
    const {job} = useJobContext()
    const {itp, item} = useContext(ITPItemContext)

    const {
        data,
        error,
        refresh,
    } = useFusinaRmiResource('ITPEvents', 'find', {
        by: 'item',
        itp: {_id: itp._id},
        item: item.item,
    })

    const [selected, setSelected] = useState<ITPEvent | undefined>(undefined)
    const [selectionCount, setSelectionCount] = useState<number | undefined>(undefined)
    const selection = useMemo(() => new Selection<ITPEvent>({
        onItemsChanged() {
            // NOTE: we may use setSelected(s => {}) and find if the old selected is still contained in the new items.
            const sel = selection.getSelection()
            setSelectionCount(sel?.length)
            if (sel?.length === 1) {
                setSelected(sel[0])
            } else {
                setSelected(undefined)
            }
        },
        onSelectionChanged() {
            const sel = selection.getSelection()
            setSelectionCount(sel?.length)
            if (sel?.length === 1) {
                setSelected(sel[0])
            } else {
                setSelected(undefined)
            }
        },
        getKey(event: ITPEvent) {
            return event._id
        },
    }), [])

    const new_event_policy = useMemo(() => ITPEventPolicy({
        securityContext: sc,
        job,
        itp,
        msg: new ITPEventFactory(sc, itp, item).new_event({}).messages[0],
    }), [sc, job, itp, item])

    const [isCreating, setIsCreating] = useState<boolean>(false)

    const revCtx = useContext(ITPRevContext)

    return <>
        <Stack>
            <CommandBar
                items={[
                    {
                        key: 'new',
                        iconProps: {
                            iconName: 'Add',
                        },
                        text: 'Nuova',
                        disabled: !new_event_policy.permissions.send || revCtx.isNotCurrent,
                        onClick() {
                            setIsCreating(true)
                        },
                    },
                    // {
                    //     key: 'open',
                    //     iconProps: {
                    //         iconName: 'EventInfo'
                    //     },
                    //     text: 'Apri'
                    // }
                ]}
                farItems={[
                    // TODO?: search something
                ]}
            />

            {error && <MaybeErrorMessageBar error={error}/>}

            {data === undefined && !error && <>
                <br/>
                <Spinner size={SpinnerSize.medium}/>
            </>}

            {data?.length === 0 && <>
                <br/>
                <MessageBar messageBarType={MessageBarType.info}>
                    Il calendario è vuoto
                </MessageBar>
            </>}

            {data?.length > 0 && <ITPEventsDetailsListBase
                selection={selection as Selection}
                selectionMode={SelectionMode.single}
                items={data}
            />}
        </Stack>

        {isCreating && <SimpleDialog
            title="Nuova notifica espletamento fasi PCQ"
            subText=""
        >
            <ITPEventsCreation
                onDismiss={() => {
                    setIsCreating(false)
                }}
                onSent={event => {
                    refresh().then(() => {
                        setIsCreating(false)
                        selection.setAllSelected(false)
                        selection.setKeySelected(event._id, true, false)
                    })
                }}
            />
        </SimpleDialog>}

        {selected && <SimpleDialog
            title="Notifica espletamento fasi PCQ"
            type={DialogType.close}
            subText=""
            onDismiss={() => {
                selection.setAllSelected(false)
            }}
            isBlocking={false}
            veryEasyDismiss
        >
            <ITPEventResourceContext.Provider value={{
                onRefreshRequired: async id => {
                    await refresh()
                    selection.setAllSelected(false)
                    selection.setKeySelected(id, true, false)
                }
            }}>
                <ITPEventMessaging event={selected}/>
            </ITPEventResourceContext.Provider>
        </SimpleDialog>}
    </>
}
