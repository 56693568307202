import * as React from "react";
import {useContext, useMemo, useState} from "react";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import type {IDetailsRowProps} from "@fluentui/react";
import {
    ActionButton,
    Callout,
    ConstrainMode,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    DialogType,
    DirectionalHint,
    DocumentCard,
    DocumentCardActivity,
    DocumentCardDetails,
    DocumentCardType,
    Icon,
    IconButton,
    IRawStyle,
    IRenderFunction,
    Link,
    mergeStyles,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    PrimaryButton,
    SelectionMode,
    SpinButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TooltipHost,
    useTheme,
    VerticalDivider
} from "@fluentui/react";
import {useForceUpdate, useId} from "@fluentui/react-hooks";
import {getQCRCodeMeaning} from "../../../fusina-itp/entities/QCRCode";
import {getITPPartyAbbr, getITPPartyLabel_it, ITPParty} from "../../../fusina-itp/entities/ITPParty";
import {getPresenceCodeActivity_it, ITP_REQUIRED_PARTIES} from "../../../fusina-itp/entities/PresenceCode";
import {ITPHeaderEditor} from "./ITPHeaderEditor";
import {ITPListItem} from "./ITPTable";
import {ITPItemEditor} from "./ITPItemEditor";
import {CancelEditsBtn} from "./CancelEditsBtn";
import {SimpleDialog} from "./SimpleDialog";
import {ITPItem} from "../../../fusina-itp/entities/ITPItem";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {useJobContext} from "../contexts/JobContext";
import {useSecurityContext} from "../contexts/SecurityContext";
import {ITPPolicy} from "../../../fusina-itp/controls/ITPPolicy";
import {ITPSignBtn} from "./ITPSignBtn";
import {RoleEnum} from "../../../fusina-authz/entities/RoleEnum";
import {emailToInitials, SignatureCard} from "./SignatureCard";
import {SignatureEmbedding} from "../../../fusina-signatures/entities/SignatureEmbedding";
import {ITPCertsView} from "./ITPCertsView";
import {ITPEventsView} from "./ITPEventsView";
import {ITPItemContext} from "../contexts/ITPItemContext";
import {ITPRejectBtn} from "./ITPRejectBtn";
import {ITPValidator} from "../../../fusina-itp/controls/ITPValidator";
import {ITPRevsList} from "./ITPRevsList";
import {ITPRevContext} from "../contexts/ITPRevContext";

const ITP_PARTY_PRESENCE_COL_WIDTH = 15

const preWrapStyle = mergeStyles({
    whiteSpace: 'pre-wrap !important',
})

const ITP_ITEM_TEMPLATE: ITPItem = {
    item: '',
    comp: '',
    desc: '',
    refDocs: '',
    recordsQCR: undefined,
    presences: {},
    notes: '',
}

const ITPSignatureCard: React.FC<{
    signatureUrlGetter?: (signature: SignatureEmbedding) => string
    signature: SignatureEmbedding
}> = props => {
    const url = useMemo(() => props?.signatureUrlGetter?.(props.signature), [props.signatureUrlGetter, props.signature])

    return <SignatureCard
        signature={props.signature}
        onClickHref={url}
    />
}

const SignaturesList: React.FC<{
    signatureUrlGetter?: (signature: SignatureEmbedding) => string
    signatures?: SignatureEmbedding[]
}> = props => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const id = useId('SignaturesList-GroupDoc')
    const theme = useTheme()

    if (props.signatures?.length === 1) {
        return <Stack style={{width: 250}}>
            <ITPSignatureCard
                signatureUrlGetter={props.signatureUrlGetter}
                signature={props.signatures[0]}
            />
        </Stack>
    }
    if (props.signatures?.length > 1) {
        return <>
            <Stack style={{width: 250}}>
                <DocumentCard
                    id={id}
                    type={DocumentCardType.compact}
                    style={{height: "3.5em", minWidth: 150}}
                    onClick={() => setIsOpen(true)}
                >
                    <DocumentCardDetails>
                        <DocumentCardActivity
                            activity={`${props.signatures.length} firme`}
                            people={props.signatures.map(s => ({
                                name: s.userEmail,
                                profileImageSrc: "",
                                initials: emailToInitials(s.userEmail)
                            }))}
                        />
                    </DocumentCardDetails>
                </DocumentCard>
            </Stack>
            <Callout
                hidden={!isOpen}
                directionalHint={DirectionalHint.topCenter}
                coverTarget
                role="dialog"
                onDismiss={() => setIsOpen(false)}
                target={'#' + id}
                isBeakVisible={false}
            >
                <Stack style={{width: 250}}>
                    {props.signatures?.map?.(s =>
                        <ITPSignatureCard
                            signatureUrlGetter={props.signatureUrlGetter}
                            signature={s}
                        />)}
                </Stack>
            </Callout>
        </>
    }
    return <Text style={{
        fontSize: `${theme.fonts.small.fontSize} !important`,
        color: theme.semanticColors.disabledText
    }}>
        &nbsp; Bozza non firmata &nbsp;
    </Text>
}

export const ITPSingleTable: React.FC<{
    isHeaderOnly?: boolean
    editable?: boolean
    itp: InspectionTestPlan
    onEditRequested?: () => void
    onEditDismiss?: () => void
    onEditSuccess?: () => void
    onDeleteSuccess?: () => void
    onSignSuccess?: () => void
    onActionFinish?: () => void
    createAction?: () => Promise<string>
    updateAction?: () => Promise<void>
    deleteAction?: () => Promise<void>
    rejectAction?: (comment?: string) => Promise<void>
    signAction?: (signatureRole: RoleEnum) => Promise<void>
    signatureUrlGetter?: (signature: SignatureEmbedding) => string
}> = props => {

    const revCtx = useContext(ITPRevContext)

    const {job} = useJobContext()

    const sc = useSecurityContext()

    const policy = useMemo(() => ITPPolicy({
        itp: props.itp,
        job: job,
        securityContext: sc,
    }), [props.itp, job])

    const [isEditingHeader, setIsEditingHeader] = useState<boolean>(false)


    const editHeaderId = useId('ITPSingleTable-editHeader')
    const deleteBtnId = useId('ITPSingleTable-deleteBtn')

    const [isSavePending, setIsSavePending] = useState<boolean>(false)

    const [editingItem, setEditingItem] = useState<ITPItem | null>(null)

    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [isDeletePending, setIsDeletePending] = useState<boolean>(false)
    const [deleteError, setDeleteError] = useState(undefined)

    const [saveError, setSaveError] = useState(undefined)

    const [openCertsItem, setOpenCertsItem] = useState<ITPItem | null>(null)
    const [openEventsItem, setOpenEventsItem] = useState<ITPItem | null>(null)

    const forceUpdate = useForceUpdate()

    const theme = useTheme()

    const itpValidator = useMemo(() => new ITPValidator(), [])

    const [openRevs, setOpenRevs] = useState<boolean>(false)

    const abolishedRowTextStyle = mergeStyles({
        fontSize: 12,
        fontWeight: 400,
        background: theme.semanticColors.bodyBackground
    })

    const rowRender_: IRenderFunction<IDetailsRowProps> = (props1, DefaultRender) => {
        const item: ITPItem = props1.item
        if (item?.abolished) {
            return <Stack horizontal tokens={{childrenGap: 's1'}} style={{
                opacity: 0.7,
                padding: `6px 12px`,
                background: 'repeating-linear-gradient(-45deg, transparent, transparent 5px, #fff 3px,  #fff 11px), repeating-linear-gradient(22deg, transparent, transparent 6px, #5555 8px)',
            }}>
                {props.editable && <Stack horizontal style={{minWidth: 132}} tokens={{childrenGap: 's1'}}>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <MessageBarButton
                        onClick={() => {
                            item.abolished = false
                            forceUpdate()
                        }}
                    >
                        Riattiva
                    </MessageBarButton>
                </Stack>}
                <Stack.Item className={abolishedRowTextStyle}>{item.item}</Stack.Item>
                <Stack.Item grow={1}>&nbsp;</Stack.Item>
                <Stack.Item className={abolishedRowTextStyle}>[Fase dismessa]</Stack.Item>
            </Stack>
        }
        return <DefaultRender {...props1}/>
    }

    const rowRender: IRenderFunction<IDetailsRowProps> = props.editable ? (props1, DefaultRender) => {
        const item: ITPItem = props1.item
        return <div style={{position: 'relative'}}>
            <div style={{
                position: 'absolute',
                left: 0,
                top: '-0.7em',
                zIndex: 2
            }}>
                <DefaultButton
                    styles={{
                        root: {
                            display: 'block',
                            margin: 0,
                            padding: 0,
                            minWidth: '1.4em',
                            width: '1.4em',
                            textAlign: 'center',
                            height: '1.4em',
                            lineHeight: '1.4em',
                            borderRadius: 999,
                        },
                        icon: {
                            fontSize: '0.7em',
                        }
                    }}
                    onClick={() => {
                        const newItem: ITPItem = {
                            ...JSON.parse(JSON.stringify(ITP_ITEM_TEMPLATE)),
                            item: makeNextItemItem(props.itp),
                        }
                        const itp_item_index = props.itp.items.findIndex(it => it.item === item.item)
                        props.itp.items.splice(itp_item_index, 0, newItem)
                        setEditingItem(newItem)
                        forceUpdate()
                    }}
                    title="Aggiungi fase"
                    iconProps={{iconName: 'Add'}}
                />
            </div>
            {rowRender_(props1, DefaultRender)}
        </div>
    } : rowRender_

    return <>
        <div style={{
            borderTop: `1px solid ${theme.palette.neutralQuaternary}`,
            borderBottom: `1px solid ${theme.palette.neutralQuaternary}`,
            padding: `8px 24px`,
            background: theme.palette.neutralLighterAlt,
        }}>
            <Stack
                horizontal
                wrap
                tokens={{childrenGap: 'l1'}}
                style={{
                    color: theme.semanticColors.disabledText,
                    fontSize: theme.fonts.medium.fontSize,
                    fontWeight: theme.fonts.medium.fontWeight,
                }}
            >
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    columnGap: theme.spacing.s1,
                    rowGap: 2,
                    alignItems: 'baseline',
                }}>
                    <span style={{textAlign: 'right'}}>Doc N.:</span>
                    <span>
                        <Text style={{
                            color: theme.semanticColors.bodyText,
                            fontSize: theme.fonts.small.fontSize,
                            fontWeight: theme.fonts.small.fontWeight,
                        }}>
                            {props.itp.docN}
                        </Text>
                        {props.itp.revN && <>
                            &nbsp; &nbsp;
                            rev.: {props.itp.revN}
                        </>}

                        {!props.editable && <>
                            &nbsp; &nbsp; &nbsp;
                            <Icon iconName="FullHistory" styles={{root: {fontSize: '0.7em'}}}/>
                            &nbsp;
                            <Link underline onClick={() => setOpenRevs(true)}>
                                Revisioni
                            </Link>
                        </>}

                    </span>
                    <span style={{textAlign: 'right'}}>Titolo:</span>
                    <span style={{
                        color: theme.semanticColors.bodyText
                    }}>
                        {props.itp.title}
                    </span>
                </div>

                <VerticalDivider/>

                {props.editable && <div>
                    <ActionButton
                        text="Modifica intestazione"
                        iconProps={{iconName: 'Edit'}}
                        id={editHeaderId}
                        onClick={() => {
                            setIsEditingHeader(v => !v)
                            forceUpdate()
                        }}
                    />
                    <Callout
                        target={'#' + editHeaderId}
                        directionalHint={DirectionalHint.leftTopEdge}
                        setInitialFocus
                        role="dialog"
                        style={{padding: '20px 24px'}}
                        onDismiss={() => {
                            setIsEditingHeader(false)
                            forceUpdate()
                        }}
                        hidden={!isEditingHeader}
                    >
                        <ITPHeaderEditor itp={props.itp}/>
                    </Callout>
                </div>}

                <Stack horizontal wrap tokens={{childrenGap: 's1'}} style={{flexGrow: 1, alignItems: 'center'}}
                       styles={{inner: {justifyContent: 'flex-end', alignItems: 'center'}}}>

                    {props.itp?.signatures?.length > 0 &&
                    !props.itp?.signatures?.find?.(s => s.role === RoleEnum.CDL_Q) &&
                    <Text style={{
                        fontSize: `${theme.fonts.small.fontSize} !important`,
                        color: theme.semanticColors.disabledText,
                    }}>
                        &nbsp; In attesa di firma da CDL-Q &nbsp;
                    </Text>}

                    {policy.permissions.write && !props.editable && <>
                        <DefaultButton
                            text="Elimina"
                            id={deleteBtnId}
                            onClick={() => {
                                setIsDeletePending(true)
                                setIsDeleting(true)
                                setTimeout(() => {
                                    setIsDeletePending(false)
                                }, 1500)
                            }}
                        />
                        {isDeleting && <Callout
                            hidden={false}
                            coverTarget
                            directionalHint={DirectionalHint.bottomAutoEdge}
                            role="dialog"
                            style={{padding: '20px 24px'}}
                            onDismiss={() => setIsDeleting(false)}
                            target={'#' + deleteBtnId}
                            isBeakVisible={false}
                            setInitialFocus
                        >
                            <Text block variant="xLarge" style={{fontWeight: 200, marginBottom: 12}}>
                                Confermi?
                            </Text>
                            <MessageBar messageBarType={MessageBarType.severeWarning}>
                                Stai per eliminare definitivamente la bozza.
                            </MessageBar>
                            <Stack horizontal style={{marginTop: 20}} tokens={{childrenGap: 'm'}}>
                                <PrimaryButton
                                    onClick={async () => {
                                        setDeleteError(undefined)
                                        setIsDeletePending(true)
                                        try {
                                            await props.deleteAction()
                                            props.onDeleteSuccess?.()
                                        } catch (e) {
                                            setDeleteError(e)
                                        } finally {
                                            setIsDeletePending(false)
                                        }
                                    }}
                                    className="ITPSingleTable-deleteConfirmBtn"
                                    text="Conferma" disabled={isDeletePending}/>
                                {isDeletePending && <Spinner size={SpinnerSize.small}/>}
                            </Stack>
                            <MaybeErrorMessageBar error={deleteError}/>
                        </Callout>}


                        <DefaultButton
                            text="Modifica"
                            onClick={props.onEditRequested}
                        />
                    </>}

                    {!props.editable && <>
                        <ITPSignBtn itp={props.itp} signatureRole={RoleEnum.AppaltatoreCQ}
                                    onSignSuccess={props.onSignSuccess}
                                    signAction={props.signAction}/>

                        <ITPRejectBtn itp={props.itp} onSuccess={props.onActionFinish}
                                      rejectAction={props.rejectAction}/>
                        <ITPSignBtn itp={props.itp} signatureRole={RoleEnum.CDL_Q}
                                    onSignSuccess={props.onSignSuccess}
                                    signAction={props.signAction}/>
                    </>}

                    {props.editable && <>
                        <CancelEditsBtn
                            editable={props.itp}
                            onClick={props.onEditDismiss}
                        />
                        <PrimaryButton
                            onClick={async () => {
                                setSaveError(undefined)
                                setIsSavePending(true);
                                try {
                                    if (props.itp?._id) {
                                        await props.updateAction()
                                    } else {
                                        props.itp._id = await props.createAction()
                                    }
                                    props.onEditSuccess?.();
                                } catch (e) {
                                    setSaveError(e)
                                } finally {
                                    setIsSavePending(false)
                                }
                            }}
                            disabled={isSavePending}
                        >
                            <b style={{fontWeight: 500}}>
                                Salva
                            </b>
                            {isSavePending && <>
                                &nbsp; <Spinner size={SpinnerSize.small}/>
                            </>}
                        </PrimaryButton>
                    </>}

                    <SignaturesList signatureUrlGetter={props.signatureUrlGetter} signatures={props.itp?.signatures}/>
                </Stack>
            </Stack>
        </div>

        <MaybeErrorMessageBar error={saveError}/>

        {props.isHeaderOnly !== true && <>

            <DetailsList
                className="OneITPTable-DetailsList"
                items={props.editable ? props.itp.items : props.itp.items.filter(it => !it.abolished)}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                selectionMode={SelectionMode.none}
                styles={{
                    root: {
                        minHeight: 200,
                        paddingBottom: 20,
                    }
                }}
                onItemInvoked={props.editable ? (item, index) => {
                    // WARNING: assuming index is relative to the full itp.items loaded without filters
                    if (index >= 0) {
                        setEditingItem(props.itp.items[index])
                    }
                } : undefined}
                columns={[
                    ...(props.editable ? [
                        {
                            key: 'edit',
                            name: ' Modifica',
                            iconName: 'Sort',
                            minWidth: 120,
                            maxWidth: 120,
                            styles: {
                                cellName: {
                                    color: theme.semanticColors.disabledText
                                }
                            },
                            onRender(item, index) {
                                if (index === undefined || index < 0 || item?.item === undefined) {
                                    return null
                                }

                                // WARNING: assuming index is relative to the full itp.items loaded without filters

                                return <Stack horizontal tokens={{childrenGap: 's1'}}>
                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                    <SpinButton
                                        title="Posizione"
                                        value={(props.itp.items.length - index - 1).toFixed(0)}
                                        styles={{
                                            root: {
                                                width: 24,
                                                maxWidth: 24,
                                                minWidth: 24,
                                            },
                                            spinButtonWrapper: {
                                                width: 23,
                                                maxWidth: 24,
                                                minWidth: 23,
                                                margin: '-6px 0',
                                            },
                                            input: {
                                                display: 'none',
                                            },
                                        }}
                                        onChange={(event, newValue) => {
                                            const nv = props.itp.items.length - parseInt(newValue) - 1
                                            if (nv >= props.itp.items.length || nv < 0) {
                                                return
                                            }
                                            const _item = props.itp.items[index]
                                            props.itp.items.splice(index, 1)
                                            props.itp.items.splice(nv, 0, _item)
                                            forceUpdate()
                                        }}
                                    />
                                    <IconButton
                                        title="Modifica"
                                        iconProps={{
                                            iconName: 'Edit',
                                        }}
                                        onClick={() => {
                                            setEditingItem(props.itp.items[index])
                                        }}
                                        styles={{
                                            root: {
                                                //height: '1.25em',
                                                margin: '-6px 0',
                                            },
                                        }}
                                    />
                                    <IconButton
                                        title="Rimuovi"
                                        iconProps={{
                                            iconName: 'Delete',
                                        }}
                                        onClick={() => {
                                            if (revCtx?.prevItp?.items?.some?.(it => it.item === item.item)) {
                                                if (confirm(`Dismettere la fase ${item.item} del PCQ pre-vigente?`)) {
                                                    props.itp.items[index].abolished = true
                                                    forceUpdate()
                                                }
                                                return
                                            }
                                            if (confirm(`Rimuovere la fase ${item.item}?`)) {
                                                props.itp.items.splice(index, 1)
                                                forceUpdate()
                                            }
                                        }}
                                        styles={{
                                            root: {
                                                //height: '1.25em',
                                                margin: '-6px 0',
                                            },
                                        }}
                                    />
                                </Stack>
                            }
                        },
                    ] : []),
                    {
                        key: 'item',
                        fieldName: 'item',
                        name: 'Fase',
                        minWidth: 50,
                        maxWidth: 80,
                        onRenderField(field_props, DefaultRender) {
                            if (field_props.itemIndex >= 0 && policy.permissions.write) {
                                const item: ITPListItem = field_props.item;
                                const msgs = itpValidator.validateItem(props.itp, item)
                                    .filter(msg => msg.itemField === 'item')
                                if (msgs.length >= 1) {
                                    return <TooltipHost
                                        content={<>
                                            Ci sono errori di validazione:
                                            <ul>
                                                {msgs.map(m => <li>{m.msg}</li>)}
                                            </ul>
                                        </>}
                                        delay={10}
                                        closeDelay={400}
                                        calloutProps={{gapSpace: 0}}
                                    >
                                        <div style={{
                                            background: theme.semanticColors.severeWarningBackground,
                                        }}>
                                            <DefaultRender {...field_props}/>
                                        </div>
                                    </TooltipHost>
                                }
                            }
                            return <DefaultRender {...field_props}/>
                        },
                        onRender(item, index) {
                            if (index >= 0 && policy.permissions.write) {
                                const msgs = itpValidator.validateItem(props.itp, item)
                                    .filter(msg => msg.itemField === 'item')
                                if (msgs.length >= 1) {
                                    return <Text
                                        style={{
                                            color: theme.semanticColors.severeWarningIcon,
                                            background: theme.semanticColors.severeWarningBackground,
                                        }}
                                    >
                                        <Icon iconName="Warning"/> &nbsp;{item.item}&nbsp;
                                    </Text>
                                }
                                return item.item
                            }
                            return item?.item ?? null
                        },
                    },
                    {
                        key: 'comp',
                        fieldName: 'comp',
                        name: 'Componente',
                        minWidth: 100,
                        maxWidth: 130,
                    },
                    {
                        key: 'desc',
                        fieldName: 'desc',
                        name: 'Descrizione del controllo',
                        minWidth: 200,
                        maxWidth: 300,
                        targetWidthProportion: 0.3,
                        isMultiline: true,
                        className: preWrapStyle,
                    },
                    {
                        key: 'refDocs',
                        fieldName: 'refDocs',
                        name: 'Documenti applicabili',
                        minWidth: 170,
                        maxWidth: 200,
                        targetWidthProportion: 0.2,
                        isMultiline: true,
                        className: preWrapStyle,
                    },
                    {
                        key: 'recordsQCR',
                        fieldName: 'recordsQCR',
                        name: 'QCR',
                        minWidth: 40,
                        maxWidth: 40,
                        onRenderField(props, DefaultRender) {
                            const item: ITPListItem = props.item;
                            // FIXME: validation is not considered here, but reimplemented
                            if (item?.recordsQCR) {
                                return <TooltipHost
                                    content={`${item?.recordsQCR} - ${getQCRCodeMeaning(item?.recordsQCR)}`}
                                    style={{fontWeight: 600}}
                                    delay={10}
                                    closeDelay={400}
                                    calloutProps={{gapSpace: 0}}
                                >
                                    <DefaultRender {...props}/>
                                </TooltipHost>
                            }
                            if (props.itemIndex >= 0 && item) {
                                return <TooltipHost
                                    content={`Codice QCR richiesto.`}
                                    style={{
                                        fontWeight: 600,
                                    }}
                                    delay={10}
                                    closeDelay={400}
                                    calloutProps={{gapSpace: 0}}
                                >
                                    <div style={{
                                        background: theme.semanticColors.severeWarningBackground,
                                        color: theme.semanticColors.severeWarningIcon,
                                    }}>
                                        <DefaultRender {...props}/>
                                    </div>
                                </TooltipHost>
                            }
                            return <DefaultRender {...props}/>
                        },
                        onRender(item: ITPListItem, index) {
                            // FIXME: validation is not considered here, but reimplemented
                            if (item?.recordsQCR) {
                                return <span style={{fontWeight: 600}}>
                                {item?.recordsQCR}
                            </span>
                            }
                            if (index >= 0 && item) {
                                return <div style={{textAlign: 'center'}}>
                                    <big><Icon iconName="Warning"/></big>
                                </div>
                            }
                            return null
                        },
                    },
                    ...(Object.values(ITPParty).map(party => ({
                        key: party,
                        minWidth: ITP_PARTY_PRESENCE_COL_WIDTH,
                        maxWidth: ITP_PARTY_PRESENCE_COL_WIDTH,
                        name: getITPPartyAbbr(party),
                        styles: {
                            cellName: {
                                overflow: 'visible',
                            } as IRawStyle,
                            cellTitle: {
                                overflow: 'visible',
                            } as IRawStyle,
                        },
                        onRenderHeader() {
                            return <TooltipHost
                                content={getITPPartyLabel_it(party)}
                                delay={10}
                                closeDelay={400}
                                calloutProps={{gapSpace: 0}}
                            >
                                {getITPPartyAbbr(party)}
                            </TooltipHost>
                        },
                        onRenderField(props, DefaultRender) {
                            const item: ITPListItem = props.item;
                            // FIXME: validation is not considered here, but reimplemented
                            if (item?.presences?.[party]) {
                                return <TooltipHost
                                    content={`Presenza ${getITPPartyLabel_it(party)}: ${item?.presences?.[party]} - ${getPresenceCodeActivity_it(item?.presences?.[party])}`}
                                    delay={10}
                                    closeDelay={400}
                                    calloutProps={{gapSpace: 0}}
                                >
                                    <DefaultRender {...props}/>
                                </TooltipHost>
                            }
                            if (props.itemIndex >= 0 && item && ITP_REQUIRED_PARTIES.includes(party)) {
                                return <TooltipHost
                                    content={`Codice presenza richiesto.`}
                                    style={{
                                        fontWeight: 600,
                                    }}
                                    delay={10}
                                    closeDelay={400}
                                    calloutProps={{gapSpace: 0}}
                                >
                                    <div style={{
                                        background: theme.semanticColors.severeWarningBackground,
                                        color: theme.semanticColors.severeWarningIcon,
                                    }}>
                                        <DefaultRender {...props}/>
                                    </div>
                                </TooltipHost>
                            }
                            return <DefaultRender {...props}/>
                        },
                        onRender(item: ITPListItem, index) {
                            // FIXME: validation is not considered here, but reimplemented
                            if (item?.presences?.[party]) {
                                return <span style={{fontWeight: 600}}>
                                {item?.presences?.[party]}
                            </span>
                            }
                            if (index >= 0 && item && party === ITPParty.cl) {
                                return <big><Icon iconName="Warning"/></big>
                            }
                            return null
                        },
                    }))),
                    {
                        key: 'notes',
                        fieldName: 'notes',
                        name: 'Note',
                        minWidth: 100,
                        maxWidth: 300,
                        isMultiline: true,
                        className: preWrapStyle,
                    },
                    ...(props.editable || !(props.itp.signatures?.length > 0) ? [] : [{
                        key: 'actions',
                        name: 'Azioni',
                        minWidth: 100,
                        maxWidth: 150,
                        onRender(item: ITPListItem, index) {
                            if (index === undefined || index < 0 || item?.item === undefined) {
                                return null
                            }
                            // NOTE: assuming we are not in editable mode => item.item is identifier
                            const itp_item: ITPItem = props.itp.items.find(it => it.item === item.item)
                            // NOTE: Assumption: we have read permission on this ITP and this implies
                            //                   read permissions on both certificates and events.
                            try {
                                return <Stack horizontal tokens={{childrenGap: 's2'}}>
                                    <IconButton
                                        title="Inviti e notifiche"
                                        iconProps={{iconName: 'ScheduleEventAction'}}
                                        onClick={() => {
                                            setOpenEventsItem(itp_item)
                                        }}
                                    />
                                    {/*getQCRCodeDmsSemantics(item.recordsQCR).isFileUploadAllowed &&
                                     // NOTE: access to the certs list is always allowed, for revisions. */}
                                    <IconButton
                                        title="Certificati"
                                        iconProps={{iconName: 'Certificate'}}
                                        onClick={() => {
                                            setOpenCertsItem(itp_item)
                                        }}
                                    />
                                </Stack>
                            } catch (e) {
                                console.error(e)
                                return <Text style={{
                                    background: theme.semanticColors.severeWarningBackground,
                                    color: theme.semanticColors.severeWarningIcon
                                }}>
                                    Errore
                                </Text>
                            }
                        }
                    }])
                ]}
                onRenderRow={rowRender}
                onRenderDetailsHeader={(detailsHeaderProps, DefaultHeader) => {
                    return <>
                        <DetailsRow
                            {...detailsHeaderProps}
                            compact={false}
                            // selectionMode={SelectionMode.none} // if you do this you break the consistency w.r.t. the table
                            itemIndex={-1}
                            item={{} as ITPListItem}
                            onRenderField={(props1, defaultRender) => {
                                if (props1.column.key === Object.values(ITPParty)[0]) {
                                    return <div style={{
                                        overflow: 'visible',
                                        width: 1,
                                        display: 'flex',
                                        flexFlow: 'column',
                                        justifyContent: 'flex-end',
                                    }}>
                                        <div style={{
                                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'flex-end',
                                            fontWeight: 500,
                                            fontSize: theme.fonts.medium.fontSize,
                                            width: (ITP_PARTY_PRESENCE_COL_WIDTH + 8 + 12) * Object.values(ITPParty).length,
                                            boxShadow: '0px -1px 0px 0px inset #5552',
                                            paddingBottom: 2,
                                        }}>
                                            Presenza
                                        </div>
                                    </div>
                                }
                                return defaultRender(props1)
                            }}
                            onRenderItemColumn={() => null}
                            styles={{
                                root: {
                                    border: '0 none',
                                    background: theme.semanticColors.bodyBackground,
                                    selectors: {
                                        ':hover': {
                                            background: theme.semanticColors.bodyBackground,
                                        }
                                    }
                                },
                            }}
                        />
                        <DefaultHeader {...detailsHeaderProps} styles={{
                            root: {
                                paddingTop: 0
                            }
                        }}/>
                    </>
                }}
                onRenderDetailsFooter={() => {
                    return <>
                        {props.editable && <ActionButton
                            iconProps={{
                                iconName: 'Add'
                            }}
                            text="Aggiungi fase"
                            onClick={() => {
                                const newItem: ITPItem = {
                                    ...JSON.parse(JSON.stringify(ITP_ITEM_TEMPLATE)),
                                    item: makeNextItemItem(props.itp),
                                }
                                props.itp.items.push(newItem)
                                setEditingItem(newItem)
                                forceUpdate()
                            }}
                        />}
                    </>
                }}
            />

            {props.editable && <SimpleDialog
                title={`Modifica fase in PCQ`}
                subText=""
                type={DialogType.normal}
                hidden={!editingItem}
                buttons={[
                    <PrimaryButton
                        text="Conferma"
                        onClick={() => {
                            setEditingItem(null)
                            forceUpdate()
                        }}
                    />
                ]}
            >
                {editingItem
                    ? <ITPItemEditor itp={props.itp} item={editingItem}/>
                    : <ITPItemEditor itp={props.itp} item={ITP_ITEM_TEMPLATE}/> /* NOTE: for smoother transition. */}
            </SimpleDialog>}

            {!props.editable && openCertsItem && <SimpleDialog
                title={`${props.itp.docN} fase ${openCertsItem.item} `}
                subText={getQCRCodeMeaning(openCertsItem.recordsQCR)}
                type={DialogType.close}
                isBlocking={false}
                onDismiss={() => {
                    setOpenCertsItem(null)
                }}
            >
                <ITPCertsView itp={props.itp} item={openCertsItem}/>
            </SimpleDialog>}

            {!props.editable && openEventsItem && <SimpleDialog
                title={`Notifiche per ${props.itp.docN} fase ${openEventsItem.item} `}
                subText={''}
                type={DialogType.close}
                isBlocking={false}
                onDismiss={() => {
                    setOpenEventsItem(null)
                }}
            >
                {/* NOTE: ITPItemContext: loaded version, but each event shall be shown with its historical revision */}
                <ITPItemContext.Provider value={{
                    itp: props.itp,
                    item: openEventsItem,
                }}>
                    <ITPEventsView/>
                </ITPItemContext.Provider>
            </SimpleDialog>}

        </>}

        {openRevs && <SimpleDialog
            title={`Revisioni PCQ ${props.itp.docN}`}
            veryEasyDismiss
            subText={''}
            type={DialogType.close}
            isBlocking={false}
            onDismiss={() => {
                setOpenRevs(false)
            }}
        >
            <ITPRevsList itpId={props.itp?._id} onDismiss={() => setOpenRevs(false)}
                         onNewRevCreated={props.onActionFinish}
                         onScreenRevId={props.itp.revId ?? props.itp._id}/>
        </SimpleDialog>}
    </>
}

const makeNextItemItem: (itp: InspectionTestPlan) => ITPItem['item'] = itp => {
    const max = itp.items.map(item => {
        try {
            return parseFloat(item?.item)
        } catch (e) {
            return null
        }
    }).reduce((previousValue, currentValue) => {
        if (!previousValue && currentValue) {
            return currentValue
        }
        if (previousValue && currentValue) {
            return previousValue < currentValue ? currentValue : previousValue
        }
        return previousValue
    }, 0)
    return (Math.floor(max) + 1).toFixed(0)
}
