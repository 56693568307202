import * as React from 'react'
import {useCallback, useMemo, useState} from 'react'
import {Stack} from '@fluentui/react/lib/Stack';
import {getSenderRecipient, Letter} from "../../../fusina-letters/entities/Letter";
import {Callout, PrimaryButton, Spinner, SpinnerSize, Text} from "@fluentui/react";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useSecurityContext} from "../contexts/SecurityContext";
import LettersPolicy from "../../../fusina-letters/controls/LettersPolicy";
import {Job} from "../../../fusina-jobs/entities/Job";
import {useId} from '@fluentui/react-hooks';
import {Company} from "../../../fusina-jobs/entities/Company";
import {RoleEnum} from "../../../fusina-authz/entities/RoleEnum";


export const SignSendOutOdSBtn: React.FC<{
    job: Job
    letter: Letter
    signatureRole: RoleEnum
    onSuccess?: (letterId: string) => void
}> = props => {

    const rmi = useFusinaRmi()
    const sc = useSecurityContext()

    const policy = useMemo(() => props.job ? LettersPolicy({
        job: props.job,
        securityContext: sc,
        oldLetter: props.letter,
        newLetter: props.letter,
        signatureRole: props.signatureRole,
    }) : undefined, [sc, props.job, props.letter])

    const [pending, setPending] = useState<boolean>(false)
    const [confirming, setConfirming] = useState<boolean>(false)

    const handle = useCallback(async () => {
        setConfirming(false)
        setPending(true)
        try {
            const letterId = props.letter._id
            await rmi.Letters.sign(letterId, props.signatureRole)
            await rmi.Letters.sendOut(letterId)
            props.onSuccess?.(letterId)
        }
            // TODO: catch ?
        finally {
            setPending(false)
        }
    }, [rmi, policy])

    const [recipientCompany, setRecipientCompany] = useState<Company | undefined>(undefined)

    const preConfirmHandle = useCallback(() => {
        setConfirming(true)
        setPending(true)
        setTimeout(() => {
            setPending(false)
        }, 1000)

        const {recipient} = getSenderRecipient(props.letter, props.job)
        rmi.JobsList.findCompanyById(recipient)
            .then(setRecipientCompany)
    }, [])

    const buttonId = useId('SignSendOutOdSBtn-preConfirm');

    if (
        (props.letter?._id && policy?.permissions?.sign !== true) ||
        (policy.iAmSender !== true)
    ) {
        return null
    }

    return <>
        <PrimaryButton
            id={buttonId} primary disabled={pending}
            onClick={preConfirmHandle}
        >
            Firma e trasmetti...
        </PrimaryButton>
        <Callout
            hidden={!confirming}
            coverTarget
            role="dialog"
            style={{padding: '20px 24px'}}
            onDismiss={() => setConfirming(false)}
            target={'#' + buttonId}
            isBeakVisible={false}
            setInitialFocus
        >
            <Text block variant="xLarge" style={{fontWeight: 200, marginBottom: 12}}>
                Confermi?
            </Text>
            Stai per firmare come {props.signatureRole}.
            <br/>
            La lettera sarà trasmessa
            {recipientCompany
                ? <> a {recipientCompany.denomination}.</>
                : <> al destinatario...</>}
            <Stack horizontal style={{marginTop: 20}} tokens={{childrenGap: 'm'}}>
                <PrimaryButton onClick={handle} className="SignSendOutOdSBtn-Confirm"
                               text="Conferma" disabled={pending}/>
                {pending && <Spinner size={SpinnerSize.small}/>}
            </Stack>
        </Callout>
    </>
}
