import {ICalendarStrings} from "@fluentui/react";

const strings: ICalendarStrings = {
    months: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre'
    ],

    shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],

    days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],

    shortDays: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],

    goToToday: 'Vai a oggi',
    prevMonthAriaLabel: 'Vai a mese precedente',
    nextMonthAriaLabel: 'Vai a mese successivo',
    prevYearAriaLabel: 'Vai ad anno precedente',
    nextYearAriaLabel: 'Vai ad anno successivo'
};

export function useCalendarStrings() {
    return strings
}
