import * as React from 'react'
import {useForceUpdate} from "@fluentui/react-hooks";
import {TextField} from "@fluentui/react";
import {Stack} from '@fluentui/react/lib/Stack';
import {Company} from "../../../fusina-jobs/entities/Company";
import {ImageUrlInput} from "./ImageUrlInput";

export const CompanyEditor: React.FC<{
    company: Company
}> = props => {

    const forceUpdate = useForceUpdate();

    return <>
        <Stack>
            <TextField
                label="Denominazione" value={props.company.denomination}
                onChange={(ev, newValue) => {
                    props.company.denomination = newValue ?? '';
                    forceUpdate()
                }}/>
            <ImageUrlInput
                label="Logo"
                value={props.company.logoURL}
                onChange={(newValue) => {
                    props.company.logoURL = newValue
                    forceUpdate()
                }}
            />
        </Stack>
    </>
}
