import * as React from 'react'
import {useEffect, useState} from 'react'
import {IStyleFunctionOrObject, TextField} from "@fluentui/react";
import {ITextFieldStyleProps, ITextFieldStyles} from "@fluentui/react/src/components/TextField/TextField.types";
import {amountFormatter} from "../../../fusina-letters/entities/EcoOdS";

const filterInputChars = (str: string) => {
    let s = str.replace(/[^0-9,.\-]/g, '').replace(/[,.]+/g, ',')
    if (s === ',') {
        s = '0,'
    }
    if (/^0[0-9].*/.test(s)) {
        s = s.substring(1)
    }
    if (/^-0[0-9].*/.test(s)) {
        s = '-' + s.substring(2)
    }
    if (s.indexOf(',') >= 0) {
        const [a, ...rest] = s.split(',')
        s = `${a},${rest.join('')}`
    }
    if (s.indexOf('-') >= 0) {
        s = '-' + s.replace(/-/g, '')
    }
    return s
}

export const DecimalField: React.FC<{
    className?: string
    label?: string
    min?: number
    /** minimum number of decimal places */
    minDp?: number
    value: number
    onChange: (newValue: number) => void
    styles?: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
}> = props => {
    const [isFocussed, setIsFocussed] = useState<boolean>()
    const [editing, setEditing] = useState<string>()

    useEffect(() => {
        try {
            if (props.value === undefined) {
                setEditing('')
            } else {
                if (editing === undefined || !isFocussed) {
                    setEditing(amountFormatter(props.value, undefined, props.minDp).replace(/[ ]/g, ''))
                }
            }
        } catch (e) {
            console.debug(e)
            setEditing('')
        }
    }, [props.value, isFocussed])

    return <TextField
        label={props.label}
        value={editing}
        styles={props.styles}
        className={props.className}
        onGetErrorMessage={value => {
            if (value === '' || value === undefined) {
                return `Minimo: ${props.min}`
            }
            try {
                const f = parseFloat(value.replace(/,/g, '.').replace(/[ ]/g, ''))
                if (!isFinite(f)) {
                    return 'Non valido'
                }
                if (props.min && f < props.min) {
                    return `Minimo: ${props.min}`
                }
            } catch (e) {
                console.debug(e)
                return 'Non valido'
            }
        }}
        deferredValidationTime={1000}
        onChange={(event, newValue) => {
            if (newValue === undefined || newValue === '') {
                setEditing('')
                props.onChange(undefined)
                return
            }
            const v = filterInputChars(newValue)
            setEditing(v)
            try {
                const f = parseFloat(v.replace(/,/g, '.').replace(/[ ]/g, ''))
                if (isFinite(f)) {
                    props.onChange(f)
                }
            } catch (e) {
            }
        }}
        onFocus={() => setIsFocussed(true)}
        onBlur={() => setIsFocussed(false)}
        maxLength={18}
    />
}
