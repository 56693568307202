import {Letter} from "../../../fusina-letters/entities/Letter";
import {Job} from "../../../fusina-jobs/entities/Job";
import {amountOrderItem, EcoOdS} from "../../../fusina-letters/entities/EcoOdS";
import * as XLSX from "xlsx/xlsx";
import {DailyOrderExpenses} from "../../../fusina-letters/entities/DailyOrderExpenses";

export function exportLettersEstimatesAndExpenses(letters: Letter[], job: Job, all_expenses: DailyOrderExpenses[], fileExt: string) {
    // NOTE: we may import type {BookType} from "xlsx/types"; but bookType is better derived from ext, not the other way around.
    // NOTE: asserting ecoOdsEstimatePolicy.permissions.read, otherwise it should be prevented by UI disable
    try {

        const array_of_arrays: (string | number | boolean)[][] = letters
            .flatMap(letter => {
                return [
                    ...((letter as EcoOdS).estimate?.items ?? [])
                        .map(oi => {
                            // This function shall return a row of the sheet...
                            //      Let's return a row about the estimate order item.
                            return [
                                letter.externalRef, letter.date, letter.subject, letter.signatures.map(s => s.role).join(', '), letter.sent,
                                ((letter as EcoOdS).estimate?.signatures ?? []).map(s => s.role).join(', '),
                                "",
                                oi.item, oi.description, oi.quantity, oi.unitOfMeasure, oi.unitPrice,
                                amountOrderItem(oi).toNumber(),
                                "Preventivo"
                            ]
                        }),
                    ...all_expenses
                        .filter(doe => doe._id.order === letter._id)
                        .flatMap(doe => {
                            // This function shall return a list of rows of the sheet...
                            //      Let's return a row for each daily order expenses item.
                            return doe.items
                                .map(oi => [ // This is a row of the sheet.
                                    letter.externalRef, letter.date, letter.subject, letter.signatures.map(s => s.role).join(', '), letter.sent,
                                    ((letter as EcoOdS).estimate?.signatures ?? []).map(s => s.role).join(', '),
                                    doe._id.date,
                                    oi.item, oi.description, oi.quantity, oi.unitOfMeasure, oi.unitPrice,
                                    amountOrderItem(oi).toNumber(),
                                    "Consuntivo"
                                ])
                        })
                ]
            })

        const wb = XLSX.utils.book_new()
        // const hh = ["n°", "OdS/NI", "Data", "Oggetto", "Firme", "Inviato", "Firme preventivo", "Preventivo", "Consuntivo"]
        const hh = [
            "n° OdS", "Data OdS", "Oggetto OdS", "Firme OdS", "Inviato",
            "Firme preventivo",
            "Data consuntivo",
            "Articolo", "Descrizione", "Quantità", "U.M.", "P.U. [€]",
            "Ammontare [€]",
            "Preventivo/Consuntivo",
        ]
        const ws = XLSX.utils.aoa_to_sheet([
            hh,
            ...array_of_arrays,
        ]);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `Preventivo e consuntivo ${(job?.code ?? '').replace(/[^a-zA-Z0-9-]*/, '')}.${fileExt}`);
    } catch
        (e) {
        console.error(e)
    }
}
