import * as React from 'react'

export const ELogo: React.FC = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                viewBox="0 0 685.35917 695.62143"
                width="1em"
                height="1em">
        <g id="g19253"
           style={{fill: "currentColor"}}
           transform="translate(-248,-469)">
            <g id="g19249"
               style={{fill: "currentColor"}}>
                <g id="g4956"
                   transform="matrix(6.145011,0,0,-6.145011,2552,936)"
                   style={{fill: "currentColor"}}>
                    <path id="path4958"
                          style={{fill: "currentColor", fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}
                          d="m -325.34981,12.141515 h 61.987 v 16.889 c -4.572,26.571 -28.296,46.834 -55.765,46.834 -30.75,0 -55.766,-25.39 -55.766,-56.6 0,-31.209 25.016,-56.601 55.766,-56.601 14.775,0 28.697,5.806 39.203,16.347 1.896,1.902 3.675,3.963 5.287,6.124 l -13.538,10.0970002 c -1.132,-1.518 -2.381,-2.965 -3.712,-4.3 -7.312,-7.3370002 -16.988,-11.3790002 -27.24,-11.3790002 -21.437,0 -38.878,17.8140002 -38.878,39.712 0,21.897 17.441,39.712 38.878,39.712 18.138,0 34.245,-12.756 38.518,-29.946 h -44.74 z"/>
                </g>
            </g>
        </g>
    </svg>
}