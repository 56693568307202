import {Icon, Label, Spinner, SpinnerSize, useTheme} from "@fluentui/react";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import * as React from "react";
import {DragEventHandler, useCallback, useRef, useState} from "react";

export const FileUploadInput: React.FC<{
    label?: string
    processFile: (newValue: File) => Promise<void>
}> = props => {

    const theme = useTheme()
    const inputRef = useRef<HTMLInputElement>();

    const [error, setError] = useState(undefined)
    const [loading, setLoading] = useState<boolean>(false)
    const handleFile = useCallback((file?: File) => {
        if (!file) {
            return
        }
        if (file.size >= 10_485_760) {  // server limit is slightly larger
            setError({
                message: 'File troppo grande (deve essere minore di 10MB)'
            })
            return;
        }
        setError(undefined)
        setLoading(true)
        props.processFile(file)
            .catch(setError)
            .finally(() => {
                if (inputRef.current) {
                    inputRef.current.value = undefined
                }
                setTimeout(() => setLoading(false), 500)
            })
    }, [])

    // file selection click
    const handleInputRef = useCallback(() => {
        handleFile(inputRef?.current?.files?.item?.(0))
    }, [inputRef])

    // drag and drop
    const [isDragging, setIsDragging] = useState<boolean>(false)
    const handleDragging = useCallback<DragEventHandler>(e => {
        e.stopPropagation()
        e.preventDefault()
        setIsDragging(true)
    }, [])
    const handleDrop = useCallback<DragEventHandler>(e => {
        e.stopPropagation()
        e.preventDefault()
        handleFile(e?.dataTransfer?.files?.item?.(0))
        setIsDragging(false)
    }, [])


    return <>
        {props.label && <Label>{props.label}</Label>}
        <label
            style={{
                display: 'block',
                position: 'relative',
                padding: 0, //theme.spacing.s1,
                border: '1px solid ' + (isDragging ? theme.semanticColors.inputFocusBorderAlt : theme.semanticColors.inputBorder),
                borderRadius: 2,
                cursor: 'pointer',
                boxShadow: isDragging ? 'inset 0 0 0 1px ' + theme.semanticColors.inputFocusBorderAlt : undefined,
            }}
            onDrop={handleDrop}
            onDragEnter={handleDragging}
            onDragOver={handleDragging}
            onDragLeave={() => setIsDragging(false)}
            onDragEnd={() => setIsDragging(false)}
        >
            <MaybeErrorMessageBar error={error} onDismiss={() => setError(undefined)}/>

            {loading
                ? <Spinner size={SpinnerSize.large} style={{margin: '2em auto', textAlign: 'center'}}/>
                : <>

                    <div style={{
                        margin: '2em auto',
                        textAlign: 'center',
                        color: theme.semanticColors.disabledText
                    }}>
                        <Icon iconName="OpenFile" style={{fontSize: '1.5em'}}/>
                    </div>

                    <input
                        style={{display: 'none'}}
                        type="file"
                        ref={inputRef}
                        onChange={handleInputRef}
                    />
                </>}
        </label>
    </>
}
