import {Job} from "../../../fusina-jobs/entities/Job";
import {useFusinaRmiResource} from "./useFusinaRmiResource";
import {useMemo} from "react";
import {User} from "../../../fusina-users/entities/User";
import {RoleEnum} from "../../../fusina-authz/entities/RoleEnum";

export interface GrantItem {
    scopeLabel: string
    role: RoleEnum
    email: string
}

export function useJobGrantsView(job: Job) {
    const {
        data: jobUsers,
        error: jobUsersError,
    } = useFusinaRmiResource('UsersAdmin', 'findUsersByGrant', job._id ? {
        scopeId: job._id,
        scopeType: 'job'
    } : undefined)

    const {
        data: contractorUsers,
        error: contractorUsersError,
    } = useFusinaRmiResource('UsersAdmin', 'findUsersByGrant', job.contractor ? {
        scopeId: job.contractor,
        scopeType: 'company'
    } : undefined)

    const {
        data: buyerUsers,
        error: buyerUsersError,
    } = useFusinaRmiResource('UsersAdmin', 'findUsersByGrant', job.buyer ? {
        scopeId: job.buyer,
        scopeType: 'company'
    } : undefined)

    const grantItems = useMemo<GrantItem[]>(() => {
        const mapMaker = (scopeLabel: string, scopeId: string) => (u: User) => u.grants
            .filter(g => g.scopeId === scopeId)
            .map(g => ({
                scopeLabel,
                role: g.role,
                email: u.email,
            }))
        return [
            ...(job._id && jobUsers ? jobUsers.flatMap(mapMaker('', job._id)) : []),
            ...(job.contractor && contractorUsers ? contractorUsers.flatMap(mapMaker('(da appaltatore)', job.contractor)) : []),
            ...(job.buyer && buyerUsers ? buyerUsers.flatMap(mapMaker('(da committente)', job.buyer)) : []),
        ]
    }, [jobUsers, contractorUsers, buyerUsers])

    const areGrantsFullyLoaded = (
        (!job._id || jobUsers !== undefined) &&
        (!job.contractor || contractorUsers !== undefined) &&
        (!job.buyer || buyerUsers !== undefined) &&
        true // close off the and loop
    )

    return {
        grantItems,
        areGrantsFullyLoaded,
        errors: [jobUsersError, contractorUsersError, buyerUsersError].filter(e => !!e)
    }
}
