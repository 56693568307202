import {IconButton, Spinner, SpinnerSize, Stack} from '@fluentui/react';
import * as React from 'react'
import {useCallback, useContext, useMemo, useState} from 'react'
import {ITPEvent} from "../../../fusina-itp/entities/ITPEvent";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useDelayedValue} from "../hooks/useDelayedValue";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {AttachmentCard, AttachmentsCountCard, ErrOrNoAttachments} from "./AttachmentCard";
import {useFusinaRmiUrl} from "../hooks/useFusinaRmiUrl";
import {FileUploadInput} from "./FileUploadInput";
import {useSecurityContext} from "../contexts/SecurityContext";
import {useJobContext} from "../contexts/JobContext";
import {ITPItemContext} from "../contexts/ITPItemContext";
import {ITPEventAttachmentsPolicy} from "../../../fusina-itp/controls/ITPEventAttachmentsPolicy";

export const ITPEventAttachments: React.FC<{
    event: ITPEvent
}> = props => {

    const securityContext = useSecurityContext()
    const {job} = useJobContext()
    const {itp} = useContext(ITPItemContext)
    const policy = useMemo(() =>
        ITPEventAttachmentsPolicy({
            securityContext,
            job,
            itp,
        }), [securityContext, job, itp])

    const [pending, setPending] = useState<boolean>(false)

    return <>
        {!pending && <ITPEventAttachmentsList
            event={props.event}
            showDelete={policy?.permissions?.delete}
        />}
        {pending && <Spinner size={SpinnerSize.small}/>}
        {policy?.permissions?.write && <ITPEventAttachmentAddBtn
            label="Aggiungi allegato"
            event={props.event}
            onBeforeProcess={() => {
                setPending(true)
            }}
            onProcessing={result => {
                result.finally(() => setPending(false))
            }}
        />}
    </>
}

export const ITPEventAttachmentsList: React.FC<{
    event: ITPEvent
    cardWidth?: string
    showDelete?: boolean
}> = props => {

    const rmi = useFusinaRmi()
    const eventId = useDelayedValue(props.event._id, 10) // Artificial delay to avoid too frequent requests
    const {
        data: attachments,
        error,
        refresh,
    } = useFusinaRmiResource('ITPEventAttachments', 'list', eventId)

    if (attachments === undefined || error) {
        return <ErrOrNoAttachments error={error} cardWidth={props.cardWidth}/>
    }

    return <Stack horizontal wrap horizontalAlign="start">
        {attachments.length <= 0 && <AttachmentsCountCard count={0} cardWidth={props.cardWidth}/>}
        {attachments?.map?.(name => <Stack horizontal style={{alignItems: 'center'}}>
            <ITPEventAttachmentCard key={name} event={props.event} name={name} width={props.cardWidth}/>
            {props.showDelete && <IconButton iconProps={{iconName: 'Delete'}} onClick={() => {
                rmi.ITPEventAttachments.delete(props.event._id, name)
                    .finally(refresh)
            }}/>}
        </Stack>)}
    </Stack>
}

export function ITPEventAttachmentCard(props: {
    event: ITPEvent
    name: string
    width?: string
}) {
    const downloadUrl = useFusinaRmiUrl('ITPEventAttachments', 'download', props.event._id, props.name)

    return <AttachmentCard width={props.width} onClickHref={downloadUrl} name={props.name}/>;
}

export const ITPEventAttachmentAddBtn: React.FC<{
    label?: string
    event: ITPEvent
    onBeforeProcess?: () => void
    onProcessing?: (result: Promise<void>) => void
}> = props => {

    const rmi = useFusinaRmi()

    const processFile = useCallback(async (file: File) => {
        props.onBeforeProcess?.()
        const res = rmi.ITPEventAttachments.upload(props.event._id, file)
        props.onProcessing?.(res)
        return res
    }, [props.event])

    return <FileUploadInput label={props.label} processFile={processFile}/>
}
