import {useEffect, useMemo, useState} from "react";

export const usePdfFileCheck = (file: File) => {
    const [fileMagicNumber, setFileMagicNumber] = useState<string | undefined>(undefined)

    // NOTE: file access is async, then we need a state for the magic number

    useEffect(() => {
        setFileMagicNumber(undefined)
        if (file) {
            file.slice(0, 5).text()
                .then(r => setFileMagicNumber(r))
        }
    }, [file])

    const error = useMemo<Error | undefined>(() => {
        if (!file) {
            return
        }
        if (file.type && file.type !== 'application/pdf') {
            return new Error('Tipo di file non ammesso.')
        }
        if (fileMagicNumber !== undefined && fileMagicNumber !== '%PDF-') {
            return new Error('Il file non è un PDF valido.')
        }
        if (!file.name.toLowerCase().endsWith('.pdf')) {
            return new Error('Estensione non ammessa. Si richiede un file con estensione PDF.')
        }
    }, [file, fileMagicNumber])

    return {
        error
    }
}
