import * as React from 'react'
import {useMemo} from 'react'
import {Job} from "../../../fusina-jobs/entities/Job";
import {Letter} from "../../../fusina-letters/entities/Letter";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useSecurityContext} from "../contexts/SecurityContext";
import LettersPolicy from "../../../fusina-letters/controls/LettersPolicy";
import {ConfirmedCalloutBtn} from "./ConfirmedCalloutBtn";
import {RoleEnum, RoleSignatureLabels} from "../../../fusina-authz/entities/RoleEnum";

/** Support letter sharing for these that are allowed to write but not allowed to sign */
export const LetterShareDraftBtn: React.FC<{
    job: Job
    letter: Letter
    notifiedRole: RoleEnum
    onSuccess?: (letterId: string) => void
}> = props => {
    const rmi = useFusinaRmi()
    const sc = useSecurityContext()

    const policy = useMemo(() => props.job ? LettersPolicy({
        job: props.job,
        securityContext: sc,
        oldLetter: props.letter,
        signatureRole: props.notifiedRole, // NOTE: we shall check that we have NO permission to sign with this role!
    }) : undefined, [sc, props.job, props.letter, props.notifiedRole])

    if (policy?.permissions?.write !== true || policy?.permissions?.sign !== false || policy?.myRoles?.includes?.(props.notifiedRole) || policy?.isCreatedByMe === false) {
        return null
    }

    return <ConfirmedCalloutBtn
        btnProps={{
            primary: true,
            text: `Invia a ${RoleSignatureLabels[props.notifiedRole]}`,
        }}
        action={async () => {
            await rmi.Letters.shareDraft(props.letter?._id)
            props.onSuccess(props.letter?._id)
        }}
        calloutChildren={<>
            Stai per inviare la bozza al {RoleSignatureLabels[props.notifiedRole]}.
        </>}
    />
}
