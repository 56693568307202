import type {ITPItem} from "../entities/ITPItem";
import {ITPPolicy, ITPPolicyContext} from "./ITPPolicy";
import {getQCRCodeDmsSemantics} from "../entities/QCRCode";
import {RoleEnum} from "../../fusina-authz/entities/RoleEnum";


export interface ITPCertificatePolicyContext extends ITPPolicyContext {
    item: ITPItem
}


/** Policy that limits the operations on an ITP certificate */
export function ITPCertificatePolicy(ctx: ITPCertificatePolicyContext) {
    const itpPolicy = ITPPolicy(ctx)

    const {
        myRoles,

        isContractor_CQ,

        isSignedByClient,

        isOwnerOrNoOwner,

    } = itpPolicy

    const isITPSignedByClient = isSignedByClient

    const qcr = getQCRCodeDmsSemantics(ctx.item.recordsQCR)

    return {
        myRoles,

        isITPSignedByClient,
        isOwnerOrNoOwner,

        qcr,

        permissions: {
            read: itpPolicy.permissions.read,

            write: isContractor_CQ && isITPSignedByClient && qcr.isFileUploadAllowed && itpPolicy.permissions.read && isOwnerOrNoOwner,

            delete: myRoles.has(RoleEnum.Admin) === true
        }
    }
}
