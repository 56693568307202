import * as React from 'react'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
    CheckboxVisibility,
    CommandBar,
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    DialogType,
    Dropdown,
    IDetailsListStyles,
    ISelectableOption,
    ISelection,
    mergeStyles,
    MessageBar,
    MessageBarType,
    ResponsiveMode,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    useTheme
} from '@fluentui/react'
import {SimpleDialog} from "./SimpleDialog";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useForceUpdate} from '@fluentui/react-hooks';
import {LetterEditor} from "./LetterEditor";
import {
    getWay,
    Letter,
    LETTER_WAYS,
    LetterTypeLabels,
    LetterTypeSupAbbrLabels,
    LetterWay,
    parseExternalRefNumber
} from "../../../fusina-letters/entities/Letter";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {useJobContext} from "../contexts/JobContext";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {PageSetting} from "../contexts/PageContext";
import {DeleteDraftButton, LetterSideChildren, UpdateLetterDraftButton} from "./LetterSideChildren";
import {useNavigate, useParams} from "react-router-dom";
import {IContextualMenuItem} from "@fluentui/react/src/components/ContextualMenu/ContextualMenu.types";
import LettersPolicy, {LettersPolicy_ExtraListFilter} from "../../../fusina-letters/controls/LettersPolicy";
import {useSecurityContext} from "../contexts/SecurityContext";
import {RoleEnum} from "../../../fusina-authz/entities/RoleEnum";
import {SignAndNotifyBtn} from "./SignAndNotifyBtn";
import {SendOutLetterBtn} from "./SendOutLetterBtn";
import {CreateSignSendOutNiBtn} from "./CreateSignSendOutNiBtn";
import {LetterStatusIcon} from "./LetterStatusIcon";
import {LetterPreviewBtn} from "./LetterPreviewBtn";
import useThrottledCallback from "../hooks/useThrottledCallback";
import {CmdSearchBox} from "./CmdSearchBox";
import {SignEstimateAndNotifyBtn} from "./SignEstimateAndNotifyBtn";
import {EcoOdsEstimatePreviewBtn} from "./EcoOdsEstimatePreviewBtn";
import {sumOrderItems} from "../../../fusina-letters/entities/EcoOdS";
import {EcoOdsEstimatePolicy} from "../../../fusina-letters/controls/EcoOdsPolicy";
import {RejectToDraftBtn} from "./RejectToDraftBtn";
import {formatDateOnly} from "./DateOnlyPicker";
import {LetterReplyBtn} from "./LetterReplyBtn";
import {SignAndNotifyReceiptBtn} from "./SignAndNotifyReceiptBtn";
import {EcoOdsExpensesBtn} from "./EcoOdsExpensesBtn";
import {SignSendOutOdSBtn} from "./SignSendOutOdSBtn";
import {IDetailsRowProps} from "@fluentui/react/lib/components/DetailsList/DetailsRow.types";
import {Job} from "../../../fusina-jobs/entities/Job";
import * as XLSX from "xlsx/xlsx";
import {LetterShareDraftBtn} from "./LetterShareDraftBtn";
import {useScreenSize} from "../hooks/useScreenSize";
import {LettersExportArchiveForm} from "./LettersExportArchiveForm";
import {EcoOdsAmountFrag} from "./EcoOdsAmountFrag";
import {JobHeadingFrag} from "./JobHeadingFrag";
import {exportLettersExpenses} from "./EcoOdsExpensesExportFunction";
import {exportLettersEstimatesAndExpenses} from "./EcoOdsEstimatesAndExpensesExportFunction";
import {RMI} from "../config";


const rowTotalCellStyle = mergeStyles({
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontFamily: 'monospace',
    userSelect: 'text',
});

const LETTER_WAYS_OPTIONS: (ISelectableOption<LetterWay> & { key: LetterWay })[] = [
    {
        key: 'buyer2contractor',
        text: 'OdS',
        title: 'Ordini di Servizio',
        data: 'buyer2contractor',
    },
    {
        key: 'contractor2buyer',
        text: 'NI',
        title: 'Note Impresa',
        data: 'contractor2buyer',
    },
]

export const FusinaLettersTable: React.FC<{}> = () => {
    const navigate = useNavigate()
    // const firstRenderTime = useMemo(() => Date.now(), [])
    const {letterId} = useParams()
    const theme = useTheme()
    const rmi = useFusinaRmi()

    const {job} = useJobContext()
    const securityContext = useSecurityContext()

    const ecoOdsEstimatePolicy = useMemo(() => EcoOdsEstimatePolicy({
        securityContext,
        job,
    }), [securityContext, job])

    const lettersPolicy = useMemo(() => LettersPolicy({
        securityContext,
        job,
    }), [securityContext, job])

    const [search, setSearch] = useState<string | null>(null)

    const {
        throttledCallback: searchHandler
    } = useThrottledCallback<(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void>(
        (event, newValue) => {
            setSearch(newValue)
        }, 200, [])

    const {
        data: storedLetters_ALL,
        error: storedLettersError,
        refresh: storedLettersRefresh,
    } = useFusinaRmiResource('Letters', 'findByJob', job._id);

    const {
        data: letterHoles,
        error: letterHolesError,
        refresh: refreshLetterHoles,
    } = useFusinaRmiResource('Letters', 'findHolesByJob', job._id);

    const [shownWays, setShownWays] = useState<LetterWay[]>(['buyer2contractor', 'contractor2buyer'])
    useEffect(() => {
        // Reset shownWays after any identity update of storedLetters_ALL
        // NOTE: this implements the required reset on incoming notification (see #2068).
        setShownWays(LETTER_WAYS_OPTIONS.map(opt => opt.key))
    }, [storedLetters_ALL])

    const {isMobile} = useScreenSize()

    const storedLetters_POLICIED = useMemo(() => {
        if (storedLetters_ALL === undefined) {
            return undefined
        }
        const list_wo_holes = storedLetters_ALL.filter(LettersPolicy_ExtraListFilter(lettersPolicy))

        const makeHoleRepresentation: (way: LetterWay, n: number) => Letter = (way, n) => {
            const ref = parseExternalRefNumber(`${n}`).normalized
            return {
                type: way === 'buyer2contractor' ? 'InfoOdS' : 'NI',
                job: job._id,
                _id: undefined, //`hole-${way}-${ref}`, // NOTE: undefined id makes it not selectable and avoids queries
                externalRef: ref,
                date: '',
                sent: false,
                signatures: [],
                subject: isMobile ? 'N. non usata.' : 'Numerazione non usata.',
                message: '',
                _is_hole: true,
            }
        }
        const list = list_wo_holes.concat(
            LETTER_WAYS.flatMap(way => letterHoles?.[way]?.map?.(n => makeHoleRepresentation(way, n)) ?? []))
            .filter(letter => shownWays.includes(getWay(letter)))

        list.reverse()
        list.sort((a, b) => {
            try {
                const an = parseExternalRefNumber(a.externalRef)
                const bn = parseExternalRefNumber(b.externalRef)
                if (an?.progressive > bn?.progressive) {
                    return -1
                }
                if (an?.progressive < bn?.progressive) {
                    return 1
                }
                if (an?.postfix36 > bn?.postfix36) {
                    return -1
                }
                if (an?.postfix36 < bn?.postfix36) {
                    return 1
                }
            } catch (e) {
                console.warn(e)
            }
            return 0
        })
        return list
    }, [storedLetters_ALL, letterHoles, lettersPolicy, shownWays])

    const storedLetters_LISTED = useMemo(() => {
        if (storedLetters_POLICIED === undefined) {
            return undefined
        }
        if (!search) {
            return storedLetters_POLICIED
        }
        const searchLow = search.toLowerCase()
        return storedLetters_POLICIED.filter(letter => JSON.stringify([
            // NOTE: do not consider ALL the fields, because the base64 logo url is likely to contain any 3-char search string
            'type:' + letter.type,
            'n:' + letter.externalRef,
            letter.subject,
            'data:' + letter.date,
        ]).toLowerCase().includes(searchLow))
    }, [storedLetters_POLICIED, search])

    const forceUpdate = useForceUpdate();

    const selection = useMemo<ISelection<Letter & { key: string }>>(() => {
        let isModal = false;
        let items: (Letter & { key: string })[] = undefined;
        let letterId_ = letterId
        const setLetterId_ = (id?: string) => {
            navigate(`/jobs/${job._id}/letters/${id ?? ''}`, {replace: true})
            letterId_ = id
        }
        // NOTE: We are hereby defining a custom logic implementing ISelection
        return {
            count: items?.find?.(letter => letterId_ && letter?._id === letterId_) ? 1 : 0,
            mode: SelectionMode.single,

            canSelectItem(item, index) {
                // console.debug('SELECTION CALLED canSelectItem')
                if (typeof index === 'number' && index < 0) {
                    return false;
                }
                return true
            },

            // Obesrvable methods.
            setChangeEvents() {
                // console.debug('SELECTION CALLED setChangeEvents')
            },

            // Initialization methods.
            setItems(items_, shouldClear) {
                // console.debug('SELECTION CALLED setItems')
                items = items_?.map?.(letter => ({...letter, key: letter._id})) ?? []
                // if (shouldClear) {
                //     navigate(`/jobs/${job._id}/letters/`, {replace: true})
                // }
            },
            getItems: () => items ?? [],

            // Read selection methods.
            getSelection: () => {
                return items?.filter?.(letter => letterId_ && letter?._id === letterId_)?.map?.(letter => ({
                    ...letter,
                    key: letter._id
                })) ?? []
            },
            getSelectedIndices: () => {
                return items?.flatMap?.((letter, i) => letterId_ && letter._id === letterId_ ? [i] : []) ?? []
            },
            getSelectedCount: () => (items?.find?.(letter => letterId_ && letter?._id === letterId_) && 1) ?? 0,
            isRangeSelected(fromIndex: number, count: number) {
                // console.debug('SELECTION CALLED isRangeSelected')
                const ii: number[] = items?.flatMap?.((letter, i) => letter._id === letterId_ ? [i] : [])
                if (ii.length <= 0) {
                    return false
                }
                const i = ii[0]
                return (i >= fromIndex) && (i < fromIndex + count)
            },

            isAllSelected: () => false,
            isKeySelected: (key: string) => {
                // console.debug('SELECTION CALLED isKeySelected', letterId_ && letterId_ === key)
                return letterId_ && letterId_ === key
            },
            isIndexSelected: (index: number) => {
                // console.debug('SELECTION CALLED isIndexSelected', {item: items?.[index], index, letterId_}, letterId_ && items?.[index]?._id === letterId_)
                return letterId_ && items?.[index]?._id === letterId_
            },

            // Write selection methods.
            setAllSelected(isAllSelected: boolean) {
                // console.debug('SELECTION CALLED setAllSelected')
                if (!isAllSelected) {
                    setLetterId_(undefined)
                }
            },
            setKeySelected(key: string, isSelected: boolean) {
                // console.debug('SELECTION CALLED setKeySelected')
                if (isSelected) {
                    setLetterId_(key)
                }
            },
            setIndexSelected(index: number, isSelected: boolean) {
                // console.debug('SELECTION CALLED setIndexSelected')
                if (isSelected) {
                    const id: string = items?.[index]?._id
                    setLetterId_(id)
                }
            },

            // TODO?
            setModal(isModal_: boolean) {
                // console.debug('SELECTION CALLED setModal')
                isModal = isModal_
            },
            isModal() {
                // console.debug('SELECTION CALLED isModal')
                return isModal
            },
            // Write range selection methods.  TODO?
            selectToKey() {
                // console.debug('SELECTION CALLED selectToKey')
            },
            selectToIndex() {
                // console.debug('SELECTION CALLED selectToIndex')
            },
            // Toggle helpers.
            toggleAllSelected() {
            },
            toggleKeySelected(key: string) {
                // console.debug('SELECTION CALLED toggleKeySelected')
                setLetterId_(letterId_ ? undefined : key)
            },
            toggleIndexSelected(index: number) {
                // console.debug('SELECTION CALLED toggleIndexSelected')
                const id: string = items?.[index]?._id
                setLetterId_(letterId_ ? undefined : id)
            },
            toggleRangeSelected(fromIndex: number) {
                // console.debug('SELECTION CALLED toggleRangeSelected')
                const id: string = items?.[fromIndex]?._id
                setLetterId_(letterId_ ? undefined : id)
            },
        }
    }, [])

    useEffect(() => {
        // NOTE: letterId may have changed from URL because of clicking on a notification.
        if (letterId && selection?.getSelection?.()?.[0]?._id !== letterId) {
            // The locally selected ID and the ID from URL do not match.
            // URL can only change by local UI interaction or external changes.
            // UI interactions are handled above and already do push the URL.
            // Then here we must have found an externally generated URL change,
            // and we need to push the change to the UI selection.
            selection.setAllSelected(false)
            selection.setKeySelected(letterId, true, true)
        }
    }, [selection, letterId])

    const selected = letterId ? storedLetters_LISTED?.find?.(letter => letter._id === letterId) : undefined // selection.count === 1 ? selection.getSelection()[0] : undefined;

    const [editItem, setEditItem] = useState<Letter | undefined>(undefined)

    const handleEdit = useCallback((letter: Letter) => {
        if (letter._is_hole) {
            return
        }
        const policy = LettersPolicy({
            securityContext,
            oldLetter: letter,
            newLetter: letter,
            job,
        })
        if (policy.permissions.write) {
            selection.setAllSelected(false)
            setEditItem(JSON.parse(JSON.stringify(letter)))
        } else {
            // No action
        }
    }, [])

    const newLetterItems: IContextualMenuItem[] = useMemo(() => Object.entries(LetterTypeLabels).flatMap(([type, label]: [Letter['type'], string]) => {
        if (!job || !securityContext) {
            return []
        }
        const way = getWay({type})
        const newLetter: Letter = {
            type,
            _id: undefined,
            date: formatDateOnly(new Date()),
            externalRef: undefined, // later defined at run time
            job: job?._id,
            message: "",
            sent: false,
            signatures: [],
            subject: "",
            estimate: type === 'EcoOdS' ? {
                items: [],
                signatures: [],
            } : undefined,
            isPrivateAsDraft: true,
            createdBy: {
                _id: securityContext?.userId ?? securityContext?.user?._id,
                email: securityContext?.user?.email,
            },
        }
        const policy = LettersPolicy({newLetter, job, securityContext})
        // console.debug({policy})
        if (!policy.permissions.write) {
            return []
        }
        return [{
            key: type,
            text: label,
            'data-automation-id': `new${type}Button`,
            onClick() {
                const maxRef = storedLetters_ALL.reduce((prev, letter) => {
                    if (getWay(letter) !== way) {
                        return prev
                    }
                    try {
                        const n = parseInt(letter.externalRef)
                        return n >= prev ? n : prev
                    } catch (e) {
                        return prev
                    }
                }, 0)
                const newRef = maxRef + 1
                newLetter.externalRef = (newRef + '').padStart(4, '0')

                // UC009
                if (selected?.type) {
                    newLetter.subject = `In risposta a ${selected.type === 'NI' ? 'NI' : 'OdS'} n°${selected.externalRef ?? ''}: ${selected.subject ?? ''}`
                }

                setEditItem(newLetter)
            }
        }]
    }), [storedLetters_ALL, job, securityContext, selected])

    const onLetterOpSuccess = useCallback((letterId: string) => {
        storedLettersRefresh()
            .then(() => {
                selection.setAllSelected(false)
                selection.setKeySelected(letterId, true, false)
            })
    }, [storedLettersRefresh, selection])

    const dlRef = useRef<HTMLDivElement>()
    const heightGridStyles = useMemo<Partial<IDetailsListStyles>>(() => ({
        root: {
            // overflowX: 'scroll',
            overflowX: 'hidden', // Avoid transient scrollbar while sidebar is appearing
            selectors: {
                '& [role=grid]': {
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'stretch',  // 'start' is not good for scrolling, 'stretch' does not change anything
                    height: `calc(98vh - ${(dlRef.current?.offsetTop ?? 200) + 25}px)`,
                    maxWidth: '100vw',
                },
            },
        },
        headerWrapper: {
            flex: '0 0 auto',
        },
        contentWrapper: {
            flex: '1 1 auto',
            overflowY: 'auto',
            overflowX: 'hidden',
        },
    }), [dlRef.current])

    const isCollapsingColumns = isMobile

    const [isExportDialOpen, setIsExportDialOpen] = useState<boolean>(false)

    const pageTitle = <>
        <div style={{paddingRight: 15}}>
            Lettere<Dropdown
            options={LETTER_WAYS_OPTIONS}
            selectedKeys={shownWays}
            onChange={(ev, opt) => {
                setShownWays(v => {
                    const newV = LETTER_WAYS_OPTIONS
                        .filter(o => o.key === opt.key ? opt.selected : v.includes(o.data))
                        .map(o => o.data)
                    if (newV.length <= 0) {
                        return LETTER_WAYS_OPTIONS
                            .map(o => o.data)
                    }
                    return newV
                })
            }}
            multiSelect
            multiSelectDelimiter='/'
            styles={{
                root: {
                    border: '0 none',
                    display: 'inline-block',
                    verticalAlign: 'text-top',
                    marginLeft: 2,
                },
                dropdown: {
                    border: '0 none',
                },
                title: {
                    paddingLeft: 4,
                    fontSize: '1.5em', // theme.fonts.large.fontSize,
                    fontWeight: 'lighter',
                    border: '0 none',
                },
                caretDownWrapper: {
                    opacity: 0.5,
                }
            }}
            dropdownWidth={90}
            responsiveMode={ResponsiveMode.large}
        />
        </div>
        <JobHeadingFrag/>
    </>

    return <>

        {selected &&
        <PageSetting title={pageTitle} sideChildren={<LetterSideChildren letter={selected} onDismiss={() => {
            selection.setAllSelected(false)
        }}>
            {selected?.type === 'EcoOdS' && <>
                <EcoOdsEstimatePreviewBtn letter={selected}/>
                <EcoOdsExpensesBtn letter={selected}/>
            </>}
            <LetterPreviewBtn letter={selected}/>
            <UpdateLetterDraftButton onClick={handleEdit} letter={selected} job={job}/>
            <DeleteDraftButton letter={selected} onSuccess={() => {
                selection.setAllSelected(false)
                storedLettersRefresh().then()
                refreshLetterHoles().then()
            }} job={job}/>

            <br/>

            <Stack tokens={{childrenGap: 's2'}}>
                {selected?.type === 'EcoOdS' && <>
                    <SignEstimateAndNotifyBtn signatureRole={RoleEnum.CDL} notifiedRole={RoleEnum.SM}
                                              notifiedRole2={RoleEnum.CM202311} job={job}
                                              letter={selected} onSuccess={onLetterOpSuccess}
                                              notifySubtype='internalEstimateSignatureRequest'/>
                    <SignEstimateAndNotifyBtn signatureRole={RoleEnum.SM} notifiedRole={RoleEnum.PM} job={job}
                                              letter={selected} onSuccess={onLetterOpSuccess}
                                              notifySubtype='internalEstimateSignatureRequest'/>
                    <SignEstimateAndNotifyBtn signatureRole={RoleEnum.PM} notifiedRole={RoleEnum.CDL} job={job}
                                              letter={selected} onSuccess={onLetterOpSuccess}
                                              notifySubtype='internalEstimateSignatureFulfill'/>
                </>}

                {(selected?.type === 'InfoOdS' || selected?.type === 'EcoOdS' || selected?.type === 'UltAccOdS') &&
                <LetterShareDraftBtn notifiedRole={RoleEnum.CDL} job={job} letter={selected}
                                     onSuccess={onLetterOpSuccess}/>}

                <SignAndNotifyBtn signatureRole={RoleEnum.CDL} notifiedRole={RoleEnum.SM} job={job} letter={selected}
                                  notifiedRole2={RoleEnum.CM202311}
                                  onSuccess={onLetterOpSuccess}
                                  editBtn={<UpdateLetterDraftButton onClick={handleEdit} letter={selected}
                                                                    job={job} forMsgBar/>}/>

                {(selected?.type === 'InfoOdS' || selected?.type === 'EcoOdS') &&
                <SignSendOutOdSBtn signatureRole={RoleEnum.SM} job={job} letter={selected}
                                   onSuccess={onLetterOpSuccess} /* ODS workflow */ />}

                {selected?.type === 'VerbaleTAOdS' && <>
                    <LetterShareDraftBtn notifiedRole={RoleEnum.CommissioningMng202409} job={job} letter={selected}
                                         onSuccess={onLetterOpSuccess}/>
                    <SignAndNotifyBtn signatureRole={RoleEnum.CommissioningMng202409} notifiedRole={RoleEnum.SM}
                                      job={job} letter={selected}
                                      onSuccess={onLetterOpSuccess}/>
                    <SignSendOutOdSBtn signatureRole={RoleEnum.SM} job={job} letter={selected}
                                       onSuccess={onLetterOpSuccess}/>
                    <SignAndNotifyBtn signatureRole={RoleEnum.Appaltatore}
                                      notifiedRole={RoleEnum.CommissioningMng202409} job={job}
                                      letter={selected}
                                      onSuccess={onLetterOpSuccess} /* ODS receipt */ />
                </>}

                {selected?.type === 'UltAccOdS' &&
                <SignAndNotifyBtn signatureRole={RoleEnum.SM} notifiedRole={RoleEnum.PM} job={job} letter={selected}
                                  onSuccess={onLetterOpSuccess}/>}

                {selected?.type === 'UltAccOdS' &&
                <SignSendOutOdSBtn signatureRole={RoleEnum.PM} job={job} letter={selected}
                                   onSuccess={onLetterOpSuccess}/>}

                {(selected?.type === 'InfoOdS' || selected?.type === 'EcoOdS' || selected?.type === 'UltAccOdS') &&
                <SignAndNotifyBtn signatureRole={RoleEnum.Appaltatore} notifiedRole={RoleEnum.CDL} job={job}
                                  letter={selected}
                                  onSuccess={onLetterOpSuccess} /* ODS receipt */ />}

                <SendOutLetterBtn job={job} letter={selected} onSuccess={onLetterOpSuccess}/>

                <CreateSignSendOutNiBtn /* NI only! */ job={job} letter={selected} onSuccess={onLetterOpSuccess}/>

                <RejectToDraftBtn job={job} letter={selected} onSuccess={onLetterOpSuccess}/>

                {selected?.type === 'NI' && <>
                    <SignAndNotifyReceiptBtn signatureRole={RoleEnum.SM} job={job} letter={selected}
                                             onSuccess={onLetterOpSuccess}/>
                </>}

                <LetterReplyBtn newLetterItems={newLetterItems} letter={selected} job={job}/>
            </Stack>

        </LetterSideChildren>}/> ||
        <PageSetting title={pageTitle} sideChildren={null}/>}

        <CommandBar
            // style={{borderBottom: "1px solid", borderBottomColor: theme.palette.neutralLight}}
            items={[
                {
                    key: 'new',
                    text: 'Nuovo',
                    iconProps: {
                        iconName: 'NewMail'
                    },
                    disabled: newLetterItems.length <= 0,
                    subMenuProps: {
                        items: newLetterItems,
                        onMenuOpened() {
                            // Prevent usage of the selected letter as if it was UC009 (see other button)
                            selection.setAllSelected(false)
                        }
                    },
                    onMouseDown() {
                        // Prevent usage of the selected letter as if it was UC009 (see other button)
                        selection.setAllSelected(false)
                    }
                },
                {
                    key: 'export',
                    text: 'Esporta',
                    iconProps: {
                        iconName: 'Download',
                    },
                    disabled: storedLetters_POLICIED === undefined,
                    subMenuProps: {
                        items: [
                            // NOTE: We could import {getFileTypeIconProps} from "@fluentui/react-file-type-icons";
                            //       and getFileTypeIconProps({extension: 'xlsx', size: 16})
                            //       but there are sizing, spacing and image sampling problems
                            {
                                key: 'xlsx',
                                text: 'Esporta XLSX',
                                iconProps: {iconName: 'ExcelDocument'},
                                onClick() {
                                    exportLetters(storedLetters_POLICIED, job, ecoOdsEstimatePolicy, 'xlsx')
                                }
                            },
                            {
                                key: 'csv',
                                text: 'Esporta CSV',
                                iconProps: {iconName: 'TextDocument'},
                                onClick() {
                                    exportLetters(storedLetters_POLICIED, job, ecoOdsEstimatePolicy, 'csv')
                                }
                            },
                            {
                                key: 'xlsx_expenses',
                                text: 'Esporta XLSX Consuntivo',
                                iconProps: {iconName: 'ExcelDocument'},
                                disabled: !ecoOdsEstimatePolicy.permissions.read,
                                onClick() {
                                    RMI.EcoOds.findExpensesByJob(job._id).then(expenses => {
                                        exportLettersExpenses(storedLetters_POLICIED, job, expenses, 'xlsx')
                                    })
                                }
                            },
                            {
                                key: 'xlsx_expenses_estimates',
                                text: 'Esporta XLSX Preventivo/Consuntivo',
                                iconProps: {iconName: 'ExcelDocument'},
                                disabled: !ecoOdsEstimatePolicy.permissions.read,
                                onClick() {
                                    RMI.EcoOds.findExpensesByJob(job._id).then(expenses => {
                                        exportLettersEstimatesAndExpenses(storedLetters_POLICIED, job, expenses, 'xlsx')
                                    })
                                }
                            },
                            // {
                            //     key: 'csv_expenses',
                            //     text: 'Esporta CSV Consuntivo',
                            //     iconProps: {iconName: 'TextDocument'},
                            //     disabled: !ecoOdsEstimatePolicy.permissions.read,
                            //     onClick() {
                            //         RMI.EcoOds.findExpensesByJob(job._id).then(expenses => {
                            //             exportLettersExpenses(storedLetters_POLICIED, job, expenses,'csv')
                            //         })
                            //     }
                            // },
                            {
                                key: 'zip',
                                text: 'Esporta ZIP...',
                                iconProps: {iconName: 'ZipFolder'},
                                onClick() {
                                    setIsExportDialOpen(true)
                                }
                            },
                        ],
                    },
                },
                {
                    key: 'filterByWay',
                    text: 'Filtra',
                    iconProps: {
                        iconName: shownWays.length <= 1 ? 'FilterSolid' : 'Filter',
                    },
                    subMenuProps: {
                        items: [
                            ...LETTER_WAYS_OPTIONS.map(opt => ({
                                key: opt.key,
                                text: opt.title,
                                onClick() {
                                    setShownWays([opt.data])
                                },
                                disabled: shownWays.length === 1 && shownWays[0] === opt.data,
                            })),
                            {
                                key: 'all',
                                text: 'Mostra tutte',
                                onClick() {
                                    setShownWays(LETTER_WAYS_OPTIONS.map(opt => opt.data))
                                },
                                disabled: shownWays.length === LETTER_WAYS_OPTIONS.length,
                            }
                        ]
                    },
                }
            ]}
            farItems={[
                {
                    key: "search",
                    onRender: () => <CmdSearchBox onChange={searchHandler}/>
                }
            ]}/>

        <MaybeErrorMessageBar error={storedLettersError ?? letterHolesError}/>

        {storedLetters_LISTED === undefined && !storedLettersError
        && <Spinner size={SpinnerSize.large}/>}

        {(storedLetters_LISTED?.length === 0 && <MessageBar
            messageBarType={MessageBarType.info}
            isMultiline={false}>
            Nessuna lettera da mostrare
        </MessageBar>) || <div ref={dlRef}>
            <DetailsList
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.single}
                selectionPreservedOnEmptyClick={true}
                constrainMode={ConstrainMode.horizontalConstrained}
                styles={heightGridStyles}
                selection={selection}
                checkboxVisibility={CheckboxVisibility.hidden}
                columns={[
                    {
                        fieldName: "externalRef",
                        key: "externalRef",
                        name: "n°",
                        minWidth: 55,
                        maxWidth: 65,
                        targetWidthProportion: 0.05,
                    },
                    {
                        fieldName: "type",
                        key: "type",
                        name: "OdS/NI",
                        minWidth: 40,
                        maxWidth: 80,
                        targetWidthProportion: 0.05,
                        styles: {
                            cellName: {
                                fontSize: '0.85em'
                            }
                        },
                        onRender(item: Letter) {
                            return LetterTypeSupAbbrLabels[item?.type] ?? '???'
                        }
                    },
                    {
                        fieldName: "date",
                        key: "date",
                        name: "Data",
                        minWidth: 70,
                        maxWidth: 100,
                        targetWidthProportion: 0.1,
                    },
                    {
                        fieldName: "subject",
                        key: "subject",
                        name: "Oggetto",
                        minWidth: 100,
                        maxWidth: 400,
                        targetWidthProportion: 0.3,
                        flexGrow: isCollapsingColumns ? undefined : 0.5,
                        isCollapsible: isCollapsingColumns,
                    },
                ...(ecoOdsEstimatePolicy.permissions.read ? [
                    {
                        key: "estimate",
                        name: "Preventivo",
                        minWidth: 100,
                        maxWidth: 170,
                        isCollapsible: isCollapsingColumns,
                        targetWidthProportion: 0.1,
                        className: rowTotalCellStyle,
                        styles: {
                            cellTitle: {
                                justifyContent: 'flex-end',
                            }
                        },
                        onRender: (letter: Letter) => {
                            if (letter.type === 'EcoOdS') {
                                return <EcoOdsAmountFrag value={sumOrderItems(letter?.estimate?.items)}/>
                            }
                            return null
                        },
                    },
                    {
                        key: "expenses",
                        name: "Consuntivo",
                        minWidth: 100,
                        maxWidth: 170,
                        isCollapsible: isCollapsingColumns,
                        targetWidthProportion: 0.1,
                        className: rowTotalCellStyle,
                        styles: {
                            cellTitle: {
                                justifyContent: 'flex-end',
                            }
                        },
                        onRender: (letter: Letter) => {
                            if (letter.type === 'EcoOdS') {
                                return letter?.expenses
                                    ? <EcoOdsAmountFrag value={letter?.expenses} expensesMeta={letter?.expensesMeta}/>
                                    : null
                            }
                            return null
                        },
                    }
                ] : []),
                    {
                        key: "status",
                        name: "Stato",
                        minWidth: 50,
                        maxWidth: 100,
                        targetWidthProportion: 0.1,
                        flexGrow: 0.1,
                        onRender: (letter: Letter) => <LetterStatusIcon letter={letter}/>,
                        isCollapsible: isCollapsingColumns,
                    }
                ]}
                items={storedLetters_LISTED ?? []}
                onRenderRow={(props) => <LetterDetailsRow {...props}/>}
                // onActiveItemChanged={(item, index) => {
                //     console.debug('onActiveItemChanged', {item, index})
                //     navigate(`/jobs/${job._id}/letters/${item?._id}`, {replace: true})
                //     setTimeout(() => {
                //         console.debug(selection)
                //     }, 10)
                // }}
                onItemInvoked={handleEdit}/>
        </div>}

        {editItem && <SimpleDialog
            subText={""}
            title={`${editItem._id ? "Modifica" : `Nuov${editItem?.type === 'NI' ? 'a' : 'o'}`}: ${LetterTypeLabels[editItem?.type]}`}
            hidden={false}
        >
            <LetterEditor
                object={editItem}
                onDismiss={() => setEditItem(undefined)}
                onSaveSuccess={() => {
                    onLetterOpSuccess(editItem._id)
                    setEditItem(undefined)
                    refreshLetterHoles().then()
                }}
            />
        </SimpleDialog>}

        <SimpleDialog
            title="Archivio OdS/NI"
            type={DialogType.close}
            subText={null}
            veryEasyDismiss
            hidden={!isExportDialOpen}
            onDismiss={() => setIsExportDialOpen(false)}
        >
            <LettersExportArchiveForm job={job} onDismiss={() => setIsExportDialOpen(false)}/>
        </SimpleDialog>
    </>
}

const LetterDetailsRow: React.FC<IDetailsRowProps> = props => {
    const actions = usePermittedLetterActions(props.item as Letter)
    const hasActions = actions?.length >= 1 && (!props.item._is_hole)
    const isHole = !!props.item._is_hole

    const theme = useTheme()

    return <DetailsRow
        {...props}
        className={props.className + (hasActions ? ' LetterDetailsRow-hasActions' : '')}
        styles={{
            root: {
                color: hasActions ? theme.semanticColors.messageText : (isHole ? '#d79c9c' : undefined),
                background: hasActions ? theme.semanticColors.warningBackground : undefined,
            }
        }}
    />
}


interface LetterAction {
    policy: 'LettersPolicy' | 'EcoOdsEstimatePolicy'
    permission: 'sign' | 'send'
    signatureRole?: RoleEnum
}

function usePermittedLetterActions(letter: Letter) {
    const securityContext = useSecurityContext()
    const {job} = useJobContext()
    return useMemo<LetterAction[]>(() => {
        const actions: LetterAction[] = []
        if (LettersPolicy({
            securityContext,
            job,
            oldLetter: letter,
        }).permissions.send) {
            actions.push({
                policy: 'LettersPolicy',
                permission: 'send',
            })
        }
        for (let signatureRole of [RoleEnum.CDL, RoleEnum.SM, RoleEnum.PM, RoleEnum.Appaltatore, RoleEnum.CommissioningMng202409]) {
            if (LettersPolicy({
                securityContext,
                job,
                oldLetter: letter,
                signatureRole,
            }).permissions.sign && !letter?.signatures?.find?.(s => s.role === signatureRole)) {
                actions.push({
                    policy: 'LettersPolicy',
                    permission: 'sign',
                    signatureRole,
                })
            }
        }
        if (letter.type === 'EcoOdS') {
            for (let signatureRole of [RoleEnum.CDL, RoleEnum.SM, RoleEnum.PM]) {
                if (EcoOdsEstimatePolicy({
                    securityContext,
                    job,
                    oldLetter: letter,
                    estimateSignatureRole: signatureRole,
                }).permissions.sign && !letter?.estimate?.signatures?.find?.(s => s.role === signatureRole)) {
                    actions.push({
                        policy: 'EcoOdsEstimatePolicy',
                        permission: 'sign',
                        signatureRole,
                    })
                }
            }
        }
        return actions
    }, [securityContext, job, letter])
}

function exportLetters(letters: Letter[], job: Job, ecoOdsEstimatePolicy: ReturnType<typeof EcoOdsEstimatePolicy>, fileExt: string) {
    // NOTE: we may import type {BookType} from "xlsx/types"; but bookType is better derived from ext, not the other way around.
    try {
        const wb = XLSX.utils.book_new()
        const hh = ["n°", "OdS/NI", "Data", "Oggetto", "Firme", "Inviato",
            ...(ecoOdsEstimatePolicy.permissions.read ?
                ["Firme preventivo", "Preventivo", "Consuntivo"] : [])]
        const ws = XLSX.utils.aoa_to_sheet([
            hh,
            ...letters.map(letter => [
                letter.externalRef, letter.type, letter.date, letter.subject, letter.signatures.map(s => s.role).join(', '), letter.sent,
                ...(ecoOdsEstimatePolicy.permissions.read ?
                    [letter.type === 'EcoOdS' ? letter.estimate.signatures.map(s => s.role).join(', ') : '',
                        letter.type === 'EcoOdS' ? sumOrderItems(letter?.estimate?.items).toNumber() : undefined,
                        letter.type === 'EcoOdS' ? letter.expenses : undefined] : [])
            ])
        ]);
        // NOTE: Styling does not work. Inspired from https://www.npmjs.com/package/sheetjs-style
        //       That package does also other stuff to make it work under the hood.
        // hh.forEach((h, i) => {
        //     const cell = ws[XLSX.utils.encode_cell({r:0, c:i})]
        //     if (!cell) {
        //         return
        //     }
        //     cell.s = {
        //         fill: {
        //             bgColor: { rgb: '1763d500'},
        //         },
        //         font: {
        //             color: { rgb: 'FFFFFF00'},
        //             bold: true,
        //         },
        //     }
        // })
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `Elenco OdS_NI ${(job?.code ?? '').replace(/[^a-zA-Z0-9-]*/, '')}.${fileExt}`);
    } catch (e) {
        console.error(e)
    }
}
