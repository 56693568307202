import * as React from 'react'
import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Company} from "../../../fusina-jobs/entities/Company";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useSecurityContext} from "./SecurityContext";

export interface ICompanyContext {
    companyIds: string[]
    company: Company,
    companyError?: Error,

    loadCompany(id: string): Promise<void>
}

const CompanyContext = createContext<ICompanyContext>({
    companyIds: [],
    get company(): Company {
        throw new Error('CompanyContext was not provided')
    },
    loadCompany() {
        throw new Error('CompanyContext was not provided')
    }
})

/**
 * (Almost) every user has a role in some company.
 * Some users may have a role in multiple companies.
 * The list of companies is retrieved from the security context.
 * The first company is used as a default company context.
 * loadCompany allows to change the currently loaded company.
 */
export const CompanyContextStore: React.FC<PropsWithChildren<{}>> = props => {
    const sc = useSecurityContext()
    const rmi = useFusinaRmi()
    const [companyIds, setCompanyIds] = useState<string[]>([])
    const [company, setCompany] = useState<Company>(undefined)
    const [companyError, setCompanyError] = useState(undefined)

    const loadCompany = useCallback((id: string) => {
        return rmi.JobsList.findCompanyById(id)
            .then(setCompany)
            .then()
            .catch(setCompanyError)
    }, [rmi])

    useEffect(() => {
        if (!sc?.user?.grants) {
            return
        }

        // list companies for which the user has a role
        const companies = [...new Set(sc.user.grants.filter(g => g.scopeType === 'company').map(g => g.scopeId))]
        setCompanyIds(companies)

        // reset current company if needed
        if (companies?.length > 0 && (company === undefined || !companies.includes(company?._id))) {
            loadCompany(companies[0])
                .then()
        }
    }, [sc?.user?.grants])

    const ctx = useMemo<ICompanyContext>(() => ({
        companyIds,
        company,
        companyError,
        loadCompany,
    }), [companyIds, company, companyError, loadCompany])

    return <CompanyContext.Provider value={ctx}>
        {props.children}
    </CompanyContext.Provider>
}

export function useCompanyContext(): ICompanyContext {
    return useContext<ICompanyContext>(CompanyContext)
}
