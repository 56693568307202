import * as React from "react";
import {useEffect, useState} from "react";
import {EventBase} from "../../../../fusina-event/entities/EventBase";
import {CalContext, CalState} from "./CalContext";
import {MultiCalView} from "./MultiCalView";
import {CalViews} from "./commons";
import {Spinner, SpinnerSize, Stack} from "@fluentui/react";

export const Calendar: React.FC<{
    onDatesRange: (min: Date, max: Date) => void

    /** Give empty array to show empty calendar. Does not clear in case of undefined. */
    events?: EventBase[]

    /** Allow reactive navigation from parent */
    view?: CalViews | string
    /** Allow reactive navigation from parent */
    selectedDate?: Date | string
    /** The given event is rendered as open if compares equal */
    openEvent?: EventBase

    /** does not react on prop change */
    onEventOpen?: (ev: EventBase) => void
    eventContextLabelGetter?: (ev: EventBase) => (string | undefined)
    eventHrefGetter?: (ev: EventBase) => (string | undefined)
    eventSmallFragContextLabelGetter?: (ev: EventBase) => (string | undefined)

    /** Allow the parent to be notified of the internal selection change */
    onSelectedDate?: (date: Date) => void
}> = props => {

    const [state, setState] = useState<CalState>(() => ({
        events: props.events ?? [],
        view: CalViews.month,
        selectedDate: new Date(),
        onEventOpen: props.onEventOpen,
        eventContextLabelGetter: props.eventContextLabelGetter,
        eventSmallFragContextLabelGetter: props.eventSmallFragContextLabelGetter,
        eventHrefGetter: props.eventHrefGetter,
    }))

    useEffect(() => {
        if (state.minDate && state.maxDate) {
            props.onDatesRange(state.minDate, state.maxDate)
        }
    }, [state.minDate, state.maxDate])

    useEffect(() => {
        if (props.events) {
            setState(s => ({...s, events: props.events ?? []}))
        }
    }, [props.events])

    useEffect(() => {
        if (props.view && props.selectedDate && CalViews[props.view]) {
            setState(s => ({
                ...s,
                view: CalViews[props.view],
                selectedDate: new Date(props.selectedDate)
            }))
        }
    }, [props.view, props.selectedDate])

    useEffect(() => {
        if (props.openEvent && !state.openEvent) {
            setState(s => ({...s, openEvent: props.openEvent}))
        }
    }, [props.openEvent])

    useEffect(() => {
        props.onSelectedDate?.(state.selectedDate)
    }, [state.selectedDate])

    return <>
        <div style={{height: 0, overflow: "visible"}}>
            <Stack styles={{
                root: {
                    display: props.events === undefined ? 'block' : 'none',
                    position: 'relative',
                    top: 'calc(50vh - 120px)', // NOTE: 120 is the offsetTop of the parent
                    zIndex: 2,
                }
            }}>
                <Spinner
                    size={SpinnerSize.large}
                    label="Caricamento degli eventi in corso..."
                />
            </Stack>
        </div>
        <CalContext.Provider value={[state, setState]}>
            <MultiCalView/>
        </CalContext.Provider>
    </>
}
