import * as React from "react";
import {Dispatch, SetStateAction, useImperativeHandle, useMemo, useState} from "react";
import {ITPStats, ITPStatsRow} from "../../../fusina-itp/entities/ITPStats";
import {ITPEventStats, ITPEventStatsRow} from "../../../fusina-itp/entities/ITPEventStats";
import {ITPCertificatesStats} from "../../../fusina-itp/entities/ITPCertificatesStats";
import {getQCRCodeMeaning, getQCRCodeTitle, QCRCode} from "../../../fusina-itp/entities/QCRCode";
import {PresenceCode} from "../../../fusina-itp/entities/PresenceCode";
import {
    Callout,
    Checkbox,
    DialogType,
    DirectionalHint,
    Icon,
    IconButton,
    Link,
    mergeStyles,
    MessageBar,
    MessageBarType,
    SearchBox,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    Toggle,
    TooltipHost,
    useTheme
} from "@fluentui/react";
import {ITPEvent} from "../../../fusina-itp/entities/ITPEvent";
import './ITPStatsTable.css'
import {Link as RouterLink} from "react-router-dom";
import {SimpleDialog} from "./SimpleDialog";
import {getITPPartyLabel_it, ITPParty} from "../../../fusina-itp/entities/ITPParty";
import {ITPCertsView} from "./ITPCertsView";
import {ITPItemContext} from "../contexts/ITPItemContext";
import {ITPEventsView} from "./ITPEventsView";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {useJob} from "../hooks/useJob";
import {useId} from "@fluentui/react-hooks";
import * as XLSX from "xlsx/xlsx";
import {MicroSpinner} from "./MicroSpinner";


const ITP_EVENT_STATUSES: (ITPEvent['status'])[] = [
    'TENTATIVE',
    'CONFIRMED',
    'CANCELLED',
]
const ITP_EVENT_STATUS_ABBR: Record<ITPEvent['status'], string> = {
    'TENTATIVE': 'T',
    'CONFIRMED': 'C',
    'CANCELLED': 'Z',
}

interface StatRowSelection {
    itpId: string
    jobId: string
}

interface ItemOpenState {
    item: string
    what: 'cert' | 'events'
}

interface ProgressStats {
    expected: number
    missing: number
    has_expected: number
    has_missing: number
    prop_missing: number
}

const makeProgressStats: () => ProgressStats = () => ({
    expected: 0,
    missing: 0,
    has_expected: 0,
    has_missing: 0,
    prop_missing: 0,
})

const addProgressStats: (dst: ProgressStats, src: ProgressStats) => void = (dst, src) => {
    dst.expected += src.expected
    dst.missing += src.missing
    dst.has_expected += src.has_expected
    dst.has_missing += src.has_missing
    dst.prop_missing += src.prop_missing
}

const truncate = (str, n) => (str.length > n) ? <>{str.slice(0, n - 1)}&hellip;</> : str;

export interface ITPStatsTableImperativeHandle {
    doExport: () => Promise<void>
}

export const ITPStatsTable: React.FC<{
    itpsStatsStruct: ITPStats
    itpEventsStatsStruct?: ITPEventStats
    itpCertsStatsStruct?: ITPCertificatesStats
    showDetails?: boolean
    setShowDetails?: Dispatch<SetStateAction<boolean>>
    innerRef?: React.Ref<ITPStatsTableImperativeHandle>
}> = props => {

    const {
        itpsStatsStruct: itpsStatsStruct_full,
        itpEventsStatsStruct,
        itpCertsStatsStruct,
        showDetails,
        setShowDetails
    } = props

    const [itpStatsFilter, setItpStatsFilter] = useState<Map<string, boolean>>(() => new Map())
    const hasFilters = itpStatsFilter.size > 0
    const itpsStatsStruct = useMemo<ITPStats>(() => {
        const filtered = JSON.parse(JSON.stringify(itpsStatsStruct_full)) as ITPStats
        for (let jobId in filtered.jobs) {
            if (itpStatsFilter.get(jobId) === false) {
                delete filtered.jobs[jobId]
            } else {
                for (let itpId in filtered.jobs[jobId].itps) {
                    if (itpStatsFilter.get(itpId) === false) {
                        delete filtered.jobs[jobId].itps[itpId]
                    }
                }
            }
        }
        return filtered
    }, [itpsStatsStruct_full, itpStatsFilter])

    const progressStatsMap = useMemo<Map<string, ProgressStats>>(() => {
        // task#2003: expected number of certs
        const map = new Map<string, ProgressStats>()
        if (itpsStatsStruct === undefined || itpEventsStatsStruct === undefined || itpCertsStatsStruct === undefined) {
            return map
        }
        const all_total = makeProgressStats()
        for (let jobId in itpsStatsStruct.jobs) {
            const job_total = makeProgressStats()
            for (let itpId in itpsStatsStruct.jobs[jobId].itps) {
                const itpStat = itpsStatsStruct.jobs[jobId].itps[itpId]
                const evStat = itpEventsStatsStruct?.jobs?.[jobId]?.itps?.[itpId]
                const certStat = itpCertsStatsStruct?.jobs?.[jobId]?.itps?.[itpId]

                const itp_total = makeProgressStats()
                const s_items = new Set(itpStat.s_items)
                itpStat.items.forEach(item => {
                    // certificati attesi = numero fasi con certificato (non S) * (notifiche confermate || 1)
                    if (!s_items.has(item)) {
                        const n_confirmed = evStat?.items_confirmed?.[item]
                        const n_expected = (n_confirmed || 1)
                        const n_certs = certStat?.items?.[item] ?? 0
                        const n_missing = n_certs >= n_expected ? 0 : n_expected - n_certs

                        addProgressStats(itp_total, {
                            expected: n_expected,
                            missing: n_missing,
                            has_expected: 1,
                            has_missing: n_missing > 0 ? 1 : 0,
                            prop_missing: n_missing / n_expected,
                        })
                        // NOTE: equivalent numbers:
                        //    progress of the phase = n_certs / n_expected = 1 - n_missing / n_expected = 1 - prop_missing / has_expected
                        //    progress of the itp   = average of progress of the phases = 1 - (sum prop_missing) / (sum has_expected)
                        //    progress of the job   = average of progress of the itps   = 1 - (sum prop_missing) / (sum has_expected)
                        //    progress overall      = average of progress of the jobs   = 1 - (sum prop_missing) / (sum has_expected)
                    }
                })
                map.set(itpId, itp_total)
                addProgressStats(job_total, itp_total)
            }
            map.set(jobId, job_total)
            addProgressStats(all_total, job_total)
        }
        map.set('all', all_total)
        return map
    }, [itpsStatsStruct, itpEventsStatsStruct, itpCertsStatsStruct])

    const itemStatsColNum = showDetails
        ? (1 + Object.keys(QCRCode).length + Object.keys(PresenceCode).length)
        : 1

    const [selected, setSelected] = useState<undefined | StatRowSelection>(undefined)
    const [itemOpen, setItemOpen] = useState<undefined | ItemOpenState>(undefined)

    const {
        data: selectedItp_list,
        error: selectedItpError,
    } = useFusinaRmiResource('ITP', 'find', selected?.itpId ? {by: 'itp', itp: {_id: selected?.itpId}} : undefined)
    const selectedItp = useMemo(() =>
        selectedItp_list?.find?.(itp => itp._id === selected?.itpId), [selected?.itpId, selectedItp_list])

    // NOTE: problems with the above access:
    //       - policy may allow stats but no read of unsigned PCQ. => setSelected is called only for signed ITPs
    //       - displaying the full-blown ITPSingleTable requires setting the JobContext and some callbacks.
    //       - it's way easies to just put a link to the full page.
    //       - users still need to have a glimpse of the item description, so we do access the ITP.

    const theme = useTheme()
    const allOfXCellStyle = mergeStyles({
        color: theme.semanticColors.disabledBodyText,
        fontSize: theme.fonts.small.fontSize,
    })
    const itpTitleStyle = mergeStyles({
        fontSize: theme.fonts.small.fontSize,
        whiteSpace: 'nowrap',
    })
    const itpRevNStyle = mergeStyles({
        fontSize: theme.fonts.small.fontSize,
        whiteSpace: 'nowrap',
        color: theme.semanticColors.disabledBodyText,
    })

    // const jobFilterButtonId = useId('callout-jobs-filter-button');
    const itpFilterButtonId = useId('callout-itps-filter-button');
    // const [isJobFilterOpen, setIsJobFilterOpen] = useState<boolean>(false)
    const [isItpFilterOpen, setIsItpFilterOpen] = useState<boolean>(false)
    // const [jobFilterSearch, setjobFilterSearch] = useState<string>('')
    const [itpFilterSearch, setItpFilterSearch] = useState<string>('')

    useImperativeHandle(props.innerRef, () => ({
        // NOTE: I'm using useImperativeHandle to minimize the amount of code changes needed to introduce exportITPStats
        async doExport() {
            await exportITPStats(itpsStatsStruct, itpEventsStatsStruct, itpCertsStatsStruct, progressStatsMap, 'xlsx');
        }
    }), [itpsStatsStruct, itpEventsStatsStruct, itpCertsStatsStruct, progressStatsMap])

    return <>
        <table className="ITPStatsTable itp_stats_table_max_vw_fix itp_stats_allow_user_selection">
            <colgroup>
                <col style={{
                    // background: theme.palette.themeLighterAlt,
                }}/>
                <col style={{
                    background: theme.palette.themeLighterAlt,
                }}/>
                <col span={itemStatsColNum} style={{
                    // borderLeft: '1px solid ' + theme.palette.themePrimary,
                    // borderRight: '1px solid ' + theme.palette.themePrimary,
                    // background: theme.palette.themeLighterAlt,
                }}/>
                <col span={ITP_EVENT_STATUSES.length} style={{
                    background: theme.palette.themeLighterAlt,
                }}/>
                <col style={{
                    // background: theme.palette.themeLighterAlt,
                }}/>
            </colgroup>
            <thead>
            {/* jobId, itpId, item, QCR..., Presence..., eventStatus..., certs. */}
            <tr>
                <td rowSpan={3}>Commessa</td>
                <td rowSpan={3} className="al">
                    PCQ
                    <div style={{float: 'right'}}>
                        <IconButton
                            iconProps={{
                                iconName: hasFilters ? 'FilterSolid' : 'Filter',
                            }}
                            id={itpFilterButtonId}
                            onClick={() => setIsItpFilterOpen(v => !v)}
                        />
                        <Callout
                            hidden={!isItpFilterOpen}
                            role='dialog'
                            target={`#${itpFilterButtonId}`}
                            onDismiss={() => setIsItpFilterOpen(false)}
                            setInitialFocus
                            directionalHint={DirectionalHint.bottomRightEdge}
                            style={{padding: '20px 12px'}}
                        >
                            <SearchBox
                                value={itpFilterSearch}
                                onChange={(event, newValue) => {
                                    setItpFilterSearch(newValue)
                                }}
                            />
                            <div style={{margin: '12px 0px 2px'}}>
                                <Stack horizontal tokens={{childrenGap: 'm'}} verticalAlign={"baseline"}>
                                    <Stack.Item><small>Tutte le commesse</small></Stack.Item>
                                    <Stack.Item grow={1}>&nbsp;</Stack.Item>
                                    <small>
                                        <Link onClick={() => {
                                            for (let jobId in itpsStatsStruct_full.jobs) {
                                                for (let itpId in itpsStatsStruct_full.jobs[jobId].itps) {
                                                    itpStatsFilter.delete(itpId)
                                                }
                                            }
                                            setItpStatsFilter(new Map(itpStatsFilter))
                                        }}>
                                            Seleziona tutto
                                        </Link>
                                    </small>
                                    <small>
                                        <Link onClick={() => {
                                            for (let jobId in itpsStatsStruct_full.jobs) {
                                                for (let itpId in itpsStatsStruct_full.jobs[jobId].itps) {
                                                    itpStatsFilter.set(itpId, false)
                                                }
                                            }
                                            setItpStatsFilter(new Map(itpStatsFilter))
                                        }}>
                                            Nascondi tutto
                                        </Link>
                                    </small>
                                </Stack>
                            </div>
                            {Object.entries(itpsStatsStruct_full.jobs)
                                .filter(([jobId, jobStruct]) =>
                                    itpStatsFilter.get(jobId) !== false)
                                .map(([jobId, jobStruct]) => <>
                                    <div style={{margin: '12px 0px 2px'}}>
                                        <Stack horizontal tokens={{childrenGap: 'm'}} verticalAlign={"baseline"}>
                                            <Stack.Item>{jobStruct.code}</Stack.Item>
                                            <Stack.Item grow={1}>&nbsp;</Stack.Item>
                                            <small>
                                                <Link onClick={() => {
                                                    for (let itpId in jobStruct.itps) {
                                                        itpStatsFilter.delete(itpId)
                                                    }
                                                    setItpStatsFilter(new Map(itpStatsFilter))
                                                }}>
                                                    Seleziona tutto
                                                </Link>
                                            </small>
                                            <small>
                                                <Link onClick={() => {
                                                    for (let itpId in jobStruct.itps) {
                                                        itpStatsFilter.set(itpId, false)
                                                    }
                                                    setItpStatsFilter(new Map(itpStatsFilter))
                                                }}>
                                                    Nascondi tutto
                                                </Link>
                                            </small>
                                        </Stack>
                                    </div>
                                    {Object.entries(jobStruct.itps)
                                        .filter(([_, itpStruct]) => {
                                            return itpStruct.docN.toLowerCase().includes(itpFilterSearch.toLowerCase())
                                        })
                                        .map(([itpId, itpStruct]) => <>
                                            <Checkbox
                                                styles={{
                                                    root: {
                                                        margin: '4px 12px'
                                                    },
                                                    checkbox: {
                                                        transform: "scale(0.7)",
                                                    }
                                                }}
                                                //indeterminate={!itpStatsFilter.has(itpId)}
                                                checked={itpStatsFilter.get(itpId) !== false}
                                                onChange={(ev, checked) => {
                                                    if (checked) {
                                                        itpStatsFilter.delete(itpId)
                                                    } else {
                                                        itpStatsFilter.set(itpId, false)
                                                    }
                                                    setItpStatsFilter(new Map(itpStatsFilter))
                                                }}
                                                onRenderLabel={() => <>
                                                    {itpStruct.docN}
                                                    <span
                                                        className={itpTitleStyle}>&nbsp;{truncate(itpStruct.title, 30)}</span>
                                                </>}
                                            />
                                        </>)}
                                </>)}
                        </Callout>
                    </div>
                </td>
                <td colSpan={itemStatsColNum} rowSpan={showDetails ? 1 : 3}>
                    <Toggle
                        inlineLabel={showDetails}
                        label="Fasi" onText="suddivise" offText="Totali"
                        checked={showDetails}
                        onChange={((event, checked) => setShowDetails(checked))}
                        styles={{
                            root: {
                                justifyContent: 'center',
                            },
                            container: {
                                justifyContent: 'center',
                            },
                            label: {
                                padding: 0,
                            },
                        }}
                    />
                </td>
                <td rowSpan={2} colSpan={ITP_EVENT_STATUSES.length}>Notifiche</td>
                <td rowSpan={2} colSpan={3}>Certificati</td>
            </tr>
            <tr>
                {showDetails && <>
                    <td colSpan={Object.keys(QCRCode).length}><small>QCR</small></td>
                    <td colSpan={Object.keys(PresenceCode).length}><small>Committente</small></td>
                    <td rowSpan={2}><b>Totale</b></td>
                </>}
            </tr>
            <tr>
                {showDetails && <>
                    {Object.keys(QCRCode).map(c =>
                        <td><abbr title={getQCRCodeTitle(QCRCode[c]) + ' - ' + getQCRCodeMeaning(QCRCode[c])}>
                            {c}
                        </abbr></td>)}
                    {Object.keys(PresenceCode).map(c =>
                        <td>{c}</td>)}
                </>}
                {ITP_EVENT_STATUSES.map(c =>
                    <td><abbr title={c}>{ITP_EVENT_STATUS_ABBR[c]}</abbr></td>)}
                <td><small>Inseriti</small></td>
                <td><small>Attesi</small></td>
                <td><small>Progresso</small></td>
            </tr>
            </thead>


            {!hasFilters && <tbody>
            <tr>
                <td className={allOfXCellStyle}>Tutte le commesse</td>
                <td className={allOfXCellStyle + ' al'}>Tutti i PCQ</td>
                <ITPStatsRowFrag stats={itpsStatsStruct.stats} showDetails={showDetails}/>
                <ITPEventStatsRowFrag dep={itpEventsStatsStruct} stats={itpEventsStatsStruct?.stats}/>
                <td>{itpCertsStatsStruct && <NumSpan num={itpCertsStatsStruct?.count}/>}</td>
                <ITPExpectedCertsRowFrag
                    progressStatsMap={progressStatsMap}
                    actualCertsCount={itpCertsStatsStruct?.count}
                />
            </tr>
            </tbody>}

            {Object.entries(itpsStatsStruct.jobs).map(([jobId, jobStruct]) => <>
                <tbody>

                {!hasFilters && Object.keys(jobStruct.itps).length > 1 &&
                <tr>
                    <td>{jobStruct.code}</td>
                    <td className={allOfXCellStyle + ' al'}>Tutti i PCQ</td>
                    <ITPStatsRowFrag stats={jobStruct.stats} showDetails={showDetails}/>
                    <ITPEventStatsRowFrag dep={itpEventsStatsStruct}
                                          stats={itpEventsStatsStruct?.jobs?.[jobId]?.stats}/>
                    <td>{itpCertsStatsStruct && <NumSpan num={itpCertsStatsStruct?.jobs?.[jobId]?.count}/>}</td>
                    <ITPExpectedCertsRowFrag
                        progressStatsMap={progressStatsMap}
                        jobId={jobId}
                        actualCertsCount={itpCertsStatsStruct?.jobs?.[jobId]?.count}
                    />
                </tr>}

                {Object.entries(jobStruct.itps).map(([itpId, itpStruct]) => <>
                    <tr onClick={itpStruct.signatures >= 1 ? () => setSelected({jobId, itpId}) : undefined}>
                        <td>{jobStruct.code}</td>
                        <td rowSpan={/* itpStruct.stats.count + */ 1} className="al itp_stats_title_max_vw_fix">
                            {itpStruct.signatures >= 1 ? <>
                                <Link to={`/jobs/${jobId}/itp/${itpId}`} as={RouterLink}>
                                    {itpStruct.docN} {/*itpStruct.revN*/}
                                    {itpStruct.revN &&
                                    <span className={itpRevNStyle}>&nbsp;rev. {itpStruct.revN}&nbsp;&nbsp;</span>}
                                </Link>
                                <span className={itpTitleStyle}>&nbsp;{itpStruct.title}</span>
                            </> : <>
                                {itpStruct.docN} {/*itpStruct.revN*/}
                                <Text variant="xSmall" style={{
                                    color: theme.semanticColors.severeWarningIcon
                                }}> [Bozza]</Text>
                            </>}
                        </td>
                        <ITPStatsRowFrag stats={itpStruct.stats} showDetails={showDetails}/>
                        <ITPEventStatsRowFrag dep={itpEventsStatsStruct}
                                              stats={itpEventsStatsStruct?.jobs?.[jobId]?.itps?.[itpId]?.stats}/>
                        <td>{itpCertsStatsStruct &&
                        <NumSpan num={itpCertsStatsStruct?.jobs?.[jobId]?.itps?.[itpId]?.count}/>}</td>
                        <ITPExpectedCertsRowFrag
                            progressStatsMap={progressStatsMap}
                            jobId={jobId}
                            itpId={itpId}
                            actualCertsCount={itpCertsStatsStruct?.jobs?.[jobId]?.itps?.[itpId]?.count}
                        />
                    </tr>

                    {/*{Object.entries(itpStruct.items).map(([item, itemStruct]) => <>*/}
                    {/*    <tr style={{fontSize: '0.8em'}}>*/}
                    {/*        <ITPStatsRowFrag stats={itemStruct.stats}/>*/}
                    {/*    </tr>*/}
                    {/*</>)}*/}
                </>)}
                </tbody>
            </>)}
        </table>

        <SimpleDialog
            veryEasyDismiss
            type={DialogType.close}
            title={selected ? `${itpsStatsStruct?.jobs?.[selected?.jobId]?.itps?.[selected?.itpId]?.docN}` : ''}
            subText={null}//selected ? `${itpsStatsStruct?.jobs?.[selected?.jobId]?.itps?.[selected?.itpId]?.title ?? ''}` : ''}
            onDismiss={() => setSelected(undefined)}
            hidden={selected === undefined}
            isBlocking={false}
        >
            <Stack horizontal tokens={{childrenGap: 'm'}}>
                <Stack.Item grow>
                    <Text block style={{color: theme.semanticColors.bodySubtext, maxWidth: 400}}>
                        {itpsStatsStruct?.jobs?.[selected?.jobId]?.itps?.[selected?.itpId]?.title}
                    </Text>
                </Stack.Item>
                <Link to={`/jobs/${selected?.jobId}/itp/${selected?.itpId}`} as={RouterLink} target="_blank">
                    Apri
                    &nbsp;<Icon iconName="OpenInNewWindow"/>
                </Link>
            </Stack>
            <MaybeErrorMessageBar error={selectedItpError}/>
            <br/>
            <table className="ITPStatsTable">
                <thead>
                <tr>
                    <td style={{textAlign: 'left', width: 75}}>
                        Fase
                    </td>
                    <td style={{textAlign: 'center', width: 200}}>
                        {selectedItp === undefined ? <MicroSpinner/> : <>Descrizione del controllo</>}
                    </td>
                    <td>
                        Notifiche<br/>
                        {itpEventsStatsStruct === undefined && <Spinner size={SpinnerSize.xSmall}/>}
                    </td>
                    <td>
                        Certificati<br/>
                        {itpCertsStatsStruct === undefined && <Spinner size={SpinnerSize.xSmall}/>}
                    </td>
                </tr>
                </thead>
                <tbody>
                {itpsStatsStruct?.jobs?.[selected?.jobId]?.itps?.[selected?.itpId]?.items?.map?.(item => <>
                    <tr>
                        <td style={{width: 4 + 75 + 4 + 4 + 200 + 4, visibility: 'visible', padding: '0 0 0 0'}}
                            colSpan={2}>
                            <TooltipHost
                                content={selectedItp?.items?.filter?.(it => it.item === item)?.map?.(it => <div
                                    key={it.item} style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'auto auto',
                                    columnGap: '1ch',
                                    rowGap: '6px'
                                }}>
                                    <b>Fase/ITEM:</b>
                                    <Text block style={{
                                        textAlign: 'left',
                                        color: theme.semanticColors.bodyText,
                                        maxWidth: 400
                                    }}>{it.item}&nbsp;</Text>
                                    <b>Componente:</b>
                                    <Text block variant="xSmall" style={{
                                        textAlign: 'left',
                                        color: theme.semanticColors.bodySubtext,
                                        maxWidth: 400
                                    }}>{it.comp}</Text>
                                    <b>Descrizione<br/>del controllo:</b>
                                    <Text block variant="xSmall" style={{
                                        textAlign: 'left',
                                        color: theme.semanticColors.bodySubtext,
                                        maxWidth: 400
                                    }}>{it.desc}</Text>
                                    <b>Documenti<br/>applicabili:</b>
                                    <Text block variant="xSmall" style={{
                                        textAlign: 'left',
                                        color: theme.semanticColors.bodySubtext,
                                        maxWidth: 400,
                                        whiteSpace: 'pre-wrap',
                                    }}>{it.refDocs}</Text>
                                    <b>QCR:</b>
                                    <Text block variant="xSmall" style={{
                                        textAlign: 'left',
                                        color: theme.semanticColors.bodySubtext,
                                        maxWidth: 400
                                    }}>
                                        <Text variant="smallPlus" style={{color: theme.semanticColors.bodySubtext}}>
                                            {it.recordsQCR} -&nbsp;
                                        </Text>
                                        {getQCRCodeMeaning(it.recordsQCR)}
                                    </Text>
                                    <span>Presenze:</span><span>&nbsp;</span>
                                    {Object.values(ITPParty).filter(party => !!it.presences[party]).map(party =>
                                        <React.Fragment key={party}>
                                            <b>{getITPPartyLabel_it(party)}:</b>
                                            <Text block variant="smallPlus" style={{
                                                textAlign: 'left',
                                                color: theme.semanticColors.bodySubtext,
                                                maxWidth: 400
                                            }}>
                                                {it?.presences?.[party]}
                                                {/*<Text block variant="xSmall">*/}
                                                {/*    {getPresenceCodeActivity_it(it?.presences?.[party])}*/}
                                                {/*</Text>*/}
                                            </Text>
                                        </React.Fragment>)}
                                    <b>Note:</b>
                                    <Text block variant="xSmall" style={{
                                        textAlign: 'left',
                                        color: theme.semanticColors.bodySubtext,
                                        maxWidth: 400,
                                        whiteSpace: 'pre-wrap',
                                    }}>{it.notes}</Text>
                                    {/*
                                    <hr /><hr />
                                    <b>Notifiche:</b>
                                    <Text block style={{textAlign: 'left'}}>
                                        {itpEventsStatsStruct && <NumSpan
                                            num={itpEventsStatsStruct?.jobs?.[selected?.jobId]?.itps?.[selected?.itpId]?.items?.[item]}
                                        />}
                                    </Text>
                                    <b>Certificati:</b>
                                    <Text block style={{textAlign: 'left'}}>
                                        {itpCertsStatsStruct && <NumSpan
                                            num={itpCertsStatsStruct?.jobs?.[selected?.jobId]?.itps?.[selected?.itpId]?.items?.[item]}
                                        />}
                                    </Text>
                                    */}
                                </div>)}
                                delay={10}
                                closeDelay={400}
                                calloutProps={{gapSpace: 0, beakWidth: 8}}  // coverTarget: true
                                // directionalHint={DirectionalHint.topRightEdge}
                                directionalHint={DirectionalHint.leftTopEdge}
                            >
                                <Stack horizontal tokens={{childrenGap: 'none'}}>
                                    <Text block style={{
                                        padding: 4,
                                        width: 75,
                                        wordWrap: 'break-word',
                                        wordBreak: 'break-all',
                                        textAlign: 'left'
                                    }}>
                                        {item}
                                    </Text>
                                    <Text block nowrap variant="smallPlus" style={{
                                        textAlign: 'left',
                                        color: theme.semanticColors.bodySubtext,
                                        maxWidth: 200,
                                        padding: 4,
                                    }}>
                                        {selectedItp?.items?.find?.(it => it.item === item)?.desc}
                                    </Text>
                                </Stack>
                            </TooltipHost>
                        </td>
                        <td onClick={() => {
                            setItemOpen({item, what: 'events'})
                        }} style={{cursor: "pointer"}}>
                            {itpEventsStatsStruct && <NumSpan
                                num={itpEventsStatsStruct?.jobs?.[selected?.jobId]?.itps?.[selected?.itpId]?.items?.[item]}
                            />}
                        </td>
                        <td onClick={() => {
                            setItemOpen({item, what: 'cert'})
                        }} style={{cursor: "pointer"}}>
                            {itpCertsStatsStruct && <NumSpan
                                num={itpCertsStatsStruct?.jobs?.[selected?.jobId]?.itps?.[selected?.itpId]?.items?.[item]}
                            />}
                        </td>
                    </tr>
                </>)}
                </tbody>
            </table>
            {selected && itemOpen &&
            <ItemOpenDialog selected={selected} itemOpen={itemOpen} onDismiss={() => setItemOpen(undefined)}/>}
            {/*<MaybeErrorMessageBar error={selectedItpError}/>*/}
            {/*{selectedItp && <ITPSingleTable*/}
            {/*    itp={selectedItp}*/}
            {/*    onEditRequested={undefined}*/}
            {/*/>}*/}
            {/*{selectedItpId && !selectedItp && !selectedItpError && <MessageBar messageBarType={MessageBarType.error}>*/}
            {/*    PCQ non trovato!*/}
            {/*</MessageBar>}*/}
        </SimpleDialog>
    </>
}


const NumSpan: React.FC<{
    num?: number
}> = props => {
    const theme = useTheme()
    const style = mergeStyles({
        color: theme.semanticColors.disabledBodyText,
        // fontFamily: 'monospace',
        fontSize: theme.fonts.small.fontSize,
    })
    return props.num
        ? <>{props.num}</>
        : <span className={style}>
            &empty;
        </span>
}

const ITPStatsRowFrag: React.FC<{
    stats: ITPStatsRow
    showDetails?: boolean
}> = props => {
    return <>
        {props.showDetails && <>
            {Object.keys(QCRCode).map(c => <td>
                <NumSpan num={props.stats.qcrCounts[c]}/></td>)}
            {Object.keys(PresenceCode).map(c => <td>
                <NumSpan num={props.stats.presencesCounts?.[ITPParty.cl]?.[c]}/></td>)}
        </>}
        <td><NumSpan num={props.stats.count}/></td>
    </>
}

const ITPEventStatsRowFrag: React.FC<{
    dep?: any
    stats?: ITPEventStatsRow
}> = props => {
    if (!props.dep) {
        // NOTE: Let's not render zeros if the dependency is missing
        return <td colSpan={ITP_EVENT_STATUSES.length}>&nbsp;</td>
    }
    // NOTE: Let's render zeros if the dependency is given, but there's no stats (no events for the given job/itp)
    return <>
        {ITP_EVENT_STATUSES.map(s => <td>
            <NumSpan num={props.stats?.statusCounts?.[s]}/></td>)}
    </>
}

export const ITPExpectedCertsRowFrag: React.FC<{
    jobId?: string
    itpId?: string
    progressStatsMap: Map<string, ProgressStats>
    actualCertsCount?: number
}> = props => {
    // NOTE: task#2003
    const theme = useTheme()
    const key = props.itpId ?? props.jobId ?? 'all'
    const progressStats = props.progressStatsMap.get(key) ?? makeProgressStats()

    const completion = progressStats.has_expected == 0 ? undefined :
        Math.min(Math.max(1. - progressStats.prop_missing / progressStats.has_expected, 0), 1)
    // duplicate code 6fc206c1-2e83-4869-a191-33888b6c3019

    return <>
        <td style={{position: "relative", zIndex: 0}}>
            {progressStats.expected > 0 ? <NumSpan num={progressStats.expected}/> : ''}
        </td>
        <td style={{position: "relative", zIndex: 0}}>
            {props.actualCertsCount !== undefined && <div role="presentation" style={{
                userSelect: 'none',
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                width: completion === undefined ? 0 : `${Math.round(completion * 100)}%`,
                background: theme.palette.themeLight,
                overflow: 'hidden',
                zIndex: -1,
            }}>
                &nbsp;
            </div>}
            {completion === undefined ? '' : <>{Math.round(completion * 100)}%</>}
        </td>
    </>
}

export const ItemOpenDialog: React.FC<{
    selected: StatRowSelection
    itemOpen: ItemOpenState
    onDismiss: () => void
}> = props => {

    const {
        data: itps,
        error,
    } = useFusinaRmiResource('ITP', 'find', {by: 'itp', itp: {_id: props.selected.itpId}})

    const itp = itps?.[0]
    const item = itp?.items?.find?.(item => item.item === props.itemOpen.item)
    const {job, jobError} = useJob(itp?.job?._id)

    if (!item || !itp || !job) {
        return <SimpleDialog
            veryEasyDismiss
            type={DialogType.close}
            title=""
            subText={null}
            onDismiss={props.onDismiss}
            hidden={false}
            isBlocking={false}
        >
            {(error || jobError) && <MaybeErrorMessageBar error={error || jobError}/> || (
                itps !== undefined && item === undefined && <MessageBar messageBarType={MessageBarType.warning}>
                    Item non trovato!
                </MessageBar> || <Spinner size={SpinnerSize.large}/>)}
        </SimpleDialog>
    }

    if (props.itemOpen.what === 'cert') {
        return <SimpleDialog
            title={`${itp.docN} fase ${item.item} `}
            subText={getQCRCodeMeaning(item.recordsQCR)}
            type={DialogType.close}
            isBlocking={false}
            onDismiss={props.onDismiss}
        >
            <ITPCertsView itp={itp} item={item}/>
        </SimpleDialog>
    }

    if (props.itemOpen.what === 'events') {
        return <SimpleDialog
            title={`Notifiche per ${itp.docN} fase ${item.item} `}
            subText={''}
            type={DialogType.close}
            isBlocking={false}
            onDismiss={props.onDismiss}
        >
            {/* NOTE: ITPItemContext: each event shall be shown with its historical revision */}
            <ITPItemContext.Provider value={{itp, item}}>
                <ITPEventsView/>
            </ITPItemContext.Provider>
        </SimpleDialog>
    }

    return null
}


async function exportITPStats(
    itpsStatsStruct: ITPStats,
    itpEventsStatsStruct: ITPEventStats,
    itpCertsStatsStruct: ITPCertificatesStats,
    progressStatsMap: Map<string, ProgressStats>,
    fileExt: 'xlsx' | 'csv'
) {
    const wb = XLSX.utils.book_new()
    const hh = ["Commessa", "PCQ Doc.N", "Rev.N", "Titolo", "Fasi", "Notifiche", "Certificati insertiti", "attesi", "Progresso"]

    const nl2s: (str: string) => string = fileExt !== 'csv' ? s => s : s =>
        s.replace(/[\n]/g, ' ')

    const ws = XLSX.utils.aoa_to_sheet([
        hh,
        ...Object.entries(itpsStatsStruct.jobs).flatMap(([jobId, jobStruct]) => {
            if (!jobStruct?.itps) {
                return []
            }
            return Object.entries(jobStruct.itps).map(([itpId, itpStruct]) => {
                if (!itpStruct) {
                    return []
                }
                const progressStats = progressStatsMap.get(itpId)

                const completion = progressStats.has_expected == 0 ? undefined :
                    Math.min(Math.max(1. - progressStats.prop_missing / progressStats.has_expected, 0), 1)
                // duplicate code 6fc206c1-2e83-4869-a191-33888b6c3019

                // The following is the row in the output excel.
                return [
                    jobStruct.code,
                    itpStruct.docN,
                    itpStruct.revN,
                    nl2s(itpStruct.title),
                    itpStruct.stats?.count ?? 0,
                    itpEventsStatsStruct?.jobs?.[jobId]?.itps?.[itpId]?.stats?.count ?? 0,
                    itpCertsStatsStruct?.jobs?.[jobId]?.itps?.[itpId]?.count ?? 0,
                    progressStats.expected,
                    completion === undefined ? '' : `${Math.round(completion * 100)}%`,
                ]
            })
        })
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, `Statistiche PCQ.${fileExt}`);
    // NOTE: the above function generates a download in the browser.
}
