import * as React from 'react'
import {useContext, useMemo, useState} from 'react'
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {ITPItem} from "../../../fusina-itp/entities/ITPItem";
import {Dropdown, IDropdownOption, Label, Stack, TextField, useTheme, VerticalDivider,} from '@fluentui/react';
import {
    ITP_REQUIRED_PARTIES,
    OptionalPresenceCodeDropdownOptions,
    PresenceCode,
    PresenceCodeDropdownOptions
} from "../../../fusina-itp/entities/PresenceCode";
import {getQCRCodeMeaning, QCRCode} from "../../../fusina-itp/entities/QCRCode";
import {useForceUpdate} from "@fluentui/react-hooks";
import {getITPPartyLabel_it, ITPParty} from "../../../fusina-itp/entities/ITPParty";
import {ITP_CODE_CLEANER} from "../../../fusina-itp/controls/ITPValidator";
import {ITPRevContext} from "../contexts/ITPRevContext";

export const ITPItemEditor: React.FC<{
    itp: InspectionTestPlan
    item: ITPItem
    onChange?: () => void
}> = props => {
    const forceUpdate = useForceUpdate()

    const revCtx = useContext(ITPRevContext)
    const isPreExisting = useMemo<boolean>(() =>
        !!revCtx?.prevItp?.items?.find?.(it => it.item === props.item.item), [revCtx?.prevItp, props.item])
    const prevItpItems = useMemo<Set<ITPItem['item']>>(() =>
        new Set(revCtx?.prevItp?.items?.map?.(item => item.item)), [revCtx?.prevItp])

    const [editingItemItem, setEditingItemItem] = useState<string>(props.item.item)

    const theme = useTheme()

    return <>
        <Stack horizontal wrap tokens={{childrenGap: 'l1'}}>
            <Stack style={{minWidth: 290}}>
                <TextField
                    label={"Fase"}
                    value={editingItemItem}
                    onChange={isPreExisting ? () => {
                    } : (event, newValue) => {
                        const val = ITP_CODE_CLEANER(newValue)
                        setEditingItemItem(val)
                        if (prevItpItems.has(val)) {
                            props.item.item = ''
                            return
                        }
                        props.item.item = val
                        forceUpdate()
                        props.onChange?.()
                    }}
                    onGetErrorMessage={isPreExisting ? () => '' : value => {
                        const matches = props.itp.items.filter(item => item.item === value)
                        if (matches.length > 1 || prevItpItems.has(value)) {
                            return 'Valore già usato'
                        }
                    }}
                    minLength={1}
                    maxLength={64}
                    readOnly={isPreExisting}
                    disabled={isPreExisting}
                    onRenderLabel={isPreExisting ? (props, DefaultRender) => <Stack horizontal verticalAlign="center">
                        <DefaultRender {...props}/>
                        <Stack.Item grow={1}>&nbsp;</Stack.Item>
                        <small>riferimento non modificabile</small>
                    </Stack> : undefined}
                />

                <TextField
                    label={"Componente"}
                    value={props.item.comp}
                    onChange={(event, newValue) => {
                        props.item.comp = newValue
                        forceUpdate()
                        props.onChange?.()
                    }}
                />

                <TextField
                    label={"Descrizione del controllo"}
                    value={props.item.desc}
                    multiline
                    autoAdjustHeight
                    onChange={(event, newValue) => {
                        props.item.desc = newValue
                        forceUpdate()
                        props.onChange?.()
                    }}
                />

                <TextField
                    label={"Documenti applicabili"}
                    value={props.item.refDocs}
                    multiline
                    autoAdjustHeight
                    onChange={(event, newValue) => {
                        props.item.refDocs = newValue
                        forceUpdate()
                        props.onChange?.()
                    }}
                />

                <TextField
                    label={"Note"}
                    value={props.item.notes}
                    multiline
                    autoAdjustHeight
                    onChange={(event, newValue) => {
                        props.item.notes = newValue
                        forceUpdate()
                        props.onChange?.()
                    }}
                />

            </Stack>

            <VerticalDivider/>

            <Stack>
                <QCRCodeDropdown
                    value={props.item.recordsQCR}
                    onChange={newValue => {
                        props.item.recordsQCR = newValue
                        forceUpdate()
                        props.onChange?.()
                    }}
                />

                <Label style={{color: theme.semanticColors.bodySubtext, transform: 'translateY(8px)'}}>
                    Presenza
                </Label>

                {Object.values(ITPParty).map(party =>
                    <PresenceCodeDropdown
                        optional={!ITP_REQUIRED_PARTIES.includes(party)}
                        label={getITPPartyLabel_it(party)}
                        value={props.item.presences?.[party]}
                        onChange={newValue => {
                            props.item.presences[party] = newValue
                            forceUpdate()
                            props.onChange?.()
                        }}
                    />)}
            </Stack>
        </Stack>
    </>
}

const QCRCodeDropdown: React.FC<{
    value: QCRCode,
    onChange: (newValue: QCRCode) => void
}> = props => {

    return (
        <Dropdown
            dropdownWidth="auto"
            placeholder="Seleziona"
            label="QCR Records"
            selectedKey={props.value}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                if (item) {
                    props.onChange(QCRCode[item.key])
                }
            }}
            onRenderOption={(option: IDropdownOption): JSX.Element => {
                return <>
                    <div
                        style={{width: 30, flexGrow: 0, flexShrink: 0, textAlign: 'center'}}
                        className="QCRCodeDropdown-option"
                    >{option.text}</div>
                    <div style={{
                        fontSize: '0.8em',
                        lineHeight: '1.1em',
                        padding: '0.25em 0',
                        flexGrow: 1
                    }}>{option.data?.desc}</div>
                </>;
            }}
            options={Object.values(QCRCode).map(code =>
                ({
                    key: code,
                    text: code,
                    data: {
                        desc: getQCRCodeMeaning(code),
                    },
                }))}
            styles={{
                dropdown: {width: 150},
                dropdownOptionText: {overflow: 'visible', whiteSpace: 'normal'},
                dropdownItem: {height: 'auto', border: '1px solid #5555'},
                dropdownItemSelected: {height: 'auto', border: '1px solid #5555'},
            }}
        />
    );
};

const PresenceCodeDropdown: React.FC<{
    value: PresenceCode
    onChange: (code: PresenceCode) => void
    label: string
    optional?: boolean
}> = props => {

    return <Dropdown
        dropdownWidth="auto"
        placeholder={props.optional ? '' : "Seleziona"}
        // required={!props.optional}
        label={props.label}
        selectedKey={props.value}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
            props.onChange(PresenceCode[item.key])
        }}
        onRenderOption={(option: IDropdownOption): JSX.Element => {
            if (option.data === undefined) {
                return <>
                    <div style={{width: 30, flexGrow: 0, flexShrink: 0, textAlign: 'center'}}>{option.text}</div>
                    <div style={{flexGrow: 1, textAlign: 'left', lineHeight: '4em'}}>
                        &lt;vuoto&gt;
                    </div>
                </>;
            }
            return <>
                <div
                    style={{width: 30, flexGrow: 0, flexShrink: 0, textAlign: 'center'}}
                    className="PresenceCodeDropdown-option"
                >{option.text}</div>
                <div style={{fontSize: '0.8em', lineHeight: '1.1em', padding: '0.25em 0', flexGrow: 1}}>
                    <p><b>Notification</b><br/> {option.data?.notif}</p>
                    <p><b>Witness or Waiver</b><br/> {option.data?.wow}</p>
                    <p><b>Activity</b><br/> {option.data?.a}</p>
                </div>
            </>;
        }}
        options={props.optional ? OptionalPresenceCodeDropdownOptions : PresenceCodeDropdownOptions}
        styles={{
            dropdown: {width: 150},
            dropdownOptionText: {overflow: 'visible', whiteSpace: 'normal'},
            dropdownItem: {height: 'auto', border: '1px solid #5555'},
            dropdownItemSelected: {height: 'auto', border: '1px solid #5555'},
        }}
    />
};
