import * as React from "react";
import {useMemo, useState} from "react";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {ITPItem} from "../../../fusina-itp/entities/ITPItem";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {
    DefaultButton,
    MessageBar,
    MessageBarType,
    ProgressIndicator,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack
} from "@fluentui/react";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {ConfirmedCalloutBtn} from "./ConfirmedCalloutBtn";
import {ITPCertListItem} from "./ITPCertsView";
import {ITPCertsDetailsListBase, ITPCertsDetailsListBaseColumns} from "./ITPCertsDetailsListBase";
import {useForceUpdate} from "@fluentui/react-hooks";


interface ITPCertDeletionListItem extends ITPCertListItem {
    state?: 'queue' | 'pending' | 'deleted' | 'error'
    error?: Error
}

export const ITPCertsDeletionView: React.FC<{
    itp: InspectionTestPlan
    item: ITPItem
    list: ITPCertListItem[]
    onFinish: () => void
}> = props => {

    const statuses = useMemo<ITPCertDeletionListItem[]>(() =>
        JSON.parse(JSON.stringify(props.list)), [props.list])

    const rmi = useFusinaRmi()
    const [done, setDone] = useState<number | null>(null)

    const forceUpdate = useForceUpdate()

    return <Stack tokens={{childrenGap: 'm'}}>

        {done === null && <div style={{margin: '-1em 0', height: '1.25em'}}>
            Stai per eliminare i seguenti certificati.
        </div>}

        <ITPCertsDetailsListBase
            styles={{
                root: {
                    marginTop: 0
                }
            }}
            selectionMode={SelectionMode.none}
            items={[...statuses]}
            columns={[
                ...ITPCertsDetailsListBaseColumns,
                ...(done === null ? [] : [{
                    key: 'status',
                    name: 'Stato',
                    minWidth: 200,
                    flexGrow: 10,
                    onRender(item: ITPCertDeletionListItem) {
                        return <Stack horizontal tokens={{childrenGap: 's1'}}>
                            <MaybeErrorMessageBar error={item.error}/>
                            {item.state === 'queue' && <span>In coda...</span>}
                            {item.state === 'pending' && <Spinner size={SpinnerSize.xSmall}/>}
                            {item.state === 'deleted' && <span>Eliminato</span>}
                        </Stack>
                    }
                }]),
            ]}
        />

        <br/>

        {done === null && <Stack horizontal style={{justifyContent: 'flex-end'}} tokens={{childrenGap: 'm'}}>
            <DefaultButton
                text='Annulla'
                onClick={props.onFinish}
            />
            <ConfirmedCalloutBtn
                btnProps={{
                    text: 'Elimina definitivamente',
                }}
                action={() => {
                    setDone(0)
                    return Promise.all(statuses.map((status, i) => new Promise<void>(resolve => {
                        status.state = 'queue'
                        setTimeout(() => {
                            status.state = 'pending'
                            if (i === 0) {
                                forceUpdate()
                            }
                            rmi.ITPCertificates.delete(props.itp._id, props.item.item, status._id)
                                .then(() => {
                                    status.state = 'deleted'
                                })
                                .catch(err => {
                                    status.state = 'error'
                                    status.error = err
                                })
                                .finally(() => {
                                    setDone(v => v + 1)  // NOTE: this also forces UI update
                                    resolve()
                                })
                        }, i * 10)
                    }))).then()
                }}
                calloutChildren={<>
                    <MessageBar messageBarType={MessageBarType.severeWarning}>
                        Stai per eliminare {props.list?.length ?? '?'} certificati.
                    </MessageBar>
                </>}
            />
        </Stack>}

        {done !== null && done < statuses.length && <ProgressIndicator
            percentComplete={done / (statuses.length || 1)}
            description="Eliminazione in corso..."
        />}

        {done === statuses.length && <Stack horizontal style={{justifyContent: 'flex-end'}}>
            <DefaultButton
                text="Chiudi"
                onClick={props.onFinish}
            />
        </Stack>}

    </Stack>
}
