import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {ITPFilterCriteria} from "../../../fusina-itp/entities/ITPFilterCriteria";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {
    ActionButton,
    Checkbox,
    mergeStyles,
    MessageBar,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    useTheme
} from "@fluentui/react";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import './ITPStatsTable.css'
import {Job} from "../../../fusina-jobs/entities/Job";


// Heavily inspired from ITPEventCreationFromJob

export const ITPSelectionFlow: React.FC<{
    onItpSelected: (itp: InspectionTestPlan) => void
    onItpDeSelected?: (itp: InspectionTestPlan) => void
    multiSelect?: boolean
    selectedIds?: string[]
    jobFilter?: (job: Job) => boolean
    itpFilter?: (itp: InspectionTestPlan) => boolean
    autoSelectJob?: boolean
}> = props => {
    const [didAutoSelectJob, setDidAutoSelectJob] = useState<boolean>(false)
    const [job, setJob] = useState<Job | undefined>(undefined)
    // const [itp, setItp] = useState<InspectionTestPlan | undefined>(undefined)  // NOTE: click on itp shall trigger onItpSelected
    const filter = useMemo<ITPFilterCriteria | undefined>(() => job ? {
        by: 'job',
        job: {_id: job._id}
    } : undefined, [job])

    const {
        data: jobsData_NO_FILTER, error: jobsError
    } = useFusinaRmiResource('JobsList', 'findGrantedJobs')

    const jobsData = useMemo(() => {
        if (props.jobFilter) {
            return jobsData_NO_FILTER?.filter?.(props.jobFilter)
        }
        return jobsData_NO_FILTER
    }, [jobsData_NO_FILTER, props.jobFilter])

    const {
        data: itpsData_NO_FILTER, error: itpsError
    } = useFusinaRmiResource('ITP', 'find', filter)

    const itpsData = useMemo(() => {
        if (props.itpFilter) {
            return itpsData_NO_FILTER?.filter?.(props.itpFilter)
        }
        return itpsData_NO_FILTER
    }, [itpsData_NO_FILTER, props.itpFilter])

    useEffect(() => {
        if (!didAutoSelectJob && !job && props.autoSelectJob && jobsData?.length === 1) {
            setJob(jobsData?.[0])
            setDidAutoSelectJob(true)
        }
    }, [job, didAutoSelectJob, props.autoSelectJob, jobsData]);

    const theme = useTheme()
    const minorParTdStyle = mergeStyles({
        fontSize: theme.fonts.small.fontSize,
        color: theme.semanticColors.bodySubtext,
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        maxHeight: 75,
        textOverflow: 'ellipsis',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
        paddingLeft: '8px !important',
        textAlign: 'left !important',
    })
    const majorTdStyle = mergeStyles({
        overflow: 'hidden',
        maxHeight: 75,
        textOverflow: 'ellipsis',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
        paddingLeft: '8px !important',
        paddingRight: '12px !important',
        textAlign: 'left !important',
    })

    const header = useMemo(() =>
        <Stack
            horizontal
            wrap
            tokens={{childrenGap: 's1'}}
            verticalAlign="center"
            styles={{
                root: {
                    marginBottom: '1em',
                },
                inner: {
                    columnGap: '0.4em',
                }
            }}
        >
            <ActionButton
                text={`Commesse`}
                iconProps={job ? {
                    iconName: 'Back'
                } : undefined}
                disabled={!job}
                onClick={() => {
                    setJob(undefined)
                }}
            />
            <Text style={{color: theme.semanticColors.disabledBodySubtext}}>
                &rsaquo;
            </Text>
            {job && <>
                <Text>
                    {job.code}
                </Text>
                <Text style={{color: theme.semanticColors.disabledBodySubtext}}>
                    &rsaquo;
                </Text>
                <Text style={{color: theme.semanticColors.disabledBodySubtext}}>
                    Selezionare {props.multiSelect ? 'uno o più' : 'un'} PCQ...
                </Text>
            </> || <Text style={{color: theme.semanticColors.disabledBodySubtext}}>
                Selezionare una commessa...
            </Text>}
        </Stack>, [job])

    if (jobsError ?? itpsError) {
        return <MaybeErrorMessageBar error={jobsError ?? itpsError}/>
    }

    if ((jobsData === undefined) || (job !== undefined && itpsData === undefined)) {
        return <Spinner size={SpinnerSize.large}/>
    }

    if (jobsData?.length < 1) {
        return <MessageBar>
            Non hai accesso ad alcuna commessa.
        </MessageBar>
    }

    if (!job) {
        return <>
            {header}
            <table className="ITPStatsTable noHides selectRow" style={{borderTop: '1px solid #7772'}}>
                <thead>
                <tr>
                    <td className={majorTdStyle}>Commessa</td>
                    <td className={minorParTdStyle}>Titolo</td>
                </tr>
                </thead>
                <tbody>
                {jobsData.map(job => <tr
                    key={job._id}
                    onClick={() => {
                        setJob(job)
                    }}
                >
                    <td className={majorTdStyle}>{job.code}</td>
                    <td className={minorParTdStyle}>{job.title}</td>
                </tr>)}
                </tbody>
            </table>
        </>
    }

    return <>
        {header}
        <table className="ITPStatsTable noHides selectRow" style={{borderTop: '1px solid #7772'}}>
            <thead>
            <tr>
                {props.multiSelect && <td className={majorTdStyle} style={{width: 30}}>&nbsp;</td>}
                <td className={majorTdStyle}>PCQ doc. N</td>
                {/*<td className={majorTdStyle}>Rev.</td>*/}
                <td className={minorParTdStyle}>Titolo</td>
            </tr>
            </thead>
            <tbody>
            {itpsData.map(itp => <tr
                key={itp._id}
                onClickCapture={() => {
                    // setItp(itp)
                    if (props.selectedIds?.includes?.(itp._id)) {
                        props.onItpDeSelected?.(itp)
                    } else {
                        props.onItpSelected?.(itp)
                    }
                }}
            >
                {props.multiSelect && <td className={majorTdStyle}>
                    <Checkbox
                        checked={props.selectedIds?.includes?.(itp._id) ?? false}
                        onChange={(ev, checked) => {
                            if (props.selectedIds?.includes?.(itp._id)) {
                                props.onItpDeSelected?.(itp)
                            } else {
                                props.onItpSelected?.(itp)
                            }
                        }}
                    />
                </td>}
                <td className={majorTdStyle}>{itp.docN}</td>
                {/*<td className={majorTdStyle}>{itp.revN}</td>*/}
                <td className={minorParTdStyle}>{itp.title}</td>
            </tr>)}
            </tbody>
        </table>
        {itpsData?.length > 0 && <div>
            <ActionButton onClick={() => {
                itpsData.forEach(itp => props.onItpSelected(itp))
            }}>Seleziona tutti</ActionButton>
            <ActionButton onClick={() => {
                itpsData.forEach(itp => props.onItpDeSelected(itp))
            }}>Deseleziona tutti</ActionButton>
        </div>}
        {itpsData?.length < 1 && <MessageBar>
            Nessun PCQ in questa commessa.
        </MessageBar>}
    </>
}
