import * as React from 'react'
import {useTheme} from "@fluentui/react";
import {useJobContext} from "../contexts/JobContext";
import {MicroSpinner} from "./MicroSpinner";
import {useScreenSize} from "../hooks/useScreenSize";


export const JobHeadingFrag: React.FC = () => {
    const theme = useTheme()
    const {job, contractor} = useJobContext()
    const {isMobile} = useScreenSize()

    return <>
        <div style={{
            fontSize: theme.fonts.medium.fontSize,
            lineHeight: '1.1em',
            display: 'flex',
            flexFlow: 'column wrap',
            textAlign: 'left',
            fontWeight: theme.fonts.medium.fontWeight,
        }}>
            {isMobile ? <>
                <div>{job?.code ?? <MicroSpinner/>} &nbsp;&nbsp; {contractor?.denomination ?? <MicroSpinner/>}</div>
            </> : <>
                <div>Appaltatore: {contractor?.denomination ?? <MicroSpinner/>}</div>
                <div>Commessa: {job?.code ?? <MicroSpinner/>}</div>
            </>}
        </div>
    </>
}
