import * as React from 'react'
import {ReactNode, useMemo} from 'react'
import {Text} from '@fluentui/react'
import {EcoOdS, sumOrderItems} from "../../../fusina-letters/entities/EcoOdS";
import {EcoOdsEstimatePolicy, ExpensesPolicy} from "../../../fusina-letters/controls/EcoOdsPolicy";
import {useJobContext} from "../contexts/JobContext";
import {useSecurityContext} from "../contexts/SecurityContext";
import {EcoOdsAmountFrag} from "./EcoOdsAmountFrag";

export const EcoOdsEstimateExpensesSmallBlock: React.FC<{
    letter: EcoOdS
}> = props => {
    const {job} = useJobContext()
    const securityContext = useSecurityContext()
    const policy = useMemo(() => EcoOdsEstimatePolicy({
        securityContext,
        job,
        oldLetter: props.letter,
    }), [props.letter])

    const estimate = useMemo<ReactNode>(() => {
        if (!policy.permissions.read) {
            return null
        }
        return <EcoOdsAmountFrag value={sumOrderItems(props.letter.estimate.items)}/>
    }, [props.letter])

    const expenses = useMemo<ReactNode>(() => {
        const expPol = ExpensesPolicy({
            securityContext,
            job,
            oldLetter: props.letter,
        })
        if (!policy.permissions.read || !expPol.permissions.read) {
            return null
        }
        return props.letter?.expenses
            ? <EcoOdsAmountFrag value={props.letter.expenses} expensesMeta={props.letter.expensesMeta}/>
            : null //<Text style={{color: theme.semanticColors.disabledSubtext}}>⸺</Text>
    }, [props.letter])

    if (!(estimate || expenses) || !policy.permissions.read) {
        return null
    }

    return <Text
        variant="small"
        styles={{
            root: {
                wordBreak: 'break-word',
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                alignItems: 'center',
            },
        }}
    >
        <div>
            Totale preventivo:
        </div>
        <div style={{textAlign: "right", fontFamily: 'monospace'}}>
            {estimate}
        </div>
        {expenses && <>
            <div>
                Consuntivo:
            </div>
            <div style={{textAlign: "right", fontFamily: 'monospace'}}>
                {expenses}
            </div>
        </>}
    </Text>
}
