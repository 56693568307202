import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {useJobContext} from "../contexts/JobContext";
import {useSecurityContext} from "../contexts/SecurityContext";
import * as React from "react";
import {useMemo, useState} from "react";
import {ITPPolicy} from "../../../fusina-itp/controls/ITPPolicy";
import {ConfirmedCalloutBtn} from "./ConfirmedCalloutBtn";
import {TextField} from "@fluentui/react";

export const ITPRejectBtn: React.FC<{
    itp: InspectionTestPlan
    onSuccess: () => void
    rejectAction?: (comment?: string) => Promise<void>
}> = props => {
    const {job} = useJobContext()
    const sc = useSecurityContext()

    const policy = useMemo(() => ITPPolicy({
        itp: props.itp,
        job: job,
        securityContext: sc,
    }), [props.itp, job])

    const [comment, setComment] = useState<string | undefined>(undefined)

    if (!policy.permissions.reject) {
        return null
    }

    return <ConfirmedCalloutBtn
        btnProps={{
            text: 'Rifiuta',
            className: 'ITPRejectBtn',
            iconProps: {
                iconName: 'EraseTool'
            }
        }}
        calloutChildren={<>
            Stai per rimandare il PCQ in bozza.<br/>
            <TextField
                label="Commento"
                value={comment}
                maxLength={512}
                multiline
                autoAdjustHeight
                onChange={(event, newValue) => {
                    setComment(newValue)
                }}
            />
        </>}
        action={() => props.rejectAction(comment)
            .then(() => props.onSuccess())}
    />
}
