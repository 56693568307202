import {EventMessage, EventPublishMessage, EventRequestMessage} from "../entities/EventMessage";
import {EventAttendee_equals} from "../entities/EventAttendee";
import {EventBase} from "../entities/EventBase";
import {getEventProperties} from "../entities/EventProperties";

/** Processing logic for event messages
 *
 * Mutates the event object passed to the constructor. */
export class EventMessageProcessor {
    constructor(
        public readonly event: EventBase
    ) {
    }

    /** Initializes processing by creating a new EventBase from the given initial request or publish message
     *
     * @return EventMessageProcessor with new event */
    static init(msg: EventRequestMessage | EventPublishMessage) {
        return new EventMessageProcessor({
            ...getEventProperties(msg),
            messages: [msg]
        })
    }

    /** Mutates the event by processing the given message */
    public apply(msg: EventMessage): void {
        this.event.messages.push(msg)
        if (msg.method === 'REQUEST' || msg.method === 'PUBLISH') {
            Object.assign(this.event, getEventProperties(msg))
        }
        if (msg.method === 'REPLY') {
            this.event.attendees
                .filter(att => EventAttendee_equals(att, msg.attendee))
                .forEach(att => {
                    att.partStat = msg.attendee.partStat
                    if (att.partStat === 'DECLINED' && att.role === 'REQ-PARTICIPANT') {
                        this.event.status = 'CANCELLED'
                    }
                    if (att.partStat === 'ACCEPTED' && att.role === 'REQ-PARTICIPANT') {
                        // && !this.event.attendees.find(att => att.partStat !== 'ACCEPTED' && att.role === 'REQ-PARTICIPANT')  // NOTE: this extra condition may be reasonable, but not for our application where only one replying participant is expected.
                        // if (!msg.attendee.waiver)  // NOTE: confirm both with or without waiver.
                        this.event.status = 'CONFIRMED'
                    }
                })
        }
    }
}
