import type {ITPParty} from "../../fusina-itp/entities/ITPParty";

/** Attendee of an event
 * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.8.4.1 */
export interface EventAttendeeBase {
    name?: string
    email?: string

    itpParty?: ITPParty

    /** Participation status
     * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.2.12 */
    partStat?: PartStat
}

export interface EventRequestAttendee extends EventAttendeeBase {
    partStat?: 'NEEDS-ACTION'

    /** Specifies whether there is an expectation of a reply from the attendee
     * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.2.17 */
    rsvp?: boolean

    /** Participation role
     * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.2.16 */
    role?: Role
}

export interface EventReplyAttendee extends EventAttendeeBase {
    partStat: "ACCEPTED" | "DECLINED"
    waiver?: boolean
}

export interface EventAttendee extends EventAttendeeBase {
    partStat?: PartStat

    /** Specifies whether there is an expectation of a reply from the attendee
     * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.2.17 */
    rsvp?: boolean

    /** Participation role
     * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.2.16 */
    role?: Role
}

/** Participation status
 * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.2.12 */
export type PartStat =
    | "NEEDS-ACTION" // Event needs action (default status)
    | "ACCEPTED"     // Event accepted
    | "DECLINED"     // Event declined
    | "TENTATIVE"    // Event tentatively accepted
// | "DELEGATED"    // Event delegated


/** Participation role
 * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.2.16 */
export type Role =
    | "CHAIR" // Indicates chair of the calendar entity
    | "REQ-PARTICIPANT" // Indicates a participant whose participation is required
    | "OPT-PARTICIPANT" // Indicates a participant whose participation is optional
    | "NON-PARTICIPANT" // Indicates a participant who is copied for information purposes only


export const EventAttendee_equals = (a: EventAttendeeBase | { email: string }, b: EventAttendeeBase | { email: string }) =>
    (a.email && b.email && a.email === b.email)


export const EVENT_PART_STAT_LABEL_IT: Record<PartStat, string> = {
    TENTATIVE: 'Tentativo',
    ACCEPTED: 'Accettato',
    DECLINED: 'Declinato',
    "NEEDS-ACTION": '',
}
