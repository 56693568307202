import {Icon, IconButton, Link, mergeStyles, Stack, Text, useTheme} from "@fluentui/react";
import {EVENT_STATUS_LABELS_IT} from "../../../../fusina-event/entities/EventProperties";
import * as React from "react";
import {useContext, useMemo} from "react";
import {EventBase} from "../../../../fusina-event/entities/EventBase";
import {CalContext} from "./CalContext";
import {Link as RouterLink} from "react-router-dom";

export const EventCardFrag: React.FC<{
    ev: EventBase
}> = props => {
    const theme = useTheme()
    const [cal] = useContext(CalContext)
    const href = useMemo(() =>
        cal.eventHrefGetter?.(props.ev), [props.ev, cal.eventHrefGetter])
    const label = props.ev.summary

    const contextLabel = useMemo<string | undefined>(() =>
        cal.eventContextLabelGetter?.(props.ev), [props.ev, cal.eventContextLabelGetter])

    const iconTdStyle = mergeStyles({
        padding: '0 16px 12px',
        verticalAlign: 'top',
        color: theme.semanticColors.bodySubtext,
    })
    const dataTdStyle = mergeStyles({
        padding: '0 12px 6px 0',
        verticalAlign: 'top',
    })

    return <div style={{maxWidth: 300, paddingBottom: 8}}>
        <Stack
            horizontal
            styles={{
                root: {
                    minWidth: 200,
                    marginBottom: 8,
                    // background: theme.palette.themeLight,
                    // borderRadius: 3,
                    // borderLeft: '6px solid ' + (props.ev.status === 'CONFIRMED'
                    // ? theme.palette.themePrimary
                    // : theme.palette.neutralQuaternaryAlt),
                }
            }}
            verticalAlign="center"
        >
            <Stack.Item style={{opacity: 0.5}}>
                &nbsp; {EVENT_STATUS_LABELS_IT[props.ev.status]}
            </Stack.Item>
            <Stack.Item grow={1}>&nbsp;</Stack.Item>
            <Stack.Item>
                <IconButton
                    iconProps={{
                        iconName: 'FullScreen'
                    }}
                    onClick={() => {
                        cal.onEventOpen?.(props.ev)
                    }}
                />
            </Stack.Item>
        </Stack>
        <table>
            <tbody>
            <tr style={{minHeight: '2.5em'}}>
                <td className={iconTdStyle} style={{lineHeight: '14px'}}>
                    <Icon iconName='SquareShapeSolid' styles={{
                        root: {
                            color: (props.ev.status === 'CONFIRMED'
                                ? theme.palette.themePrimary
                                : theme.palette.neutralQuaternaryAlt)
                        }
                    }}/>
                </td>
                <td className={dataTdStyle}>
                    <Text variant="large" style={{lineHeight: '14px'}}>
                        {href && <Link to={href} as={RouterLink} target="_blank" onClick={ev => {
                            // NOTE: we support both the custom JS behavior AND the native link,
                            //       which means the user can open in a new tab OR this tab.
                            if (cal.onEventOpen) {
                                cal.onEventOpen?.(props.ev)
                                ev.preventDefault()
                                return false
                            }
                        }}>{label}</Link> || label}
                    </Text>
                </td>
            </tr>
            <tr>
                <td className={iconTdStyle}><Icon iconName='Clock'/></td>
                <td className={dataTdStyle}>
                    <time dateTime={new Date(props.ev.dtStart).toISOString()}>
                        {new Date(props.ev.dtStart).toLocaleTimeString(undefined, {
                            hour: '2-digit',
                            minute: '2-digit',
                            timeZoneName: 'short',
                        })}
                    </time>
                </td>
            </tr>
            {props.ev.location && <tr>
                <td className={iconTdStyle}><Icon iconName='Location'/></td>
                <td className={dataTdStyle}>
                    <Text block variant='small' styles={{
                        root: {
                            maxHeight: 120,
                            boxShadow: 'inset 0 -20px 0 ' + theme.semanticColors.bodyBackground,
                            whiteSpace: 'pre-wrap',
                        }
                    }}>
                        {props.ev.location}
                    </Text>
                </td>
            </tr>}
            {contextLabel && <tr>
                <td className={iconTdStyle}><Icon iconName='Event'/></td>
                <td className={dataTdStyle}>{contextLabel}</td>
            </tr>}
            <tr>
                <td className={iconTdStyle}><Icon iconName='Contact'/></td>
                <td className={dataTdStyle}>{props.ev.organizer.name ?? props.ev.organizer.email}</td>
            </tr>
            {props.ev.description && <tr>
                <td className={iconTdStyle}><Icon iconName='List'/></td>
                <td className={dataTdStyle}>
                    <Text block variant='small' styles={{
                        root: {
                            maxHeight: 120,
                            boxShadow: 'inset 0 -20px 0 ' + theme.semanticColors.bodyBackground,
                            whiteSpace: 'pre-wrap',
                        }
                    }}>
                        {props.ev.description}
                    </Text>
                </td>
            </tr>}
            </tbody>
        </table>
    </div>
}
