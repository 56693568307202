import {useCallback, useEffect, useState} from "react";
import {useRmi} from "./useRmi";

export type ReturnType<T> = T extends (...args: any[]) => Promise<infer R> ? R : never;
export type ArgsType<T> = T extends (...args: infer T) => any ? T : never;

export interface CacheValue {
    data: unknown
    expiresAt: Date
    fetch: Promise<void>

    refresh()
}

const CACHE = new Map<string, CacheValue>()

const CACHE_USAGES = new Map<string, number>()
const CACHE_DATA_SETTERS = new Map<string, ((data: unknown) => void)[]>()

export function invalidateAllCache() {
    for (let [key, value] of CACHE.entries()) {
        if (CACHE_USAGES.get(key) > 0) {
            value.refresh?.()
        } else {
            CACHE.delete(key)
            CACHE_USAGES.delete(key)
        }
    }
}

export function useRmiResource<T, K extends keyof T = keyof T, M extends keyof T[K] = keyof T[K], E = Error>(path: K, method: M, ...args: ArgsType<T[K][M]>): {
    data?: ReturnType<T[K][M]>,
    error?: E,
    refresh: () => Promise<void>
} {
    const rmi = useRmi<T>()

    const cacheKey = JSON.stringify({path, method, args})

    // NOTE: without equality check between cacheKey and cacheKey_of_data
    //       fast reactions may lead to inconsistencies caused by usage of data loaded for a different cacheKey.
    const [cacheKey_of_data, setCacheKey_of_data] = useState<string>(undefined)
    const [data, setData] = useState<ReturnType<T[K][M]>>(undefined)
    const [error, setError] = useState<E>(undefined)
    // NOTE: data state is required only for reaction on fetch fulfill; data could actually be taken only from cache.

    const refresh = useCallback<() => Promise<void>>(() => {
        if (args === undefined || args.includes(undefined)) {
            setCacheKey_of_data(undefined)
            setData(undefined);
            setError(undefined);
            console.debug('RMI Resource fetch skipped because of some undefined arg', {path, method})
            return Promise.resolve()
        }

        // Allocate cache entry
        const value: CacheValue = {
            refresh,
            data: undefined,
            fetch: undefined,
            expiresAt: new Date(Date.now() + 500), // Let's assume max age is almost zero and use SWR cache policy.
            // NOTE: an explicit call to refresh will instantly start fetching new data.
        };
        CACHE.set(cacheKey, value)

        value.fetch = (async () => {
            console.debug('RMI Resource fetch...', {path, method, args})
            try {
                const prom = (rmi[path][method] as T[K][M] & Function)(...args)
                const _data = await prom
                setCacheKey_of_data(cacheKey)
                setData(_data)
                setError(undefined)
                CACHE_DATA_SETTERS.get(cacheKey)?.forEach?.(ds => ds(_data))
                value.data = _data
            } catch (e) {
                console.error('RMI Resource fetch error', {path, method}, e)
                setCacheKey_of_data(undefined)
                setData(undefined);
                setError(e)
            }
        })()
        return value.fetch
    }, [rmi, cacheKey])

    useEffect(() => {
        setCacheKey_of_data(undefined)
        setData(undefined); // NOTE: Don't show data from a different query/cacheKey after props change
        setError(undefined);

        const hit = CACHE.get(cacheKey)
        if (!hit) {
            refresh().then()
        } else {
            if (new Date() > hit.expiresAt) {
                console.debug('RMI Resource: using stale while revalidating.')
                refresh().then()
            } else {
                hit.fetch.then(() => {
                    setCacheKey_of_data(cacheKey)
                    setData(hit.data as ReturnType<T[K][M]>)
                })
            }
        }
    }, [cacheKey])

    useEffect(() => {
        CACHE_USAGES.set(cacheKey, (CACHE_USAGES.get(cacheKey) | 0) + 1)
        CACHE_DATA_SETTERS.set(cacheKey, [...(CACHE_DATA_SETTERS.get(cacheKey) ?? []), setData])
        // console.debug('RMI cache entry usage increased to', CACHE_USAGES.get(cacheKey))
        return () => {
            CACHE_USAGES.set(cacheKey, (CACHE_USAGES.get(cacheKey) | 0) - 1)
            CACHE_DATA_SETTERS.set(cacheKey, (CACHE_DATA_SETTERS.get(cacheKey) ?? []).filter(ds => ds !== setData))
            // console.debug('RMI cache entry usage decreased to', CACHE_USAGES.get(cacheKey))
        }
    }, [cacheKey])

    return {
        data: (cacheKey === cacheKey_of_data ? data : undefined)
            ?? CACHE.get(cacheKey)?.data as ReturnType<T[K][M]>,
        error,
        refresh,
    }
}
