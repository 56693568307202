import * as React from 'react'
import {useCallback, useMemo} from 'react'
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import './ITPDiff.css';
import {mergeStyles, useTheme} from "@fluentui/react";
import {ITPItem} from "../../../fusina-itp/entities/ITPItem";
import {getITPPartyLabel_it, ITPParty} from "../../../fusina-itp/entities/ITPParty";
import Diff from 'text-diff'

export const ITPDiff: React.FC<{
    before: InspectionTestPlan
    after: InspectionTestPlan
}> = props => {
    const {before: L, after: R} = props
    const items = useMemo<string[]>(() => {
        const items = new Set<string>()
        L.items.forEach(it => items.add(it.item))
        R.items.forEach(it => items.add(it.item))
        const list = [...items]
        list.sort()
        return list
    }, [L, R])

    const theme = useTheme()
    const minorParTdStyle = mergeStyles({
        fontSize: theme.fonts.small.fontSize,
        color: theme.semanticColors.bodySubtext,
        whiteSpace: 'pre-wrap',
        // overflow: 'hidden',
        // maxHeight: 75,
        maxWidth: 350,
        // textOverflow: 'ellipsis',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
        paddingLeft: '8px !important',
        paddingRight: '8px !important',
        textAlign: 'left !important',
    })
    const majorTdStyle = mergeStyles({
        // overflow: 'hidden',
        // maxHeight: 75,
        // textOverflow: 'ellipsis',
        minWidth: '10ch',
        textAlign: 'left !important',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
        paddingLeft: '8px !important',
        paddingRight: '12px !important',
    })
    const fieldTdStyle = mergeStyles({
        // overflow: 'hidden',
        // maxHeight: 75,
        // textOverflow: 'ellipsis',
        fontWeight: 500,
        minWidth: '10ch',
        textAlign: 'left !important',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
        paddingLeft: '8px !important',
        paddingRight: '12px !important',
    })

    const diff = useCallback((title: string, accessor: (itp: InspectionTestPlan) => string) => {
        const left = accessor(L) ?? ''
        const right = accessor(R) ?? ''
        if (left === right) {
            return null
        }
        return <tr>
            <td className={fieldTdStyle} colSpan={2}>{title}</td>
            <td className={minorParTdStyle}>
                <TextDiff left={left} right={right}/>
            </td>
        </tr>
    }, [L, R])

    const diff_item = useCallback((item: string, title: string, accessor: (item: ITPItem) => string) => {
        const LI = L.items.find(it => it.item === item) ?? {}
        const RI = R.items.find(it => it.item === item) ?? {}
        const left = accessor(LI as ITPItem) ?? ''
        const right = accessor(RI as ITPItem) ?? ''
        if (left === right) {
            return null
        }
        return <tr>
            <td className={majorTdStyle}>
                {item}
            </td>
            <td className={fieldTdStyle}>{title}</td>
            <td className={minorParTdStyle}>
                <TextDiff left={left} right={right}/>
            </td>
        </tr>
    }, [L, R])

    return <>
        <table className="ITPStatsTable">
            <thead>
            <tr>
                <td className={majorTdStyle} colSpan={2}>&nbsp;</td>
                <td className={majorTdStyle}>Differenze</td>
            </tr>
            </thead>
            <tbody>
            {diff('Rev.', itp => itp.revN)}
            </tbody>
            <tbody>
            {diff('Titolo', itp => itp.title)}
            </tbody>
            <tbody>
            {diff('(Disposizione fasi)', itp => itp.items.map(it => it.item).join(',  '))}
            </tbody>
            <tbody>
            <tr>
                <td colSpan={3} style={{lineHeight: '1px', overflow: "hidden"}}>&nbsp;</td>
            </tr>
            </tbody>
            <thead>
            <tr>
                <td className={majorTdStyle} colSpan={3}>Fase</td>
            </tr>
            </thead>
            {items.map(item => {
                return <tbody>
                {diff_item(item, 'Componente', item => item.comp)}
                {diff_item(item, 'Descrizione del controllo', item => item.desc)}
                {diff_item(item, 'Documenti applicabili', item => item.refDocs)}
                {diff_item(item, 'QCR Records', item => item.recordsQCR)}
                {Object.keys(ITPParty).map(party =>
                    diff_item(item, `Presenza ${getITPPartyLabel_it(ITPParty[party])}`,
                        item => item.presences?.[party]))}
                {diff_item(item, 'Note', item => item.notes)}
                {diff_item(item, '(Dismessa/abilitata)', item => item.abolished ? 'dismessa' : 'abilitata')}
                </tbody>
            })}
        </table>
    </>
}


export const TextDiff: React.FC<{
    left: string
    right: string
}> = props => {
    const html = useMemo<string>(() => {
        const diff = new Diff({timeout: 2, editCost: 6})
        const textDiff = diff.main(props.left, props.right)
        diff.cleanupSemantic(textDiff)
        return diff.prettyHtml(textDiff)
    }, [props.left, props.right])

    if (html?.length < 60) {
        return <span className="ITPDiff-TextDiff-239784">
            <del>{props.left}</del>
            &nbsp;
            <ins>{props.right}</ins>
        </span>
    }

    return <span className="ITPDiff-TextDiff-239784" dangerouslySetInnerHTML={{__html: html}}/>
}
