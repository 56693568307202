import * as React from 'react'
import {useCallback, useMemo, useState} from 'react'
import {Stack} from '@fluentui/react/lib/Stack';
import {Callout, PrimaryButton, Spinner, SpinnerSize, Text} from "@fluentui/react";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useSecurityContext} from "../contexts/SecurityContext";
import {RoleEnum} from "../../../fusina-authz/entities/RoleEnum";
import LettersPolicy from "../../../fusina-letters/controls/LettersPolicy";
import {Job} from "../../../fusina-jobs/entities/Job";
import {useId} from '@fluentui/react-hooks';
import {NI} from "../../../fusina-letters/entities/NI";


export const SignAndNotifyReceiptBtn: React.FC<{
    signatureRole: RoleEnum,
    job: Job
    letter: NI
    onSuccess?: (letterId: string, signatureId: string) => void
}> = props => {

    const rmi = useFusinaRmi()
    const sc = useSecurityContext()

    const policy = useMemo(() => props.job ? LettersPolicy({
        job: props.job,
        securityContext: sc,
        oldLetter: props.letter,
        signatureRole: props.signatureRole
    }) : undefined, [sc, props.job, props.letter])

    const [pending, setPending] = useState<boolean>(false)
    const [confirming, setConfirming] = useState<boolean>(false)

    const handle = useCallback(() => {
        setConfirming(false)
        setPending(true)
        rmi.Letters.sign(props.letter._id, props.signatureRole)
            .then(signatureId => {
                console.debug({signatureId})
                props.onSuccess?.(props.letter._id, signatureId)
            })
            .catch(console.error) // TODO
            .finally(() => setPending(false))
    }, [rmi, policy])

    const preConfirmHandle = useCallback(() => {
        setConfirming(true)
        setPending(true)
        setTimeout(() => {
            setPending(false)
        }, 1000)
    }, [])

    const buttonId = useId('SignAndNotifyReceiptBtn-preConfirm');

    if (policy?.permissions?.sign !== true) {
        return null
    }

    return <>
        <PrimaryButton
            id={buttonId} primary disabled={pending}
            onClick={preConfirmHandle}
            style={{minHeight: 1, padding: '2px 8px', lineHeight: '1.2em'}}
        >
            Firma come SM
        </PrimaryButton>
        <Callout
            hidden={!confirming}
            coverTarget
            role="dialog"
            style={{padding: '20px 24px'}}
            onDismiss={() => setConfirming(false)}
            target={'#' + buttonId}
            isBeakVisible={false}
            setInitialFocus
        >
            <Text block variant="xLarge" style={{fontWeight: 200, marginBottom: 12}}>
                Confermi?
            </Text>
            Stai per firmare come {props.signatureRole}.
            <br/>
            Saranno notificati Appaltatore e CDL.
            <Stack horizontal style={{marginTop: 20}} tokens={{childrenGap: 'm'}}>
                <PrimaryButton onClick={handle} className="SignAndNotifyReceiptBtn-Confirm"
                               text="Conferma" disabled={pending}/>
                {pending && <Spinner size={SpinnerSize.small}/>}
            </Stack>
        </Callout>
    </>
}
