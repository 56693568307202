import * as React from "react";
import {Job} from "../../../fusina-jobs/entities/Job";
import {GrantItem, useJobGrantsView} from "../hooks/useJobGrantsView";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {
    DetailsList,
    DetailsListLayoutMode,
    Link,
    MessageBar,
    MessageBarType,
    SelectionMode,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import {RoleLabels} from "../../../fusina-authz/entities/RoleEnum";

export const JobGrantsView: React.FC<{
    job: Job
}> = props => {

    const {
        grantItems,
        areGrantsFullyLoaded,
        errors: grantsLoadErrors,
    } = useJobGrantsView(props.job)

    return <div>
        <MessageBar messageBarType={MessageBarType.info}>
            Accedi a <Link href="#/admin/users/">Admin/Utenti</Link> per modificare le informazioni qui riportate.
        </MessageBar>
        <DetailsList
            selectionMode={SelectionMode.none}
            items={grantItems ?? []}
            layoutMode={DetailsListLayoutMode.justified}
            compact
            columns={[
                {
                    key: 'role',
                    fieldName: 'role',
                    minWidth: 175,
                    maxWidth: 200,
                    name: 'Ruolo',
                    flexGrow: 0.3,
                    targetWidthProportion: 0.2,
                    onRender(item: GrantItem) {
                        return RoleLabels[item?.role] ?? item?.role
                    }
                },
                {
                    key: 'email',
                    fieldName: 'email',
                    minWidth: 200,
                    name: 'Utente',
                    flexGrow: 0.3,
                    targetWidthProportion: 0.4,
                },
                {
                    key: 'scope',
                    fieldName: 'scopeLabel',
                    minWidth: 120,
                    maxWidth: 140,
                    name: '',
                    flexGrow: 0.3,
                    targetWidthProportion: 0.2,
                },
            ]}
        />

        {grantsLoadErrors?.length > 0
            ? <MaybeErrorMessageBar error={grantsLoadErrors[0]}/>
            : (areGrantsFullyLoaded
                ? null
                : <Spinner size={SpinnerSize.medium}/>)}
    </div>
}
