import * as React from "react";
import {useState} from "react";
import {ActionButton} from "@fluentui/react";
import {EcoItem} from "../../../fusina-jobs/entities/EcoItem";
import {EcoItemsImporter} from "./EcoItemsImporter";
import {IButtonProps} from "@fluentui/react/src/components/Button/Button.types";
import {SimpleDialog} from "./SimpleDialog";

export const EcoItemsImporterBtn: React.FC<{
    onImport: (items: EcoItem[]) => void
    btnProps?: IButtonProps
}> = props => {

    const [isOpen, setIsOpen] = useState<boolean>(false)

    return <>
        <ActionButton
            {...props.btnProps}
            onClick={() => setIsOpen(true)}
        />
        <SimpleDialog
            title="Importazione articoli da CSV/XLSX "
            subText=""
            hidden={!isOpen}
        >
            <EcoItemsImporter
                onDismiss={() => setIsOpen(false)}
                onImport={items => {
                    setIsOpen(false)
                    props.onImport(items)
                }}
            />
        </SimpleDialog>
    </>

}
