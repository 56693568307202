import * as React from 'react'
import {useState} from 'react'
import {Letter} from "../../../fusina-letters/entities/Letter";
import {EstimateAttachmentCard, LetterAttachmentCard} from "./LetterAttachmentCard";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {IconButton, Stack,} from "@fluentui/react";
import {useDelayedValue} from "../hooks/useDelayedValue";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {EcoOdS} from "../../../fusina-letters/entities/EcoOdS";
import {AttachmentsCountCard, ErrOrNoAttachments} from './AttachmentCard';

export const LetterAttachmentsList: React.FC<{
    letter: Letter
    cardWidth?: string
    collapse?: boolean
    showDelete?: boolean
}> = props => {

    const rmi = useFusinaRmi()
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
    const letterId = useDelayedValue(props.letter._id, 10) // Artificial delay to avoid too frequent requests
    const {
        data: attachments,
        error,
        refresh,
    } = useFusinaRmiResource('Letters', 'listAttachments', letterId)

    if (attachments === undefined || error) {
        return <ErrOrNoAttachments error={error} cardWidth={props.cardWidth}/>
    }
    if (props.collapse && isCollapsed && attachments?.length > 1) {
        return <AttachmentsCountCard
            cardWidth={props.cardWidth}
            count={attachments?.length}
            onClick={() => setIsCollapsed(false)}
        />
    }
    return <Stack horizontal wrap horizontalAlign="start">
        {attachments.length <= 0 && <AttachmentsCountCard count={0} cardWidth={props.cardWidth}/>}
        {attachments?.map?.(name => <Stack horizontal style={{alignItems: 'center'}}>
            <LetterAttachmentCard key={name} letter={props.letter} name={name} width={props.cardWidth}/>
            {props.showDelete && <IconButton iconProps={{iconName: 'Delete'}} onClick={() => {
                rmi.Letters.deleteAttachment(props.letter._id, name)
                    .finally(refresh)
            }}/>}
        </Stack>)}
    </Stack>
}

export const EstimateAttachmentsList: React.FC<{
    letter: EcoOdS
    cardWidth?: string
    collapse?: boolean
    showDelete?: boolean
}> = props => {

    const rmi = useFusinaRmi()
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
    const letterId = useDelayedValue(props.letter._id, 10) // Artificial delay to avoid too frequent requests
    const {
        data: attachments,
        error,
        refresh,
    } = useFusinaRmiResource('EcoOds', 'listEstimateAttachments', letterId)

    if (attachments === undefined || error) {
        return <ErrOrNoAttachments error={error} cardWidth={props.cardWidth}/>
    }
    if (props.collapse && isCollapsed && attachments?.length > 1) {
        return <AttachmentsCountCard
            cardWidth={props.cardWidth}
            count={attachments?.length}
            onClick={() => setIsCollapsed(false)}
        />
    }
    return <Stack horizontal wrap horizontalAlign="start">
        {attachments.length <= 0 && <AttachmentsCountCard count={0} cardWidth={props.cardWidth}/>}
        {attachments?.map?.(name => <Stack horizontal style={{alignItems: 'center'}}>
            <EstimateAttachmentCard key={name} letter={props.letter} name={name} width={props.cardWidth}/>
            {props.showDelete && <IconButton iconProps={{iconName: 'Delete'}} onClick={() => {
                rmi.EcoOds.deleteEstimateAttachment(props.letter._id, name)
                    .finally(refresh)
            }}/>}
        </Stack>)}
    </Stack>
}
