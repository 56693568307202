import * as React from "react";
import {createContext, PropsWithChildren, useCallback, useContext, useState} from "react";
import {Job} from "../../../fusina-jobs/entities/Job";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {Company} from "../../../fusina-jobs/entities/Company";

export interface IJobContext {
    job?: Job
    jobError?: Error
    contractor?: Company
    contractorError?: Error

    jobs: Job[]
    jobsError?: Error

    setJob(job: Job): void

    refreshJobs(): Promise<void>

    requireJob(jobId: string, maxAgeMs: number): Promise<Job>
}

const JobContext = createContext<IJobContext>({
    jobs: [],
    refreshJobs(): Promise<void> {
        throw new Error('JobContext was not provided')
    },
    setJob() {
        throw new Error('JobContext was not provided')
    },
    requireJob() {
        throw new Error('JobContext was not provided')
    }
})

/**
 * This component lists the jobs accessible by the user.
 * setJob allows to load a particular job as a current one.
 */
export const JobContextStore: React.FC<PropsWithChildren<{
    jobId?: string
}>> = props => {
    const [job, setJob] = useState<Job | undefined>(undefined)
    const [jobFetchDate, setJobFetchDate] = useState<Date>(undefined)
    const [jobError, setJobError] = useState<Error>(undefined)

    const {
        data: jobs,
        error: jobsError,
        refresh: refreshJobs,
    } = useFusinaRmiResource('JobsList', 'findGrantedJobs')

    const {
        data: contractor,
        error: contractorError,
    } = useFusinaRmiResource('JobsList', 'findCompanyById', job?.contractor)

    const rmi = useFusinaRmi()
    const requireJob = useCallback(async (jobId: string, maxAgeMs: number) => {
        if (job?._id === jobId && jobFetchDate
            && Date.now() < jobFetchDate.getTime() + maxAgeMs) {

            console.debug('JobContextStore.requireJob using cached job')
            return job
        }
        setJob(undefined)
        try {
            const newJob = await rmi.JobsList.findJobById(jobId)
            setJob(newJob)
            setJobError(undefined)
            setJobFetchDate(new Date())
            return newJob
        } catch (e) {
            setJobError(e)
            throw e
        }
    }, [job, refreshJobs, jobFetchDate])

    const ctx = {
        job,
        jobError,
        contractor,
        contractorError,

        jobs,
        jobsError,
        setJob,
        requireJob,
        refreshJobs,
    }

    return <JobContext.Provider value={ctx}>
        {props.children}
    </JobContext.Provider>
}

export function useJobContext(): IJobContext {
    return useContext<IJobContext>(JobContext)
}
