import * as React from "react";
import {useContext, useMemo} from "react";
import {Stack, Text, useTheme} from "@fluentui/react";
import {getLocalWeekDay, isSameDate} from './commons'
import {CalContext} from "./CalContext";
import {SmallEventFrag} from "./SmallEventFrag";


export const MonthDateFrag: React.FC<{
    isOtherMonth?: boolean
    i: number,
    date: Date,
    onClick: () => void,
}> = props => {
    const [cal] = useContext(CalContext)
    const LIST_LIMIT = 3
    const thisDateEvents = useMemo(() => {
            const ee = cal.events
                .filter(ev => ['TENTATIVE', 'CONFIRMED'].includes(ev.status))
                .filter(ev => isSameDate(new Date(ev.dtStart), props.date))
            ee.sort((a, b) => {
                return new Date(a.dtStart).getTime() - new Date(b.dtStart).getTime()
            })
            return ee
        },
        [props.date, cal.events])

    const truncatedDateEvents = useMemo(() => thisDateEvents.slice(0, LIST_LIMIT), [thisDateEvents])

    const theme = useTheme()
    return <Stack
        styles={{
            root: {
                position: "relative",
                overflow: 'hidden',
                gridColumn: props.i === 0 ? (1 + getLocalWeekDay(props.date)) + "" : undefined,
                border: "1px solid " + (props.isOtherMonth ? theme.palette.neutralLighter : theme.palette.neutralLight),
                cursor: "pointer",
                "&:hover": {
                    background: theme.palette.themeLighterAlt,
                    border: "1px solid " + theme.palette.themeLight,
                }
            }
        }}
        onClick={props.onClick}
    >
        <div style={{position: "absolute", top: 0, left: 8}} role="rowheader">
            <Text variant="large" style={{color: props.isOtherMonth ? theme.semanticColors.disabledText : undefined}}>
                <time dateTime={props.date.toISOString().split("T")[0]}>
                    {props.date.getDate()}
                </time>
            </Text>
        </div>
        <Stack
            style={{
                position: "absolute",
                top: 24, right: 12, left: 0,
                bottom: thisDateEvents.length > LIST_LIMIT ? 18 : 8,
                gap: 1
            }}
        >
            {truncatedDateEvents.map(ev => <SmallEventFrag ev={ev}/>)}
            {thisDateEvents.length > LIST_LIMIT && <Text
                style={{
                    color: theme.semanticColors.disabledText,
                    fontStyle: 'italic',
                    padding: '0 2px',
                    lineHeight: '18px',
                    height: 18
                }} variant='small' block
            >
                +{thisDateEvents.length - LIST_LIMIT}&nbsp;eventi
            </Text>}
        </Stack>
    </Stack>;
}
