import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {AuditLogEnvelope} from "../../../fusina-audit/entities/AuditLog";
import {useFilteredAuditLogs} from "../hooks/useFilteredAuditLogs";
import {
    CommandBar,
    MessageBar,
    MessageBarType,
    ProgressIndicator,
    Spinner,
    SpinnerSize,
    Stack,
    TextField,
    useTheme,
} from "@fluentui/react";
import {PageSetting} from "../contexts/PageContext";

import * as echarts from 'echarts/core';
import {
    DataZoomComponent,
    DataZoomComponentOption,
    GridComponent,
    GridComponentOption,
    MarkLineComponent,
    TitleComponent,
    TitleComponentOption,
    TooltipComponent,
    TooltipComponentOption
} from 'echarts/components';
import {BoxplotChart, BoxplotSeriesOption, LineChart, LineSeriesOption} from 'echarts/charts';
import {UniversalTransition} from 'echarts/features';
import {CanvasRenderer} from 'echarts/renderers';

// echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    MarkLineComponent,
    DataZoomComponent,
    CanvasRenderer,
    UniversalTransition,
    BoxplotChart,
    // ToolboxComponent,
]);

type EChartsOption = echarts.ComposeOption<TitleComponentOption | TooltipComponentOption | GridComponentOption | LineSeriesOption | DataZoomComponentOption | BoxplotSeriesOption>;

const MIN_DATE = new Date(Date.now() - 24 * 3600_000).toISOString().split('T')[0]

export const AdminAuditGraphs: React.FC = () => {
    const [filter, setFilter] = useState(`select(.t > "${MIN_DATE}") | select((.m.type=="System" and (.m.msg | startswith("SSE "))) or (.m.type=="Login") or (.m.sessionId)) | {t, m:{type: .m.type, sessionId: .m.sessionId, msg: .m.msg, email: .m.email}}`)

    const {
        rows,
        error,
        pending,
    } = useFilteredAuditLogs(filter)

    const graphRef = useRef<HTMLDivElement>()

    const [graph, setGraph] = useState<echarts.ECharts | undefined>(undefined)

    const theme = useTheme()

    useEffect(() => {
        if (graphRef.current) {
            const g = echarts.init(graphRef.current)
            g.setOption<EChartsOption>({
                // toolbox: {
                //     feature: {
                //         dataZoom: {
                //             yAxisIndex: 'none'
                //         },
                //         // restore: {},
                //         // saveAsImage: {}
                //     }
                // },
                tooltip: {
                    trigger: 'axis',
                    // formatter: function (params: any) {
                    //     const [date, concurrents] = params[0].value
                    //     return (
                    //         date.toLocaleString(undefined, {
                    //             dateStyle: 'short',
                    //             timeStyle: 'medium',
                    //         }) +
                    //         ' : ' +
                    //         concurrents
                    //     );
                    // },
                    // axisPointer: {}
                },
                grid: {
                    top: 150,
                },
                xAxis: {
                    type: 'time',
                    splitLine: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    minInterval: 1,
                    // boundaryGap: ['0%', '100%'],
                    // minorSplitLine: {
                    //     show: true
                    // },
                    splitLine: {
                        show: true
                    },
                },
                dataZoom: [
                    {
                        type: 'inside',
                        xAxisIndex: [0],
                        filterMode: 'filter',
                        minValueSpan: 1800_000,
                    },
                    {
                        type: 'slider',
                        xAxisIndex: [0],
                        filterMode: 'filter',
                        height: 20,
                        minValueSpan: 1800_000,
                    },
                    {
                        type: 'slider',
                        yAxisIndex: [0],
                        filterMode: 'none',
                        width: 20,
                    }
                ],
                series: [
                    {
                        name: 'Connessioni SSE',
                        type: 'line',
                        showSymbol: false,
                        data: [],
                        color: theme.palette.themePrimary,
                        lineStyle: {
                            width: 1
                        },
                        areaStyle: {
                            color: theme.palette.themePrimary,
                            opacity: 0.5,
                        },
                        sampling: 'none',
                        connectNulls: true,
                        step: 'end',
                    },
                    {
                        name: 'Sessioni',
                        type: 'line',
                        showSymbol: false,
                        data: [],
                        color: theme.palette.yellow,
                        lineStyle: {
                            width: 1
                        },
                        areaStyle: {
                            color: theme.palette.yellowLight,
                            opacity: 0.1,
                        },
                        sampling: 'none',
                        connectNulls: true,
                        step: 'end',
                    }
                ]
            })
            setGraph(g)
            const rh = () => g.resize()
            window?.addEventListener?.('resize', rh)
            return () => {
                g.dispose()
                setGraph(undefined)
                window?.removeEventListener?.('resize', rh)
            }
        }
    }, [graphRef?.current])

    useEffect(() => {
        if (rows && graph) {
            const ssePoints = SSEReduce(rows)
            graph.setOption<EChartsOption>({
                // yAxis: {
                //     max: Math.max(...filterTopOutliers(filterTopOutliers(filterTopOutliers(filterTopOutliers(filterTopOutliers(ssePoints.map(p => p.concurrent))))))),
                // },
                series: [
                    {
                        data: ssePoints.map(point => [
                            point.date,
                            point.concurrent// === 0 ? null : point.concurrent,
                        ]),
                        markLine: {
                            // animation: false,
                            label: {
                                formatter: '{b}',
                                align: 'left',
                                rotate: 30,
                            },
                            lineStyle: {
                                color: theme.palette.themeDark,
                                type: "solid",
                                width: 1,
                                opacity: 0.5,
                            },
                            symbol: ['none', 'none'],
                            data: rows.flatMap(row => {
                                if (row.m.type === 'Login') {
                                    return [
                                        {xAxis: new Date(row.t) as unknown as string, name: row.m.email},
                                    ]
                                } else {
                                    return []
                                }
                            }),
                        }
                    },
                    {
                        data: SessionsReduce(rows).map(point => [
                            point.date,
                            point.sessions,
                        ])
                    }
                ]
            })
        }
    }, [graph, rows])

    const [isFilterShown, setIsFilterShown] = useState<boolean>(false)

    console.debug('AuditAdminGraphs render', rows?.length, 'rows')

    return <>
        <PageSetting title="Audit"/>

        <CommandBar
            items={[]}
            farItems={[
                {
                    key: 'jq',
                    text: 'JQ',
                    iconProps: {
                        iconName: 'FilterSettings',
                    },
                    onClick: () => setIsFilterShown(v => !v)
                },
            ]}
        />
        {isFilterShown && <Stack horizontal>
            {pending && <Spinner size={SpinnerSize.xSmall} styles={{root: {width: 24}}}/> ||
            <div style={{width: 24}}>&nbsp;</div>}
            <Stack.Item grow={1}>
                <TextField
                    value={filter}
                    onChange={((event, newValue) => setFilter(newValue))}
                />
            </Stack.Item>
            <div style={{width: 14}}>
            </div>
        </Stack>}
        {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
        <div>
            <ProgressIndicator
                percentComplete={undefined}
                styles={{root: pending ? {} : {opacity: 0}, itemProgress: {padding: 0}}}
            />
            <div ref={graphRef} style={{height: 400}}>
            </div>
        </div>
    </>
}

function SSEReduce(rows: AuditLogEnvelope[]) {
    const opens = new Map<string, Date>()
    const points: {
        date: Date,
        concurrent: number,
    }[] = []

    for (let row of rows) {
        const date = new Date(row.t)

        // Auto-close outdated points
        for (let [open_id, open_date] of opens.entries()) {
            const auto_close_ts = open_date.getTime() + 600_000
            if (date.getTime() > auto_close_ts) {
                opens.delete(open_id)
                points.push({
                    date: new Date(auto_close_ts),
                    concurrent: opens.size,
                })
            }
        }

        // Push repeated data for minimum frequency
        let min_freq_ts: number;
        while (points.length > 0 && date.getTime() > (min_freq_ts = points[points.length - 1].date.getTime() + 600_000)) {
            points.push({
                date: new Date(min_freq_ts),
                concurrent: points[points.length - 1].concurrent,
            })
        }

        // Maybe get SSE data
        if (row.m?.type === 'System') {
            const match = row.m.msg.match(/SSE ([a-zA-Z0-9\-_]{8,32}) (Started\.|Closed\.|End of async iteration\.)/i)
            if (match) {
                const id = match[1]
                const ev = match[2]
                if (ev && id) {
                    if (ev === 'Started.') {
                        opens.set(id, date)
                    }
                    if (ev === 'Closed.' || ev === 'End of async iteration.') {
                        opens.delete(id)
                    }

                    // Push new data point
                    points.push({
                        date,
                        concurrent: opens.size,
                    })
                }
            }
        }
    }

    return points
}

function SessionsReduce(rows: AuditLogEnvelope[]) {
    const sessions = new Map<string, Date>()
    const points: {
        date: Date,
        sessions: number,
    }[] = []

    for (let row of rows) {
        const date = new Date(row.t)

        // Auto-close outdated points
        for (let [session_id, session_date] of sessions.entries()) {
            const auto_close_ts = session_date.getTime() + 3600_000
            if (date.getTime() > auto_close_ts) {
                sessions.delete(session_id)
                points.push({
                    date: new Date(auto_close_ts),
                    sessions: sessions.size,
                })
            }
        }

        // Push repeated data for minimum frequency
        let min_freq_ts: number;
        while (points.length > 0 && date.getTime() > (min_freq_ts = points[points.length - 1].date.getTime() + 600_000)) {
            points.push({
                date: new Date(min_freq_ts),
                sessions: points[points.length - 1].sessions,
            })
        }

        // Maybe get session data
        if ((row.m as any)?.sessionId) {
            const sessionId = (row.m as any)?.sessionId as string
            sessions.set(sessionId, date)

            // Push new data point
            points.push({
                date,
                sessions: sessions.size,
            })
        }
    }

    return points
}


// function mean(vv: number[]): number {
//     return vv.reduce((a, b) => a + b, 0) / (vv.length || 1)
// }
//
// function filterTopOutliers(vv: number[], s: number = 2): number[] {
//     const m0 = mean(vv)
//     const m1 = mean(vv.map(v => v * v))
//     const std = Math.sqrt(m1 - m0 * m0)
//     const z = m0 + s * std
//     return vv.filter(v => v < z)
// }
