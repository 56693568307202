import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useId} from "@fluentui/react-hooks";
import {
    Callout,
    DefaultButton,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Text
} from "@fluentui/react";
import {Stack} from "@fluentui/react/lib/Stack";

function tryMaybeSerialize(x: object): string | undefined {
    try {
        return JSON.stringify(x)
    } catch (e) {
        console.debug(e)
        return undefined
    }
}

export const CancelEditsBtn: React.FC<{
    /** object to be tracked for changes to ask for confirm before cancelling */
    editable?: object
    onClick: () => void
}> = props => {

    const [pending, setPending] = useState<boolean>(false)
    const [confirming, setConfirming] = useState<boolean>(false)

    // NOTE: we are going to store the editing object in order to detect changes and ask confirm before losing them
    const [preEdit, setPreEdit] = useState<string>(undefined)
    useEffect(() => {
        if (!props.editable) {
            setPreEdit(undefined)
        }
        if (!preEdit) {
            setPreEdit(tryMaybeSerialize(props.editable))
        }
    }, [props.editable])
    // NOTE: reaction on obj instance is sufficient as successive edits are found with no need for reaction

    useEffect(() => {
        const h: OnBeforeUnloadEventHandler = ev => {
            ev.preventDefault()
            // @ts-ignore
            return event.returnValue = "Perderai le modifiche effettuate.";
        }
        // TODO?: FIXME?: beforeunload is not fired on navigation with location hash change (incl. back button).
        window?.addEventListener?.('beforeunload', h, {capture: true})
        return () => {
            window?.removeEventListener?.('beforeunload', h, {capture: true})
        }
    }, [])

    const handle = useCallback(() => {
        setConfirming(false)
        setPending(false)
        props.onClick?.()
    }, [props.onClick])

    const preConfirmHandle = useCallback(() => {
        const postEdit = tryMaybeSerialize(props.editable)
        console.debug({preEdit, postEdit})
        if (preEdit && postEdit && postEdit === preEdit) {
            return handle()
        }
        setConfirming(true)
        setPending(true)
        setTimeout(() => {
            setPending(false)
        }, 500)
    }, [preEdit])

    const buttonId = useId('CancelEditsBtn-preConfirm');

    return <>
        <DefaultButton id={buttonId} text="Annulla" onClick={preConfirmHandle}/>
        <Callout
            hidden={!confirming}
            coverTarget
            role="dialog"
            style={{padding: '20px 24px'}}
            onDismiss={() => setConfirming(false)}
            target={'#' + buttonId}
            isBeakVisible={false}
            setInitialFocus
        >
            <Text block variant="xLarge" style={{fontWeight: 200, marginBottom: 12}}>
                Confermi?
            </Text>
            <MessageBar messageBarType={MessageBarType.severeWarning}>
                Perderai le modifiche effettuate.
            </MessageBar>
            <Stack horizontal style={{marginTop: 20}} tokens={{childrenGap: 'm'}}>
                <PrimaryButton onClick={handle} className="CancelEditsBtn-Confirm"
                               text="Conferma" disabled={pending}/>
                {pending && <Spinner size={SpinnerSize.small}/>}
            </Stack>
        </Callout>
    </>
}
