import {createRmiStubs} from "../../js-rmi-react/createRmiStubs";
import {FusinaLoginRmiNaming, FusinaRmiNaming} from "../../fusina-dms-rmi-api/naming";

export const SERVER_BASE_URL = `${location.protocol}//${location.hostname}:${location.port}`

export const RMI_API_ROOT = `${SERVER_BASE_URL}/api/m`

export const RMI = createRmiStubs<FusinaRmiNaming>(RMI_API_ROOT, [
    'JobsAdmin',
    'UsersAdmin',
    'SecurityContext',
    'JobsList',
    'Letters',
    'EcoOds',
    'NI',
    'Notifications',
    'GdL',
    'Audit',

    'ITP',
    'ITPRevision',
    'ITPEvents',
    'ITPCertificates',
    'ITPEventAttachments',
])

export const PUBLIC_RMI = createRmiStubs<FusinaLoginRmiNaming>(`${SERVER_BASE_URL}/api/p`, [
    'auth',
])
