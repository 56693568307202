import * as React from 'react'
import {Icon, Text, useTheme} from '@fluentui/react'
import {getReceived, Letter} from "../../../fusina-letters/entities/Letter";

export const LetterStatusIcon: React.FC<{ letter: Letter }> = ({letter}) => {
    const theme = useTheme()
    if (getReceived(letter)) {
        return <div className="LetterStatusIcon"
                    style={{position: "relative", display: "inline-block", fontSize: '1.5em'}} title="Ricevuto">
            &nbsp; &nbsp; &nbsp;
            <Icon iconName="CheckMark"
                  style={{position: "absolute", left: 6, top: 0, color: theme.palette.themePrimary}}/>
            <Icon iconName="CheckMark"
                  style={{position: "absolute", left: -2, top: 0, color: theme.semanticColors.bodyBackground}}/>
            <Icon iconName="CheckMark"
                  style={{position: "absolute", left: -1, top: 0, color: theme.semanticColors.bodyBackground}}/>
            <Icon iconName="CheckMark"
                  style={{position: "absolute", left: 1, top: 0, color: theme.semanticColors.bodyBackground}}/>
            <Icon iconName="CheckMark"
                  style={{position: "absolute", left: 2, top: 0, color: theme.semanticColors.bodyBackground}}/>
            <Icon iconName="CheckMark"
                  style={{position: "absolute", left: 0, top: 0, color: theme.palette.themePrimary}}/>
        </div>
    }
    if (letter.sent) {
        return <Icon className="LetterStatusIcon" iconName="CheckMark" title="Inviato, in attesa di ricevuta"
                     style={{fontSize: '1.5em', color: theme.palette.neutralTertiaryAlt}}/>
    }
    if (letter.signatures?.length <= 0) {
        if (letter.type === 'EcoOdS' && letter.estimate?.signatures?.length > 0) {
            return <Icon className="LetterStatusIcon" iconName="CirclePause"
                         title="Preventivo (ordine in attesa, non inviato)"
                         style={{fontSize: '1.5em', color: theme.semanticColors.warningIcon}}/>
        }

        if (letter._is_hole) {
            return <></>
        }

        if (letter.isPrivateAsDraft) {
            return <span className="LetterStatusIcon" title="Bozza">
            <Text variant="xSmall" style={{
                // letterSpacing: -1,
                color: theme.semanticColors.severeWarningIcon
            }}>[Bozza privata]</Text>
            </span>
        }

        return <span className="LetterStatusIcon" title="Bozza">
            <Text variant="xSmall" style={{
                // letterSpacing: -1,
                color: theme.semanticColors.severeWarningIcon
            }}>[Bozza]</Text>
        </span>
    }
    // else: signed, not sent
    // TODO?: the policy could tell whether or not the letter is ready to be sent.
    return <Icon className="LetterStatusIcon" iconName="CirclePause"
                 title="Non inviato (in attesa di firme o invio da altri utenti)"
                 style={{fontSize: '1.5em', color: theme.semanticColors.warningIcon}}/>
}
