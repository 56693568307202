/** From File to base64 data url of compressed image */
export default function recodeImageFileToURL(opts: {
    file: File,
    maxWidth: number,
    maxHeight: number,
    type?: string,
    quality?: number,
}): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        try {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                return reject(new Error('Could not get canvas context.'))
            }
            ctx.imageSmoothingEnabled = true
            ctx.imageSmoothingQuality = 'high'

            const img = new Image();
            const obj = URL.createObjectURL(opts.file);
            img.onload = function () {
                const srcWidth = img.width
                const srcHeight = img.height
                const scale = Math.min(1, opts.maxWidth / srcWidth, opts.maxHeight / srcHeight);
                const width = scale * srcWidth
                const height = scale * srcHeight
                canvas.width = width
                canvas.height = height

                ctx.drawImage(img, 0, 0, width, height);

                URL.revokeObjectURL(obj)

                const url = canvas.toDataURL(opts.type, opts.quality)
                resolve(url)
            }
            img.onerror = reject
            img.src = obj  // Start
        } catch (e) {
            reject(e)
        }
    })
}
