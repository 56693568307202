import * as React from 'react'
import {useMemo} from 'react'
import {Icon, ThemeProvider, useTheme} from '@fluentui/react'
import {Base8s5gf76h} from "./layout/Base8s5gf76h";
import {OverTopBar} from "./components/OverTopBar";
import {TopBar} from "./components/TopBar";
import {NavLink} from "react-router-dom";
import {usePageContext} from "./contexts/PageContext";
import {useJobContext} from "./contexts/JobContext";
import {BasePageFooter} from "./components/BasePageFooter";
import {FrontendComponent, usePermittedFrontendComponents} from "./hooks/usePermittedFrontendComponents";
import {myMatrixTheme, myTheme} from "./themes";


export const Page: React.FC<React.PropsWithChildren<{}>> = props => {
    const {
        pageTheme
    } = usePageContext()

    return <ThemeProvider theme={pageTheme === 'matrix' ? myMatrixTheme : myTheme}>
        <PageInner {...props}/>
    </ThemeProvider>
}

const PageInner: React.FC<React.PropsWithChildren<{}>> = props => {
    const {
        title,
        iconName,
        sideChildren,
        hideNavLinks,
        pageBackground,
        pageTheme,
    } = usePageContext()

    const theme = useTheme();

    const navLinkStyler = useMemo<(props: { isActive: boolean }) => React.CSSProperties>(() => ({isActive}) => ({
        background: isActive ? theme.palette.neutralLighter : undefined,
        color: theme.palette.neutralDark,
        fontWeight: isActive ? 600 : 400,
        boxShadow: isActive
            ? `inset 0 -1px 0 0 ${theme.palette.neutralLight}, inset -16px 0px 8px -10px ${theme.palette.neutralLight}`
            : undefined,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    }), [theme])

    const {job} = useJobContext()
    const comps = usePermittedFrontendComponents()

    // NOTE: in NavLink, the trailing slash determines the behaviour of "active" under hierarchical links.

    return <Base8s5gf76h
        overTopBarStyle={pageTheme === 'matrix'
            ? {boxShadow: 'inset 0 -2px 0 -1px ' + theme.palette.neutralLight}
            : undefined}
        pageBackground={pageBackground ?? theme.semanticColors.bodyBackground}
        overTopBarChildren={<OverTopBar/>}
        topBarChildren={(title || iconName) && <TopBar
            icon={iconName && <Icon iconName={iconName}/>}
            title={title}/>}
        navLinks={hideNavLinks ? undefined : <>
            <NavLink to="/" style={navLinkStyler}>Home</NavLink>

            {job && <>
                {/*<a>Commessa {job?.code}</a>*/}
                {/*<NavLink to={`/jobs/${job._id}`} style={navLinkStyler}>Commessa {job?.code}</NavLink>*/}
                <NavLink to={`/jobs/${job._id}/`} style={navLinkStyler}>Commessa {job?.code}</NavLink>
                {comps.has(FrontendComponent.OdsNi) &&
                <NavLink to={`/jobs/${job._id}/letters`} style={navLinkStyler}> &nbsp; &nbsp; Lettere OdS/NI</NavLink>}
                {comps.has(FrontendComponent.GdL) &&
                <NavLink to={`/jobs/${job._id}/gdl/`} style={navLinkStyler}> &nbsp; &nbsp; Giornale del
                    Lavoro</NavLink>}
                {comps.has(FrontendComponent.ITP) && <>
                    <NavLink to={`/jobs/${job._id}/itp`} style={navLinkStyler}> &nbsp; &nbsp; Piani Controllo
                        Qualità</NavLink>
                    <NavLink to={`/jobs/${job._id}/itpCal`} style={navLinkStyler}>
                        &nbsp; &nbsp; &nbsp; &nbsp; Calendario</NavLink>
                </>}
            </>}

            {comps.has(FrontendComponent.ITPStats) && <>
                <NavLink to="/itpStats" style={navLinkStyler}> Statistiche PCQ</NavLink>
            </>}

            {comps.has(FrontendComponent.ITPGlobalEventsCal) && <>
                <NavLink to="/itpGlobalCal" style={navLinkStyler}> Calendario PCQ</NavLink>
            </>}

            {comps.has(FrontendComponent.admin) && <>
                <a>Admin</a>
                <NavLink to="/admin/users" style={navLinkStyler}> &nbsp; &nbsp; Utenti</NavLink>
                <NavLink to="/admin/companies" style={navLinkStyler}> &nbsp; &nbsp; Imprese</NavLink>
                <NavLink to="/admin/jobs" style={navLinkStyler}> &nbsp; &nbsp; Commesse</NavLink>
            </>}

        </>}
        mainChildren={props.children}
        sideChildren={sideChildren}
        footer={<BasePageFooter/>}
    />
}
