import {Icon, Label, Spinner, SpinnerSize, useTheme} from "@fluentui/react";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import * as React from "react";
import {useCallback, useRef, useState} from "react";
import recodeImageFileToURL from "../../../dom-image-recode/recodeImageFileToURL";

export const ImageUrlInput: React.FC<{
    label?: string
    value?: string
    onChange?: (newValue: string) => void
}> = props => {

    const theme = useTheme()
    const inputRef = useRef<HTMLInputElement>();

    const [error, setError] = useState(undefined)
    const [loading, setLoading] = useState<boolean>(false)
    const handleInputRef = useCallback(() => {
        const file = inputRef?.current?.files?.item?.(0)
        if (!file) {
            return
        }
        setError(undefined)
        setLoading(true)
        recodeImageFileToURL({
            file,
            maxHeight: 150,
            maxWidth: 375,
            type: 'image/png',
            // quality: 0.9,
        })
            .then(url => {
                props.onChange?.(url)
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }, [inputRef])


    return <>
        {props.label && <Label>{props.label}</Label>}
        <label style={{
            display: 'block',
            position: 'relative',
            padding: 0, //theme.spacing.s1,
            border: '1px solid ' + theme.semanticColors.inputBorder,
            borderRadius: 2,
            cursor: 'pointer',
        }}>
            <MaybeErrorMessageBar error={error}/>

            {loading
                ? <Spinner size={SpinnerSize.large} style={{margin: '2em auto', textAlign: 'center'}}/>
                : <>

                    {props.value
                        ? <img src={props.value} alt="" style={{margin: '0.5em auto', display: 'block'}}/>
                        : <div style={{
                            margin: '2em auto',
                            textAlign: 'center',
                            color: theme.semanticColors.disabledText
                        }}>
                            <Icon iconName="Photo2Add" style={{fontSize: '1.5em'}}/>
                        </div>}

                    <input
                        style={{display: 'none'}}
                        type="file"
                        accept="image/jpeg,image/jpg,image/bmp,image/png,image/gif,image/webp,image/svg+xml"
                        ref={inputRef}
                        onChange={handleInputRef}
                    />

                </>}
        </label>
    </>
}
