import * as React from 'react'
import {Label, useTheme} from "@fluentui/react";

import {CKEditor} from '@ckeditor/ckeditor5-react';

// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import {EditorConfig} from "@ckeditor/ckeditor5-core/src/editor/editorconfig";
// import List from '@ckeditor/ckeditor5-list';
// import Table from '@ckeditor/ckeditor5-table';
import sanitizeHtml from 'sanitize-html';


const CKE_CONFIG: EditorConfig = {
    toolbar: ['undo', 'redo', 'numberedList', 'bulletedList', 'bold', 'italic', 'insertTable'],
    table: {
        contentToolbar: [],
        tableToolbar: [],
    }
}

function preClean(html: unknown): string {
    try {
        return sanitizeHtml(html?.toString?.(), {
            allowedTags: [
                'b', 'i', 'em', 'strong',
                'br', 'p',
                'table', 'tbody', 'thead', 'tfoot', 'tr', 'td',
                'ol', 'ul', 'li',
                'div', 'span',
            ],
            allowedSchemes: [],
        })
    } catch (e) {
        console.warn(e)
    }
    return ''
}

export const Editor: React.FC<{
    initialValue?: unknown
    label: string
    onChange: (value) => void
}> = props => {

    const theme = useTheme()
    return <>
        <Label>{props.label}</Label>
        <div style={{
            padding: 0, //theme.spacing.s1,
            border: '1px solid ' + theme.semanticColors.inputBorder,
            width: '100%',
            minWidth: 600,
            borderRadius: 2
        }}>
            <CKEditor
                editor={ClassicEditor}
                data={preClean(props.initialValue)}
                config={CKE_CONFIG}
                onChange={(event, editor) => {
                    // noinspection TypeScriptValidateJSTypes
                    const data = editor.getData();
                    props.onChange(preClean(data))
                }}
            />
        </div>
    </>
}
