export enum RoleEnum {
    CDL = "CDL",
    CDL_Q = "CDL_Q",
    SM = "SM",
    PM = "PM",
    Admin = "Admin",

    /** Contractor-side user for OdS/NI, with permissions to read quality control plans */
    Appaltatore = "Appaltatore",

    /** Contractor-side user responsible for quality control plans */
    AppaltatoreCQ = "AppaltatoreCQ",


    /** New requirement dated on 2022/07/13
     * buyer-side supervisor, possibly external w.r.t. the buyer business. */
    Supervisor20220713 = "Supervisor20220713",

    /** New requirement from ~august~september 2022
     * buyer-side "supervisor", different from the previous, possibly external w.r.t. the buyer business.
     * Has permissions to create drafts of OdS, without permission to write them. */
    OdSCompiler202209 = "OdSCompiler202209",

    // /** New requirement dated on 2022/07/13 */
    // EstimatesSupervisor = "EstimatesSupervisor",
    // /** New requirement dated on 2022/07/13 */
    // ExpensesSupervisor = "ExpensesSupervisor",

    /** New requirement from ~october~november 2023
     * buyer-side supervisors that can see the PCQs global calendar, statistics, events, documents, in read-only.
     */
    SupervisorQ202310 = "SupervisorQ202310",

    /** New requirement from ~october~november 2023
     * contractor-side read-only access to individual PCQs.
     * Should only be used in itp-scoped grants.
     */
    QObserver202310 = "QObserver202310",

    /** New requirement from ~october~november 2023
     * base read-only access to jobs as support for itp-scoped QObserver202310.
     */
    JobReadAccess202310 = "JobReadAccess202310",

    /** Construction Manager
     * Buyer-side role between the CDL and the SM.
     */
    CM202311 = "CM202311",

    /** Commissioning Manager
     * Buyer-side role between the CDL and the SM.
     */
    CommissioningMng202409 = "CommissioningMng202409"
}

export const RoleLabels: Record<RoleEnum, string> = {
    [RoleEnum.CDL]: 'CDL',
    [RoleEnum.CDL_Q]: 'CDL-Q',
    [RoleEnum.SM]: 'SM',
    [RoleEnum.PM]: 'PM',
    [RoleEnum.Admin]: 'Amministratore',
    [RoleEnum.Appaltatore]: 'Appaltatore (OdS/NI, GdL)',
    [RoleEnum.AppaltatoreCQ]: 'Appaltatore-CQ',
    [RoleEnum.Supervisor20220713]: 'Supervisore (OdS/NI, GdL)',
    [RoleEnum.OdSCompiler202209]: 'Compilatore OdS',
    [RoleEnum.SupervisorQ202310]: 'Supervisore (PCQ)',
    [RoleEnum.QObserver202310]: 'Osservatore PCQ',
    [RoleEnum.JobReadAccess202310]: '(accesso base)',
    [RoleEnum.CM202311]: 'Construction Manager',
    [RoleEnum.CommissioningMng202409]: 'Commissioning Manager',
}

export const RoleSignatureLabels: Record<RoleEnum, string> = {
    [RoleEnum.CDL]: 'CDL',
    [RoleEnum.CDL_Q]: 'CDL-Q',
    [RoleEnum.SM]: 'SM',
    [RoleEnum.PM]: 'PM',
    [RoleEnum.Admin]: 'Amministratore',
    [RoleEnum.Appaltatore]: 'Appaltatore',
    [RoleEnum.AppaltatoreCQ]: 'Appaltatore-CQ',
    [RoleEnum.Supervisor20220713]: 'Supervisore',
    [RoleEnum.OdSCompiler202209]: 'Compilatore OdS',
    [RoleEnum.SupervisorQ202310]: 'Supervisore CQ',
    [RoleEnum.QObserver202310]: 'Osservatore PCQ',
    [RoleEnum.JobReadAccess202310]: '(nessuno)',
    [RoleEnum.CM202311]: 'Construction Manager',
    [RoleEnum.CommissioningMng202409]: 'Commissioning Manager',
}

export const RoleSignaturePdfLabels: Record<RoleEnum, string> = {
    [RoleEnum.CDL]: 'CDL',
    [RoleEnum.CDL_Q]: 'CDL-Q',
    [RoleEnum.SM]: 'SM',
    [RoleEnum.PM]: 'PM',
    [RoleEnum.Admin]: 'Amministratore',
    [RoleEnum.Appaltatore]: 'Appaltatore',
    [RoleEnum.AppaltatoreCQ]: 'Appaltatore-CQ',
    [RoleEnum.Supervisor20220713]: 'Supervisore',
    [RoleEnum.OdSCompiler202209]: 'Compilatore OdS',
    [RoleEnum.SupervisorQ202310]: 'Supervisore CQ',
    [RoleEnum.QObserver202310]: 'Osservatore PCQ',
    [RoleEnum.JobReadAccess202310]: '',
    [RoleEnum.CM202311]: 'Construction Manager',
    [RoleEnum.CommissioningMng202409]: 'Commissioning Manager',
}
