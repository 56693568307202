import * as React from 'react'
import {useCallback, useMemo, useState} from 'react'
import {CommandBar, DetailsList, PrimaryButton, Selection, SelectionMode, useTheme} from '@fluentui/react'
import {SimpleDialog} from "../components/SimpleDialog";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useForceUpdate} from '@fluentui/react-hooks';
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {IColumn} from "@fluentui/react/src/components/DetailsList/DetailsList.types";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import useThrottledCallback from "../hooks/useThrottledCallback";
import {Company} from "../../../fusina-jobs/entities/Company";
import {CompanyEditor} from "../components/CompanyEditor";
import {PageSetting} from "../contexts/PageContext";
import {CmdSearchBox} from "../components/CmdSearchBox";
import {CancelEditsBtn} from "../components/CancelEditsBtn";

const companiesColumns: IColumn[] = [
    {
        fieldName: "denomination",
        key: "denomination",
        name: "Denominazione",
        targetWidthProportion: 1,
        minWidth: 300,
        flexGrow: 1,
    },
]


export const AdminCompanies: React.FC<{}> = () => {
    const theme = useTheme()
    const rmi = useFusinaRmi()

    const [search, setSearch] = useState<string | null>(null)

    const {
        throttledCallback: searchHandler
    } = useThrottledCallback<(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void>(
        (event, newValue) => {
            setSearch(newValue)
        }, 200, [])

    const {
        data: storedCompanies_,
        error: storedCompaniesError,
        refresh: storedCompaniesRefresh,
    } = useFusinaRmiResource('JobsAdmin', 'listCompanies'); //, null, search)

    const storedCompanies = useMemo(() => {
        if (!search) {
            return storedCompanies_
        }
        const searchLow = search.toLowerCase()
        return storedCompanies_.filter(com => JSON.stringify([
            // NOTE: do not consider ALL the fields, because the base64 logo url is likely to contain any 3-char search string
            com.denomination,
        ]).toLowerCase().includes(searchLow))
    }, [storedCompanies_, search])

    const forceUpdate = useForceUpdate();

    const selection = useMemo<Selection<{ key: string, ID: string }>>(() =>
        new Selection({onSelectionChanged: forceUpdate}), [])

    const [editingCompany, setEditingCompany] = useState<Company | undefined>(undefined)

    const editCompany = useCallback((id: string) => {
        setEditingCompany(
            JSON.parse(JSON.stringify( // deep clone
                storedCompanies.find(job => job._id === id))))
    }, [storedCompanies])

    return <>
        <PageSetting title="Admin Imprese"/>

        <CommandBar
            style={{borderBottom: "1px solid", borderBottomColor: theme.palette.neutralLight}}
            items={[
                {
                    key: 'new',
                    text: 'Nuova',
                    iconProps: {
                        iconName: 'Add'
                    },
                    onClick() {
                        setEditingCompany({
                            denomination: "",
                            logoURL: "",
                        })
                    }
                },
            ]}
            farItems={[
                {
                    key: "search",
                    onRender: () => <CmdSearchBox onChange={searchHandler}/>
                }
            ]}/>

        <DetailsList
            //onRenderDetailsHeader={() => null}
            selection={selection}
            columns={companiesColumns}
            items={storedCompanies ?? []}
            selectionMode={SelectionMode.single}
            onItemInvoked={(item) => editCompany(item._id)}
        />

        <MaybeErrorMessageBar error={storedCompaniesError}/>

        {editingCompany && <SimpleDialog
            subText={""}
            title={editingCompany._id ? 'Modifica impresa' : 'Nuova impresa'}
            hidden={false}
            buttons={[
                <CancelEditsBtn editable={editingCompany} onClick={() => setEditingCompany(undefined)}/>,
                <PrimaryButton key="save" onClick={() => {
                    (editingCompany._id
                        ? rmi.JobsAdmin.updateCompany(editingCompany._id, editingCompany)
                        : rmi.JobsAdmin.createCompany(editingCompany))
                        .then(() => {
                            storedCompaniesRefresh()
                            setEditingCompany(undefined)
                        })
                        .catch(e => {
                            console.error('Error while trying to save job', e)
                        })
                        .finally(() => forceUpdate());

                }}>Salva</PrimaryButton>
            ]}>
            <CompanyEditor company={editingCompany}/>
        </SimpleDialog>}
    </>
}
