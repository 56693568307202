import * as React from 'react'
import {useMemo, useState} from 'react'
import {ProgressIndicator} from "@fluentui/react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {useNavigate, useParams} from "react-router-dom";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import {Calendar} from "../components/calendar";
import {ITPEvent} from "../../../fusina-itp/entities/ITPEvent";
import {PageSetting} from "../contexts/PageContext";
import {ITPEventFilterCriteria} from "../../../fusina-itp/entities/ITPEventFilterCriteria";
import useThrottledCallback from "../hooks/useThrottledCallback";

export const ITPGlobalCalendarPage: React.FC = () => {

    const {view: url_view, date: url_date, eventId} = useParams()

    const [criteria, setCriteria_] = useState<ITPEventFilterCriteria | undefined>(undefined)

    const {
        throttledCallback: setCriteria,
        isDelaying,
    } = useThrottledCallback(setCriteria_, 500, [])

    const {
        data,
        error,
    } = useFusinaRmiResource('ITPEvents', 'find', criteria)

    const openEvent = useMemo(() =>
        data?.find?.(ev => ev._id === eventId), [data, eventId])
    // NOTE: openEvent depends on what is loaded, which depends on the filter criteria, which depends on Calendar;
    //       openEvent is initially undefined and is found only when viewing the week or month where the event starts.

    const navigate = useNavigate()

    if (data === undefined && error) {
        return <MaybeErrorMessageBar error={error}/>
    }

    return <>
        <PageSetting
            iconName="Trackers"
            title={<>
                <div style={{paddingRight: 15, lineHeight: '1.125em', textAlign: 'left'}}>
                    <div style={{fontSize: '0.95em'}}>Calendario Generale espletamento fasi PCQ</div>
                </div>
            </>}
            sideChildren={null}
        />

        <MaybeErrorMessageBar error={error}/>

        <ProgressIndicator
            percentComplete={undefined}
            styles={{root: (data === undefined || isDelaying) ? {} : {opacity: 0}, itemProgress: {padding: 0}}}
        />

        <div
            style={{
                margin: '12px 24px'
            }}
        >
            <Calendar
                events={data}
                onDatesRange={(min, max) => {
                    setCriteria({
                        by: 'dtStart',
                        dtStartMin: new Date(min),
                        dtStartMax: new Date(max),
                    })
                }}
                view={url_view}
                selectedDate={url_date}
                openEvent={openEvent}
                eventContextLabelGetter={(ev: ITPEvent) => ev?.itp?.job?.code}
                eventSmallFragContextLabelGetter={(ev: ITPEvent) => ev?.itp?.job?.code}
                onEventOpen={(ev: ITPEvent) => {
                    // NOTE: we replace the current location to support the back button and then we navigate.
                    navigate(`/itpGlobalCal/week/${ev?.dtStart}/${ev?._id}`, {replace: true})
                    setTimeout(() => {
                        navigate(`/jobs/${ev?.itp?.job?._id}/itp/${ev?.itp?._id}/events/${ev?._id}`)
                    }, 1)
                }}
                eventHrefGetter={(ev: ITPEvent) =>
                    `/jobs/${ev?.itp?.job?._id}/itp/${ev?.itp?._id}/events/${ev?._id}`}
            />
        </div>

    </>
}
