import * as React from 'react'
import {useMemo} from 'react'
import {useForceUpdate} from "@fluentui/react-hooks";
import {ComboBox, IComboBoxOption, TextField} from "@fluentui/react";
import {Stack} from '@fluentui/react/lib/Stack';
import {Job} from "../../../fusina-jobs/entities/Job";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {EcoItemsEditor} from "./EcoItemsEditor";

export const JobEditor: React.FC<{
    job: Job
}> = props => {

    const {
        data: storedCompanies,
        error: storedCompaniesError,
    } = useFusinaRmiResource('JobsAdmin', 'listCompanies')

    const forceUpdate = useForceUpdate();

    const companyOptions = useMemo<IComboBoxOption[]>(() => storedCompanies
        ?.map?.(company => ({
            key: company._id as string,
            text: company.denomination,
        })) ?? [], [storedCompanies])

    return <>
        <Stack>
            <MaybeErrorMessageBar error={storedCompaniesError}/>
            <Stack horizontal tokens={{childrenGap: "m"}}>
                <Stack.Item grow={1}>
                    <TextField
                        label="Codice" value={props.job.code}
                        onChange={(ev, newValue) => {
                            props.job.code = newValue ?? '';
                            forceUpdate()
                        }}/>
                </Stack.Item>
                <Stack.Item grow={1}>
                    <TextField
                        label="Nº Contratto" value={props.job.contract}
                        onChange={(ev, newValue) => {
                            props.job.contract = newValue ?? '';
                            forceUpdate()
                        }}/>
                </Stack.Item>
            </Stack>
            <ComboBox
                label="Committente"
                className="JobEditor-Buyer"
                options={companyOptions}
                selectedKey={props.job.buyer}
                onChange={(ev, newValue) => {
                    props.job.buyer = newValue.key?.toString?.() ?? '';
                    forceUpdate()
                }}
            />
            <ComboBox
                label="Appaltatore"
                className="JobEditor-Appaltatore"
                options={companyOptions}
                selectedKey={props.job.contractor}
                onChange={(ev, newValue) => {
                    props.job.contractor = newValue.key?.toString?.() ?? '';
                    forceUpdate()
                }}/>
            <TextField
                label="Titolo commessa" value={props.job.title}
                onChange={(ev, newValue) => {
                    props.job.title = newValue ?? '';
                    forceUpdate()
                }}/>
            <EcoItemsEditor items={props.job.contractItems}/>
        </Stack>
    </>
}
