import * as React from "react";
import {useContext, useEffect, useMemo, useRef} from "react";
import {DateRangeType, Stack, Text, useTheme} from "@fluentui/react";
import {getLocalWeekDay, MONTHS_IT, WEEK_DAYS_ABBR_IT} from './commons'
import {MultiCalTopBar} from "./MultiCalTopBar";
import {CalContext} from "./CalContext";
import {WeekHourFrag} from "./WeekHourFrag";

const datesOfWeek: (date: Date) => Date[] = date => {
    const d = new Date(date)
    d.setDate(d.getDate() - getLocalWeekDay(d))
    const dd: Date[] = new Array(7)
    for (let i = 0; i < 7; i++) {
        dd[i] = new Date(d)
        d.setDate(d.getDate() + 1)
    }
    return dd
}

export const WeekCalView: React.FC = () => {
    const [cal, setCalState] = useContext(CalContext)

    const prev = useMemo(() => {
        const d = new Date(cal.selectedDate)
        d.setDate(d.getDate() - 7)
        return d
    }, [cal.selectedDate])

    const next = useMemo(() => {
        const d = new Date(cal.selectedDate)
        d.setDate(d.getDate() + 7)
        return d
    }, [cal.selectedDate])

    const dates = useMemo(() => datesOfWeek(cal.selectedDate), [cal.selectedDate])

    useEffect(() => {
        if (dates) {
            const minDate = new Date(dates[0])
            minDate.setHours(0, 0, 0, 0)
            const maxDate = new Date(dates[dates.length - 1])
            maxDate.setHours(24, 0, 0, -1)
            setCalState(s => ({...s, minDate, maxDate}))
        }
    }, [dates])

    const gridRef = useRef<HTMLDivElement>()
    useEffect(() => {
        if (gridRef.current) {
            const y0 = gridRef.current.offsetTop
            gridRef.current.style.minHeight = `calc(100vh - ${y0 + 60}px)`
        }
    }, [gridRef.current])

    const {minHour, maxHour} = useMemo(() => {
        const hh: number[] = cal.events.map(ev => new Date(ev.dtStart).getHours())
        return {
            minHour: Math.min(8, ...hh),
            maxHour: Math.max(18, ...hh),
        }
    }, [cal.events])

    const hours = useMemo(() => {
        const hh = []
        for (let h = minHour; h <= maxHour; h++) {
            hh.push(h)
        }
        return hh
    }, [minHour, maxHour])

    const theme = useTheme()

    return <>
        <MultiCalTopBar
            prev={prev}
            next={next}
            dateRangeType={DateRangeType.Week}
            title={<>
                {dates[0].getDate()}
                &nbsp;
                {dates[0].getMonth() !== dates[dates.length - 1].getMonth() && MONTHS_IT[dates[0].getMonth()]}
                &nbsp; - &nbsp;
                {dates[dates.length - 1].getDate()}
                &nbsp;
                {MONTHS_IT[dates[dates.length - 1].getMonth()] + ' ' + cal.selectedDate.getFullYear()}
            </>}
        />
        <div
            ref={gridRef}
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                gridTemplateRows: `min-content repeat(${hours.length - 1}, auto) min-content`,
                // gridGap: 1,
                marginTop: 24,
                columnGap: 8,
            }}
        >
            {dates.map((d, i) => <Stack.Item
                onClick={() => {
                    setCalState(s => ({...s, selectedDate: d}))
                }}
                styles={{
                    root: {
                        textAlign: 'center',
                        paddingBottom: 8,
                        background: d.getTime() === cal.selectedDate.getTime() ? theme.semanticColors.bodyStandoutBackground : theme.semanticColors.bodyBackground,
                    }
                }}
            >
                <Text variant="small" style={{textTransform: 'uppercase', color: theme.semanticColors.bodySubtext}}>
                    {WEEK_DAYS_ABBR_IT[d.getDay()]}
                </Text>
                <br/>
                <Text variant="xLarge">
                    {d.getDate()}
                </Text>
            </Stack.Item>)}

            {hours.flatMap((h, hi) =>
                dates.map((d, di) =>
                    <WeekHourFrag
                        date={d}
                        dayIndex={di}
                        hour={h}
                        hourIndex={hi}
                    />))}
        </div>
    </>
}
