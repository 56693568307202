import {EventAttendeeBase} from "../../../fusina-event/entities/EventAttendee";
import {getITPPartyLabel_it} from "../../../fusina-itp/entities/ITPParty";

export const ITPEventAttendeeLabel: (attendee: EventAttendeeBase) => string = attendee => {
    if (attendee.name) {
        return `${attendee.name}${attendee.itpParty ? ` (${getITPPartyLabel_it(attendee.itpParty)})` : ''}`
    }
    if (attendee.itpParty) {
        return getITPPartyLabel_it(attendee.itpParty)
    }
    return attendee.email
}
