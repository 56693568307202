import * as React from 'react'
import {useEffect, useMemo, useState} from 'react'
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {Job} from "../../../fusina-jobs/entities/Job";
import {
    CommandBar,
    DefaultButton,
    DetailsList,
    DialogType,
    Icon,
    ISelection,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    PrimaryButton,
    ProgressIndicator,
    Selection,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Text,
    useTheme
} from "@fluentui/react";
import {getFileTypeIconProps} from "@fluentui/react-file-type-icons";
import {useFusinaRmiUrl} from "../hooks/useFusinaRmiUrl";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {SimpleDialog} from "./SimpleDialog";
import {formatDateOnly} from "./DateOnlyPicker";
import {DateOnlyPickerExpanded} from "./DateOnlyPickerExpanded";
import {FileUploadInput} from "./FileUploadInput";
import {CmdDateSearchBox} from "./CmdDateSearchBox";
import {GdLPolicy} from "../../../fusina-gdl/controls/GdLPolicy";
import {useSecurityContext} from "../contexts/SecurityContext";
import {GdLDeletionDialog} from "./GdLDeletionDialog";
import {useLocallyStoredState} from "../hooks/useLocallyStoredState";

interface Item {
    fn: string
    date: string
    ext: string
}

export const GdLTable: React.FC<{
    job: Job
}> = props => {
    const securityContext = useSecurityContext()
    const rmi = useFusinaRmi()

    const {
        data: files,
        error,
        refresh,
    } = useFusinaRmiResource('GdL', 'list', props.job?._id)

    const [searchDates, setSearchDates] = useState<string>(undefined)
    const searchDatesRegex = useMemo(() => searchDates ? new RegExp('^' + searchDates.replace(/_/g, '[0-9]')) : undefined, [searchDates])

    const [reverse, setReverse] = useLocallyStoredState<boolean>('GdLTable-4859654892-reverse', false)

    const items = useMemo<Item[]>(() => {
        const items = files?.map?.(fn => ({
            fn,
            date: fn.split('.')[0]?.replace(/-/g, '/'),
            ext: fn.split('.')[1],
        }))
        if (reverse) {
            items?.reverse?.()
        }
        return items
    }, [files, reverse])

    const filteredItems = useMemo<Item[]>(() =>
        searchDatesRegex ? items?.filter?.(it => searchDatesRegex.test(it.date)) : items, [searchDatesRegex, items])

    const [selected, setSelected] = useState<Item | undefined>()
    const [selectedItems, setSelectedItems] = useState<Item[]>([])

    const selectedUrl = useFusinaRmiUrl('GdL', 'download', props.job?._id, selected?.date)

    const selection = useMemo(() => new Selection<Item>({
        onSelectionChanged() {
            const sel = selection.getSelection()
            if (sel?.length === 1) {
                setSelected(sel[0])
            } else {
                setSelected(undefined)
            }
            setSelectedItems(sel?.slice?.() ?? [])
        },
        getKey(item) {
            return item.date
        },
    }) as unknown as ISelection<Item & { key: string }>, [])

    const [isAdding, setIsAdding] = useState<boolean>(false)

    const [deletingItems, setDeletingItems] = useState<Item[] | undefined>(undefined)

    const policy = useMemo(() => GdLPolicy({
        job: props.job,
        securityContext,
        // The following data is to probe the add permission
        filename: 'probe.xls',
        date: formatDateOnly(new Date()),
    }), [securityContext, props.job])

    if (error) {
        return <MaybeErrorMessageBar error={error}/>
    }
    if (items === undefined) {
        return <Spinner size={SpinnerSize.medium}/>
    }
    return <>
        <CommandBar
            items={[
                {
                    key: 'add',
                    iconProps: {
                        iconName: 'Add',
                    },
                    text: 'Carica GdL',
                    onClick() {
                        setIsAdding(true)
                    },
                    disabled: !policy.permissions.add
                },
                {
                    key: 'download',
                    iconProps: {
                        iconName: 'Download',
                    },
                    text: 'Download',
                    disabled: !selected,
                    href: selectedUrl,
                },
                ...(policy.permissions.delete ? [{
                    key: 'delete',
                    iconProps: {
                        iconName: 'Delete',
                    },
                    text: 'Elimina',
                    disabled: !(selectedItems?.length >= 1),
                    onClick() {
                        setDeletingItems(selectedItems)
                    }
                }] : []),
            ]}
            farItems={[
                {
                    key: 'search',
                    iconProps: {
                        iconName: 'Calendar',
                    },
                    text: 'Filtra date',
                    onClick() {
                        const d = new Date()
                        const m = d.getMonth() + 1
                        setSearchDates(`${d.getFullYear()}/${m >= 10 ? m : '0' + m}`)
                    },
                    onRender: searchDates ? () => {
                        return <CmdDateSearchBox value={searchDates} onChange={(search) => setSearchDates(search)}/>
                    } : undefined
                }
            ]}
        />
        {filteredItems?.length > 0 &&
        <DetailsList
            selectionMode={SelectionMode.multiple}
            selection={selection}
            columns={[
                {
                    key: 'icon',
                    iconName: 'Page',
                    isIconOnly: true,
                    minWidth: 16,
                    maxWidth: 16,
                    name: 'File type',
                    onRender(item: Item) {
                        return <Icon {...getFileTypeIconProps({extension: item.ext.toLowerCase(), size: 16})} />
                    }
                },
                {
                    key: 'date',
                    name: 'Data',
                    minWidth: 100,
                    isSorted: true,
                    isSortedDescending: !reverse,
                    fieldName: 'date',
                    onRender(item: Item) {
                        return <time dateTime={item.date.replace(/\//, '-')}>{item.date}</time>
                    },
                    onColumnClick() {
                        setReverse(!reverse)
                    }
                }
            ]}
            items={filteredItems}
            onItemInvoked={(item: Item) => {
                if (item.date === selected?.date && selectedUrl && window?.location?.href) {
                    window.location.href = selectedUrl
                }
            }}
        />
        || <MessageBar
            messageBarType={MessageBarType.info}
            isMultiline={false}
            actions={items.length > filteredItems.length
                ? <MessageBarButton onClick={() => setSearchDates(undefined)} text="Annulla filtro"/>
                : undefined}
        >
            Nessun Giornale del Lavoro da mostrare.
        </MessageBar>}

        {isAdding && <InsertDialog
            job={props.job}
            existingItems={items}
            onDismiss={() => {
                setIsAdding(false)
                // refresh().then()
            }}
            onSuccess={(date: string) => {
                setIsAdding(false)
                refresh().then()
                selection.setAllSelected(false)
                selection.setKeySelected(date, true, false)
            }}
        />}

        {deletingItems && <GdLDeletionDialog
            job={props.job}
            deletingItems={deletingItems}
            onDismiss={() => setDeletingItems(undefined)}
            onFinish={() => {
                setDeletingItems(undefined)
                refresh().then()
            }}
        />}
    </>
}


const InsertDialog: React.FC<{
    onDismiss: () => void
    existingItems: Item[]
    job: Job
    onSuccess: (date: string) => void
}> = props => {
    const theme = useTheme()
    const rmi = useFusinaRmi()

    const today = useMemo(() => formatDateOnly(new Date()), [])
    const yesterday = useMemo(() => formatDateOnly(new Date(Date.now() - 24 * 3600_000)), [])

    const [date, setDate] = useState<string>(formatDateOnly(new Date()))
    const [isDateConfirmed, setIsDateConfirmed] = useState<boolean>(false)
    const isDateOccupied = useMemo(() => !!props.existingItems.find(it => it.date === date), [date, props.existingItems])

    const [error, setError] = useState()
    const [pending, setPending] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [file, setFile] = useState<File | undefined>(undefined)
    const [fileMagicNumber, setFileMagicNumber] = useState<string | undefined>(undefined)
    useEffect(() => {
        if (file) {
            file.slice(0, 5).text()
                .then(r => setFileMagicNumber(r))
        }
    }, [file])
    const fileCheckError = useMemo<Error | undefined>(() => {
        if (!file) {
            return
        }
        if (file.type && file.type !== 'application/pdf') {
            return new Error('Tipo di file non ammesso.')
        }
        if (fileMagicNumber !== undefined && fileMagicNumber !== '%PDF-') {
            return new Error('Il file non è un PDF valido.')
        }
        if (!file.name.toLowerCase().endsWith('.pdf')) {
            return new Error('Estensione non ammessa. Si richiede un file con estensione PDF.')
        }
    }, [file, fileMagicNumber])

    if (!isDateConfirmed) {
        return <SimpleDialog
            type={DialogType.largeHeader}
            title='Caricamento nuovo Giornale del Lavoro'
            subText='Selezionare la data per la quale si intende caricare il file.'
            buttons={[
                <DefaultButton text='Annulla' onClick={props.onDismiss}/>,
                <PrimaryButton text='Prosegui' disabled={!date || isDateOccupied} onClick={() => {
                    setIsDateConfirmed(true)
                }}/>
            ]}
        >
            <DateOnlyPickerExpanded
                value={date}
                onChange={date => setDate(date)}
                restrictedDates={props.existingItems.map(it => it.date)}
            />
            {date && <>
                <br/>
                Data selezionata: <b>{date}</b>
                {date === today && ' (oggi)'}
                {date === yesterday && ' (ieri)'}
                <br/>
                {isDateOccupied && <>
                    <br/>
                    <MessageBar messageBarType={MessageBarType.warning}>
                        È già presente un GdL per la data {date === today ? 'di oggi' : 'selezionata'}.
                    </MessageBar>
                </> ||
                <Text style={{
                    color: theme.semanticColors.disabledText,
                    fontSize: theme.fonts.small.fontSize,
                    fontWeight: theme.fonts.small.fontWeight
                }}>
                    Sono disabilitate le date successive a oggi e quelle per le quali è già presente un GdL.
                </Text>}
            </>}
        </SimpleDialog>
    }

    return <SimpleDialog
        onDismiss={success ? () => props.onSuccess(date) : undefined}
        type={DialogType.largeHeader}
        title='Caricamento nuovo Giornale del Lavoro'
        subText={pending
            ? 'Upload file in corso...'
            : (success
                ? 'Caricamento completato!'
                : (file ? '' : `Selezionare un file PDF firmato con il GdL per la data selezionata.`))}
        buttons={[
            success
                ? <DefaultButton text='Chiudi' onClick={() => props.onSuccess(date)}/>
                : <DefaultButton text='Annulla' onClick={
                    file ? () => setFile(undefined) : props.onDismiss}/>,
            file && !pending && !success && <PrimaryButton
                text="Conferma"
                disabled={!!fileCheckError}
                onClick={() => {
                    setPending(true)
                    return rmi.GdL.add(props.job._id, date, file)
                        .then(() => {
                            setSuccess(true)
                            setTimeout(() => props.onSuccess(date), 5000)
                        })
                        .catch((e) => {
                            setError(e)
                            setFile(undefined)
                        })
                        .finally(() => setPending(false))
                }}
            />,
        ]}
    >
        {!success && !pending && <>
            {file === undefined && <FileUploadInput
                label={date}
                processFile={async file => {
                    setFile(file)
                }}
            /> || <>
                Confermi di aver selezionato un file PDF firmato?
                <br/>
                <br/>
                Data selezionata: {date}
                {date === today && ' (oggi)'}
                {date === yesterday && ' (ieri)'}
                <br/>
                File selezionato: <b>{file.name}</b>
                <small>
                    <br/>
                    Tipo: {file.type}
                    <br/>
                    Dimensione: {Math.ceil(file.size / 1024)}kB
                </small>
                <MaybeErrorMessageBar error={fileCheckError}/>
            </>}
        </> || <ProgressIndicator percentComplete={success ? 1 : undefined}/>}
        <MaybeErrorMessageBar error={error}/>
    </SimpleDialog>
}
