import {ActionButton} from "@fluentui/react";
import * as React from "react";
import {useMemo} from "react";
import {EcoOdS} from "../../../fusina-letters/entities/EcoOdS";
import {useNavigate} from "react-router-dom";
import {ExpensesPolicy} from "../../../fusina-letters/controls/EcoOdsPolicy";
import {useJobContext} from "../contexts/JobContext";
import {useSecurityContext} from "../contexts/SecurityContext";

export const EcoOdsExpensesBtn: React.FC<{
    letter: EcoOdS
}> = props => {
    const navigate = useNavigate()
    const securityContext = useSecurityContext()
    const {job} = useJobContext()

    const policy = useMemo(() =>
        ExpensesPolicy({
            job,
            securityContext,
            oldLetter: props.letter,
        }), [props.letter, job, securityContext])

    if (policy.permissions.read !== true) {
        return null
    }

    return <ActionButton
        iconProps={{iconName: "Table"}}
        text="Consuntivo"
        onClick={() => {
            navigate(`/jobs/${props.letter.job}/letters/${props.letter._id}/expenses`)
        }}
    />
}
