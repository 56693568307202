import {InspectionTestPlan} from "../entities/InspectionTestPlan";
import {ITPItem} from "../entities/ITPItem";
import {ITPEvent} from "../entities/ITPEvent";
import {EventPublishMessage, EventReplyMessage} from "../../fusina-event/entities/EventMessage";
import {getPresenceCodeDmsSemantics, PresenceCodeDmsSemantics} from "../entities/PresenceCode";
import {EventReplyAttendee, EventRequestAttendee} from "../../fusina-event/entities/EventAttendee";
import {ITPParty} from "../entities/ITPParty";
import {SecurityContext} from "../../fusina-authz/entities/SecurityContext";
import {EventMessageProcessor} from "../../fusina-event/controls/EventMessageProcessor";
import {EventProperties, getEventProperties} from "../../fusina-event/entities/EventProperties";


export interface EvContact {
    name: string
    email: string
}

/** Factory class -- builds ITP events and event messages */
export class ITPEventFactory {
    constructor(
        protected readonly securityContext: SecurityContext,
        protected readonly itp: InspectionTestPlan,
        protected readonly item: ITPItem,
    ) {
    }

    /** Get appropriate attendee properties for the given presence code semantics */
    protected static getAttendeeProps(ps: PresenceCodeDmsSemantics): Partial<EventRequestAttendee> {
        return {
            rsvp: ps.isWitnessOrWaiverMandatory ? true : undefined,
            role: ps.isNotificationMandatory
                ? (ps.isWitnessOrWaiverMandatory
                    ? 'REQ-PARTICIPANT' : 'OPT-PARTICIPANT')
                : "OPT-PARTICIPANT",
        }
    }

    /** Creation of new event containing request or publish message */
    new_event(props: Partial<EventProperties>, clientContacts?: EvContact[]): ITPEvent {
        const ps = getPresenceCodeDmsSemantics(this.item.presences.cl)
        const pro = EventMessageProcessor.init({
            summary: `${this.itp.docN} ${this.item.item}`,
            comment: undefined,
            dtStamp: new Date(),
            dtStart: new Date(),
            duration: "PT1H",
            ...props,

            method: ps.isWitnessOrWaiverMandatory
                ? 'REQUEST' : 'PUBLISH',
            organizer: {
                email: this.securityContext?.user?.email,
                name: this.securityContext?.user?.fullName,
                telephone: this.securityContext?.user?.telephone,
            },
            status: ps.isWitnessOrWaiverMandatory
                ? 'TENTATIVE' : 'CONFIRMED',
            attendees: clientContacts?.map?.(contact => ({
                ...contact,
                ...ITPEventFactory.getAttendeeProps(ps),
                itpParty: ITPParty.cl,
            })) ?? []
        })
        return {
            ...pro.event,
            itp: {
                _id: this.itp._id,
                docN: this.itp.docN,
                job: this.itp.job,
                revId: this.itp.revId ?? this.itp._id,  /// NOTE: if there's no revision, we are indicating it as dummy.
            },
            item: this.item.item,
            itpItemCopy: this.item,
        }
    }

    /** Reply message to set participation status */
    reply(partStat: EventReplyAttendee['partStat'], party?: ITPParty, waiver?: boolean): EventReplyMessage {
        return {
            method: "REPLY",
            comment: undefined,
            dtStamp: new Date(),
            attendee: party ? {
                email: this.itp.contacts[party]?.email ?? this.securityContext?.user?.email,
                name: this.itp.contacts[party]?.name ?? this.securityContext?.user?.fullName,
                itpParty: party,
                partStat,
                waiver,
            } : {
                email: this.securityContext?.user?.email,
                name: this.securityContext?.user?.fullName,
                partStat,
                waiver,
            },
        }
    }

    /** Publish the same event unchanged */
    publish(event: ITPEvent, parties: ITPParty[]): EventPublishMessage {
        return {
            method: 'PUBLISH',
            dtStamp: new Date(),
            ...getEventProperties(event),
            attendees: parties.map(party => ({
                ...ITPEventFactory.getAttendeeProps(getPresenceCodeDmsSemantics(this.item.presences[party])),
                email: this.itp.contacts[party]?.email,
                name: this.itp.contacts[party]?.name,
                itpParty: party,
            }))
        }
    }

    updateStatus(event: ITPEvent, status: EventPublishMessage['status']): EventPublishMessage {
        return {
            ...getEventProperties(event),
            method: 'PUBLISH',
            dtStamp: new Date(),
            status,
        }
    }
}
