import * as React from "react";
import {useContext, useMemo, useState} from "react";
import {ITPEvent} from "../../../fusina-itp/entities/ITPEvent";
import {Stack, TextField, useTheme} from "@fluentui/react";
import {ConfirmedCalloutBtn} from "./ConfirmedCalloutBtn";
import {useSecurityContext} from "../contexts/SecurityContext";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {ITPParty} from "../../../fusina-itp/entities/ITPParty";
import {EventMessage} from "../../../fusina-event/entities/EventMessage";
import {ITPEventPolicy} from "../../../fusina-itp/controls/ITPEventPolicy";
import {useJobContext} from "../contexts/JobContext";
import {ITPItemContext} from "../contexts/ITPItemContext";
import {ITPEventFactory} from "../../../fusina-itp/controls/ITPEventFactory";
import {EventAttendee, EventAttendee_equals} from "../../../fusina-event/entities/EventAttendee";
import {ITPEventResourceContext} from "../contexts/ITPEventResourceContext";
import {ITPEventAttendeeLabel} from "./ITPEventAttendeeLabel";

export const ITPEventMessageForm: React.FC<{
    event: ITPEvent
}> = props => {
    const sc = useSecurityContext()
    const {itp, item} = useContext(ITPItemContext)
    const evf = useMemo(() => new ITPEventFactory(sc, itp, item), [sc, itp, item])

    const missingReplies = useMemo<EventAttendee[]>(() =>
        props.event.status === 'TENTATIVE' ?
            props.event.attendees.filter(a => a.rsvp && a.partStat !== 'ACCEPTED' && a.partStat !== 'DECLINED')
                .slice(0, 1) : [], [props.event.status, props.event.attendees])

    const canConfirm = useMemo<boolean>(() => {
        const rsvps = props.event?.attendees?.filter?.(a => a.rsvp) ?? [];
        return rsvps?.length <= 0 ? true : !!rsvps.find(a => a.partStat === 'ACCEPTED')
    }, [props.event.attendees])

    const meAlreadyReplied = useMemo<boolean>(() =>
        !!props.event.attendees
            .filter(a => EventAttendee_equals(a, sc.user))
            .find(a => ['ACCEPTED', 'DECLINED'].includes(a.partStat)), [props.event.attendees])

    const meRequired = useMemo<boolean>(() =>
        !!props.event.attendees
            .filter(a => EventAttendee_equals(a, sc.user))
            .find(a => a.role === 'REQ-PARTICIPANT'), [props.event.attendees])

    const theme = useTheme()

    if (props.event.status === 'CANCELLED') {
        return null
    }

    return <Stack tokens={{childrenGap: 's1'}}>
        <Stack horizontal horizontalAlign="end" tokens={{childrenGap: 's2'}} verticalAlign="baseline"
               style={{opacity: meAlreadyReplied ? 0.5 : undefined}}>

            {/* @ts-ignore */}
            {props.event.status !== 'CANCELLED' && <EventMessageSendBtn
                event={props.event}
                btnText="Cancella"
                explainer="Stai per annullare definitivamente l'incontro."
                msg={evf.updateStatus(props.event, 'CANCELLED')}
            />}

            {props.event.status !== 'CONFIRMED' && canConfirm && <EventMessageSendBtn
                event={props.event}
                btnText="Conferma"
                explainer="Stai per confermare l'incontro."
                msg={evf.updateStatus(props.event, 'CONFIRMED')}
            />}

            <EventMessageSendBtn
                event={props.event}
                btnText="Declina"
                explainer={meRequired
                    ? <>
                        La tua presenza è obbligatoria!<br/>
                        Stai per annullare l'incontro.
                    </>
                    : <>Stai per declinare l'incontro.</>}
                msg={evf.reply('DECLINED', ITPParty.cl)}
            />
            <EventMessageSendBtn
                event={props.event}
                btnText="Accetta"
                explainer="Stai per accettare l'incontro."
                msg={evf.reply('ACCEPTED', ITPParty.cl)}
            />
            <EventMessageSendBtn
                event={props.event}
                btnText="Waiver"
                explainer={<>
                    Stai per accettare l'appuntamento <br/>
                    rinunciando a parteciparvi (waiver). <br/>
                    L'incontro si terrà in tua assenza.
                </>}
                msg={evf.reply('ACCEPTED', ITPParty.cl, true)}
            />
        </Stack>
        {missingReplies?.length > 0 && <>
            <div style={{color: theme.semanticColors.disabledText, flexGrow: 1, fontSize: theme.fonts.small.fontSize}}>
                In attesa di risposta da:
                <ul style={{
                    listStyle: 'none',
                    padding: 0,
                    margin: 0,
                }}>
                    {missingReplies?.map(ITPEventAttendeeLabel).map(la => <li>{la}</li>)}
                </ul>
            </div>
        </>}
        {meAlreadyReplied && <>
            <div style={{color: theme.semanticColors.disabledText, flexGrow: 1}}>
                Hai già risposto a questo invito.
            </div>
        </>}
    </Stack>
};

const EventMessageSendBtn: React.FC<{
    event: ITPEvent
    msg: EventMessage
    btnText: string
    explainer: string | React.ReactNode
}> = props => {
    const rmi = useFusinaRmi()

    const sc = useSecurityContext()
    const {job} = useJobContext()
    const {itp} = useContext(ITPItemContext)
    const {onRefreshRequired} = useContext(ITPEventResourceContext)

    const [comment, setComment] = useState<string | undefined>(undefined)

    const policy = useMemo(() => ITPEventPolicy({
        securityContext: sc,
        job,
        itp,
        msg: props.msg,
    }), [sc, job, itp, props.msg])

    if (policy.permissions.send !== true) {
        return null
    }

    return <div>
        <ConfirmedCalloutBtn
            btnProps={{
                text: props.btnText,
            }}
            action={async () => {
                return rmi.ITPEvents.process(props.event._id, props.msg).then(() => {
                    onRefreshRequired?.(props.event._id)
                })
            }}
            calloutChildren={<>
                {props.explainer}
                <TextField
                    style={{
                        minWidth: 250,
                    }}
                    styles={{
                        field: {
                            fontStyle: 'italic'
                        }
                    }}
                    label="Commento"
                    value={comment}
                    maxLength={512}
                    multiline
                    autoAdjustHeight
                    onChange={(event, newValue) => {
                        props.msg.comment = newValue
                        setComment(newValue)
                    }}
                />
            </>}
            confirmBtnProps={{
                text: 'Invia'
            }}
        />
    </div>
}
