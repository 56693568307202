import * as React from 'react'
import {useEffect, useState} from 'react'
import {User} from "../../../fusina-users/entities/User";
import {ActionButton, TextField} from "@fluentui/react";
import {ImageUrlInput} from "./ImageUrlInput";
import {Stack} from "@fluentui/react/lib/Stack";
import {useForceUpdate} from "@fluentui/react-hooks";
import {PasswordHashing} from "../../../fusina-login/controls/PasswordHashing";
import {UserGrantsEditor} from "./UserGrantsEditor";
import {UserGlobalRolesEditor} from "./UserGlobalRolesEditor";
import {RoleEnum} from "../../../fusina-authz/entities/RoleEnum";
import {UserItpOwnershipTransferDialog} from "./UserItpOwnershipTransferDialog";


function genPassword() {
    // TODO: FIXME: this is for testing only
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 20;
    let password = "";
    for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password
}


export const UserEditor: React.FC<{
    user: User
}> = props => {

    const forceUpdate = useForceUpdate();

    const [generatedPwHash, setGeneratedPwHash] = useState<{ pw: string, hash: string } | undefined>(undefined);
    useEffect(() => {
        if (props.user?._id) {
            return
        }
        (async () => {
            const PASSWORDS_HASHING = new PasswordHashing()
            const pw = genPassword()
            const hash = await PASSWORDS_HASHING.hash(pw)
            // TODO: FIXME: this is for testing only
            props.user.credentials = {
                canLogin: true,
                password: {hash, createdAt: new Date()}
            }
            setGeneratedPwHash({pw, hash})
        })()
    }, [props.user?._id])

    const [showItpOwnershipTransfer, setShowItpOwnershipTransfer] = useState<boolean>(false)

    return <>
        <Stack>
            <TextField
                label="Nome completo"
                value={props.user.fullName}
                onChange={(ev, newValue) => {
                    props.user.fullName = newValue?.length >= 1 ? newValue : undefined
                    forceUpdate()
                }}
            />
            <TextField
                label="Email" value={props.user.email}
                readOnly={props.user._id !== undefined}
                onChange={(ev, newValue) => {
                    if (props.user._id === undefined) {
                        props.user.email = (newValue ?? '').trim().toLowerCase();
                    }
                    forceUpdate()
                }}/>
            {props.user?._id === undefined && <>
                <TextField
                    label={generatedPwHash?.pw
                        ? "Password"
                        : "Password (...)"}
                    type="password"
                    value={generatedPwHash?.pw ?? ''}
                    canRevealPassword
                    readOnly
                />
            </>}
            <TextField
                label="Telefono"
                type="tel"
                autoComplete="off"
                value={props.user.telephone}
                onChange={(ev, newValue) => {
                    props.user.telephone = newValue?.length >= 1 ? newValue : undefined
                    forceUpdate()
                }}
            />
            <ImageUrlInput
                label="Firma o timbro"
                value={props.user.signatureImageURL}
                onChange={(newValue) => {
                    props.user.signatureImageURL = newValue
                    forceUpdate()
                }}
            />
            <UserGrantsEditor
                grants={props.user.grants ?? (props.user.grants = [])}
                onChange={() => forceUpdate()}
            />

            <UserGlobalRolesEditor
                user={props.user}
                onChange={() => forceUpdate()}
            />

            {props.user?._id && props.user?.grants?.some?.(grant => grant.role === RoleEnum.AppaltatoreCQ) && <>
                <br/>
                <ActionButton
                    iconProps={{iconName: 'ChangeEntitlements'}} //Transition'}}
                    onClick={() => setShowItpOwnershipTransfer(true)}
                >
                    Trasferimento gestione PCQ...
                </ActionButton>
            </>}
        </Stack>

        {showItpOwnershipTransfer && <UserItpOwnershipTransferDialog
            userForDisplay={props.user}
            userId={props.user?._id}
            onDismiss={() => setShowItpOwnershipTransfer(false)}
        />}
    </>
}
