import * as React from "react";
import {useMemo, useState} from "react";
import {Letter} from "../../../fusina-letters/entities/Letter";
import {Label, MessageBar, MessageBarType, Spinner, SpinnerSize} from "@fluentui/react";
import {EstimateAttachmentsList, LetterAttachmentsList} from "./LetterAttachmentsList";
import {EstimateAttachmentAddBtn, LetterAttachmentAddBtn} from "./LetterAttachmentAddBtn";
import {EcoOdsEstimatePolicy} from "../../../fusina-letters/controls/EcoOdsPolicy";
import {useSecurityContext} from "../contexts/SecurityContext";
import {useJobContext} from "../contexts/JobContext";

export const LetterAttachmentsEditor: React.FC<{
    letter: Letter
}> = props => {

    const [pending, setPending] = useState<boolean>(false)

    const sc = useSecurityContext()
    const {job} = useJobContext()

    const ecoOdsEstimatePolicy = useMemo(() => {
        if (props.letter.type === 'EcoOdS') {
            return EcoOdsEstimatePolicy({
                securityContext: sc,
                oldLetter: props.letter,
                job,
            })
        }
    }, [props.letter])

    if (!props.letter?._id) {
        return <MessageBar messageBarType={MessageBarType.info}>
            Salvare la bozza prima di aggiungere allegati
        </MessageBar>
    }

    return <>
        <Label>Allegati {props.letter.type === 'NI' ? 'alla NI' : 'al OdS'}</Label>
        {pending
            ? <Spinner size={SpinnerSize.large}/>
            : <LetterAttachmentsList letter={props.letter} showDelete/>}
        <LetterAttachmentAddBtn
            label={`Aggiungi allegato ${props.letter.type === 'NI' ? 'alla NI' : 'al OdS'}`}
            letter={props.letter}
            onBeforeProcess={() => {
                setPending(true)
            }}
            onProcessing={result => {
                result.finally(() => setPending(false))
            }}
        />

        {props.letter.type === 'EcoOdS' && ecoOdsEstimatePolicy?.permissions?.read && <>
            <br/>

            <Label>Allegati al preventivo</Label>
            {pending
                ? <Spinner size={SpinnerSize.large}/>
                : <EstimateAttachmentsList letter={props.letter} showDelete={ecoOdsEstimatePolicy.permissions.write}/>}
            {ecoOdsEstimatePolicy.permissions.write &&
            <EstimateAttachmentAddBtn
                label="Aggiungi allegato al preventivo"
                letter={props.letter}
                onBeforeProcess={() => {
                    setPending(true)
                }}
                onProcessing={result => {
                    result.finally(() => setPending(false))
                }}
            />}
        </>}
    </>
}
