export class InvalidContextException extends Error {

}

export class PolicyViolationException extends Error {
    report: true = true
    private readonly policy: string; // string only for encapsulation to avoid edit from catcher
    private readonly missingPermission: string;

    constructor(policy: string | object, message?: string, missingPermission?: string) {
        let policyStr;
        if (typeof policy === 'string') {
            policyStr = policy
        } else {
            try {
                policyStr = JSON.stringify(policy, undefined, 2)
            } catch (e) {
                policyStr = '[policy serialization error]'
            }
        }
        super((message ?? 'operational policy violation') +
            ` (missing permission: "${missingPermission}")` + ' ' + policyStr);
        this.policy = policyStr
        this.missingPermission = missingPermission
    }

    toString(): string {
        return super.toString() + ` [${this.missingPermission}, ${this.policy}]`;
    }
}
