import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardPreview,
    DocumentCardTitle,
    DocumentCardType,
    Spinner,
    SpinnerSize,
    useTheme
} from "@fluentui/react";
import {getFileTypeIconProps} from "@fluentui/react-file-type-icons";
import * as React from "react";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";

export function AttachmentCard(props: { width: string, onClickHref: string, name: string }) {
    const theme = useTheme()
    const iconProps = getFileTypeIconProps({extension: fileName2ext(props.name), size: 24})

    return <DocumentCard
        type={DocumentCardType.compact}
        style={{height: 32, minWidth: 150, width: props.width ?? "fit-content"}}
        onClickHref={props.onClickHref}  // NOTE: response header: Content-Disposition: attachment ==> it should download.
    >
        <DocumentCardPreview
            previewImages={[{
                previewIconProps: {
                    iconName: fileName2IconName(props.name),
                    ...iconProps,
                    style: {
                        fontSize: theme.fonts.xLarge.fontSize,
                        color: theme.palette.themePrimary
                    },
                },
                width: 32,
            }]}
            styles={{
                previewIcon: {
                    backgroundColor: theme.palette.neutralLighterAlt
                }
            }}
        />
        <DocumentCardDetails>
            <DocumentCardTitle
                title={props.name}
                styles={{root: {fontSize: `${theme.fonts.small.fontSize} !important`}}}
                shouldTruncate
            />
        </DocumentCardDetails>
    </DocumentCard>;
}

function fileName2ext(name: string): string {
    const split = name.split('.')
    return split[split.length - 1].toLowerCase()
}

function fileName2IconName(name: string): string {
    const ext = fileName2ext(name)
    return {
        'zip': 'ZipFolder',
        'pdf': 'PDF',
        'doc': 'WordDocument',
        'docx': 'WordDocument',
        'xls': 'ExcelDocument',
        'xlsx': 'ExcelDocument',
        'jpg': 'FileImage',
        'jpeg': 'FileImage',
        'png': 'FileImage',
        'gif': 'FileImage',
        'jfif': 'FileImage',
        'tif': 'FileImage',
        'tiff': 'FileImage',
    }[ext] ?? 'TextDocument'
}

export function ErrOrNoAttachments(props: { cardWidth: string, error?: Error }) {
    // return <Spinner size={SpinnerSize.small} style={{height: 32 + 2}}/>
    if (props.error) {
        return <MaybeErrorMessageBar error={props.error}/>
    }
    return <DocumentCard
        type={DocumentCardType.compact}
        style={{height: 32, minWidth: 150, width: props.cardWidth ?? "fit-content"}}
    >
        <DocumentCardDetails styles={{root: {justifyContent: "center"}}}>
            <Spinner size={SpinnerSize.xSmall}/>
        </DocumentCardDetails>
    </DocumentCard>;
}


export function AttachmentsCountCard(props: {
    cardWidth: string,
    count: number,
    onClick?: () => void,
}) {
    const theme = useTheme()
    return <DocumentCard
        type={DocumentCardType.compact}
        style={{height: 32, minWidth: 150, width: props.cardWidth ?? "fit-content"}}
        onClick={props.onClick}
    >
        {props.count > 0 && <DocumentCardPreview
            previewImages={[{
                previewIconProps: {
                    iconName: "Attach",
                    style: {
                        fontSize: theme.fonts.xLarge.fontSize,
                        color: theme.palette.themePrimary
                    },
                },
                width: 32,
            }]}
            styles={{
                previewIcon: {
                    backgroundColor: theme.palette.neutralLighterAlt
                }
            }}
        />}
        <DocumentCardDetails styles={{
            root: {
                background: props.count >= 1 ? undefined : theme.palette.neutralLighterAlt
            }
        }}>
            <DocumentCardTitle
                title={props.count >= 1 ? `${props.count} allegati...` : "Nessun allegato"}
                styles={{
                    root: {
                        fontSize: `${theme.fonts.small.fontSize} !important`,
                        color: props.count >= 1 ? undefined : theme.semanticColors.disabledText
                    }
                }}
            />
        </DocumentCardDetails>
    </DocumentCard>;
}
