import * as React from 'react'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {Job} from "../../../fusina-jobs/entities/Job";
import {
    DefaultButton,
    DialogType,
    MessageBar,
    MessageBarType,
    Pivot,
    PivotItem,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    useTheme
} from "@fluentui/react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {useNavigate, useParams} from "react-router-dom";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {ITPItem} from "../../../fusina-itp/entities/ITPItem";
import {ITPPolicy} from "../../../fusina-itp/controls/ITPPolicy";
import {useSecurityContext} from "../contexts/SecurityContext";
import {ITPHeaderEditor} from "./ITPHeaderEditor";
import {useForceUpdate} from "@fluentui/react-hooks";
import {ITPSingleTable} from "./ITPSingleTable";
import {SimpleDialog} from "./SimpleDialog";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useFusinaRmiUrl} from "../hooks/useFusinaRmiUrl";
import {ITPEventsExportTools} from "./ITPEventsExportTools";

export interface ITPListItem extends ITPItem {
}

const GLOBAL_new_itp_draft: [InspectionTestPlan] = [undefined]

const NewITPEditor: React.FC<{
    itpTemplate: InspectionTestPlan
    onCreateSuccess?: (itp: InspectionTestPlan) => void
}> = props => {

    const [itp, setItp] = useState<InspectionTestPlan | undefined>(GLOBAL_new_itp_draft[0])
    useEffect(() => {
        if (props.itpTemplate) {
            if (GLOBAL_new_itp_draft[0] === undefined) {
                GLOBAL_new_itp_draft[0] = JSON.parse(JSON.stringify(props.itpTemplate))
            }
            if (itp === undefined) {
                setItp(GLOBAL_new_itp_draft[0])
            }
        }
    }, [props.itpTemplate])

    const [isHeaderSet, setIsHeaderSet] = useState<boolean>(false)

    const forceUpdate = useForceUpdate()

    const rmi = useFusinaRmi()

    if (!itp) {
        return <Spinner size={SpinnerSize.large}/>
    }

    if (!isHeaderSet) {
        return <div style={{padding: 24, margin: '0 auto', maxWidth: 600}}>
            <ITPHeaderEditor itp={itp} onChange={() => forceUpdate()}/>
            <br/>
            <Stack horizontal style={{justifyContent: "end"}}>
                <PrimaryButton
                    text="Avanti"
                    disabled={!itp.docN || !itp.title}
                    onClick={() => setIsHeaderSet(true)}
                />
            </Stack>
        </div>
    }
    return <SimpleDialog
        type={DialogType.largeHeader}
        title="Creazione nuovo PCQ"
        subText=""
        noMaxWidth
    >
        <ITPSingleTable // editable for create
            itp={itp}
            editable
            onEditDismiss={() => setIsHeaderSet(false)}
            onEditSuccess={() => {
                setIsHeaderSet(false)
                props.onCreateSuccess(itp)
            }}
            createAction={() => rmi.ITP.create(itp)}
        />
    </SimpleDialog>
}

const MaybeEditableITPSingleTable: React.FC<{
    itp: InspectionTestPlan
    onActionFinish?: () => void
    collapse?: boolean
}> = props => {
    const [editing, setEditing] = useState<InspectionTestPlan | null>(null)
    const [isCollapsed, setIsCollapsed] = useState<boolean>(props.collapse)
    const rmi = useFusinaRmi()

    return <>
        <div style={{opacity: editing ? 0.2 : 1}}>
            <ITPSingleTable // maybe collapsed (still not editable)
                isHeaderOnly={isCollapsed}
                itp={props.itp}
                onEditRequested={() => {
                    setEditing(JSON.parse(JSON.stringify(props.itp)))
                }}
                onDeleteSuccess={() => {
                    props.onActionFinish?.()
                }}
                onSignSuccess={props.onActionFinish}
                onActionFinish={props.onActionFinish}
                deleteAction={() => rmi.ITP.delete(props.itp._id)}
                rejectAction={(comment) => rmi.ITP.reject(props.itp._id, comment || null)}
                signAction={(signatureRole) => rmi.ITP.sign(props.itp._id, signatureRole).then()}
                signatureUrlGetter={signature => useFusinaRmiUrl('ITP', 'findSignaturePdf', props.itp._id, signature._id)}
            />
            {isCollapsed && <div style={{
                height: 0,
                overflow: 'visible',
            }}>
                <DefaultButton
                    styles={{
                        root: {
                            display: 'block',
                            margin: '0 auto',
                            padding: 0,
                            minWidth: '2em',
                            width: '2em',
                            textAlign: 'center',
                            height: '2em',
                            lineHeight: '2em',
                            position: 'relative',
                            top: '-1em',
                            borderRadius: 999,
                        }
                    }}
                    onClick={() => {
                        setIsCollapsed(false)
                    }}
                    title="Espandi tabella PCQ"
                    iconProps={{iconName: 'ScrollUpDown'}}
                />
            </div>}
        </div>
        {editing && <SimpleDialog
            type={DialogType.largeHeader}
            title="Modifica PCQ"
            subText=""
            noMaxWidth
            hidden={!editing}
        >
            <ITPSingleTable // editable for update
                itp={editing}
                editable
                onEditDismiss={() => {
                    setEditing(null)
                }}
                onEditSuccess={() => {
                    setEditing(null)
                    props.onActionFinish?.()
                }}
                onSignSuccess={props.onActionFinish}
                onActionFinish={props.onActionFinish}
                updateAction={() => rmi.ITP.update(editing)}
                deleteAction={() => rmi.ITP.delete(props.itp._id)}
            />
        </SimpleDialog>}
    </>
}

const GLOBAL_new_itp_template__static: [InspectionTestPlan] = [undefined];

export const ITPTable: React.FC<{
    job: Job
}> = props => {

    const {itpId} = useParams()
    const sc = useSecurityContext()

    const {
        data: itps_STORED_UNREV,
        error,
        refresh,
    } = useFusinaRmiResource('ITP', 'find', {by: 'job', job: {_id: props.job._id}})  // Start loading before it's needed.

    const itps_STORED = useMemo(() =>
        itps_STORED_UNREV?.slice?.()?.reverse?.(), [itps_STORED_UNREV])

    /** True IFF we surely know the ITP is not available */
    const isItpMissing = useMemo<boolean>(() => {
        if (itps_STORED !== undefined && itpId && !itpId?.startsWith?.('~')) {
            return !itps_STORED?.find?.(itp => itp._id === itpId)
        }
        return false
    }, [itps_STORED, itpId])

    const [isRefreshPending, setIsRefreshPending] = useState<boolean>(false)

    const new_itp_template = useMemo<InspectionTestPlan>(() => ({
        _id: undefined,
        owner: {
            _id: sc.user?._id ?? sc.userId,
            email: sc.user?.email,
        },
        items: [],
        docN: '',
        job: {
            _id: props.job._id,
            code: props.job.code,
        },
        contacts: {},
        title: '',
        signatures: []
    }), [props.job, sc])


    /** for ITP creation permission */
    const new_itp_policy = useMemo(() => ITPPolicy({
        itp: new_itp_template,
        job: props.job,
        securityContext: sc,
    }), [props.job, sc, new_itp_template])

    const navigate = useNavigate()

    const refreshWithPending = useCallback(async () => {
        setIsRefreshPending(true)
        try {
            await refresh()
        } finally {
            setIsRefreshPending(false)
        }
    }, [refresh])

    const [new_itp_template__static, set_new_itp_template__static] = useState<InspectionTestPlan | undefined>(GLOBAL_new_itp_template__static?.[0])
    useEffect(() => {
        if (new_itp_policy?.permissions?.write && props.job?._id && new_itp_template) {
            if (GLOBAL_new_itp_template__static[0] === undefined) {
                GLOBAL_new_itp_template__static[0] = new_itp_template
            }
            if (new_itp_template__static === undefined) {
                set_new_itp_template__static(GLOBAL_new_itp_template__static[0])
            }
        }
    }, [props.job, sc, new_itp_template, new_itp_policy])

    const theme = useTheme()

    const newItpPivotItem = useMemo(() => <PivotItem
        headerText="Nuovo PCQ"
        headerButtonProps={new_itp_policy.permissions.write
            ? undefined
            : {
                disabled: true,
                style: {
                    color: theme.semanticColors.disabledText
                },
            }}
        itemIcon="Add"
        itemKey="~new"
    >
        {new_itp_template__static === undefined && <Spinner size={SpinnerSize.large}/>}
        {new_itp_policy.permissions.write && new_itp_template__static && <>

            <br/>
            <NewITPEditor itpTemplate={new_itp_template__static} onCreateSuccess={newItp => {
                refreshWithPending().then(() => {
                    setTimeout(() => {
                        navigate(`/jobs/${props.job._id}/itp/${newItp._id}`)
                        setTimeout(() => {
                            if (window?.location?.reload && window?.location?.protocol !== 'http') {
                                // NOTE: this causes problems during e2e tests => don't reload on plain http.
                                window.location.reload()
                            }
                        }, 100)
                    }, 100)
                })
            }}/>

        </>}
    </PivotItem>, [new_itp_template__static])
    // NOTE: above: we have to preserve the editor across ITP data reactions (eg. incoming notification)!

    if (itps_STORED === undefined) {
        return <>
            <br/>
            <br/>
            <Spinner size={SpinnerSize.large}/>
        </>
    }

    if (itps_STORED?.length === 0 && !new_itp_policy.permissions.write) {
        return <>
            <MessageBar messageBarType={MessageBarType.info}>
                L'appaltatore non ha ancora creato e firmato alcun PCQ.
            </MessageBar>
        </>
    }

    return <>
        <MaybeErrorMessageBar error={error}/>

        <Pivot
            onLinkClick={item => {
                navigate(`/jobs/${props.job._id}/itp/${item.props.itemKey ?? ''}`)
            }}
            selectedKey={itpId ?? (itps_STORED?.length > 1 ? '~all' : (itps_STORED?.[0]?._id ?? '~new'))}
            overflowBehavior='menu'
            styles={{
                root: {
                    marginLeft: 24,
                    opacity: isRefreshPending ? 0.3 : 1,
                },
                itemContainer: {
                    opacity: isRefreshPending ? 0.3 : 1,
                }
            }}
        >
            {newItpPivotItem}

            <PivotItem
                headerText="Notifiche"
                itemIcon="Download"
                itemKey="~export"
            >
                <div style={{padding: 24, margin: '0 auto', maxWidth: 600}}>
                    <ITPEventsExportTools job={props.job}/>
                </div>
            </PivotItem>

            {itps_STORED?.length > 1 && <PivotItem
                headerText="Tutti i PCQ"
                itemCount={itps_STORED.length}
                itemKey="~all"
            >
                {itps_STORED?.map?.(itp => <>
                    <div style={{
                        margin: 24,
                        boxShadow: '0 4px 10px -3px #777a',
                    }}>
                        <MaybeEditableITPSingleTable
                            collapse={itps_STORED.length > 1}
                            itp={itp}
                            onActionFinish={() => {
                                navigate(`/jobs/${props.job._id}/itp/${itp._id}`)
                                refreshWithPending().then()
                            }}
                        />
                    </div>
                </>)}
            </PivotItem>}

            {itps_STORED?.map?.(itp =>
                <PivotItem headerText={itp.docN} itemKey={itp._id}>
                    <br/>
                    <MaybeEditableITPSingleTable itp={itp} onActionFinish={() => refreshWithPending()}/>
                </PivotItem>
            )}

            {isItpMissing && <PivotItem headerText=" " itemKey={itpId}>
                <MessageBar messageBarType={MessageBarType.warning}>
                    PCQ non trovato.
                    {new_itp_policy.permissions.write !== true && <>
                        &nbsp;Potrebbe essere in bozza.
                    </>}
                </MessageBar>
            </PivotItem>}
        </Pivot>

        {(itps_STORED === undefined || isRefreshPending) && <Spinner size={SpinnerSize.large}/>}
    </>
}
