import {createContext} from "react";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";

export interface IITPRevContext {
    /** Current confirmed ITP version */
    prevItp?: InspectionTestPlan

    /** False if the on-screen ITP is not the current revision */
    isNotCurrent?: boolean
}

export const ITPRevContext = createContext<IITPRevContext>({})
