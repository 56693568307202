import {ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn} from "@fluentui/react";
import * as React from "react";
import {IDetailsListProps} from "@fluentui/react/lib/components/DetailsList/DetailsList.types";
import {ITPCertListItem} from "./ITPCertsView";

export const ITPCertsDetailsListBaseColumns: IColumn[] = [
    {
        key: 'fileName',
        fieldName: 'fileName',
        name: 'File',
        iconName: 'File',
        minWidth: 250,
        flexGrow: 0.1,
        onRender(item: ITPCertListItem) {
            return <code>{item.fileName}</code>
        }
    },
    {
        key: 'date',
        fieldName: 'date',
        name: 'Inserimento',
        minWidth: 140,
        flexGrow: 0.05,
        onRender(item: ITPCertListItem) {
            try {
                const d = new Date(item.date)
                return d.toLocaleDateString(undefined, {
                    dateStyle: 'medium',
                }) + ' ' + d.toLocaleTimeString(undefined, {
                    hour: '2-digit',
                    minute: '2-digit',
                })
            } catch (e) {
                return null
            }
        }
    },
]

export const ITPCertsDetailsListBase: React.FC<Partial<IDetailsListProps> & {
    items: ITPCertListItem[]
}> = props => {
    // TODO?: allow for optionally limited height and vertical scrolling?
    return <DetailsList
        onShouldVirtualize={() => false}
        constrainMode={ConstrainMode.horizontalConstrained}
        compact
        columns={ITPCertsDetailsListBaseColumns}
        layoutMode={DetailsListLayoutMode.justified}
        {...props}
    />
}
