import * as React from 'react'
import {PropsWithChildren, useCallback, useMemo, useState} from 'react'
import {Stack} from '@fluentui/react/lib/Stack';
import {Letter, LetterTypeLabels, LetterTypeSupAbbrLabels} from "../../../fusina-letters/entities/Letter";
import {
    ActionButton,
    Callout,
    DialogType,
    DirectionalHint,
    FontWeights,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    PrimaryButton,
    Separator,
    Spinner,
    SpinnerSize,
    Text
} from "@fluentui/react";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useSecurityContext} from "../contexts/SecurityContext";
import LettersPolicy from "../../../fusina-letters/controls/LettersPolicy";
import {Job} from "../../../fusina-jobs/entities/Job";
import {SignatureCard} from "./SignatureCard";
import {LetterStatusIcon} from "./LetterStatusIcon";
import {EstimateAttachmentsList, LetterAttachmentsList} from "./LetterAttachmentsList";
import {useId} from "@fluentui/react-hooks";
import {EcoOdsEstimatePolicy} from "../../../fusina-letters/controls/EcoOdsPolicy";
import {useJobContext} from "../contexts/JobContext";
import {EcoOdsEstimateExpensesSmallBlock} from "./EcoOdsEstimateExpensesSmallBlock";
import {useFusinaRmiUrl} from "../hooks/useFusinaRmiUrl";
import {useScreenSize} from "../hooks/useScreenSize";
import {SimpleDialog} from "./SimpleDialog";


// FIXME?: useJobContext does not work here :(

export const LetterSideChildren: React.FC<PropsWithChildren<{
    onDismiss: () => void
    letter: Letter
}>> = props => {
    const {onDismiss, ...rest} = props
    const {isMobile} = useScreenSize()

    if (isMobile) {
        return <SimpleDialog
            type={DialogType.close}
            subText={null}
            onDismiss={onDismiss}
        >
            <div style={{maxWidth: 400}}>
                <LetterSideChildrenContent {...rest}/>
            </div>
        </SimpleDialog>
    }
    return <LetterSideChildrenContent {...rest}/>
}

export const LetterSideChildrenContent: React.FC<PropsWithChildren<{
    letter: Letter
}>> = props => {
    const rmi = useFusinaRmi()
    const securityContext = useSecurityContext()
    const {job} = useJobContext()

    const ecoOdsEstimatePolicy = useMemo(() => EcoOdsEstimatePolicy({
        securityContext,
        job,
    }), [securityContext, job])

    const visible_signatures = useMemo(() =>
        props?.letter?.signatures?.filter?.(s => !s.invisible), [props?.letter?.signatures])

    return <>
        <Stack tokens={{childrenGap: 'm'}}>
            <Stack>
                <Stack horizontal>
                    <Stack.Item grow={1}>
                        <Text variant="large" style={{fontWeight: FontWeights.regular}}>
                            {LetterTypeSupAbbrLabels[props.letter?.type]} n°{props.letter.externalRef}
                        </Text>
                    </Stack.Item>
                    <LetterStatusIcon letter={props.letter}/>
                </Stack>
                <Text variant="xSmall">
                    {LetterTypeLabels?.[props.letter?.type] ?? ''}
                </Text>
            </Stack>
            <Text variant="small" style={{minHeight: '2.5em', wordBreak: 'break-word'}}>
                Oggetto: {props.letter.subject.substring(0, 100)} {props.letter.subject.length > 100 ? '...' : ''}
            </Text>

            {props.letter?.type === 'EcoOdS' && <>
                <EcoOdsEstimateExpensesSmallBlock letter={props.letter}/>
                <br/>
            </>}

            <LetterAttachmentsList key={`${props.letter?._id}-attachments`} letter={props.letter} collapse
                                   cardWidth='100%'/>
            <Stack tokens={{childrenGap: 's'}} className="LetterSignatures">
                {visible_signatures?.length >= 1 &&
                <Separator styles={{content: {background: 'transparent'}}}>Firme</Separator>}
                {visible_signatures?.map?.(signature =>
                    <SignatureCard
                        onClickHref={useFusinaRmiUrl('Letters', 'findSignaturePdf', props.letter?._id, signature._id)}
                        signature={signature}/>)}
            </Stack>
            {props.letter?.type === 'EcoOdS' && ecoOdsEstimatePolicy.permissions.read && <>
                <Stack className="EstimateSignatures">
                    <Separator styles={{content: {background: 'transparent'}}}>Preventivo</Separator>

                    <EstimateAttachmentsList key={`${props.letter?._id}-estimate_attachments`}
                                             letter={props.letter} collapse cardWidth='100%'/>

                    {props.letter.estimate.signatures?.map?.(signature =>
                        <SignatureCard
                            onClickHref={useFusinaRmiUrl('EcoOds', 'findEstimateSignaturePdf', props.letter?._id, signature._id)}
                            signature={signature}/>)}

                </Stack>
            </>}

            <br/>
            <Stack>
                {props.children}
            </Stack>
        </Stack>
    </>
}

export const UpdateLetterDraftButton: React.FC<{
    job: Job
    letter: Letter
    onClick: (letter: Letter) => void
    forMsgBar?: boolean
}> = props => {

    const sc = useSecurityContext()

    const policy = useMemo(() => props.job ? LettersPolicy({
        job: props.job,
        securityContext: sc,
        oldLetter: props.letter,
        newLetter: props.letter,
    }) : undefined, [sc, props.job, props.letter])

    if (policy?.permissions?.write !== true) {
        return null
    }

    const Comp = props.forMsgBar ? MessageBarButton : ActionButton;

    return <Comp iconProps={{iconName: 'Edit'}} text="Modifica" onClick={() => {
        props.onClick(props.letter)
    }}/>
}

export const DeleteDraftButton: React.FC<{
    job: Job
    letter: Letter
    onSuccess: (letterId: string) => void
}> = props => {
    const rmi = useFusinaRmi()
    const sc = useSecurityContext()

    const policy = useMemo(() => props.job ? LettersPolicy({
        job: props.job,
        securityContext: sc,
        oldLetter: props.letter,
    }) : undefined, [sc, props.job, props.letter])

    const [pending, setPending] = useState<boolean>(false)
    const [confirming, setConfirming] = useState<boolean>(false)

    const handle = useCallback(() => {
        setConfirming(false)
        setPending(true)
        rmi.Letters.deleteDraft(props.letter._id)
            .then(() => {
                props.onSuccess(props.letter._id)
            })
            .catch(console.error) // TODO
            .finally(() => setPending(false))
    }, [rmi, policy])

    const preConfirmHandle = useCallback(() => {
        setConfirming(true)
        setPending(true)
        setTimeout(() => {
            setPending(false)
        }, 1000)
    }, [])

    const buttonId = useId('DeleteDraftButton-preConfirm');

    if (policy?.permissions?.write !== true) {
        return null
    }

    return <>
        <ActionButton id={buttonId} iconProps={{iconName: 'Delete'}} text="Elimina" onClick={preConfirmHandle}/>
        <Callout
            hidden={!confirming}
            coverTarget
            directionalHint={DirectionalHint.topAutoEdge}
            role="dialog"
            style={{padding: '20px 24px'}}
            onDismiss={() => setConfirming(false)}
            target={'#' + buttonId}
            isBeakVisible={false}
            setInitialFocus
        >
            <Text block variant="xLarge" style={{fontWeight: 200, marginBottom: 12}}>
                Confermi?
            </Text>
            <MessageBar messageBarType={MessageBarType.severeWarning}>
                Stai per eliminare definitivamente la bozza.
            </MessageBar>
            <Stack horizontal style={{marginTop: 20}} tokens={{childrenGap: 'm'}}>
                <PrimaryButton onClick={handle} className="DeleteDraftButton-Confirm"
                               text="Conferma" disabled={pending}/>
                {pending && <Spinner size={SpinnerSize.small}/>}
            </Stack>
        </Callout>
    </>
}
