import * as React from 'react'

import {PageSetting} from "../contexts/PageContext";
import {Link, useParams} from "react-router-dom";
import {Spinner, SpinnerSize} from "@fluentui/react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {useJob} from "../hooks/useJob";
import {EcoOdsExpensesTable} from "../components/EcoOdsExpensesTable";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import {JobHeadingFrag} from "../components/JobHeadingFrag";
import {EcoOdS} from "../../../fusina-letters/entities/EcoOdS";
import {EcoOdsExpensesMeta} from "../components/EcoOdsExpensesMeta";


export const EcoOdsExpenses: React.FC = () => {
    const {jobId, letterId} = useParams()
    const {job, jobError} = useJob(jobId)

    const {
        data: letter,
        error: letterError,
    } = useFusinaRmiResource('Letters', 'findById', letterId)

    if (!job || !letter) {
        return <MaybeErrorMessageBar error={letterError ?? jobError}/> || <>
            <br/>
            <Spinner size={SpinnerSize.large}/>
        </>
    }

    return <>
        <PageSetting
            iconName="Table"
            title={<>
                <div style={{paddingRight: 15}}>Consuntivo <Link to={`jobs/${jobId}/letters/${letterId}`}>OdS
                    n°{letter.externalRef}</Link></div>
                <JobHeadingFrag/>
            </>}
            sideChildren={null}
        />
        <EcoOdsExpensesTable letterId={letterId}/>
        <EcoOdsExpensesMeta letter={letter as EcoOdS}/>
    </>
}
