import * as React from "react";
import {useContext, useMemo} from "react";
import {Stack, Text, useTheme} from "@fluentui/react";
import {isSameDate} from './commons'
import {CalContext} from "./CalContext";
import {SmallEventFrag} from "./SmallEventFrag";

export const WeekHourFrag: React.FC<{
    dayIndex: number
    hourIndex: number
    date: Date
    hour: number
}> = props => {
    const [cal, setCalState] = useContext(CalContext)
    const thisHourEvents = useMemo(() => {
            const ee = cal.events
                .filter(ev => ['TENTATIVE', 'CONFIRMED'].includes(ev.status))
                .filter(ev => isSameDate(new Date(ev.dtStart), props.date))
                .filter(ev => new Date(ev.dtStart).getHours() === props.hour)
            ee.sort((a, b) => {
                return new Date(a.dtStart).getTime() - new Date(b.dtStart).getTime()
            })
            return ee
        },
        [props.date, cal.events, props.hour])

    const theme = useTheme()
    return <Stack.Item
        styles={{
            root: {
                background: props.date.getTime() === cal.selectedDate.getTime() ? theme.semanticColors.bodyStandoutBackground : theme.semanticColors.bodyBackground,
                position: 'relative',
                borderTop: '1px solid ' + theme.palette.neutralLight,
                minHeight: 18,
                overflow: 'hidden',
            }
        }}
    >
        {props.dayIndex === 0 && <Text
            block variant="xSmall"
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
            }}
        >
            {props.hour}:00
        </Text>}

        <Stack
            styles={{
                root: {
                    gap: 1,
                }
            }}
        >
            {thisHourEvents
                .map(ev => <SmallEventFrag ev={ev}/>)}
        </Stack>
    </Stack.Item>
}
