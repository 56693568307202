import {RoleEnum} from "../../fusina-authz/entities/RoleEnum";
import {InvalidContextException} from "../../fusina-policies/exceptions";
import {PolicyContext} from "../../fusina-policies/PolicyContext";
import {getSenderRecipient, Letter} from "../entities/Letter";
import {Job} from "../../fusina-jobs/entities/Job";
import {SignatureEmbedding} from "../../fusina-signatures/entities/SignatureEmbedding";
import {getMetLetterNumberingOptions, LetterNumberingOptions} from "../entities/LetterNumberingOptions";

export interface InfoOdsPolicyContext extends PolicyContext {
    job?: Job
    oldLetter?: Letter
    newLetter?: Letter

    /** may be deprecated ? this is to condition listing on type and not on single letter or job */
    letterType?: Letter['type'] | '*'
    signatureRole?: RoleEnum

    letterNumberingOptions?: LetterNumberingOptions
    isFreeSlotByTypeJobRef?: boolean
}

/** This is an extra hiding filter to give a simplified view to some roles -- not a secrecy policy. */
export function LettersPolicy_ExtraListFilter(policy: ReturnType<typeof LettersPolicy>): (letter: Letter) => boolean {
    const roles = new Set(policy.myRoles)
    // return () => true
    return (letter: Letter) => {
        if (roles.has(RoleEnum.Admin)) {
            return true
        }
        if (letter.sent) {
            return roles.has(RoleEnum.CDL) || roles.has(RoleEnum.CM202311) || roles.has(RoleEnum.SM) || roles.has(RoleEnum.PM) || roles.has(RoleEnum.CDL_Q) || roles.has(RoleEnum.Admin) || roles.has(RoleEnum.Appaltatore) || roles.has(RoleEnum.Supervisor20220713) || roles.has(RoleEnum.AppaltatoreCQ) || roles.has(RoleEnum.OdSCompiler202209) || roles.has(RoleEnum.CommissioningMng202409)
        } else {
            if (letter.type === 'NI') {
                return roles.has(RoleEnum.Appaltatore) || roles.has(RoleEnum.AppaltatoreCQ)
                // || (letter?.signatures?.length > 0 && roles.has(RoleEnum.Supervisor20220713))  // this has been removed because this supervisor is buyer-side and the NI is still not sent here.
            }
            if (letter.type === 'InfoOdS' || letter.type === 'UltAccOdS' || letter.type === 'EcoOdS' || letter.type === 'VerbaleTAOdS') {
                if (letter.signatures.length + (letter.type === 'EcoOdS' ? letter.estimate.signatures.length : 0) > 0) {
                    // not a draft
                    return roles.has(RoleEnum.CDL) || roles.has(RoleEnum.CM202311) || roles.has(RoleEnum.CommissioningMng202409) || roles.has(RoleEnum.SM) || roles.has(RoleEnum.PM) || (letter?.signatures?.length > 0 && roles.has(RoleEnum.Supervisor20220713))
                } else {
                    // draft
                    return (roles.has(RoleEnum.CDL) || roles.has(RoleEnum.OdSCompiler202209) || roles.has(RoleEnum.CommissioningMng202409)) &&
                        (!letter.isPrivateAsDraft || letter.createdBy?._id === policy.myUserId)
                }
            }
            const []: never[] = [letter];
            console.error('Unexpected letter type')
            return false
        }
    }
}

/** Compute operational policy for the given context */
export default function LettersPolicy(ctx: InfoOdsPolicyContext) { // NOTE: let TS infer the exact type?
    const sc = ctx.securityContext;

    if (!sc || !ctx.job?._id) {
        throw new InvalidContextException()
    }

    const letterJob = ctx.newLetter?.job ?? ctx.oldLetter?.job
    const letterType = ctx.newLetter?.type ?? ctx.oldLetter?.type

    // NOTE: the policy can also be evaluated without letter, for listing

    if (letterJob && ctx.job?._id !== letterJob) {
        throw new InvalidContextException()
    }

    // TODO: validate objects against schema

    const grants = sc.user?.grants?.filter?.(g => (
        (g.scopeType === 'job' && g.scopeId === ctx.job._id) ||
        (g.scopeType === 'company' && g.scopeId === ctx.job.buyer) ||
        (g.scopeType === 'company' && g.scopeId === ctx.job.contractor)
    )) ?? []

    const myRoles: Set<RoleEnum> = new Set([...grants.map(g => g.role), ...ctx.securityContext?.user?.globalRoles ?? []])

    const iAmBuyer = myRoles.has(RoleEnum.CDL) || myRoles.has(RoleEnum.SM) || myRoles.has(RoleEnum.PM) || myRoles.has(RoleEnum.OdSCompiler202209) || myRoles.has(RoleEnum.CM202311) || myRoles.has(RoleEnum.CommissioningMng202409)
    const iAmBuyer_CQ = myRoles.has(RoleEnum.CDL_Q)
    const iAmContractor = myRoles.has(RoleEnum.Appaltatore)
    const iAmAdmin = myRoles.has(RoleEnum.Admin)
    const iAmSupervisor = myRoles.has(RoleEnum.Supervisor20220713)
    const iAmContractor_CQ = myRoles.has(RoleEnum.AppaltatoreCQ)

    const {sender, recipient} = letterType ? getSenderRecipient({type: letterType}, ctx.job) : {
        sender: undefined,
        recipient: undefined
    }

    const senderIsBuyer = sender && sender === ctx.job.buyer
    const senderIsContractor = sender && sender === ctx.job.contractor

    const recipientIsBuyer = recipient && recipient === ctx.job.buyer
    const recipientIsContractor = recipient && recipient === ctx.job.contractor

    const iAmSender = (iAmBuyer && senderIsBuyer) || (iAmContractor && senderIsContractor)
    const iAmRecipient = (iAmBuyer && recipientIsBuyer) || (iAmContractor && recipientIsContractor)

    const signatures: SignatureEmbedding[] = ctx.oldLetter?.signatures ?? []
    const signedByMe = !!signatures.find(s => s.userId === sc.userId)
    const signedByCDL = !!signatures.find(s => s.role === RoleEnum.CDL)
    const signedByCommMng = !!signatures.find(s => s.role === RoleEnum.CommissioningMng202409)
    const signedBySM = !!signatures.find(s => s.role === RoleEnum.SM)
    const signedByPM = !!signatures.find(s => s.role === RoleEnum.PM)
    const signedByAppaltatore = !!signatures.find(s => s.role === RoleEnum.Appaltatore)

    const estimateSignatures = ctx.oldLetter?.type === 'EcoOdS' ? ctx.oldLetter?.estimate?.signatures ?? [] : []
    const noEstimateSignatures = estimateSignatures.length <= 0
    const hasEstimateSignedByPM = !!estimateSignatures.find(s => s.role === RoleEnum.PM)
    const changingEstimate = ctx.oldLetter?.type === 'EcoOdS' && ctx.newLetter?.type === 'EcoOdS' && JSON.stringify(ctx.oldLetter.estimate) !== JSON.stringify(ctx.newLetter.estimate)

    /** Mid phase following first estimate signature and preceding the last estimate signature (the PM's signature) */
    const isEstimateSigningPhase = ctx.oldLetter?.type === 'EcoOdS' && !noEstimateSignatures && !hasEstimateSignedByPM

    const sent = ctx.oldLetter?.sent === true

    const isNiSeenByCDL = ctx?.oldLetter?.type === 'NI' ? (ctx.oldLetter.seenByCDL === true) : undefined;

    const changingType = ctx.oldLetter && ctx.newLetter && ctx.newLetter?.type !== ctx.oldLetter?.type
    const changingJob = ctx.oldLetter && ctx.newLetter && ctx.newLetter?.job !== ctx.oldLetter?.job
    const noSignatures = !(signatures.length > 0) && !(ctx.newLetter?.signatures?.length > 0)

    const isCreatedByMe = ctx.oldLetter?.createdBy?._id ? (ctx.oldLetter?.createdBy?._id === sc.user?._id) : null;

    const isSignatureVisible = (
        (letterType === 'VerbaleTAOdS' && [RoleEnum.CommissioningMng202409, RoleEnum.SM, RoleEnum.Appaltatore].includes(ctx.signatureRole)) ||
        (letterType === 'UltAccOdS' && [RoleEnum.SM, RoleEnum.PM, RoleEnum.Appaltatore].includes(ctx.signatureRole)) ||
        (letterType === 'InfoOdS' && [RoleEnum.CDL, RoleEnum.SM, RoleEnum.Appaltatore].includes(ctx.signatureRole)) ||
        (letterType === 'EcoOdS' && [RoleEnum.CDL, RoleEnum.SM, RoleEnum.Appaltatore].includes(ctx.signatureRole)) ||
        (letterType === 'NI' && [RoleEnum.Appaltatore, RoleEnum.CDL, RoleEnum.SM].includes(ctx.signatureRole)) ||
        false // close off the OR chain
    )

    const isPrivateAsDraft = ctx.oldLetter?.isPrivateAsDraft || ctx.newLetter?.isPrivateAsDraft;

    const metLetterNumberingOptions: string[] | undefined = ctx.newLetter && ctx.letterNumberingOptions ?
        getMetLetterNumberingOptions(ctx.newLetter, ctx.letterNumberingOptions) : undefined
    const isFreeSlotByTypeJobRef = ctx.isFreeSlotByTypeJobRef

    const isChangingMessageOrSubject = (ctx.oldLetter && ctx.newLetter) ? (
        ctx.oldLetter.message !== ctx.newLetter.message || ctx.oldLetter.subject !== ctx.newLetter.subject) : undefined

    const props = {
        myUserId: sc?.userId ?? sc?.user?._id,
        isCreatedByMe,
        isPrivateAsDraft,

        iAmBuyer, iAmContractor, iAmSender, iAmRecipient, iAmAdmin,
        iAmContractor_CQ,
        iAmSupervisor: iAmSupervisor,

        myGrants: grants,
        myRoles: [...myRoles],

        letterType,
        changingType, changingJob, noSignatures,
        signingRole: ctx.signatureRole,
        isSignatureVisible,

        signedByMe, signedByCDL, signedBySM, signedByPM, signedByAppaltatore,
        signedByCommMng,

        hasEstimateSignedByPM,
        noEstimateSignatures, changingEstimate,
        isEstimateSigningPhase,
        sent,
        isNiSeenByCDL,

        metLetterNumberingOptions,
        isFreeSlotByTypeJobRef,
        isChangingMessageOrSubject,
    }

    return {
        ...props,
        permissions: {
            /** Permission to list letters in the given job. More specific filtering policies apply. */
            list: iAmBuyer || iAmContractor || iAmAdmin || iAmSupervisor || iAmContractor_CQ || iAmBuyer_CQ,

            read: iAmSender || (iAmRecipient && sent) || iAmAdmin || (iAmSupervisor && !noSignatures) || (iAmContractor_CQ && sent) || (iAmBuyer_CQ && sent),
            // NOTE: read only depends on the roles (and the job), and not on the specific letter.

            write: !changingJob && noSignatures && (iAmSender || (iAmAdmin && isChangingMessageOrSubject === false)) && !sent &&  // (task #2650)
                !isEstimateSigningPhase &&
                isFreeSlotByTypeJobRef !== false &&
                (iAmAdmin ||  // (task #2650)
                    metLetterNumberingOptions === undefined || metLetterNumberingOptions?.length >= 1) && // NOTE: always defined in the backend
                (noEstimateSignatures || !changingEstimate) &&
                (
                    (senderIsBuyer && myRoles.has(RoleEnum.OdSCompiler202209) && (
                        (isPrivateAsDraft && isCreatedByMe !== false)
                        // NOTE: the compiler can only write private drafts created by them or with unknown creator; the compiler shall not edit the draft after it has been shared; this is just for increased safety against uncontrolled concurrent access.
                    )) ||
                    (senderIsBuyer && myRoles.has(RoleEnum.CDL) && (
                        (isPrivateAsDraft && isCreatedByMe !== false) || letterType !== 'VerbaleTAOdS'
                        // NOTE: As a CDL you can write OdS letters with one exception: you can write VerbaleTAOdS only if you are the creator of it and it's still a private draft, or the creatore is unknown.
                    )) ||
                    (senderIsBuyer && myRoles.has(RoleEnum.CommissioningMng202409) && (
                        letterType === 'VerbaleTAOdS'
                        // NOTE: As a CommMng you can only write VerbaleTAOdS.
                    )) ||
                    (senderIsContractor && myRoles.has(RoleEnum.Appaltatore)) ||
                    (iAmAdmin && isChangingMessageOrSubject === false) ||  // (task #2650)
                    false // close off the or chain
                ),
            // NOTE: signatures can't be added or changed by write operation; only drafts can be written.

            sign:
                !signedByMe && // Can't sign two times
                !!ctx.signatureRole && myRoles.has(ctx.signatureRole) &&  // I have the specified role
                ((sent && iAmRecipient) || iAmSender) && // I am sending or I have already received
                (letterType === 'EcoOdS' ? hasEstimateSignedByPM : true) &&  // EcoOds cannot be signed unless the estimate is signed by the PM (which implies it's also signed by SM, CDL).
                (
                    ((letterType === 'EcoOdS' || letterType === 'InfoOdS' || letterType === 'UltAccOdS') && (
                        (ctx.signatureRole === RoleEnum.CDL && iAmSender) ||
                        (ctx.signatureRole === RoleEnum.SM && iAmSender && signedByCDL) ||
                        (ctx.signatureRole === RoleEnum.Appaltatore && iAmRecipient && sent) ||
                        false // close off the or chain
                    )) ||

                    ((letterType === 'UltAccOdS') && (
                        (ctx.signatureRole === RoleEnum.CDL && iAmSender) ||
                        (ctx.signatureRole === RoleEnum.SM && iAmSender && signedByCDL) ||
                        (ctx.signatureRole === RoleEnum.PM && iAmSender && signedBySM) ||
                        (ctx.signatureRole === RoleEnum.Appaltatore && iAmRecipient && sent) ||
                        false // close off the or chain
                    )) ||

                    ((letterType === 'VerbaleTAOdS') && (
                        // (ctx.signatureRole === RoleEnum.CDL && iAmSender) ||
                        (ctx.signatureRole === RoleEnum.CommissioningMng202409 && iAmSender) ||
                        (ctx.signatureRole === RoleEnum.SM && iAmSender && signedByCommMng) ||
                        (ctx.signatureRole === RoleEnum.Appaltatore && iAmRecipient && sent) ||
                        false // close off the or chain
                    )) ||

                    ((letterType === 'NI') && (
                        (ctx.signatureRole === RoleEnum.CDL && iAmRecipient && sent) || // WARNING: specifications have changed over time: 1. CDL signs NI; 2. CDL does not sign NI anymore, they only mark as read; 3. CDL signs NI as original.
                        (ctx.signatureRole === RoleEnum.SM && iAmRecipient && sent && (isNiSeenByCDL === true || signedByCDL)) ||  // WARNING: se above changes
                        (ctx.signatureRole === RoleEnum.Appaltatore && iAmSender) ||
                        false // close off the or chain
                    )) ||

                    false // close off the or chain
                ),

            rejectToDraft: !(noSignatures && noEstimateSignatures) && (sent === false || iAmAdmin) &&  // (task #2650)
                (
                    (senderIsBuyer && myRoles.has(RoleEnum.CommissioningMng202409)) ||
                    (senderIsBuyer && myRoles.has(RoleEnum.CDL)) ||
                    (senderIsBuyer && myRoles.has(RoleEnum.SM)) ||
                    (senderIsBuyer && myRoles.has(RoleEnum.PM)) ||
                    // TODO?: (task #3899) Ask if we should add: /* (senderIsBuyer && myRoles.has(RoleEnum.CM202311)) || */
                    // (senderIsContractor && myRoles.has(RoleEnum.Appaltatore)) ||
                    iAmAdmin ||  // (task #2650)
                    false // close off the or chain
                ),

            send: sent === false && signedByMe && (
                (letterType === 'VerbaleTAOdS' && iAmBuyer && senderIsBuyer && signedByCommMng && signedBySM) ||
                (letterType === 'UltAccOdS' && iAmBuyer && senderIsBuyer && signedBySM && signedByPM) ||
                (letterType === 'InfoOdS' && iAmBuyer && senderIsBuyer && signedByCDL && signedBySM) ||
                (letterType === 'EcoOdS' && iAmBuyer && senderIsBuyer && signedByCDL && signedBySM) ||
                (letterType === 'NI' && iAmContractor && senderIsContractor && signedByAppaltatore) ||
                false // close off the or chain
            ),

            /** UC009 */
            reply: sent && iAmRecipient && (
                (recipientIsContractor && signedByAppaltatore) ||
                (recipientIsBuyer && signedBySM) ||
                false // close off the or chain
            )
        }
    }
}
