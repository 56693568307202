import * as React from 'react'
import {amountFormatter, EcoOdSExpensesMeta} from "../../../fusina-letters/entities/EcoOdS";
import {Icon, Text, useTheme} from "@fluentui/react";
import Decimal from "decimal.js-light";

export const EcoOdsAmountFrag: React.FC<{
    value?: number | string | Decimal
    expensesMeta?: EcoOdSExpensesMeta
}> = props => {
    const theme = useTheme()
    try {
        return <>
            {props.expensesMeta?.closed && <>
                <Icon
                    iconName="CompletedSolid"
                    styles={{
                        root: {
                            color: theme.semanticColors.successIcon,
                            verticalAlign: 'top',
                        }
                    }}
                />
                &nbsp;
            </>}
            {amountFormatter(props.value, 2)}
            &nbsp;€
        </>
    } catch (e) {
        console.error(e)
        return <Text style={{color: theme.semanticColors.severeWarningIcon}}>
            error
        </Text>
    }
}
