import * as React from "react";
import {useContext, useMemo} from "react";
import {EventBase} from "../../../../fusina-event/entities/EventBase";
import {Callout, DirectionalHint, Stack, useTheme} from "@fluentui/react";
import {getShortTimeString} from "./commons";
import {EventCardFrag} from "./EventCardFrag";
import {useId} from "@fluentui/react-hooks";
import {CalContext} from "./CalContext";

export const SmallEventFrag: React.FC<{
    ev: EventBase
}> = props => {
    const [cal, setCalStat] = useContext(CalContext)
    const theme = useTheme()
    const id = useId('SmallEventFrag-')

    const isOpen = cal.openEvent &&
        ((cal.openEvent === props.ev)
            // @ts-ignore
            || (cal.openEvent?._id && props.ev?._id && cal.openEvent?._id === props.ev?._id))

    const contextLabel = useMemo<string | undefined>(() =>
        cal.eventSmallFragContextLabelGetter?.(props.ev), [props.ev, cal.eventSmallFragContextLabelGetter])

    return <Stack.Item
        onClick={() => {
            if (!isOpen) {
                setCalStat(s => ({...s, openEvent: props.ev}))
            } else {
                setCalStat(s => ({...s, openEvent: undefined}))
            }
        }}
        id={id}
        styles={{
            root: {
                cursor: 'pointer',
                position: 'relative',
                background: theme.palette.themeLight,
                borderRadius: 3,
                padding: "0 2px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                height: "1.1em",
                whiteSpace: "nowrap",
                lineHeight: "1.1em",
                fontSize: theme.fonts.small.fontSize,
                borderLeft: "6px solid " + (props.ev.status === "CONFIRMED"
                    ? theme.palette.themePrimary
                    : theme.palette.neutralQuaternaryAlt),
                '&:hover': {
                    outline: '1px solid ' + theme.palette.themePrimary,
                }
            }
        }}
    >
        {getShortTimeString(new Date(props.ev.dtStart))} {contextLabel} {props.ev.summary}

        {isOpen && <Callout
            role="dialog"
            onDismiss={() => {
                setCalStat(s => ({...s, openEvent: undefined}))
            }}
            hidden={!isOpen}
            directionalHint={DirectionalHint.rightCenter}
            target={`#${id}`}
        >
            <EventCardFrag ev={props.ev}/>
        </Callout>}
    </Stack.Item>
}
