import * as React from "react";
import {useContext, useMemo} from "react";
import {ITPEvent} from "../../../fusina-itp/entities/ITPEvent";
import {CommandBarButton, Label, Stack} from "@fluentui/react";
import {ITPEventFullView} from "./ITPEventFullView";
import {ITPEventMessagesView} from "./ITPEventMessagesView";
import {ITPEventMessageForm} from "./ITPEventMessageForm";
import {EVENT_STATUS_LABELS_IT} from "../../../fusina-event/entities/EventProperties";
import {useFusinaRmiUrl} from "../hooks/useFusinaRmiUrl";
import {useSecurityContext} from "../contexts/SecurityContext";
import {useJobContext} from "../contexts/JobContext";
import {ITPItemContext} from "../contexts/ITPItemContext";
import {ITPEventPolicy} from "../../../fusina-itp/controls/ITPEventPolicy";
import {FrontendComponent, usePermittedFrontendComponents} from "../hooks/usePermittedFrontendComponents";
import {useNavigate} from "react-router-dom";
import {ITPEventAttachments} from "./ITPEventAttachments";

export const ITPEventMessaging: React.FC<{
    event: ITPEvent,
}> = props => {

    const downloadPdf = useFusinaRmiUrl('ITPEvents', 'export', props.event._id, 'pdf')
    const downloadEml = useFusinaRmiUrl('ITPEvents', 'export', props.event._id, 'eml')
    const downloadIcs = useFusinaRmiUrl('ITPEvents', 'export', props.event._id, 'ics')

    const securityContext = useSecurityContext()
    const {job} = useJobContext()
    const {itp} = useContext(ITPItemContext)
    const policy = useMemo(() =>
        ITPEventPolicy({
            securityContext,
            job,
            itp,
        }), [securityContext, job, itp])

    const comps = usePermittedFrontendComponents()
    const navigate = useNavigate()

    return <Stack
        tokens={{childrenGap: 'l2'}}
        horizontal wrap
        verticalAlign="stretch" horizontalAlign="stretch"
    >
        <Stack tokens={{childrenGap: 's1'}} grow={1} style={{maxWidth: 500}}>
            <Stack horizontal verticalAlign="start" styles={{
                root: {
                    marginTop: 5,
                }
            }}>
                <div style={{color: props.event.status === 'CANCELLED' ? 'red' : undefined}}>
                    <b>&nbsp;&nbsp;Stato:</b> {EVENT_STATUS_LABELS_IT[props.event?.status] ?? '?'}
                </div>
                <Stack.Item grow={1}>
                    &nbsp;
                </Stack.Item>
                {(comps.has(FrontendComponent.ITPGlobalEventsCal) || comps.has(FrontendComponent.ITPCal)) && <Stack>
                    <CommandBarButton
                        styles={{
                            root: {
                                padding: '10px 8px',
                                margin: '-9px 0 -8px',
                            }
                        }}
                        text="Calendario"
                        iconProps={{
                            iconName: 'Calendar',
                        }}
                        onClick={() => {
                            if (comps.has(FrontendComponent.ITPGlobalEventsCal)) {
                                navigate(`/itpGlobalCal/week/${props.event?.dtStart}/${props.event?._id}`)
                            } else {
                                navigate(`/jobs/${job?._id}/itpCal/week/${props.event?.dtStart}/${props.event?._id}`)
                            }
                        }}
                    />
                </Stack>}
                <Stack>
                    <CommandBarButton
                        styles={{
                            root: {
                                padding: '10px 8px',
                                margin: '-9px 0 -8px',
                            }
                        }}
                        text="Download"
                        iconProps={{
                            iconName: 'Download',
                        }}
                        menuProps={{
                            items: [
                                {
                                    key: 'PDF',
                                    iconProps: {
                                        iconName: 'PDF',
                                    },
                                    text: 'PDF',
                                    href: downloadPdf,
                                },
                                ...(policy.isClient_CQ ? [
                                    {
                                        key: 'EML',
                                        iconProps: {
                                            iconName: 'MailAttached',
                                        },
                                        text: 'Email',
                                        href: downloadEml,
                                    },
                                    {
                                        key: 'ICS',
                                        iconProps: {
                                            iconName: 'Calendar',
                                        },
                                        text: 'iCalendar',
                                        href: downloadIcs,
                                    },
                                ] : []),
                            ]
                        }}
                    />
                </Stack>
            </Stack>
            <ITPEventFullView eventProps={props.event}/>
            <ITPEventAttachments event={props.event}/>
        </Stack>
        <Stack tokens={{childrenGap: 's1'}} grow={1} style={{maxWidth: 500}}>
            <Stack>
                <Label>Messaggi</Label>
                <ITPEventMessagesView
                    event={props.event}
                    maxHeight={'max(200px, calc(90vh - 300px))'}/>
            </Stack>
            <ITPEventMessageForm event={props.event}/>
        </Stack>
    </Stack>;
}
