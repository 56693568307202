import {BaseLetter} from "./BaseLetter";
import {EcoItem} from "../../fusina-jobs/entities/EcoItem";
import Decimal from 'decimal.js-light';
import {SignatureEmbedding} from "../../fusina-signatures/entities/SignatureEmbedding";

export interface OrderItem extends EcoItem {
    quantity: number
}

export interface OrderEstimate {
    signatures: SignatureEmbedding[]
    items: OrderItem[]
}

export interface EcoOdS extends BaseLetter {
    type: "EcoOdS"
    estimate: OrderEstimate

    /** de-normalized total of order expenses referring to this order */
    expenses?: number

    expensesMeta?: EcoOdSExpensesMeta
}

export interface EcoOdSExpensesMeta {
    /** Who's able to write the expenses referring to this order is also able to indicate the completion (task #1443) */
    closed?: boolean
}

export function amountOrderItem(item: OrderItem): Decimal {
    const p = new Decimal(item.unitPrice ?? 0)
    const q = new Decimal(item.quantity ?? 0)
    return p.mul(q).toDecimalPlaces(2)
}

export function sumOrderItems(items: OrderItem[]): Decimal {
    return items
        .map(it => amountOrderItem(it))
        .reduce((prev, it) => prev.add(it), new Decimal(0))
        .toDecimalPlaces(2)
}

export function amountFormatter(x: number | Decimal | string, dp?: number, minDp?: number): string | undefined {
    if (x === undefined || x.toString().trim().length <= 0) {
        return undefined
    }
    try {
        const d = x instanceof Decimal ? x : new Decimal(x)
        const f = d.toFixed(dp)  // decimals, including zeros
            .replace('.', ',')  // italian floating point
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')  // thousands separation
        if (minDp) {
            const dec = f.split(',')[1]
            if (dec === undefined) {
                return `${f},00`
            }
            if (dec.length === 0) {
                return `${f}00`
            }
            if (dec.length === 1) {
                return `${f}0`
            }
        }
        return f
    } catch (e) {
        console.error(e)
    }
}

// TODO: export function amountParser(x: string): number
