import * as React from 'react'
import {useCallback, useState} from 'react'
import {amountFormatter, amountOrderItem, OrderItem, sumOrderItems} from "../../../fusina-letters/entities/EcoOdS";
import {
    ActionButton,
    Callout,
    DetailsList,
    DetailsListLayoutMode,
    DirectionalHint,
    IColumn,
    IconButton,
    IIconProps,
    Label,
    mergeStyles,
    SearchBox,
    SelectionMode,
    TextField,
    useTheme
} from "@fluentui/react";
import {useForceUpdate, useId} from "@fluentui/react-hooks";
import {Stack} from "@fluentui/react/lib/Stack";
import {EcoItem} from "../../../fusina-jobs/entities/EcoItem";
import {DecimalField} from "./DecimalField";
import useThrottledCallback from "../hooks/useThrottledCallback";

const rowTotalCellStyle = mergeStyles({
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontFamily: 'monospace',
    userSelect: 'text',
});

const descClassName = mergeStyles({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    fontSize: '0.8em',
    cursor: 'pointer',
})

const userRolesColumns: IColumn[] = [
    {
        name: 'Articolo',
        key: 'item',
        fieldName: 'item',
        minWidth: 100,
        targetWidthProportion: 0.2,
        styles: {
            cellTitle: {
                padding: '0 9px',
            }
        }
    },
    {
        name: 'Descrizione',
        key: 'description',
        fieldName: 'description',
        minWidth: 200,
        targetWidthProportion: 0.4,
        flexGrow: 1,
        styles: {
            cellTitle: {
                padding: '0 9px',
            }
        }
    },
    {
        name: 'U.M.',
        key: 'unitOfMeasure',
        fieldName: 'unitOfMeasure',
        ariaLabel: 'Unità di misura',
        minWidth: 70,
        targetWidthProportion: 0.1,
        styles: {
            cellTitle: {
                padding: '0 9px',
            }
        }
    },
    {
        name: 'P.U. [€]',
        key: 'unitPrice',
        minWidth: 120,
        targetWidthProportion: 0.1,
        styles: {
            cellTitle: {
                justifyContent: 'flex-end',
                padding: '0 9px',
            }
        }
    },
    {
        name: 'Quantità',
        key: 'quantity',
        minWidth: 90,
        targetWidthProportion: 0.1,
        styles: {
            cellTitle: {
                justifyContent: 'flex-end',
                padding: '0 9px',
            }
        }
    },
    {
        name: 'Ammontare',
        key: 'amount',
        minWidth: 120,
        targetWidthProportion: 0.1,
        className: rowTotalCellStyle,
        styles: {
            cellTitle: {
                justifyContent: 'flex-end',
            }
        }
    },
    {
        name: '',
        key: 'buttons',
        minWidth: 30,
        targetWidthProportion: 0.1,
    },
]

const addItemIcon: IIconProps = {iconName: 'Add'};
const delItemIcon: IIconProps = {iconName: 'Delete'};


export const OrderItemsEditor: React.FC<{
    items: OrderItem[]
    contractualItems?: EcoItem[]
}> = props => {

    const theme = useTheme()
    const forceUpdate = useForceUpdate();
    const totalSpanId = useId('OrderItemsEditor-Total');

    const _renderItemColumn = useCallback((item: OrderItem, index: number, column: typeof userRolesColumns[number]) => {
        if (column.key === 'item') {
            return <TextField
                className="OrderItemsEditor-item"
                value={item.item}
                onChange={(event, newValue) => {
                    item.item = newValue
                    forceUpdate()
                }}/>
        }
        if (column.key === 'description') {
            return <TextField
                className="OrderItemsEditor-description"
                value={item.description}
                onChange={(event, newValue) => {
                    item.description = newValue
                    forceUpdate()
                }}/>
        }
        if (column.key === 'unitOfMeasure') {
            return <TextField
                className="OrderItemsEditor-unitOfMeasure"
                value={item.unitOfMeasure}
                onChange={(event, newValue) => {
                    item.unitOfMeasure = newValue
                    forceUpdate()
                }}/>
        }
        if (column.key === 'unitPrice') {
            return <DecimalField
                className="OrderItemsEditor-unitPrice"
                value={item.unitPrice}
                min={0}
                minDp={2}
                styles={{
                    field: {
                        textAlign: 'right',
                        fontFamily: 'monospace !important',
                    }
                }}
                onChange={(newValue) => {
                    item.unitPrice = newValue
                    forceUpdate()
                }}/>
        }
        if (column.key === 'quantity') {
            return <DecimalField
                className="OrderItemsEditor-quantity"
                value={item.quantity}
                min={0}
                styles={{
                    field: {
                        textAlign: 'right',
                        fontFamily: 'monospace !important',
                    }
                }}
                onChange={(newValue) => {
                    item.quantity = newValue
                    forceUpdate()
                }}/>
        }
        if (column.key === 'amount') {
            if (item.quantity && item.unitPrice) {
                try {
                    return <>
                        {amountFormatter(amountOrderItem(item), 2)} €
                    </>
                } catch (e) {
                    console.debug(e)
                    return 'error'
                }
            }
            return null
        }
        if (column.key === 'buttons') {
            return <>
                <IconButton
                    className="OrderItemsEditor-delete"
                    iconProps={delItemIcon}
                    onClick={() => {
                        props.items.splice(index, 1)
                        forceUpdate()
                    }}/>
            </>
        }
        return null
    }, [])

    const addItemBtnId = useId('AddItemBtn')
    const [isAddItemCalloutOpen, setIsAddItemCalloutOpen] = useState<boolean>(false)

    const [search, setSearch_] = useState<string>('')
    const {
        throttledCallback: setSearch
    } = useThrottledCallback(setSearch_, 200, [])

    return <>
        <DetailsList
            compact
            onShouldVirtualize={() => false}
            cellStyleProps={{
                cellLeftPadding: 2,
                cellRightPadding: 2,
                cellExtraRightPadding: 2,
            }}
            selectionMode={SelectionMode.none}
            items={[...props.items]}
            columns={userRolesColumns}
            onRenderItemColumn={_renderItemColumn}
        />
        <Stack horizontal wrap>
            <>
                <ActionButton
                    id={addItemBtnId}
                    iconProps={addItemIcon}
                    onClick={() => setIsAddItemCalloutOpen(true)}
                >
                    Aggiungi articolo
                </ActionButton>
                <Callout
                    hidden={!isAddItemCalloutOpen}
                    role="dialog"
                    calloutWidth={400}
                    style={{padding: '0 10px 10px', width: 500}}
                    directionalHint={DirectionalHint.bottomAutoEdge}
                    onDismiss={() => setIsAddItemCalloutOpen(false)}
                    target={'#' + addItemBtnId}
                    setInitialFocus
                >
                    <Stack style={{margin: '10px 0 -1em', position: 'relative', zIndex: 2}}>
                        <Stack.Item>
                            <SearchBox onChange={(ev, v) => setSearch(v.toLowerCase())} placeholder="Cerca"/>
                        </Stack.Item>
                    </Stack>
                    <DetailsList
                        compact
                        items={props.contractualItems?.filter?.(item =>
                            `${item.item} ${item.description} ${item.unitOfMeasure} ${item.unitPrice}`.toLowerCase().includes(search)) ?? []}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        columns={[
                            {
                                key: 'item',
                                name: 'Articolo',
                                fieldName: 'item',
                                minWidth: 100,
                                maxWidth: 120,
                                flexGrow: 0.1,
                                targetWidthProportion: 0.2,
                            },
                            {
                                key: 'description',
                                name: 'Descrizione',
                                fieldName: 'description',
                                minWidth: 150,
                                flexGrow: 10,
                                targetWidthProportion: 0.8,
                            },
                        ]}
                        onRenderItemColumn={(item, index, column) => {
                            if (column.key === 'description' && item.description?.length > 40) {
                                return <div className={descClassName}>
                                    {item.description}
                                </div>
                            }
                            return item[column.fieldName]
                        }}
                        onActiveItemChanged={(item: EcoItem) => {
                            props.items.push({
                                ...item,
                                quantity: 0,
                            })
                            forceUpdate()
                            setIsAddItemCalloutOpen(false)
                        }}
                    />
                    <div style={{margin: '0 0 0 1em'}}>
                        <ActionButton
                            iconProps={addItemIcon}
                            onClick={() => {
                                props.items.push({
                                    item: '',
                                    description: '',
                                    unitOfMeasure: '',
                                    unitPrice: 0,
                                    quantity: 0,
                                })
                                forceUpdate()
                                setIsAddItemCalloutOpen(false)
                            }}
                        >
                            Aggiungi articolo non listato
                        </ActionButton>
                    </div>
                </Callout>
            </>
            <Stack.Item grow={1}>
                &nbsp;
            </Stack.Item>
            <Stack.Item>
                <Label htmlFor={totalSpanId} style={{display: 'inline'}}>Totale:&nbsp;&nbsp;</Label>
                <span id={totalSpanId} className="OrderItemsEditor-Total" style={{
                    fontFamily: 'monospace',
                    paddingRight: 36,
                    fontSize: theme.fonts.small.fontSize,
                    userSelect: 'text'
                }}>
                    {total(props.items)}&nbsp;€
                </span>
            </Stack.Item>
        </Stack>
    </>
}

function total(items: OrderItem[]): string {
    try {
        return amountFormatter(sumOrderItems(items), 2)
    } catch (e) {
        console.error(e)
        return 'error'
    }
}
