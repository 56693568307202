import {useMemo} from "react";
import {useSecurityContext} from "../contexts/SecurityContext";
import {useJobContext} from "../contexts/JobContext";
import LettersPolicy from "../../../fusina-letters/controls/LettersPolicy";
import {GdLPolicy} from "../../../fusina-gdl/controls/GdLPolicy";
import {ITPPolicy} from "../../../fusina-itp/controls/ITPPolicy";
import {InspectionTestPlan} from "../../../fusina-itp/entities/InspectionTestPlan";
import {makeJobsAdminPolicy} from "../../../fusina-jobs/controls/JobsAdminPolicy";
import {RoleEnum} from "../../../fusina-authz/entities/RoleEnum";
import {ITPCountsPolicy} from "../../../fusina-itp/controls/ITPCountsPolicy";
import {ITPGlobalEventsPolicy} from "../../../fusina-itp/controls/ITPGlobalEventsPolicy";


export enum FrontendComponent {
    OdsNi,
    GdL,
    ITP,
    admin,
    ITPStats,
    ITPCal,
    ITPGlobalEventsCal,
}


/** Provides a set of FrontendComponent's that shall be accessible to the user in the context. */
export const usePermittedFrontendComponents: () => Set<FrontendComponent> = () => {

    const securityContext = useSecurityContext()
    const {job} = useJobContext()

    return useMemo(() => {

        const comps = new Set<FrontendComponent>()

        try {
            if (job && LettersPolicy({
                securityContext,
                job,
            }).permissions.list) {
                comps.add(FrontendComponent.OdsNi)
            }
        } catch (e) {
            console.error(e)
        }

        try {
            if (job && GdLPolicy({
                securityContext,
                job,
            }).permissions.read) {
                comps.add(FrontendComponent.GdL)
            }
        } catch (e) {
            console.error(e)
        }

        try {
            if (job && (ITPPolicy({
                securityContext,
                job,
                itp: {  // Let's probe the permissions with this simulated ITP
                    items: [],
                    job: {
                        _id: job._id,
                        code: job.code,
                    },
                    signatures: [
                        {role: RoleEnum.AppaltatoreCQ},
                        {role: RoleEnum.CDL_Q},
                    ],
                } as InspectionTestPlan, // FIXME: avoid this with some listing policy
            }).permissions.read || securityContext?.user?.grants?.some?.(g => g.scopeType === 'itp'))) {
                comps.add(FrontendComponent.ITP)
                comps.add(FrontendComponent.ITPCal)
            }
        } catch (e) {
            console.error(e)
        }

        try {
            if (makeJobsAdminPolicy(securityContext).permissions.adminJobs) {
                comps.add(FrontendComponent.admin)
            }
        } catch (e) {
            console.error(e)
        }

        try {
            if (ITPCountsPolicy({
                securityContext,
            }).permissions.count) {
                comps.add(FrontendComponent.ITPStats)
            }
        } catch (e) {
            console.error(e)
        }

        try {
            if (ITPGlobalEventsPolicy({
                securityContext,
            }).permissions.read_global_events) {
                comps.add(FrontendComponent.ITPGlobalEventsCal)
            }
        } catch (e) {
            console.error(e)
        }

        return comps

    }, [securityContext, job])
}
