import {PolicyContext} from "../../fusina-policies/PolicyContext";
import {RoleEnum} from "../../fusina-authz/entities/RoleEnum";
import {Job} from "../../fusina-jobs/entities/Job";
import {getJobRoles} from "../../fusina-jobs/controls/JobGrants";

export interface GdLPolicyContext extends PolicyContext {
    job: Job
    date?: string
    filename?: string
}

export const DATE_REGEX = /^(20[1-4][0-9])\/(0[1-9]|10|11|12)\/(0[1-9]|[1-2][0-9]|30|31)$/

const getFileExtension = (fn: string) => {
    const split = fn.split('.')
    return split[split.length - 1]
}

export function GdLPolicy(ctx: GdLPolicyContext) {
    const sc = ctx.securityContext

    const myRoles: Set<RoleEnum> = new Set([...getJobRoles(ctx.job, sc?.user?.grants), ...ctx.securityContext?.user?.globalRoles ?? []])

    const iAmBuyer = myRoles.has(RoleEnum.CDL) || myRoles.has(RoleEnum.CM202311) || myRoles.has(RoleEnum.SM) || myRoles.has(RoleEnum.PM) || myRoles.has(RoleEnum.CommissioningMng202409)
    const iAmBuyerCDL = myRoles.has(RoleEnum.CDL)
    const iAmContractor = myRoles.has(RoleEnum.Appaltatore)
    const iAmAdmin = myRoles.has(RoleEnum.Admin)
    const iAmSupervisor = myRoles.has(RoleEnum.Supervisor20220713)

    const isValidDate = ctx.date && DATE_REGEX.test(ctx.date) && new Date(ctx.date).getFullYear() >= 2010;
    const isDateInPast = isValidDate && (new Date(ctx.date) < new Date())

    const fileExtension = ctx.filename ? getFileExtension(ctx.filename) : undefined
    const isFileExtGood = /[a-zA-Z0-9]{1,20}/.test(fileExtension)

    const props = {
        iAmBuyer, iAmContractor, iAmAdmin, iAmBuyerCDL,
        isValidDate, isDateInPast,
        isFileExtGood,
        goodFileExtension: isFileExtGood ? fileExtension : undefined
    }

    return {
        ...props,

        permissions: {

            read: iAmAdmin || iAmContractor || iAmBuyer || iAmSupervisor,

            add: (iAmAdmin || iAmContractor) && isDateInPast && isFileExtGood,

            delete: (iAmAdmin || iAmBuyerCDL) && isValidDate,

        }
    }
}
