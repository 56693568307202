import * as React from 'react'
import {createContext, PropsWithChildren, ReactNode, useContext, useEffect} from 'react'
import {SecurityContext} from "../../../fusina-authz/entities/SecurityContext";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {Spinner, SpinnerSize} from "@fluentui/react";
import {UnauthorizedError} from "../../../js-rmi/exceptions";
import {MaybeErrorMessageBar} from "../components/MaybeErrorMessageBar";
import {subscribe401, unsubscribe401} from "../../../js-rmi/RmiStub";

const DEFAULT_SC: SecurityContext = {
    userId: null
}

const ReactSecurityContext = createContext<SecurityContext>(DEFAULT_SC)

export const SecurityContextStore: React.FC<PropsWithChildren<{
    visitorPage: ReactNode
}>> = props => {
    const {
        data: sc,
        error: scError,
        refresh: scRefresh,
    } = useFusinaRmiResource('SecurityContext', 'read');

    useEffect(() => {
        if (sc && !scError) {
            subscribe401(scRefresh)
            return () => {
                unsubscribe401(scRefresh)
            }
        }
    }, [scRefresh, sc, scError])

    useEffect(() => {
        if (sc && !scError) {
            let cancelled = false;
            let to; // NOTE: using setTimeout instead of setInterval -- safeguard against bugs with machine suspension
            const cb = async () => {
                if (cancelled) {
                    return
                }
                await scRefresh?.()
                // [SESSION MANAGEMENT] renew the server-side session every 15min while the page is open
                // NOTE: this may be redundant as the notifications SSE is renewed every 10min.
                to = setTimeout(cb, 15 * 60_000)
            };
            to = setTimeout(cb, 15 * 60_000)
            return () => {
                cancelled = true
                clearTimeout(to)
            }
        }
    }, [scRefresh, sc, scError])

    console.debug({securityContext: sc, scError})

    if (sc === undefined && scError === undefined) {
        return <Spinner size={SpinnerSize.large} style={{marginTop: '30vh'}}/>
    }

    if (scError) {
        const isUnexpected = !(scError instanceof UnauthorizedError)
        if (isUnexpected) {
            console.error(scError)
        }
        return <>
            {props.visitorPage}
            {isUnexpected && <MaybeErrorMessageBar error={scError}/>}
        </>
    }

    return <ReactSecurityContext.Provider value={sc ?? DEFAULT_SC}>
        {props.children}
    </ReactSecurityContext.Provider>
}

export function useSecurityContext(): SecurityContext {
    return useContext<SecurityContext>(ReactSecurityContext)
}
