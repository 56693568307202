import {ELogo} from "./ELogo";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useScreenSize} from "../hooks/useScreenSize";

export function OverTopBarFirstLogos() {
    const navigate = useNavigate()

    const {isMobile} = useScreenSize()

    return <div
        style={{
            flexGrow: 0,
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            cursor: 'pointer',
            margin: '-3px 0',
            minHeight: 24,
        }}
        onClick={() => navigate('/')}
    >
        <ELogo/>
        <span style={{marginLeft: 10, fontSize: isMobile ? "1em" : "1.3em", lineHeight: '0.8em', fontWeight: "normal"}}>
            Cantiere Fusina
        </span>
    </div>;
}
