import {useJobContext} from "../contexts/JobContext";
import {useEffect} from "react";

export function useJob(jobId?: string, maxAgeMs: number = 60_000) {
    const {
        job,
        jobError,
        contractor,
        contractorError,
        requireJob,
    } = useJobContext()

    useEffect(() => {
        if (jobId) {
            requireJob(jobId, maxAgeMs)
                .then()
        }
    }, [jobId, maxAgeMs])

    return {
        job: jobId && job?._id === jobId ? job : undefined,
        jobError,
        contractor,
        contractorError,
    }
}
