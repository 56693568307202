import * as React from 'react'
import {useCallback, useMemo, useState} from 'react'
import {Stack} from '@fluentui/react/lib/Stack';
import {Letter} from "../../../fusina-letters/entities/Letter";
import {
    Callout,
    CompoundButton,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Text
} from "@fluentui/react";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {useSecurityContext} from "../contexts/SecurityContext";
import {RoleEnum, RoleSignatureLabels} from "../../../fusina-authz/entities/RoleEnum";
import LettersPolicy from "../../../fusina-letters/controls/LettersPolicy";
import {Job} from "../../../fusina-jobs/entities/Job";
import {useId} from '@fluentui/react-hooks';


/**
 * Call rmi.Letters.sign after confirm.
 * Policy is checked and nothing is rendered in case of missing permission.
 *
 * NOTE: message content is also checked to prevent signing of an empty letter.
 * NOTE: in case of EcoOdS returning to CDL with empty message
 *       it would display a message bar warning followed by reject to draft button,
 *       which is misleading the user to reject because the message is empty (which is expected),
 *       and this is why we need to repeat the Edit button here (inside the message bar).
 */
export const SignAndNotifyBtn: React.FC<{
    signatureRole: RoleEnum,
    notifiedRole: RoleEnum,
    notifiedRole2?: RoleEnum,
    job: Job
    letter: Letter
    onSuccess?: (letterId: string, signatureId: string) => void
    editBtn?: JSX.Element
}> = props => {

    const rmi = useFusinaRmi()
    const sc = useSecurityContext()

    const policy = useMemo(() => props.job ? LettersPolicy({
        job: props.job,
        securityContext: sc,
        oldLetter: props.letter,
        signatureRole: props.signatureRole
    }) : undefined, [sc, props.job, props.letter])

    const [pending, setPending] = useState<boolean>(false)
    const [confirming, setConfirming] = useState<boolean>(false)

    const handle = useCallback(() => {
        setPending(true)
        rmi.Letters.sign(props.letter._id, props.signatureRole)
            .then(signatureId => {
                console.debug({signatureId})
                props.onSuccess?.(props.letter._id, signatureId)
            })
            .catch(console.error) // TODO
            .finally(() => {
                setPending(false)
                setConfirming(false)
            })
    }, [rmi, policy])

    const preConfirmHandle = useCallback(() => {
        setConfirming(true)
        setPending(true)
        setTimeout(() => {
            setPending(false)
        }, 1000)
    }, [])

    const buttonId = useId('SignAndNotifyBtn-preConfirm');

    if (policy?.permissions?.sign !== true) {
        return null
    }

    if (policy?.permissions?.write === true) {
        if (props.letter?.subject?.trim?.()?.length <= 0) {
            return <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline
                actions={props.editBtn}
                styles={props.editBtn ? {root: {marginBottom: 20}} : undefined}
            >
                L'oggetto è vuoto.
            </MessageBar>
        }
        if (props.letter?.message?.trim?.()?.length <= 0) {
            return <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline
                actions={props.editBtn}
                styles={props.editBtn ? {root: {marginBottom: 20}} : undefined}
            >
                Il messaggio è vuoto.
            </MessageBar>
        }
    }

    return <>

        {policy.isSignatureVisible && <CompoundButton
            id={buttonId} primary disabled={pending}
            onClick={preConfirmHandle}
            style={{minHeight: 1, padding: '2px 8px', lineHeight: '1.2em'}}
        >
            Firma&nbsp;come&nbsp;{RoleSignatureLabels[props.signatureRole]}
            <br/>
            e&nbsp;invia&nbsp;a&nbsp;{RoleSignatureLabels[props.notifiedRole]}
        </CompoundButton> || <PrimaryButton
            id={buttonId} disabled={pending}
            onClick={preConfirmHandle}
            style={{minHeight: 1, padding: '2px 8px', lineHeight: '1.2em'}}
            text={`Conferma e invia a ${RoleSignatureLabels[props.notifiedRole]}`}
        />}

        <Callout
            hidden={!confirming}
            coverTarget
            role="dialog"
            style={{padding: '20px 24px'}}
            onDismiss={() => setConfirming(false)}
            target={'#' + buttonId}
            isBeakVisible={false}
            setInitialFocus
        >
            <Text block variant="xLarge" style={{fontWeight: 200, marginBottom: 12}}>
                Confermi?
            </Text>
            {policy.isSignatureVisible && <>
                Stai per firmare come {RoleSignatureLabels[props.signatureRole]}.
                <br/>
            </>}
            Una notifica sarà inviata
            al {RoleSignatureLabels[props.notifiedRole]}{props.notifiedRole2 && ` e al ${RoleSignatureLabels[props.notifiedRole2]}`}.
            <Stack horizontal style={{marginTop: 20}} tokens={{childrenGap: 'm'}}>
                <PrimaryButton onClick={handle} className="SignAndNotifyBtn-Confirm"
                               text="Conferma" disabled={pending}/>
                {pending && <Spinner size={SpinnerSize.small}/>}
            </Stack>
        </Callout>
    </>
}
