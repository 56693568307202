import {ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn} from "@fluentui/react";
import * as React from "react";
import {IDetailsListProps} from "@fluentui/react/lib/components/DetailsList/DetailsList.types";
import {ITPEvent} from "../../../fusina-itp/entities/ITPEvent";
import {EVENT_STATUS_LABELS_IT} from "../../../fusina-event/entities/EventProperties";

export const ITPEventsDetailsListBaseColumns: IColumn[] = [
    {
        key: 'itpEventN',
        fieldName: 'itpEventN',
        name: "N.",
        minWidth: 50,
        maxWidth: 80,
        flexGrow: 0.01,
    },
    {
        key: 'dtStart',
        fieldName: 'dtStart',
        name: "Data e ora d'incontro",
        minWidth: 165,
        maxWidth: 200,
        flexGrow: 0.05,
        onRender(item: ITPEvent) {
            try {
                const d = new Date(item.dtStart)
                return d.toLocaleDateString(undefined, {dateStyle: 'long'}) + ' '
                    + d.toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit',
                        timeZoneName: 'short',
                    })
            } catch (e) {
                return null
            }
        },
    },
    {
        key: 'location',
        fieldName: 'location',
        name: "Luogo per l'ispezione",
        minWidth: 200,
        flexGrow: 0.1,
    },
    {
        key: 'description',
        fieldName: 'description',
        name: 'Oggetto',
        minWidth: 200,
        flexGrow: 0.1,
    },
    {
        key: 'status',
        fieldName: 'status',
        name: 'Stato',
        minWidth: 90,
        flexGrow: 0.05,
        onRender(item: ITPEvent) {
            try {
                return <span style={{color: item.status === 'CANCELLED' ? 'red' : undefined}}>
                    {EVENT_STATUS_LABELS_IT[item.status] ?? '?'}
                </span>
            } catch (e) {
                return null
            }
        }
    },
]

export const ITPEventsDetailsListBase: React.FC<Partial<IDetailsListProps> & {
    items: ITPEvent[]
}> = props => {
    // TODO?: allow for optionally limited height and vertical scrolling?
    return <DetailsList
        onShouldVirtualize={() => false}
        constrainMode={ConstrainMode.horizontalConstrained}
        compact
        columns={ITPEventsDetailsListBaseColumns}
        layoutMode={DetailsListLayoutMode.justified}
        {...props}
    />
}
