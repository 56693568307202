import * as React from "react";
import {useContext, useEffect, useMemo, useRef} from "react";
import {DateRangeType, useTheme} from "@fluentui/react";
import {CalViews, MONTHS_IT, WEEK_DAY_OFFSET} from './commons'
import {MonthDateFrag} from "./MonthDateFrag";
import {MultiCalTopBar} from "./MultiCalTopBar";
import {CalContext} from "./CalContext";

const datesOfMonth: (date: Date) => Date[] = date => {
    // back to start of month
    const d = new Date(date)
    d.setDate(1)

    // find end of month + end of week (excluded)
    const stop = new Date(d)
    stop.setMonth(stop.getMonth() + 1)
    stop.setDate(1)
    while (stop.getDay() != WEEK_DAY_OFFSET) {
        stop.setDate(stop.getDate() + 1)
    }

    // find start of week
    while (d.getDay() != WEEK_DAY_OFFSET) {
        d.setDate(d.getDate() - 1)
    }

    // scan dates
    const dd: Date[] = []
    while (d.getTime() < stop.getTime() && dd.length < 31 + 6 + 6) {
        dd.push(new Date(d))
        d.setDate(d.getDate() + 1)
    }
    return dd
}

export const MonthCalView: React.FC = props => {
    const [cal, setCalState] = useContext(CalContext)

    const prev = useMemo(() => {
        const d = new Date(cal.selectedDate)
        d.setMonth(d.getMonth() - 1)
        return d
    }, [cal.selectedDate])

    const next = useMemo(() => {
        const d = new Date(cal.selectedDate)
        d.setMonth(d.getMonth() + 1)
        return d
    }, [cal.selectedDate])

    const dates = useMemo(() => datesOfMonth(cal.selectedDate), [cal.selectedDate])

    useEffect(() => {
        if (dates) {
            const minDate = new Date(dates[0])
            minDate.setHours(0, 0, 0, 0)
            const maxDate = new Date(dates[dates.length - 1])
            maxDate.setHours(24, 0, 0, -1)
            setCalState(s => ({...s, minDate, maxDate}))
        }
    }, [dates])

    const gridRef = useRef<HTMLDivElement>()
    useEffect(() => {
        if (gridRef.current) {
            const y0 = gridRef.current.offsetTop
            gridRef.current.style.height = `calc(100vh - ${y0 + 60}px)`
        }
    }, [gridRef.current])

    const theme = useTheme()

    return <>
        <MultiCalTopBar
            prev={prev}
            next={next}
            dateRangeType={DateRangeType.Month}
            title={MONTHS_IT[cal.selectedDate.getMonth()] + ' ' + cal.selectedDate.getFullYear()}
        />
        <div
            ref={gridRef}
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                gridGap: 1,
                marginTop: 24,
            }}
        >
            {dates.map((d, i) => <MonthDateFrag
                isOtherMonth={d.getMonth() !== cal.selectedDate.getMonth()}
                i={i} date={d}
                onClick={() => setCalState(s => ({...s, selectedDate: d, view: CalViews.week}))}
            />)}
        </div>
    </>
}
