import {useEffect, useState} from "react";

/** Reactively returns the provided value after the given timeout.
 * If the value changes, it returns undefined for the duration of the given timeout.
 */
export function useDelayedValue<T>(value: T | undefined, timeout: number): T | undefined {
    const [v, setV] = useState<T | undefined>(undefined)
    useEffect(() => {
        setV(undefined)
        const t = setTimeout(() => {
            setV(value)
        }, timeout)
        return () => {
            clearTimeout(t)
        }
    }, [value])
    return v
}
