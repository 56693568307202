import {EventOrganizer} from "./EventOrganizer";
import {EventAttendee} from "./EventAttendee";
import {ITPEvent} from "../../fusina-itp/entities/ITPEvent";

/** Mixin of properties of an event object */
export interface EventProperties {
    /** https://datatracker.ietf.org/doc/html/rfc5545#section-3.8.1.12 */
    summary: string

    /** https://datatracker.ietf.org/doc/html/rfc5545#section-3.8.1.5 */
    description?: string

    /** Organizer of the event
     * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.8.4.3 */
    organizer: EventOrganizer

    /** Attendees of the event
     * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.8.4.1 */
    attendees: EventAttendee[]

    /** @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.8.1.11 */
    status?: 'TENTATIVE' | 'CONFIRMED' | 'CANCELLED'

    /** When the event starts (iso format if string)
     * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.8.2.4 */
    dtStart: Date | string

    /** Duration
     * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.8.2.5
     * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.3.6
     */
    duration?: string

    /** The intended venue such as conference or meeting rooms
     * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.8.1.7 */
    location?: string
}

export const EventProperties_keys: (keyof EventProperties)[] = [
    'summary',
    'description',
    'organizer',
    'attendees',
    'status',
    'dtStart',
    'duration',
    'location',
]

export const getEventProperties: <T extends EventProperties>(evp: T) => EventProperties = evp =>
    Object.fromEntries(
        Object.entries(evp)
            .filter(([k, v]) => EventProperties_keys.includes(k as keyof EventProperties))) as EventProperties


export const EVENT_STATUS_LABELS_IT: Record<ITPEvent['status'], string> = {
    'TENTATIVE': 'Tentativo',
    'CONFIRMED': 'Confermato',
    'CANCELLED': 'Cancellato',
}
