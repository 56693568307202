import {Label, Stack, useTheme} from "@fluentui/react";
import {EventMessage} from "../../../fusina-event/entities/EventMessage";
import * as React from "react";
import {useEffect, useMemo, useRef} from "react";
import {ITPEvent} from "../../../fusina-itp/entities/ITPEvent";
import {useSecurityContext} from "../contexts/SecurityContext";
import {ITPEventAttendeeLabel} from "./ITPEventAttendeeLabel";
import {EVENT_STATUS_LABELS_IT} from "../../../fusina-event/entities/EventProperties";
import {EVENT_PART_STAT_LABEL_IT} from "../../../fusina-event/entities/EventAttendee";

export const ITPEventMessagesView: React.FC<{
    event: ITPEvent
    maxHeight: string
}> = props => {
    const ref = useRef<HTMLDivElement>()
    const sc = useSecurityContext()
    const me = useMemo(() =>
        props.event.organizer.email === sc.user.email ? 'organizer' : 'attendee', [sc, props.event])

    const theme = useTheme()

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTo({
                top: ref.current.scrollHeight
            })
        }
    }, [ref.current, props.event.messages])

    return <div style={{
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'stretch',
        // border: '1px solid ' + theme.semanticColors.inputBorder,
        // borderRadius: 3,
    }}>
        <div ref={ref} style={{
            maxHeight: props.maxHeight,
            minWidth: 250,
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: 8,
            flexGrow: 1,
            flexShrink: 1,
            display: 'flex',
            flexFlow: 'column',
            rowGap: 8,
        }}>
            {props.event.messages.map(msg =>
                <EventMessageRow msg={msg} me={me}/>)}
        </div>
    </div>
}

const EventMessageRow: React.FC<{
    msg: EventMessage
    me: 'organizer' | 'attendee'
}> = props => {
    const theme = useTheme()
    const side = msg2side(props.msg, props.me)
    const bg = side === 'out' ? theme.palette.themeLighter : theme.palette.neutralLighter
    const ts = useMemo<string>(() => {
        const d = new Date(props.msg.dtStamp);
        return [
            d.toLocaleDateString(undefined, {dateStyle: "short"}),
            d.toLocaleTimeString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
            }),
        ].join(" ");
    }, [props.msg.dtStamp])

    return <div style={{
        display: 'flex',
        flexFlow: side === 'out' ? 'row-reverse' : 'row',
        justifyContent: 'flex-start',
        position: 'relative',
    }}>
        <div style={{
            width: 12,
            height: 12,
            margin: '0 -6px',
            position: 'relative',
            zIndex: 1,
            overflow: 'hidden'
        }}>
            <div style={{
                width: 12,
                height: 12,
                background: bg,
                transform: 'translateY(-8px) rotate(45deg)',
                position: 'relative',
                zIndex: 1,
            }}>
                &nbsp;
            </div>
        </div>
        <div style={{
            flexGrow: 1,
            background: bg,
            padding: '4px 8px 2px',
            borderRadius: '6px',
            position: 'relative',
            zIndex: 2,
            maxWidth: 'max(350px, 75%)',
        }}>
            <Stack>
                <Label style={{
                    fontSize: theme.fonts.small.fontSize,
                    fontWeight: 600,
                    margin: 0,
                    padding: 0,
                }}>
                    {props.msg.method === 'REPLY' && <>
                        {ITPEventAttendeeLabel(props.msg.attendee)}
                    </>}
                    {props.msg.method === 'REQUEST' && 'Organizzatore'}
                    {props.msg.method === 'PUBLISH' && 'Organizzatore'}
                </Label>

                <div>
                    {props.msg.method === 'REPLY' && EVENT_PART_STAT_LABEL_IT[props.msg.attendee?.partStat]}
                    {props.msg.method === 'REPLY' && props.msg.attendee.waiver && <>
                        &nbsp;con rinuncia alla presenza (waiver)
                    </>}
                    {props.msg.method === 'REQUEST' && EVENT_STATUS_LABELS_IT[props.msg.status]}
                    {props.msg.method === 'PUBLISH' && EVENT_STATUS_LABELS_IT[props.msg.status]}
                </div>

                {props.msg.comment && <div style={{
                    margin: '4px 0',
                    color: theme.semanticColors.messageText,
                    fontStyle: 'italic',
                    maxWidth: 350,
                    whiteSpace: 'pre-wrap',
                }}>
                    {props.msg.comment}
                </div>}

                <div style={{
                    fontSize: '0.66em',
                    textAlign: 'right',
                    color: theme.semanticColors.disabledBodyText,
                    marginTop: 4,
                }}>
                    {ts}
                </div>
            </Stack>
        </div>
        <div style={{
            width: 16,
            overflow: 'hidden',
        }}>
            &nbsp;
        </div>
    </div>
}

const msg2side: (msg: Pick<EventMessage, 'method'>, me: 'organizer' | 'attendee') => ('in' | 'out') = (msg, me) => {
    if (msg.method === 'REQUEST') {
        return me === 'organizer' ? 'out' : 'in'
    }
    if (msg.method === 'REPLY') {
        return me === 'attendee' ? 'out' : 'in'
    }
    if (msg.method === 'PUBLISH') {
        return me === 'organizer' ? 'out' : 'in'
    }
    const []: never[] = [msg.method]  // static completeness check
    return 'in'
}
