import * as React from 'react'

import {AdminJobs} from "./AdminJobs";
import {Navigate, Route, Routes} from "react-router-dom";
import {AdminCompanies} from "./AdminCompanies";
import {PageSetting} from "../contexts/PageContext";
import {AdminUsers} from "./AdminUsers";
import {AdminAudit} from "./AdminAudit";
import {AdminAuditGraphs} from "./AdminAuditGraphs";
import {AdminAuditSessions} from "./AdminAuditSessions";


export const AdminHome: React.FC = () => {
    return <>
        <PageSetting
            title='Admin anagrafiche'
            iconName='Admin'
            sideChildren={null}
            pageBackground={null}
            hideNavLinks={false}
        />
        <Routes>
            <Route path="companies" element={<AdminCompanies/>}/>
            <Route path="jobs" element={<AdminJobs/>}/>
            <Route path="users" element={<AdminUsers/>}/>
            <Route path="audit" element={<AdminAudit/>}/>
            <Route path="auditGraphs" element={<AdminAuditGraphs/>}/>
            <Route path="auditSessions" element={<AdminAuditSessions/>}/>
            <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
    </>
}
