import * as React from 'react'
import {PropsWithChildren, useState} from 'react'
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {EcoOdS} from "../../../fusina-letters/entities/EcoOdS";
import {
    DefaultButton,
    mergeStyles,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    useTheme
} from "@fluentui/react";
import {useFusinaRmi} from "../hooks/useFusinaRmi";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";

export const EcoOdsExpensesMeta: React.FC<{
    letter: EcoOdS
}> = props => {
    return <>
        <div style={{margin: 24, width: 'max-content', minWidth: 150}}>
            <ClosedFlag letter={props.letter}/>
        </div>
    </>
}

const Card: React.FC<PropsWithChildren<{}>> = props => {
    const theme = useTheme()
    const stylePar = mergeStyles({
        marginBottom: theme.spacing.l1,
        display: 'flex',
        flexFlow: 'row',
    })
    const styleEl = mergeStyles({
        padding: theme.spacing.l1,
        borderRadius: 2,
        boxShadow: '0 1.6px 3.6px 0 rgba(0,0,0,.132),0 .3px .9px 0 rgba(0,0,0,.108)',
    })

    return <div className={stylePar}>
        <div className={styleEl}>
            {props.children}
        </div>
    </div>
}


const ClosedFlag: React.FC<{
    letter: EcoOdS
}> = props => {
    const {refresh} = useFusinaRmiResource('Letters', 'findById', props?.letter?._id)
    const rmi = useFusinaRmi()
    const meta = props?.letter?.expensesMeta ?? {}
    const [error, setError] = useState<Error | undefined>(undefined)
    const [isPending, setIsPending] = useState<boolean>(false)
    const theme = useTheme()

    if (error) {
        return <MaybeErrorMessageBar error={error} onDismiss={() => setError(undefined)}/>
    }

    if (isPending) {
        return <Spinner size={SpinnerSize.large}/>
    }

    if (!meta.closed) {
        return <>
            <Stack tokens={{childrenGap: 'm'}}>
                <Text variant="smallPlus" style={{color: theme.semanticColors.bodySubtext}}>
                    L'inserimento del consuntivo è aperto.
                </Text>
                <Stack.Item>
                    <DefaultButton
                        text="Marca come completato"
                        onClick={async () => {
                            setIsPending(true)
                            try {
                                await rmi.EcoOds.writeExpensesMeta(props.letter._id, {...meta, closed: true})
                                // Little delay to give a visual feedback of the operation
                                await new Promise(resolve => setTimeout(resolve, 400))
                                await refresh()
                            } catch (e) {
                                setError(e)
                            } finally {
                                setIsPending(false)
                            }
                        }}
                    />
                </Stack.Item>
            </Stack>
        </>
    }

    if (meta.closed) {
        return <>
            <MessageBar
                messageBarType={MessageBarType.success}
                actions={<>
                    <MessageBarButton
                        text="Riapri"
                        onClick={async () => {
                            setIsPending(true)
                            try {
                                await rmi.EcoOds.writeExpensesMeta(props.letter._id, {...meta, closed: false})
                                // Little delay to give a visual feedback of the operation
                                await new Promise(resolve => setTimeout(resolve, 400))
                                await refresh()
                            } catch (e) {
                                setError(e)
                            } finally {
                                setIsPending(false)
                            }
                        }}
                    />
                </>}
            >
                L'inserimento del consuntivo è marcato come completato.
            </MessageBar>
        </>
    }
}
